import isFunction from 'lodash/isFunction'
import set from 'lodash/set'
import React from 'react'

const ApiContext = React.createContext()

const nexus = {}

const ApiContextProvider = (props) => {
  const { children, value } = props

  set(nexus, 'getCurrentUser', value?.AccountApi?.getCurrentUser)
  set(nexus, 'getActiveOrder', value?.OrderApi?.getActiveOrder)
  return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}

const getCurrentUser = () => {
  if (isFunction(nexus.getCurrentUser)) {
    return nexus.getCurrentUser()
  }
}

const getActiveOrder = () => {
  if (isFunction(nexus.getActiveOrder)) {
    return nexus.getActiveOrder()
  }
}

ApiContextProvider.displayName = 'ApiContextProvider'

export { ApiContextProvider, ApiContext, getActiveOrder, getCurrentUser }
