import './slider.css'

import {
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderThumb,
  RangeSliderTrack
} from '@chakra-ui/react'
import first from 'lodash/first'
import last from 'lodash/last'
import React from 'react'

import { Box, Flex } from '../layout'
import { Picto } from '../media'

const RANGE_SCALE = 6

const Slider = (props) => {
  const { range, currency = '€', handleChange, minValue, maxValue, setMin, setMax, color } = props

  const SliderPicto = () => {
    return <Picto width="12px" height="12px" icon="chevronDown" color="#6c6c6c" />
  }

  const ValueBox = ({ index }) => (
    <Box
      w="12%"
      className="slider-label"
      key={index}
      color={
        minValue == range[index]
          ? '#FF7A00'
          : maxValue == range[index]
          ? '#E70081'
          : index == 0
          ? '#14CA82'
          : index == 5
          ? color
          : '#999'
      }
      fontWeight={minValue || maxValue != range[index] ? 'bold' : 'normal'}>
      {range[index]}
    </Box>
  )

  const handleRange = (values) => {
    setMin(first(values))
    setMax(last(values))
    handleChange(values)
  }

  const span = []
  for (let i = 0; i < RANGE_SCALE; i++) {
    span.push(<span key={i} className="slider-pipe"></span>)
  }

  const valueBox = []
  for (let i = 0; i < RANGE_SCALE; i++) {
    valueBox.push(<ValueBox key={i} index={i} />)
  }

  return (
    <Flex flexDirection="column" alignItems="center" w="100%">
      <Flex w="85%">
        <RangeSlider
          aria-label={['min', 'max']}
          defaultValue={[minValue, maxValue]}
          minStepsBetweenThumbs="1"
          onChange={(val) => handleRange(val)}
          min={first(range)}
          max={last(range)}>
          <RangeSliderTrack bg="#dfdfdf" className="slider-track" h="0.6em">
            <RangeSliderFilledTrack bg={color} />
          </RangeSliderTrack>
          <RangeSliderThumb boxSize={6} index={0}>
            <Box position="absolute" bottom="25px" fontSize="14px" color={color} fontWeight="700">
              {minValue}
              {currency}
            </Box>
            <Box as={SliderPicto} />
          </RangeSliderThumb>
          <RangeSliderThumb boxSize={6} index={1}>
            <Box position="absolute" bottom="25px" fontSize="14px" color={color} fontWeight="700">
              {maxValue}
              {currency}
            </Box>
            <Box as={SliderPicto} />
          </RangeSliderThumb>
        </RangeSlider>
      </Flex>
      <Flex w="100%" flexDirection="column" alignItems="center">
        <Flex w="85%" justifyContent="space-between" alignItems="center">
          {span}
        </Flex>
        <Flex w="96%" justifyContent="space-between" alignItems="center" textAlign="center">
          {valueBox}
        </Flex>
      </Flex>
    </Flex>
  )
}

export { Slider }
