import './defaultLayout.css'

import { Container, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import PropTypes from 'prop-types'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { WebsiteContext } from '../context'
import Breadcrumb from '../navigation/Breadcrumb'
import { Dimension } from '../utils'

const DefaultLayout = (props) => {
  const {
    children,
    website,
    breadCrumbData = [],
    isCheckout,
    isBlurred = false,
    isProduct,
    headerData,
    headerComponent = () => {},
    footerData,
    footerComponent = () => {},
    headerFixedThreshold = 768,
    footerFixedThreshold = 0,
    backgroundColor = 'white',
    backgroundImage,
    contentBackgroundColor = '#ffffff',
    contentBackgroundImage,
    headerBackgroundColor,
    headerBackgroundImage,
    footerBackgroundColor,
    footerBackgroundImage,
    maxWidth = '100vw',
    headerMaxWidth,
    contentMaxWidth,
    footerMaxWidth,
    scrollToTop,
    noBreadcrumb = false,
    maintenance = false
  } = props
  const windowGlobal = typeof window !== 'undefined' && window
  const [headerFixed, setHeaderFixed] = useState(false)
  const [footerFixed, setFooterFixed] = useState(false)

  const [footerHeight, setFooterHeight] = useState()
  const [headerHeight, setHeaderHeight] = useState()
  const headerRef = useRef()
  const footerRef = useRef()

  const setDimensions = () => {
    setHeaderHeight(headerRef?.current?.clientHeight)
    setFooterHeight(footerRef?.current?.clientHeight)
  }

  const websiteContext = useContext(WebsiteContext)

  const toggleFixed = () => {
    const { innerWidth: width } = window
    const headerMin = Dimension.getSize(headerFixedThreshold)
    const footerMin = Dimension.getSize(footerFixedThreshold)
    setHeaderFixed(width < headerMin)
    setFooterFixed(width < footerMin)
  }

  useEffect(() => {
    setDimensions()
  })

  useEffect(() => {
    toggleFixed()
    windowGlobal?.addEventListener('resize', toggleFixed)
    return () => {
      windowGlobal?.removeEventListener('resize', toggleFixed)
    }
  }, [headerFixedThreshold, footerFixedThreshold])

  return (
    <Flex
      direction="column"
      minH="100vh"
      maxW="100vw"
      backgroundImage={backgroundImage}
      backgroundColor={backgroundColor}>
      <Container
        ref={headerRef}
        backgroundColor={headerBackgroundColor}
        backgroundImage={headerBackgroundImage}
        maxW={headerMaxWidth || '100vw'}
        position={headerFixed ? 'sticky' : 'static'}
        top="0px"
        m="0"
        p="0"
        zIndex={1000}>
        {headerComponent({ data: headerData })}
      </Container>
      <Container
        display="flex"
        flexDirection="column"
        mb={footerFixed ? footerHeight : 0}
        p={0}
        maxW={contentMaxWidth || maxWidth}
        flexGrow="1"
        alignItems="flex-start"
        backgroundColor={contentBackgroundColor}
        backgroundImage={contentBackgroundImage}
        position="relative"
        zIndex={1}
        filter={isBlurred ? 'blur(10px)' : 'none'}>
        <Flex
          className="slogan-wrapper"
          display={{ base: maintenance ? 'none' : 'flex', md: 'none' }}
          p="2px 0"
          align="center"
          justify="center"
          margin="auto"
          order="-2">
          {website?.subtitle1 && !isCheckout && (
            <Text color={websiteContext.mainColor} fontWeight={700} fontSize="12px" as="span">
              {website?.subtitle1}
              {website?.subtitle2 && (
                <Text color="rgb(51,51,51)" fontWeight={400} fontSize="12px" as="span">
                  {` ${website?.subtitle2}`}
                </Text>
              )}
            </Text>
          )}
        </Flex>
        {children}
        {!noBreadcrumb && breadCrumbData?.length > 1 && (
          <Flex maxWidth="1000px" className="breadcrumb-wrapper" w="100%" margin="auto" order="-1">
            <Breadcrumb data={breadCrumbData} truncated={isProduct} />
          </Flex>
        )}
      </Container>
      <Container
        ref={footerRef}
        backgroundColor={footerBackgroundColor}
        backgroundImage={footerBackgroundImage}
        maxW={footerMaxWidth || maxWidth}
        position={footerFixed ? 'fixed' : 'static'}
        bottom="0"
        padding="0"
        zIndex={0}>
        {footerComponent({ data: footerData })}
      </Container>
      {scrollToTop}
    </Flex>
  )
}

DefaultLayout.propTypes = {
  headerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  headerComponent: PropTypes.elementType,
  footerData: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  footerComponent: PropTypes.elementType,
  headerFixedThreshold: PropTypes.oneOfType([
    PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    PropTypes.number
  ]),
  footerFixedThreshold: PropTypes.oneOfType([
    PropTypes.oneOf(['sm', 'md', 'lg', 'xl']),
    PropTypes.number
  ]),
  backgroundColor: PropTypes.string,
  backgroundImage: PropTypes.string,
  contentBackgroundColor: PropTypes.string,
  contentBackgroundImage: PropTypes.string,
  headerBackgroundColor: PropTypes.string,
  headerBackgroundImage: PropTypes.string,
  footerBackgroundColor: PropTypes.string,
  footerBackgroundImage: PropTypes.string,
  p: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  px: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  py: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pt: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pb: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pl: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pr: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  headerMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  contentMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  footerMaxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

DefaultLayout.defaultProps = {
  headerComponent: () => {},
  footerComponent: () => {},
  headerFixedThreshold: 768,
  footerFixedThreshold: 0,
  backgroundColor: 'white',
  maxWidth: '100vw'
}

export default DefaultLayout
