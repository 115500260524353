import { useEffect, useState } from 'react'

const useIsMobile = () => {
  const windowGlobal = typeof window !== 'undefined' && window
  const [width, setWidth] = useState(windowGlobal?.innerWidth)

  const handleWindowSizeChange = () => {
    setWidth(windowGlobal?.innerWidth)
  }

  useEffect(() => {
    windowGlobal?.addEventListener('resize', handleWindowSizeChange)
    return () => {
      windowGlobal?.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])

  return width <= 768
}

export default useIsMobile
