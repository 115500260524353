import { Box, Flex, Picto, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { priceFormat } from '../utils'

const OrderConfirmationMessage = (props) => {
  const { amount, orderId } = props

  return (
    <Box align="center">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        align="center"
        justify="center"
        mt="20px"
        w="100%">
        <Picto
          icon="checkCercle"
          color="#39a132"
          width="24px"
          height="24px"
          style={{ marginRight: '10px' }}
        />
        <Text
          as="h1"
          style={{
            fontSize: '24px',
            fontWeight: 700,
            color: '#000000'
          }}>
          Merci pour votre confiance !
        </Text>
      </Flex>
      <Box mt="10px" w="100%">
        <Text fontSize="16px">{`Votre commande n° ${orderId}`}</Text>
        <Text fontSize="16px">
          de{' '}
          <Text as="span" fontWeight="900">
            {priceFormat(amount, 'EUR')}
          </Text>{' '}
          a bien été enregistrée.
        </Text>
        {/* {`Votre commande n° ${orderId} de `}<Text as="span" fontWeight="900">{priceFormat(amount, 'EUR')}</Text>{` a bien été enregistrée`} */}
      </Box>
    </Box>
  )
}
export default OrderConfirmationMessage
