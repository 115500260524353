import React from 'react'

const PaymentContext = React.createContext()

const PaymentContextProvider = (props) => {
  const { children, value } = props

  return <PaymentContext.Provider value={value}>{children}</PaymentContext.Provider>
}

PaymentContextProvider.displayName = 'PaymentContextProvider'

export { PaymentContextProvider, PaymentContext }
