import { Flex, Link, Svg } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
const Service = (props) => {
  const { title, hook, icon, link } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex
      direction="column"
      justifyContent="space-between"
      alignItems="center"
      w={{ base: '100%', md: '270px' }}
      style={{
        border: '1px solid rgb(192,192,192)',
        height: '180px',
        paddingTop: '40px',
        paddingBottom: '10px',
        background: 'linear-gradient(to bottom right,#eef4ff 24%,transparent 0)',
        backgroundSize: '61% 100%',
        backgroundRepeat: 'no-repeat',
        position: 'relative'
      }}>
      <Flex position="absolute" top="5px" left="5px" >
      <Svg data={icon} color={websiteContext?.mainColor} width="34px" height="34px" />
      </Flex>
      <span
        style={{
          fontSize: '20px',
          lineHeight: '20px',
          maxWidth: '170px',
          fontWeight: 700,
          color: 'rgb(51,51,51)',
          textAlign: 'center',
          minHeight: '40px'
        }}>
        {title}
      </span>
      <span
        style={{
          fontSize: '14px',
          lineHeight: '17px',
          color: 'rgb(51,51,51)',
          textAlign: 'center',
          maxWidth: '230px',
          minHeight: '34px'
        }}>
        {hook}
      </span>
      <Link
        to={link}
        style={{
          width: '160px',
          height: '35px',
          lineHeight: '35px',
          textAlign: 'center',
          border: '1px solid rgb(192,192,192)'
        }}
        onClick={() =>
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Pages produit',
            eventAct: 'Départ bloc service',
            eventLab: link
          })
        }>
        EN SAVOIR PLUS
      </Link>
    </Flex>
  )
}

export { Service }
