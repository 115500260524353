import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import FaqQuestion from './FaqQuestion'

const FaqCategory = (props) => {
  const { faqCategory, selected } = props
  return (
    <Flex flexDirection={{ base: 'column', md: 'row' }}>
      <Text
        as="h2"
        width={{ base: '100%', md: '130px' }}
        color="#333"
        fontWeight={700}
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        fontSize={{ base: '24px', md: '26px' }}
        lineHeight={{ base: '24px', md: '26px' }}
        mr={{ base: 0, md: '20px' }}
        mb={{ base: '20px', md: 0 }}
        mt={{ base: '47px', md: 0 }}>
        {faqCategory?.title}
      </Text>
      <Flex
        direction="column"
        width={{ base: '100%', md: '770px' }}
        pb="40px"
        borderLeft={{ base: 'none', md: '1px solid #d3d3d3' }}
        pl={{ base: 0, md: '20px' }}>
        {faqCategory?.faq_questions?.map((faqQuestion) => (
          <FaqQuestion
            key={'faqQuestion_' + faqQuestion?.id}
            faqQuestion={faqQuestion}
            selected={selected}
          />
        ))}
      </Flex>
    </Flex>
  )
}

export default FaqCategory
