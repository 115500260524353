import { ChevronRightIcon } from '@chakra-ui/icons'
import { Box, Flex, Stack, Text } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { memo, useContext } from 'react'
const NextSteps = () => {
  return (
    <Flex direction="column" mt="50px" borderBottom="1px solid #e5e6e8" pb="20px" mb="20px">
      <Text fontWeight="800" fontSize="16px" textAlign="center" mb="10px">
        Prochaines étapes :
      </Text>
      <Stack
        direction={['column', 'row']}
        divider={
          <ChevronRightIcon
            display={['none', 'flex']}
            color={'#4f4f4f'}
            h={10}
            w={10}
            style={{ border: 0 }}
          />
        }
        spacing={4}>
        <StepsItem step="1" title="Vous recevrez par e-mail une confirmation de commande." />
        <StepsItem
          step="2"
          title="Vous serez averti par e-mail à l'expédition de votre commande."
        />
        <StepsItem step="3" title="Vous serez débité à l'expédition." />
      </Stack>
    </Flex>
  )
}

const StepsItem = (props) => {
  const { step, title } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex py="12px" justifyContent="start" align="center">
      <Box borderRadius="50%" bg={websiteContext?.mainColor} w="40px" h="40px" mx="15px" pt="7px">
        <Text textAlign="center" fontWeight="600" color="#fff">
          {step}
        </Text>
      </Box>
      <Text maxW="230px" fontSize="16px">
        {title}
      </Text>
    </Flex>
  )
}

export default memo(NextSteps)
