import { ListItem, Text, UnorderedList } from '@stars-ecom/shared-atoms-ui'
import isNil from 'lodash/isNil'
import React, { memo, useContext, useEffect, useMemo } from 'react'

import { DataLayerUtils, WebsiteContext } from '../../context'
import { priceFormat } from '../../utils'
import PopoverInfo from '../PopoverInfo'

const _PopoverMultiPayment = (props) => {
  const { price, currency = 'EUR', product, multipaymentData = [] } = props
  const websiteContext = useContext(WebsiteContext)

  const getMultipayment = useMemo(() =>
    /*params = [
        { max: 7000, count: 2 },
        { min: 7000, max: 12000, count: 3 },
        { min: 12000, count: 4 }
      ]*/
    {
      console.log('multipaymentData', multipaymentData)
      for (const p of multipaymentData) {
        if ((isNil(p.min) || price >= p.min) && (isNil(p.max) || price < p.max)) {
          return [p.count, price / p.count, p.applicationFee]
        }
      }
      return null
    }, [price, multipaymentData])
  useEffect(() => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Pages produit',
      eventAct: 'Ouverture popin facilité paiement',
      eventLab: product?.customFields?.externalCode
    })
  }, [])
  if (isNil(getMultipayment)) {
    return null
  }
  return (
    <PopoverInfo
      labelButton={`Ou ${getMultipayment[0]}x ${priceFormat(getMultipayment[1], currency)}`}
      fontSize="14px"
      color={websiteContext?.mainColor}>
      <Text
        style={{
          fontSize: '13px',
          color: '#242a30',
          fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif',
          paddingRight: '15px'
        }}>
        Pour tout paiement par carte bancaire, vous pouvez régler votre commande en plusieurs fois
        (paiement fractionné) :
      </Text>
      <UnorderedList
        className="bullet"
        style={{
          fontSize: '13px',
          color: '#242a30',
          fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif'
        }}>
        {multipaymentData?.map((item, i) => (
          <ListItem key={`item_${i}`} _before={{ color: websiteContext?.mainColor }}>
            En{' '}
            <strong>
              <span style={{ color: websiteContext?.mainColor, fontSize: '16px' }}>
                {item.count}x
              </span>{' '}
              dès {priceFormat(item.min, 'EUR')}
            </strong>{' '}
            d&acute;achat,
          </ListItem>
        ))}
      </UnorderedList>
      <Text
        style={{
          marginTop: '5px',
          width: '100%',
          fontSize: '12px',
          color: '#242a30',
          fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif',
          backgroundColor: '#eef4ff',
          padding: '10px',
          textAlign: 'justify'
        }}>
        {`${
          getMultipayment[2]
            ? `Ce service vous sera facturé ${priceFormat(
                getMultipayment[2],
                'EUR'
              )} pour participation aux frais de dossiers et
        traitement. `
            : ''
        }Le paiement des mensualités s'organise comme suit : sur la 1ère
        mensualité, nous prélevons l'intégralité des frais de port de votre commande,
        les frais de dossier ainsi que le montant des articles expédiés divisé par le nombre
        de mensualités choisies. L'échéancier mensuel débute à la date
        d'expédition de chaque colis, le nombre d'échéances étant défini en
        fonction de la durée proposée.`}
      </Text>
    </PopoverInfo>
  )
}

const PopoverMultiPayment = memo(_PopoverMultiPayment)

export { PopoverMultiPayment }
