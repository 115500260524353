import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Text
} from '@stars-ecom/shared-atoms-ui'
import React, { useContext, useState } from 'react'

import { ApiContext, DataLayerUtils, WebsiteContext } from '../context'

const NewsletterForm = () => {
  const websiteContext = useContext(WebsiteContext)
  const [email, setEmail] = useState()
  const apiContext = useContext(ApiContext)
  const [errorValidation, setErrorValidation] = useState(false)
  const [alert, setAlert] = useState({ active: false })
  const checkValidation = () => {
    // don't remember from where i copied this code, but this works.
    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    setAlert({ active: false })
    setErrorValidation(!re.test(email))
    return !re.test(email)
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    DataLayerUtils.addEvent({
      event: 'qwampEvent',
      eventCat: 'Newsletter',
      eventAct: 'Inscription'
    })
    try {
      const errorEmail = checkValidation()
      if (!errorEmail) {
        const result = await apiContext?.MarketingApi?.addNewSubscriber(email)
        if (result?.codsoc) {
          setAlert({
            active: true,
            state: 'success',
            message: 'Votre adresse e-mail a été correctement inscrite à notre Newsletter.'
          })
        } else {
          setAlert({
            active: true,
            state: 'error',
            message: 'Votre adresse e-mail est déjà inscrite à notre Newsletter.'
          })
        }
      }
    } catch (e) {
      setAlert({
        active: true,
        state: 'error',
        message: `Erreur lors de l'inscription à la Newsletter.`
      })
    }
  }
  return (
    <Box className="newsletter-input" w={['auto', 'auto', 'auto', '45%']}>
      <form onSubmit={handleSubmit}>
        <InputGroup display={['block', 'block', 'flex', 'flex']}>
          <Input
            placeholder="Votre adresse email"
            type="email"
            bg="#fff"
            onChange={(e) => setEmail(e.target.value)}
            onBlur={checkValidation}
            border="1px solid"
            _placeholder={{ color: errorValidation ? '#d60000' : '#959292' }}
            borderColor={errorValidation ? '#d60000' : '#c1c1c1'}
          />
          <InputRightElement width="auto" position={['initial', 'initial', 'absolute', 'absolute']}>
            <Button
              w={['100%', '100%', 'auto', 'auto']}
              mt={['24px', '24px', '0', '0']}
              pl="25px"
              pr="25px"
              fontFamily="PT Sans, Arial, sans-serif"
              type="submit"
              bg={{ base: websiteContext?.darkColor, md: websiteContext?.mainColor }}
              color="#fff"
              fontWeight="normal"
              _hover={{
                backgroundColor: 'white',
                color: websiteContext?.mainColor,
                borderColor: websiteContext?.lightColor,
                border: '1px solid'
              }}
              borderBottomLeftRadius="0"
              borderTopLeftRadius="0"
              borderBottomRightRadius={['0', '0', '6px', '6px']}
              borderTopRightRadius={['0', '0', '6px', '6px']}>{`Je m'inscris`}</Button>
          </InputRightElement>
        </InputGroup>
        {errorValidation && (
          <Text style={{ color: '#d60000', fontSize: '14px' }} mt={{ base: '15px', md: '0px' }}>
            Veuillez entrer un email valide.
          </Text>
        )}
        {alert?.active && (
          <Text
            mt={{ base: '15px', md: '0px' }}
            style={{
              color: alert?.state === 'success' ? '#30ac29' : '#d60000',
              fontSize: '14px',
              fontFamily: websiteContext?.fontFamily
            }}>
            {alert?.message}
          </Text>
        )}
      </form>
    </Box>
  )
}

export default NewsletterForm
