import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

const ProductTag = (props) => {
  const { color = '007dc7', label = '', fontSize = '19px', height = '25px' } = props
  return (
    <Flex
      style={{
        backgroundColor: color,
        borderRadius: '4px',
        paddingLeft: '12px',
        paddingRight: '12px',
        float: 'left'
      }}
      justify="center"
      align="center"
      overflow="hidden">
      <Text
        as="span"
        style={{
          lineHeight: height,
          fontSize: fontSize,
          color: '#ffffff',
          fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif'
        }}>
        {label}
      </Text>
    </Flex>
  )
}

export default memo(ProductTag)
