import { Flex, Link, Picto, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const BroadcastButton = (props) => {
  const { label = 'Nos diffusions TV', link = '/diffusions' } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Link
      id="broadcastButton"
      to={link}
      style={{
        border: 'none',
        boxShadow: 'none',
        display: 'flex ',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: 'auto'
      }}
      onClick={DataLayerUtils.addEvent({
        event: 'qwampEvent',
        eventCat: 'Header',
        eventAct: 'Clic diffusion'
      })}>
      <Flex
        sx={{
          '#broadcastButton:hover svg path': {
            fill: websiteContext?.mainColor
          }
        }}
        direction="column"
        alignSelf={{ base: 'flex-start', md: 'initial' }}
        align="center">
        {!websiteContext?.isMobile && (
          <Picto icon="diffusion" width="32px" height="32px" color="#333" />
        )}
        <Text
          mt={{ md: '4px' }}
          lineHeight="1"
          fontSize={{ base: '14px', md: '12px' }}
          fontWeight="normal">
          {label}
        </Text>
      </Flex>
    </Link>
  )
}

export default BroadcastButton
