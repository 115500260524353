import {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper
} from '@chakra-ui/react'
import React from 'react'

import { Button, Input } from '../forms'
import { useNumberInput } from '../hooks'
import { Flex } from '../layout'
import { ChakraIcons } from '../media'

function QantityInput(props) {
  const { value = 0, onChange = () => {}, isLoading = false } = props
  const { getInputProps, getIncrementButtonProps, getDecrementButtonProps } = useNumberInput({
    onChange,
    step: 1,
    value,
    defaultValue: value,
    min: 1,
    max: 100
  })

  const inc = getIncrementButtonProps()
  const dec = getDecrementButtonProps()
  const input = getInputProps({ isReadOnly: true })

  return (
    <Flex direction="column">
      <Button variant="link" {...inc} isDisabled={isLoading}>
        <ChakraIcons.TriangleUpIcon boxSize="10px" color="#333" />
      </Button>
      <Input w="45px" paddingX="4px" textAlign="center" {...input} isReadOnly={isLoading} />
      <Button variant="link" {...dec} isDisabled={isLoading}>
        <ChakraIcons.TriangleDownIcon boxSize="10px" color="#333" />
      </Button>
    </Flex>
  )
}

export {
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  QantityInput
}
