import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect /*, useRef, useState*/ } from 'react'

import { WebsiteContext } from '../context'
import FaqCategory from './FaqCategory'

const FaqLayout = (props) => {
  const { faq, selected /*, onClickOutside*/ } = props
  const websiteContext = useContext(WebsiteContext)
  const windowGlobal = typeof window !== 'undefined' && window

  // const faqRef = useRef()

  // useEffect(() => {
  //   const handleClickOutsideFaq = (event) => {
  //     if (faqRef.current && !faqRef.current.contains(event.target)) {
  //         onClickOutside && onClickOutside()
  //         if (windowGlobal?.location?.hash) {
  //           goToAnchorFaq(windowGlobal?.location?.hash)
  //         }
  //     }
  //   }
  //   document.addEventListener('click', handleClickOutsideFaq, true)
  //   return () => {
  //     document.removeEventListener('click', handleClickOutsideFaq, true)
  //   }
  // }, [onClickOutside])

  const goToAnchorFaq = (hash) => {
    setTimeout(() => {
      let anchorId = hash?.replace('#', '')
      let top = document.getElementById(anchorId)?.offsetTop || 0
      top = websiteContext?.isMobile ? top : top + 210
      windowGlobal?.scrollTo({ top, behavior: 'smooth' })
    }, 150)
  }

  useEffect(() => {
    if (selected) {
      goToAnchorFaq(selected)
    }
  }, [selected])

  return (
    <Flex
      // ref={faqRef}
      flexDirection="column"
      width="100%"
      maxWidth="1000px"
      pb="50px"
      pl={{ base: '15px', md: 0 }}
      pr={{ base: '15px', md: 0 }}>
      <Text
        as="h1"
        width="100%"
        pt={{ base: 0, md: '60px' }}
        pb={{ base: 0, md: '50px' }}
        mr={{ base: 0, md: '20px' }}
        color="#333"
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        fontWeight={700}
        fontSize={{ base: '35px', md: '60px' }}
        lineHeight={{ base: '35px', md: '60px' }}>
        {faq?.title}
      </Text>
      <Flex
        direction="column"
        backgroundColor={{ base: 'transparent', md: '#ffffff' }}
        pt={{ base: 0, md: '40px' }}
        pb={{ base: 0, md: '40px' }}
        pl={{ base: 0, md: '35px' }}
        pr={{ base: 0, md: '35px' }}>
        {faq?.faq_categories
          ?.filter((c) => !isEmpty(c.faq_questions))
          ?.map((faqCategory) => (
            <FaqCategory
              key={`faq_category_${faqCategory?.id}`}
              faqCategory={faqCategory}
              selected={selected}
            />
          ))}
      </Flex>
    </Flex>
  )
}

export default FaqLayout
