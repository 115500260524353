import { Box } from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import React from 'react'

import { DataLayerUtils } from '../context'
const VideoThumbnail = (props) => {
  const { video, updateMainVideo, mainVideo } = props
  dayjs.extend(duration)
  const durations = dayjs.duration(video?.playingInfos?.duration, 'seconds')
  return (
    <Box
      onClick={() => {
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Header',
          eventAct: 'Lecture vidéo',
          eventLab: video.title
        })
        updateMainVideo(video)
      }}>
      <Box className="top">
        <img src={video?.images?.thumbnail?.src[0]?.url} alt={video.title} />
        <Box className="over">
          {mainVideo && <Box className="active">Lecture en cours...</Box>}
          {!mainVideo && <Box className="inactive"></Box>}
        </Box>
      </Box>
      <Box className="bottom">
        <Box className="duration" fontFamily="PT Sans, Arial, Sans-serif">
          <strong>REPLAY </strong>
          {durations.format('HH:mm:ss')}
        </Box>
        <Box className="emission-title" fontFamily="PT Sans, Arial, Sans-serif">
          {video.title}
        </Box>
      </Box>
    </Box>
  )
}

export default VideoThumbnail
