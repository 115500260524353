import dayjs from 'dayjs'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import isString from 'lodash/isString'
import set from 'lodash/set'
import trim from 'lodash/trim'
import React, { memo, useContext, useEffect, useState } from 'react'

import useDidMountEffect from '../../hooks/useDidMountEffect'
import { Picto } from '../../media'
import { Text } from '../../typography'
import { DatePicker } from '../DatePicker'
import IconButton from '../IconButton'
import { Input, InputGroup, InputLeftElement, InputRightElement } from '../Input'
import { RadioGroup } from '../Radio'
import FieldWrapper from './FieldWrapper'
import { FormContext } from './FormContext'

const Field = (props) => {
  const { field } = props
  const { value, originValue, formOptions, onFormChange } = useContext(FormContext)
  const {
    label,
    text,
    type,
    attrKey,
    placeholder = ' ',
    values = [],
    isRequired = false,
    readOnly = false,
    validator //= () => () => () => {}
  } = field
  const [isFieldValid, setIsFieldValid] = useState()
  const [fieldErrors, setFieldErrors] = useState([])
  const [showPassword, setShowPassword] = useState(false)

  const validate = (_originValue) => (_value) => async () => {
    try {
      if (!attrKey) {
        setFieldErrors([])
        setIsFieldValid(false)
      } else if (validator && isFunction(validator)) {
        await validator(_originValue)(_value).validate(get(_value, attrKey), {
          abortEarly: false
        })
        setFieldErrors([])
        setIsFieldValid(checkFieldValid(_originValue)(_value))
      } else if (validator && isObject(validator)) {
        const { label: validatorLabel = '', rules = [] } = validator
        const result = { label: validatorLabel }
        let score = 0
        let i = 0
        for (const rule of rules) {
          const { label: ruleLabel, validator: ruleValidator, weight } = rule
          set(result, `rules.${i}`, { label: ruleLabel, isValid: true })
          try {
            await ruleValidator(_originValue)(_value).validate(get(_value, attrKey), {
              abortEarly: false
            })
            score = score + weight
          } catch (ruleErr) {
            set(result, `rules.${i}`, { label: ruleLabel, isValid: false })
          }
          i++
        }
        if (score < 1 && trim(get(_value, attrKey))) {
          setFieldErrors(result)
          setIsFieldValid(false)
        } else if (score < 1 && !trim(get(_value, attrKey))) {
          if (isRequired) {
            setFieldErrors(result)
            setIsFieldValid(false)
          } else {
            setFieldErrors([])
            setIsFieldValid(false)
          }
        } else if (trim(get(_value, attrKey))) {
          setFieldErrors([])
          setIsFieldValid(true)
        } else if (!trim(get(_value, attrKey))) {
          setFieldErrors([])
          setIsFieldValid(false)
        }
      }
    } catch (err) {
      console.log('Error', err)
      setFieldErrors([...err?.errors])
      setIsFieldValid(false)
    }
  }

  const checkFieldValid = (_originValue) => (_value) => {
    let val = get(_value, attrKey)
    let originVal = get(_originValue, attrKey)
    if (isString(val)) {
      val = trim(val)
    }
    return originVal != val && !isEmpty(val)
  }

  const onChange = async (val) => {
    onFormChange(attrKey)(val)
  }

  useDidMountEffect(
    () => {
      validate(originValue)(value)()
    },
    [get(value, attrKey)],
    formOptions?.validateOnMount
  )

  useEffect(() => {
    set(field, 'triggerValidation', validate)
  }, [])

  let component
  switch (type?.toLowerCase()) {
    case 'input':
      component = (
        <Input
          type="text"
          defaultValue={get(value, attrKey)?.trim()}
          placeholder={formOptions?.isFloating && label ? ' ' : placeholder}
          disabled={readOnly}
          onChange={(e) => onChange(e.target.value)}
          onBlur={validate}
          onFocus={validate}
          w={{ base: '90%', md: '320px' }}
          _disabled={{
            backgroundColor: formOptions?.isFloating ? '#FFFFFF' : '#EEEEEE',
            opacity: 1,
            cursor: 'not-allowed'
          }}
        />
      )
      break
    case 'password':
      component = (
        <>
          <InputGroup
            backgroundColor={readOnly ? '#eee' : 'white'}
            width={{ base: '90%', md: '320px' }}>
            <Input
              type={showPassword ? 'text' : 'password'}
              value={get(value, attrKey)}
              placeholder={formOptions?.isFloating && label ? ' ' : placeholder}
              disabled={readOnly}
              onChange={(e) => onChange(e.target.value)}
              onBlur={validate}
              w={{ base: '100%', md: '320px' }}
              _disabled={{
                backgroundColor: '#EEEEEE',
                opacity: 1,
                cursor: 'not-allowed'
              }}
            />
            <InputRightElement w="20px">
              <IconButton
                _hover={{ border: '0' }}
                _focus={{ border: '0' }}
                variant="unstyled"
                icon={<Picto color="grey" width="16px" height="16px" icon="eye" />}
                onClick={() => setShowPassword(!showPassword)}
              />
            </InputRightElement>
          </InputGroup>
          {formOptions?.isFloating && (
            <Input
              display="none"
              value={get(value, attrKey) || ''}
              placeholder={placeholder}
              disabled={true}
            />
          )}
        </>
      )
      break
    case 'radio':
      component = (
        <RadioGroup
          defaultValue={get(value, attrKey)}
          onChange={(v) => {
            onChange(v)
          }}
          readOnly={readOnly}
          options={values}
          color={formOptions?.mainColor}
        />
      )
      break
    case 'yesnoradio':
      component = (
        <RadioGroup
          defaultValue={get(value, attrKey)}
          direction={{ base: 'column', md: 'row' }}
          onChange={(v) => {
            onChange(v)
          }}
          options={['Oui', 'Non']}
          color={formOptions?.mainColor}
        />
      )
      break
    case 'date':
      component = (
        <>
          <InputGroup
            width={{
              base: '90%',
              md: '320px'
            }}>
            <InputLeftElement pointerEvents="none" ml="2px">
              <Picto icon="calendar" color="#E0E0E0" width="20px" height="20px" />
            </InputLeftElement>

            <DatePicker
              dateFormat="dd/MM/yyyy"
              selected={
                dayjs(get(value, attrKey)).isValid() ? dayjs(get(value, attrKey)).toDate() : null
              }
              maxDate={dayjs().subtract(18, 'year').toDate()}
              onChange={(d) => onChange(d)}
              //isInvalid={!isEmpty(errors) && showError}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onBlur={validate}
              //onKeyPress={validate}
              //onFocus={() => setShowHelpMessage(true)}
              inputProps={{
                //borderColor: !isEmpty(errors) && showError ? '#E53E3E' : 'inherit',
                //boxShadow: !isEmpty(errors) && showError ? '0 0 0 1px #e53e3e' : 'none',
                paddingLeft: '40px',
                backgroundColor: 'white'
              }}
            />
          </InputGroup>
          {formOptions?.isFloating && (
            <Input
              display="none"
              value={get(value, attrKey) || ''}
              placeholder={placeholder}
              disabled={true}
            />
          )}
        </>
      )
      break
    case 'title':
      return (
        <Text
          as="h3"
          fontSize="16px"
          fontWeight={700}
          fontFamily="PT Sans, Arial, sans-serif"
          mb="10px">
          {label}
        </Text>
      )
    case 'text':
      return (
        <Text
          as="span"
          fontSize="14px"
          fontWeight={400}
          fontFamily="PT Sans, Arial, sans-serif"
          mb="10px">
          {text}
        </Text>
      )
    default:
      return `unknown type ${type}`
  }

  return (
    <FieldWrapper field={field} fieldErrors={fieldErrors} isFieldValid={isFieldValid}>
      {component}
    </FieldWrapper>
  )
}

export default memo(Field)
