import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { compact, isFunction } from 'lodash'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
dayjs.extend(customParseFormat)

const filter = (collection = [], options = {}) => {
  const {
    currentDate = Date.now(),
    dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ',
    additionalFilter,
    preProcessor,
    postProcessor
  } = options
  const filterFunction = (c, i) => {
    if (isNil(c)) {
      return false
    }

    if (isFunction(preProcessor)) {
      preProcessor(c, i)
    }
    const startDate = get(c, 'startDate') || get(c, 'customFields.startDate')
    const endDate = get(c, 'endDate') || get(c, 'customFields.endDate')
    const result =
      (isNil(startDate) || dayjs(startDate, dateFormat).valueOf() <= currentDate) &&
      (isNil(endDate) || dayjs(endDate, dateFormat).valueOf() >= currentDate)

    return result
  }

  if (isFunction(additionalFilter) && !isFunction(postProcessor)) {
    return collection.filter(filterFunction).filter(additionalFilter)
  }

  if (isFunction(postProcessor) && !isFunction(additionalFilter)) {
    return compact(postProcessor(collection.filter(filterFunction)))
  }

  if (isFunction(additionalFilter) && isFunction(postProcessor)) {
    return compact(postProcessor(collection.filter(filterFunction).filter(additionalFilter)))
  }

  return collection.filter(filterFunction)
}

export { filter }
