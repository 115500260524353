import { Flex, Text, useDisclosure } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { CartContext, ShippingContext, UserContext } from '../../context'
import { CustomerHelper, ShippingUtils } from '../../utils'
import AddressSelection from './AddressSelection'
import ContactForm from './ContactForm'
import NewAddressForm from './NewAddressForm'
import ShippingModeItem from './ShippingModeItem'

const StandardShippingMethod = (props) => {
  const {
    shippingMethod,
    isHeavy,
    validateShipping,
    isDisabled = false,
    isLoading = false,
    isAlreadySelected = false
  } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const currentUser = useContext(UserContext)
  const currentCart = useContext(CartContext)
  const { shipping, setShipping } = useContext(ShippingContext)

  const updateShipping = ({
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  const onNewAdressFormClose = (newCustomer) => {
    if (newCustomer) {
      const address = CustomerHelper.getDefaultShippingAddress(newCustomer)
      updateShipping({ shippingCustomerAddress: address, isHeavy })
    }
    onClose()
  }

  return (
    <ShippingModeItem
      isDisabled={isDisabled}
      title="Livraison à domicile"
      subTitle="Expédié sous 5 jours"
      value="ship"
      shippingMethod={shippingMethod}
      isHeavy={isHeavy}>
      {isOpen ? (
        <NewAddressForm onClose={onNewAdressFormClose} />
      ) : (
        <Flex direction="column" pl={{ md: '100px' }}>
          <Text
            as="h3"
            fontFamily="PT Sans, Arial, sans-serif"
            p={{ base: '0 0 20px 40px', md: '20px 0' }}
            fontWeight="600">
            Adresse de livraison
          </Text>
          <AddressSelection
            isDisabled={isAlreadySelected}
            isHeavy={isHeavy}
            showAddressForm={onOpen}
          />
          <ContactForm
            shippingMethod={shippingMethod}
            isLoading={isLoading}
            isHeavy={isHeavy}
            validateShipping={validateShipping}
            isValid={true}
            onClose={onClose}
          />
        </Flex>
      )}
    </ShippingModeItem>
  )
}

export default StandardShippingMethod
