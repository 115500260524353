import './loyaltyPopin.css'

import {
  Box,
  chroma,
  Button,
  Flex,
  GatsbyImage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Svg,
  Text
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { useContext } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { OfferUtils, priceFormat } from '../utils'

const LoyaltyBoxContent = (props) => {
  const { key, title, content, picto, bgColor } = props

  return (
    <Box
      key={key}
      w={{ base: '100%', md: '49%' }}
      bg="#fff"
      p={{ base: '18px 15px', md: '12px 18px 12px 12px' }}
      m={{ base: '8px 0', md: '0' }}
      className="loyalty-box">
      <Flex align="center" position="relative">
        <Flex
          align="center"
          justify="space-around"
          position={{ base: 'absolute', md: 'initial' }}
          top="0"
          bgColor={bgColor}
          color="#fff">
          <Box w={{ base: '70px', md: 'auto' }} h={{ base: '70px', md: '120px' }}>
            <Flex 
              width={{ base: '100%', md: '90px' }}
              height="100%"
              justify="center" 
              sx={{
                '& > div > svg': {
                  margin: 'auto'
                }
              }}
            >
              <Svg color="#fff" data={picto} width="85%" height="100%" margin="auto"/>
            </Flex>
          </Box>
        </Flex>
        <Flex
          direction="column"
          ml={{ md: '10px' }}
          sx={{
            ' & > div > p::first-letter': {
              textTransform: 'uppercase'
            }
          }}>
          <Flex
            w={{ base: '40%', md: 'auto' }}
            h={{ base: '70px', md: 'auto' }}
            ml={{ base: '95px', md: '0' }}
            align="center">
            <Text
              fontSize={{ base: '16px', md: '18px' }}
              fontFamily="PT Sans, Arial, sans-serif"
              pb={{ md: '10px' }}
              textTransform="lowercase"
              fontWeight="700"
              lineHeight="1">
              {title}
            </Text>
          </Flex>
          <Flex
            fontFamily="PT Sans, Arial, sans-serif"
            direction="column"
            fontSize="13px"
            mt={{ base: '10px', md: '0' }}
            pt={{ base: '6px', md: '0' }}
            borderTop={{ base: '1px solid #c0C0C0', md: 'none' }}
            dangerouslySetInnerHTML={{ __html: content }}></Flex>
        </Flex>
      </Flex>
    </Box>
  )
}

const LoyaltyPopin = (props) => {
  const { isOpen, onClose, loyaltyPage, isRenew = false } = props
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)

  const {
    standardPrice = 2999,
    price = 2999,
    currency = 'EUR'
  } = OfferUtils.getCurrentProductOffer(
    loyaltyPage?.loyalty_card,
    'STANDARD',
    process.env.GATSBY_API_CHANNEL_ID,
    currentUser
  )

  const addLoyaltyCard = async () => {
    await apiContext?.OrderApi?.addItemToOrder(get(loyaltyPage?.loyalty_card, 'variants.0.id'), 1)
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      motionPreset="slideInRight"
      size="xl"
      scrollBehavior="inside"
      isCentered>
      <ModalOverlay />
      <ModalContent
        maxW="50rem"
        maxH="auto"
        borderRadius="0"
        w="100%"
        h={{ base: '100%', md: 'auto' }}
        overflow={{ base: 'auto', md: 'hidden' }}>
        <ModalCloseButton
          style={{ top: '10px', boxShadow: 'none' }}
          sx={{
            svg: {
              width: '16px',
              height: '16px'
            }
          }}
        />
        <ModalBody p="0">
          <Flex direction="column">
            <Flex>
              <Flex
                w="100%"
                h="auto"
                p={{ base: '18px 24px 18px 18px', md: '18px 45px' }}
                justify="space-between"
                bg={{
                  base: loyaltyPage?.bgColor,
                  md: `linear-gradient(to right, ${loyaltyPage?.bgColor} 0%, ${chroma(loyaltyPage?.bgColor).brighten().hex()} 30%, ${loyaltyPage?.bgColor} 50%)`
                }}>
                <Flex direction="column" w={{ base: 'auto', md: '50%' }} justify="center">
                  <Text
                    fontFamily="PT Sans, Arial, sans-serif"
                    fontSize={{ base: '24px', md: '27px' }}
                    fontWeight="700">
                    {loyaltyPage?.title}
                  </Text>
                  <Flex w="65px" h="6px" bg="#fff" mt="15px"></Flex>
                </Flex>
                <Flex w="50%" justify="space-around" display={{ base: 'none', md: 'flex' }}>
                  <GatsbyImage
                    image={loyaltyPage?.loyalty_card_image?.image?.childImageSharp?.gatsbyImageData}
                    alt="Carte Fidélité"
                    loading="eager"
                    style={{ width: '215px' }}
                  />
                </Flex>
              </Flex>
              <Flex></Flex>
            </Flex>
            <Flex direction="column" p={{ base: '0 18px', md: '0 45px' }} bg="#f6f3f1">
              <Flex direction="column" w="100%" m="0 auto" justifyContent="space-between">
                {loyaltyPage?.sectionBoxPicto?.map((section, i) => (
                  <Box key={`section_${i}`}>
                    <Box
                      p="10px 0 6px"
                      fontWeight={700}
                      fontSize={{ base: '18px', md: '22px' }}
                      fontFamily="PT Sans, Arial, sans-serif">
                      {section.sectionTitle}
                    </Box>
                    <Flex
                      direction={{ base: 'column', md: 'row' }}
                      w="100%"
                      m="0 auto"
                      justifyContent="space-between">
                      {section?.pictoBox?.map((box, j) => {
                        return (
                          <LoyaltyBoxContent
                            key={`boxPicto_${i}_${j}`}
                            title={box?.title}
                            content={box?.content}
                            picto={box?.picto}
                            bgColor={loyaltyPage?.secondaryColor}
                          />
                        )
                      })}
                    </Flex>
                  </Box>
                ))}
              </Flex>
            </Flex>
            <Flex bg="#f6f3f1" pt="30px" pb="25px" justify="center" align="center">
              <Flex>
                <Flex direction="column" align="flex-end">
                  <Text
                    fontSize="28px"
                    fontWeight={700}
                    fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                    alignSelf="flex-end">
                    {priceFormat(price, currency)}
                  </Text>
                  {standardPrice !== price && (
                    <Flex direction={{ base: 'column', md: 'row' }} m="0" p="0" mt="-10px">
                      <Text ml="10px" fontSize="12px">
                        au lieu de
                      </Text>
                      <Text
                        fontSize="12px"
                        textDecoration="line-through"
                        ml="5px"
                        alignSelf="flex-end">
                        {`${priceFormat(standardPrice, currency)}`}
                      </Text>
                    </Flex>
                  )}
                </Flex>
              </Flex>
              <Button
                onClick={() => {
                  addLoyaltyCard()
                  onClose()
                }}
                backgroundColor={websiteContext.mainColor}
                _hover={{
                  backgroundColor: '#fff',
                  color: websiteContext?.mainColor,
                  border: '1px solid',
                  borderColor: websiteContext?.mainColor
                }}
                color="#fff"
                textTransform="uppercase"
                fontSize="16px"
                fontFamily="PT Sans, Arial, sans-serif"
                w={{ base: '200px', md: '38%' }}
                h="50px"
                whiteSpace="normal"
                ml={{ base: '20px', md: '32px' }}
                borderRadius="0px">
                {`${isRenew ? `Renouveler` : `Adhérer à`} la carte fidélité`}
              </Button>
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoyaltyPopin
