import { Crypto } from '@stars-ecom/shared-atoms-ui'
import axios from 'axios'
import dayjs from 'dayjs'
import { get } from 'lodash'
import difference from 'lodash/difference'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import keys from 'lodash/keys'
import omit from 'lodash/omit'
import set from 'lodash/set'
import take from 'lodash/take'
import trim from 'lodash/trim'
import React, { useEffect, useReducer } from 'react'

import { CustomerHelper, OfferUtils } from '../utils'
import { getUser } from './UserContext'

const DataLayerContext = React.createContext()

const windowGlobal = typeof window !== 'undefined' && window

const nexus = {}

const initEventState = (params = {}) => {
  const { pageType, pageUrl, hostname, breadcrumb = [] } = params
  const currentUser = getUser()
  const event = {}
  if (currentUser) {
    set(event, 'user.userLogged', 'connecté')
    set(event, 'user.customerId', currentUser?.id)
    set(event, 'user.title', currentUser.title == 'Monsieur' ? 1 : 2)
    set(event, 'user.lastname', currentUser.lastname)
    set(event, 'user.firstname', currentUser.firstname)
    set(event, 'user.hashedId', Crypto.sha256(currentUser?.emailAddress)?.toString())
    set(event, 'user.hashedId_m', Crypto.md5(currentUser?.emailAddress)?.toString())
    set(
      event,
      'user.birthdate',
      dayjs(currentUser.customFields?.birthdate)?.isValid()
        ? dayjs(currentUser.customFields?.birthdate).format('YYYY-MM-DD')
        : ''
    )
    set(event, 'user.postcode', CustomerHelper.getDefaultBillingAddress(currentUser)?.postalCode)
  } else {
    set(event, 'user.userLogged', 'déconnecté')
    set(event, 'user.title', '')
    set(event, 'user.lastname', '')
    set(event, 'user.firstname', '')
    set(event, 'user.hashedId', '')
    set(event, 'user.hashedId_m', '')
    set(event, 'user.dob', '')
    set(event, 'user.postcode', '')
  }
  if (currentUser?.customFields?.externalCode) {
    set(event, 'user.userStatus', 'client')
  } else {
    set(event, 'user.userStatus', 'prospect')
  }
  if (currentUser?.customFields?.loyaltyStatus) {
    set(event, 'user.loyaltyStatus', 'oui')
  } else {
    set(event, 'user.loyaltyStatus', 'non')
  }
  if (breadcrumb.length >= 2) {
    set(event, 'breadcrumb2', get(breadcrumb || [], '1.label', ''))
  }
  if (breadcrumb.length >= 3) {
    set(event, 'breadcrumb3', get(breadcrumb || [], '2.label', ''))
  }

  return {
    ...event,
    env: process.env.GATSBY_API_STAGE,
    pageType,
    pageUrl,
    hostname,
    event: 'pageLoaded'
  }
}

const initEventReducer = (state = {}, action = {}) => {
  switch (action.type) {
    case 'reset':
      return {}
    case 'update':
      return { ...state, ...action.event }
    case 'init':
      return { ...state, ...initEventState(action.event), ready: true }
  }
  return state
}

const DataLayerContextProvider = (props) => {
  const { children, value = {} } = props
  const { pageType, location, breadcrumb = [] } = value
  const [initEvent, dispatchInitEvent] = useReducer(initEventReducer, {})

  const addEvent = (event) => {
    windowGlobal?.dataLayer?.push(event)
  }

  const updateDataLayer = (event) => {
    dispatchInitEvent({ type: 'update', event })
  }

  set(nexus, 'updateDataLayer', updateDataLayer)
  set(nexus, 'addEvent', addEvent)

  useEffect(() => {
    if (initEvent?.ready) {
      windowGlobal?.dataLayer?.push(omit(initEvent, ['ready']))
      dispatchInitEvent({ type: 'reset' })
    }
  }, [initEvent])

  useEffect(() => {
    dispatchInitEvent({
      type: 'init',
      event: { pageType, pageUrl: location.href, hostname: location.hostname, breadcrumb }
    })
  }, [location?.href])

  return <DataLayerContext.Provider value={value}>{children}</DataLayerContext.Provider>
}

DataLayerContextProvider.displayName = 'DataLayerContextProvider'

const updateDataLayer = (event) => {
  if (isFunction(nexus.updateDataLayer)) {
    return nexus.updateDataLayer(event)
  }
}

const addEvent = (event) => {
  if (isFunction(nexus.addEvent)) {
    return nexus.addEvent(event)
  }
}

const addProductImpression = ({
  command = 'productImpression',
  productList,
  listName,
  offerType = 'STANDARD',
  productCategory1,
  productCategory2
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: command,
    currencyCode: 'EUR',
    productImpressionListName: listName,
    productList: productList?.map((p, i) => {
      const productOffer = OfferUtils.getCurrentProductOffer(
        p,
        offerType,
        process.env.GATSBY_API_CHANNEL_ID
      )

      return {
        productName: p?.name, // string - Nom du produit
        productId: p?.customFields?.externalCode,
        productVendureId: p?.id,
        productCodeOffre: productOffer?.offerCode, // string - Code offre du produit
        productUnitPrice: productOffer?.price / 100, // floating - Prix du produit TTC
        promoDiscount: productOffer.discount ? `-${productOffer.discount}%` : '', // string - Pourcentage de remise du prix, si il y a une remise, sinon laisser vide
        productPictoNouveau: p?.facetValues?.find((fv) => fv?.name?.toLowerCase() === 'nouveau')
          ? 'Nouveau'
          : '', // string - Pictos attribués au produit : nouveau
        productPictoPromo: take(
          p?.facetValues
            ?.filter(
              (fv) =>
                fv?.facet?.code === 'product-tag-channel' &&
                fv?.customFields?.visible &&
                fv?.name?.toLowerCase() !== 'nouveau'
            )
            ?.map((fv) => fv.name),
          2
        ), // string - Pictos attribués au produit : -10% SUPP. + FRAIS DE PORT OFFERTS
        productRating: `${p?.reviewAvg?.rate ? `${Math.round(p?.reviewAvg?.rate)}/5` : ''}`, // string - La note des avis client sur le produit
        productRatingNumber: `${p?.reviewAvg?.nbReviews || ''}`, // string - Le nombre d'avis client qui ont été laissés
        productCategory1,
        productCategory2 /*productVideo: product?.assets
          ? product?.assets?.map((a) => a?.mimeType)?.includes('video/mp4')
            ? 'oui'
            : 'non'
          : '',
        productInStock: stockLevel ? (stockLevel === 'IN_STOCK' ? 'oui' : 'non') : '',
        productDelivery: stockLevel
          ? stockLevel === 'IN_STOCK'
            ? 'Expédié sous 3 à 5 jours'
            : 'Expédié sous 1 à 2 semaines'
          : '',*/,
        productImpressionListPosition: i // A quelle position/rang se trouve le produit dans la liste
      }
    })
  })
}

const addProductClick = ({
  command = 'productClick',
  product,
  position,
  listName,
  offerType = 'STANDARD',
  productCategory1,
  productCategory2
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: command,
    currencyCode: 'EUR',
    productImpressionListName: listName,
    productList: [product].map((p) => {
      const productOffer = OfferUtils.getCurrentProductOffer(
        p,
        offerType,
        process.env.GATSBY_API_CHANNEL_ID
      )
      return {
        productName: p?.name, // string - Nom du produit
        productId: p?.customFields?.externalCode,
        productVendureId: p?.customFields?.id,
        productCodeOffre: productOffer?.offerCode, // string - Code offre du produit
        productUnitPrice: productOffer?.price / 100, // floating - Prix du produit TTC
        promoDiscount: productOffer.discount ? `-${productOffer.discount}%` : '', // string - Pourcentage de remise du prix, si il y a une remise, sinon laisser vide
        productPictoNouveau: p?.facetValues?.find((fv) => fv?.name?.toLowerCase() === 'nouveau')
          ? 'Nouveau'
          : '', // string - Pictos attribués au produit : nouveau
        productPictoPromo: take(
          p?.facetValues
            ?.filter(
              (fv) =>
                fv?.facet?.code === 'product-tag-channel' &&
                fv?.customFields?.visible &&
                fv?.name?.toLowerCase() !== 'nouveau'
            )
            ?.map((fv) => fv.name),
          2
        ), // string - Pictos attribués au produit : -10% SUPP. + FRAIS DE PORT OFFERTS
        productRating: `${p?.reviewAvg?.rate ? `${Math.round(p?.reviewAvg?.rate)}/5` : ''}`, // string - La note des avis client sur le produit
        productRatingNumber: `${p?.reviewAvg?.nbReviews || ''}`, // string - Le nombre d'avis client qui ont été laissés
        productCategory1,
        productCategory2 /*productVideo: product?.assets
          ? product?.assets?.map((a) => a?.mimeType)?.includes('video/mp4')
            ? 'oui'
            : 'non'
          : '',
        productInStock: stockLevel ? (stockLevel === 'IN_STOCK' ? 'oui' : 'non') : '',
        productDelivery: stockLevel
          ? stockLevel === 'IN_STOCK'
            ? 'Expédié sous 3 à 5 jours'
            : 'Expédié sous 1 à 2 semaines'
          : '',*/,
        productImpressionListPosition: position // A quelle position/rang se trouve le produit dans la liste
      }
    })
  })
}

const addProductDetail = ({
  command = 'productDetail',
  product,
  offerType = 'STANDARD',
  stockLevel
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: command,
    currencyCode: 'EUR',
    productList: [product].map((p) => {
      const productOffer = OfferUtils.getCurrentProductOffer(
        p,
        offerType,
        process.env.GATSBY_API_CHANNEL_ID
      )
      return {
        productName: p?.name, // string - Nom du produit
        productId: p?.customFields?.externalCode,
        productCodeOffre: productOffer?.offerCode, // string - Code offre du produit
        productUnitPrice: productOffer?.price / 100, // floating - Prix du produit TTC
        promoDiscount: productOffer.discount ? `-${productOffer.discount}%` : '', // string - Pourcentage de remise du prix, si il y a une remise, sinon laisser vide
        productPictoNouveau: p?.facetValues?.find((fv) => fv?.name?.toLowerCase() === 'nouveau')
          ? 'Nouveau'
          : '', // string - Pictos attribués au produit : nouveau
        productPictoPromo: take(
          p?.facetValues
            ?.filter(
              (fv) =>
                fv?.facet?.code === 'product-tag-channel' &&
                fv?.customFields?.visible &&
                fv?.name?.toLowerCase() !== 'nouveau'
            )
            ?.map((fv) => fv.name),
          2
        ), // string - Pictos attribués au produit : -10% SUPP. + FRAIS DE PORT OFFERTS
        productRating: `${p?.reviewAvg?.rate ? `${Math.round(p?.reviewAvg?.rate)}/5` : ''}`, // string - La note des avis client sur le produit
        productRatingNumber: `${p?.reviewAvg?.nbReviews || ''}`, // string - Le nombre d'avis client qui ont été laissés
        productVideo: product?.assets
          ? product?.assets?.map((a) => a?.mimeType)?.includes('video/mp4')
            ? 'oui'
            : 'non'
          : '',
        productInStock: stockLevel ? (stockLevel === 'IN_STOCK' ? 'oui' : 'non') : '',
        productDelivery: stockLevel
          ? stockLevel === 'IN_STOCK'
            ? 'Expédié sous 3 à 5 jours'
            : 'Expédié sous 1 à 2 semaines'
          : '' // A quelle position/rang se trouve le produit dans la liste
      }
    })
  })
}

const addToCart = ({
  command = 'cartProductAdded',
  product,
  productVariant,
  offerType = 'STANDARD',
  stockLevel,
  orderCode,
  quantity = 1
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: command,
    currencyCode: 'EUR',
    cartId: orderCode,
    productList: [product].map((p) => {
      const productOffer = OfferUtils.getCurrentVariantOffer(
        productVariant,
        offerType,
        process.env.GATSBY_API_CHANNEL_ID
      )
      return {
        productName: p?.name, // string - Nom du produit
        productId: p?.customFields?.externalCode,
        productVariant: trim(productVariant?.name?.replace(p?.name || '', '')), // string - SKU
        productCodeOffre: productOffer?.offerCode, // string - Code offre du produit
        productUnitPrice: productOffer?.price / 100, // floating - Prix du produit TTC
        promoDiscount: productOffer.discount ? `-${productOffer.discount}%` : '', // string - Pourcentage de remise du prix, si il y a une remise, sinon laisser vide
        productPictoNouveau: p?.facetValues?.find((fv) => fv?.name?.toLowerCase() === 'nouveau')
          ? 'Nouveau'
          : '', // string - Pictos attribués au produit : nouveau
        productPictoPromo: take(
          p?.facetValues
            ?.filter(
              (fv) =>
                fv?.facet?.code === 'product-tag-channel' &&
                fv?.customFields?.visible &&
                fv?.name?.toLowerCase() !== 'nouveau'
            )
            ?.map((fv) => fv.name),
          2
        ), // string - Pictos attribués au produit : -10% SUPP. + FRAIS DE PORT OFFERTS
        productUpsell: offerType === 'UPSELL',
        productRating: `${p?.reviewAvg?.rate ? `${Math.round(p?.reviewAvg?.rate)}/5` : ''}`, // string - La note des avis client sur le produit
        productRatingNumber: `${p?.reviewAvg?.nbReviews || ''}`, // string - Le nombre d'avis client qui ont été laissés
        productVideo: product?.assets
          ? product?.assets?.map((a) => a?.mimeType)?.includes('video/mp4')
            ? 'oui'
            : 'non'
          : '',
        productInStock: stockLevel ? (stockLevel === 'IN_STOCK' ? 'oui' : 'non') : '',
        productDelivery: stockLevel
          ? stockLevel === 'IN_STOCK'
            ? 'Expédié sous 3 à 5 jours'
            : 'Expédié sous 1 à 2 semaines'
          : '', // A quelle position/rang se trouve le produit dans la liste
        productQuantity: quantity
      }
    })
  })
}

const removeFromCart = ({
  command = 'cartProductRemoved',
  product,
  productVariant,
  offerType = 'STANDARD',
  stockLevel,
  orderCode,
  quantity = 1
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: command,
    currencyCode: 'EUR',
    cartId: orderCode,
    productList: [product].map((p) => {
      const productOffer = OfferUtils.getCurrentVariantOffer(
        productVariant,
        offerType,
        process.env.GATSBY_API_CHANNEL_ID
      )
      return {
        productName: p?.name, // string - Nom du produit
        productId: p?.customFields?.externalCode,
        productVariant: trim(productVariant?.name?.replace(p?.name || '', '')), // string - SKU
        productCodeOffre: productOffer?.offerCode, // string - Code offre du produit
        productUnitPrice: productOffer?.price / 100, // floating - Prix du produit TTC
        promoDiscount: productOffer.discount ? `-${productOffer.discount}%` : '', // string - Pourcentage de remise du prix, si il y a une remise, sinon laisser vide
        productPictoNouveau: p?.facetValues?.find((fv) => fv?.name?.toLowerCase() === 'nouveau')
          ? 'Nouveau'
          : '', // string - Pictos attribués au produit : nouveau
        productPictoPromo: take(
          p?.facetValues
            ?.filter(
              (fv) =>
                fv?.facet?.code === 'product-tag-channel' &&
                fv?.customFields?.visible &&
                fv?.name?.toLowerCase() !== 'nouveau'
            )
            ?.map((fv) => fv.name),
          2
        ), // string - Pictos attribués au produit : -10% SUPP. + FRAIS DE PORT OFFERTS
        productUpsell: offerType === 'UPSELL',
        productRating: `${p?.reviewAvg?.rate ? `${Math.round(p?.reviewAvg?.rate)}/5` : ''}`, // string - La note des avis client sur le produit
        productRatingNumber: `${p?.reviewAvg?.nbReviews || ''}`, // string - Le nombre d'avis client qui ont été laissés
        productVideo: product?.assets
          ? product?.assets?.map((a) => a?.mimeType)?.includes('video/mp4')
            ? 'oui'
            : 'non'
          : '',
        productInStock: stockLevel ? (stockLevel === 'IN_STOCK' ? 'oui' : 'non') : '',
        productDelivery: stockLevel
          ? stockLevel === 'IN_STOCK'
            ? 'Expédié sous 3 à 5 jours'
            : 'Expédié sous 1 à 2 semaines'
          : '', // A quelle position/rang se trouve le produit dans la liste
        productQuantity: quantity
      }
    })
  })
}

const addPromotionImpression = ({
  promotionName,
  promotionId,
  promotionCreative,
  promotionPosition,
  promotions = []
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: 'promotionImpression',
    promotionList: isEmpty(promotions)
      ? [
          {
            promotionName, // string - Nom de la promotion
            promotionId, // string - ID unique de la promotion
            promotionCreative, // string - nom de l'image
            promotionPosition // string - nom de la position de la promotion
          }
        ]
      : promotions.map((p) => ({
          promotionName: p.promotionName, // string - Nom de la promotion
          promotionId: p.promotionId, // string - ID unique de la promotion
          promotionCreative: p.promotionCreative, // string - nom de l'image
          promotionPosition: p.promotionPosition // string - nom de la position de la promotion
        }))
  })
}

const addPromotionClick = ({
  promotionName,
  promotionId,
  promotionCreative,
  promotionPosition
}) => {
  addEvent({
    event: 'santianoEcommerce',
    eventCommand: 'promotionClick',
    promotionList: [
      {
        promotionName, // string - Nom de la promotion
        promotionId, // string - ID unique de la promotion
        promotionCreative, // string - nom de l'image
        promotionPosition // string - nom de la position de la promotion
      }
    ]
  })
}

const getChannel = () => {
  let channel = `${process.env.GATSBY_API_WEBSITE}`
  return channel
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/\s/g, '')
    .toLowerCase()
}

const getProductReviewAvg = async (product, dataType = 'rate') => {
  const { reviewAvg, customFields } = product || {}

  if (reviewAvg) {
    return reviewAvg[dataType]
  } else {
    try {
      const res = await axios.get(
        `review/${process.env.GATSBY_API_VERSION}/${getChannel()}/${customFields?.productGroupCode}`
      )

      return res.data[dataType] || ''
    } catch (e) {
      console.log('error', e)
    }
  }
}
const addCheckoutEvent = async ({ order, command, customer, fields, ...rest }) => {
  const event = {
    event: 'santianoEcommerce',
    eventCommand: command,
    currencyCode: 'EUR',
    cart_id: order?.code,
    ...rest,

    // variables spécifiques à orderConfirmation
    orderId: order?.code,
    orderRevenue: (order?.totalWithTax || 0) / 100,
    orderTax: ((order?.totalWithTax || 0) - (order?.total || 0)) / 100,
    orderShippingCost: (order?.shippingWithTax || 0) / 100,
    orderCoupon: get(order, 'couponCodes.0', ''),
    orderPaymentMethod: 'cb',
    orderShippingMethod: get(order, 'shippingLines.0.shippingMethod', ''),
    buyerHistory: customer?.customFields?.externalCode ? 'Ancien client' : 'Nouveau client',
    optinCartefid: order?.customFields?.isLoyalty ? 'oui' : 'non',
    productList: await Promise.all(
      order?.lines?.map(async (ol) => {
        let offer = {}
        try {
          offer = OfferUtils.getCurrentVariantOffer(
            ol.productVariant,
            ol?.customFields?.type,
            process.env.GATSBY_API_CHANNEL_ID
          )
        } catch (e) {
          console.log(e)
        }

        const productRatingPromise = getProductReviewAvg(ol?.productVariant?.product, 'rate')
        const productRatingNumberPromise = getProductReviewAvg(
          ol?.productVariant?.product,
          'nbReviews'
        )

        const [productRating, productRatingNumber] = await Promise.all([
          productRatingPromise,
          productRatingNumberPromise
        ])

        return {
          productName: ol?.productVariant?.product?.name[0].concat(
            ol?.productVariant?.product?.name.substring(1).toLowerCase()
          ),
          productId: ol?.productVariant?.customFields?.externalCode,
          productCodeOffre: offer?.offerCode,
          productUnitPrice: offer?.price / 100,
          promoDiscount: offer?.discount ? `-${offer?.discount}%` : '',
          productPictoNouveau: offer?.offerType2 === 'NV' ? 'Nouveau' : '',
          productPictoPromo: take(
            ol?.productVariant?.product?.facetValues
              ?.filter(
                (fv) =>
                  fv?.facet?.code === 'product-tag-channel' &&
                  fv?.customFields?.visible &&
                  fv?.name?.toLowerCase() !== 'nouveau'
              )
              ?.map((fv) => fv.name),
            2
          ),
          productRating: `${productRating || ''}/5`,
          productRatingNumber: productRatingNumber,
          productUpsell: ol?.customFields?.type === 'UPSELL' ? true : false,
          productVariant: trim(
            ol?.productVariant?.name?.replace(ol?.productVariant?.product?.name || '', '')
          ),
          productInStock: ol?.customFields?.stockLevel
            ? ol?.customFields?.stockLevel === 'IN_STOCK'
              ? 'oui'
              : 'non'
            : '',
          productDelivery: ol?.customFields?.stockLevel
            ? ol?.customFields?.stockLevel === 'IN_STOCK'
              ? 'Expédié sous 3 à 5 jours'
              : 'Expédié sous 1 à 2 semaines'
            : '',
          productQuantity: ol.quantity // quantité au panier
        }
      })
    )
  }

  const allFields = keys(event)
  const finalEvent = omit(event, difference(allFields, fields))
  addEvent(finalEvent)
}

const addOrderConfirmationEvent = (params) => {
  const fields = [
    'event',
    'eventCommand',
    'currencyCode',
    'cart_id',
    'orderId',
    'orderRevenue',
    'orderTax',
    'orderShippingCost',
    'orderCoupon',
    'orderShippingMethod',
    'buyerHistory',
    'optinCartefid',
    'productList'
  ]
  addCheckoutEvent({ ...params, fields })
}

const addPaymentEvent = (params) => {
  const fields = [
    'event',
    'eventCommand',
    'currencyCode',
    'cart_id',
    'checkoutStepNumber',
    'checkoutStepName',
    'productList'
  ]
  addCheckoutEvent({ ...params, fields })
}

const addShippingEvent = (params) => {
  const fields = [
    'event',
    'eventCommand',
    'currencyCode',
    'cart_id',
    'checkoutStepNumber',
    'checkoutStepName',
    'productList'
  ]
  addCheckoutEvent({ ...params, fields })
}

const addShoppingCartEvent = (params) => {
  const fields = [
    'event',
    'eventCommand',
    'currencyCode',
    'cart_id',
    'checkoutStepNumber',
    'checkoutStepName',
    'productList'
  ]
  addCheckoutEvent({ ...params, fields })
}

const addShoppingCartLoginEvent = (params) => {
  const fields = [
    'event',
    'eventCommand',
    'currencyCode',
    'cart_id',
    'checkoutStepNumber',
    'checkoutStepName',
    'productList'
  ]
  addCheckoutEvent({ ...params, fields })
}

const DataLayerUtils = {
  addEvent,
  updateDataLayer,
  addProductImpression,
  addProductClick,
  addProductDetail,
  addToCart,
  removeFromCart,
  addPromotionImpression,
  addPromotionClick,
  addOrderConfirmationEvent,
  addPaymentEvent,
  addShippingEvent,
  addShoppingCartEvent,
  addShoppingCartLoginEvent
}
export { DataLayerContextProvider, DataLayerContext, DataLayerUtils }
