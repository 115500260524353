import {
  Alert,
  AlertIcon,
  Button,
  Checkbox,
  CircularProgress,
  CloseButton,
  Flex,
  Text
} from '@stars-ecom/shared-atoms-ui'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import set from 'lodash/set'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'

const AccountPreferences = () => {
  const [preferences, setPreferences] = useState({})
  const [alert, setAlert] = useState()
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [loading, setLoading] = useState(false)
  const windowGlobal = typeof window !== 'undefined' && window
  const scrollToTop = () => windowGlobal.scrollTo({ top: 0, behavior: 'smooth' })

  const getData = async () => {
    try {
      setLoading(true)
      const result = await apiContext?.MarketingApi?.getMarketingPreference(currentUser?.id)
      if (result) {
        setPreferences(result)
      }
    } catch (e) {
      console.error(e)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (currentUser) {
      getData()
    }
  }, [currentUser])

  const handleChange = (field) => (value) => {
    const newPreferences = cloneDeep(preferences)
    set(newPreferences, field, value ? 1 : 0)
    setPreferences(newPreferences)
  }

  const save = async () => {
    try {
      setLoading(true)
      await apiContext?.MarketingApi?.upsertMarketingPreference(currentUser?.id, preferences)
      setAlert({
        active: true,
        status: 'success',
        message: 'Préférences sauvegardées avec succés.'
      })
    } catch (e) {
      setAlert({
        active: true,
        status: 'error',
        message: 'Erreur de sauvegarde, veuillez réessayer plus tard.'
      })
    } finally {
      setLoading(false)
      scrollToTop()
    }
  }

  const Field = ({ label, field }) => (
    <Flex direction="column" align="center" justify="flex-start" width="25%">
      <Checkbox
        isChecked={get(preferences, field, false)}
        onChange={(e) => handleChange(field)(e.target.checked)}
        backgroundColorChecked={websiteContext?.mainColor}
      />
      <Text fontSize="12px" mt="10px">
        {label}
      </Text>
    </Flex>
  )
  return (
    <Flex width="100%" position="relative" backgroundColor="#ffffff" mb="20px">
      <Flex direction="column" padding={{ base: '15px', md: '40px' }} maxWidth="100%">
        <Text
          as="h2"
          fontFamily={websiteContext?.fontFamilyNarrow}
          fontSize="26px"
          borderBottom={{ base: '1px solid #c1c1c1', md: 'none' }}
          color="#333333"
          fontWeight={700}
          mb="20px">
          Mes préférences
        </Text>
        {alert && (
          <Alert status={alert.status} mb="20px">
            <AlertIcon />
            {alert.message}
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => {
                setAlert(null)
              }}
            />
          </Alert>
        )}
        <Text
          mb="40px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="14px"
          color="#333333"
          textAlign="justify">
          Vous avez ici la possibilité de décider quelles communications vous souhaitez recevoir de
          la part de la société {websiteContext?.title?.toUpperCase()} et de nos partenaires
          sélectionnés : actualités, bons plans, promos, nouveauté produit… et d’exercer ainsi votre
          droit de retirer votre consentement à tout moment. Vous pouvez aussi vous opposer sans
          motif au traitement de vos données.
          <br />
          <br />
          Pour exercer vos droits précités, il vous suffit de cocher ou décocher la case
          correspondante à votre choix puis cliquer sur le bouton « Enregistrer »
        </Text>

        <Flex direction={{ base: 'column', md: 'row' }} width="100%" mb="30px">
          <Flex direction="column" width="350px">
            <Text
              as="h3"
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize="16px"
              fontWeight={700}
              color="#333333"
              mb="10px">
              Les actus, promos, nouveautés de {websiteContext?.title}
            </Text>
            <Text as="p" fontSize="11px" color="#333333" fontFamily="PT Sans, Arial, sans-serif">
              Restez informés des dernières actualités autour de l&acute;émission{' '}
              {websiteContext?.title}, des nouveautés, des lancements de nouveaux produits. Ne ratez
              plus nos bons plans et soyez à l&acute;affût de nos offres promos.
            </Text>
          </Flex>
          <Flex direction="row" flexGrow="1" pt="10px">
            <Field label="Email" field="email" />
            <Field label="SMS" field="sms" />
            <Field label="Courrier" field="mail" />
            <Field label="Téléphone" field="phone" />
          </Flex>
        </Flex>
        <Flex direction={{ base: 'column', md: 'row' }} width="100%" mb="30px">
          <Flex direction="column" width="350px">
            <Text
              as="h3"
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize="16px"
              fontWeight={700}
              color="#333333"
              mb="10px">
              Les partenaires de {websiteContext?.title}
            </Text>
            <Text as="p" fontSize="11px" color="#333333" fontFamily="PT Sans, Arial, sans-serif">
              Soyez au courant des offres exclusives de nos partenaires.
            </Text>
          </Flex>
          <Flex direction="row" flexGrow="1" pt="10px">
            <Field label="Email" field="partnerEmail" />
            <Field label="SMS" field="partnerSMS" />
            <Field label="Courrier" field="partnerMail" />
            <Field label="Téléphone" field="partnerPhone" />
          </Flex>
        </Flex>
        <Flex direction={{ base: 'column', md: 'row' }} width="100%" mb="30px">
          <Flex direction="column" width="350px">
            <Text
              as="h3"
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize="16px"
              fontWeight={700}
              color="#333333"
              mb="10px">
              Les offres rien que pour vous
            </Text>
            <Text as="p" fontSize="11px" color="#333333" fontFamily="PT Sans, Arial, sans-serif">
              Bénéficiez d&acute;offres exclusives ciblées rien que pour vous et ne ratez plus votre
              cadeau pour votre anniversaire.
            </Text>
          </Flex>
          <Flex direction="row" flexGrow="1" pt="10px">
            <Field label="" field="targeting" />
            <Flex width="75%" />
          </Flex>
        </Flex>
        <Flex direction="row" justify="flex-end">
          <Button
            onClick={save}
            variant="solid"
            style={{
              borderRadius: 0,
              color: '#ffffff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              fontSize: '19px',
              paddingLeft: '30px',
              paddingRight: '30px',
              fontWeight: 400,
              fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif',
              boxShadow: 'none'
            }}
            backgroundColor={websiteContext.mainColor}
            _hover={{ backgroundColor: websiteContext.darkColor }}>
            Enregistrer
          </Button>
        </Flex>
      </Flex>

      {loading && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          justify="center"
          paddingTop="90px"
          style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )}
    </Flex>
  )
}

export default AccountPreferences
