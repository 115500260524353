import { ApiContextProvider } from './ApiContext'
import { CartContextProvider } from './CartContext'
import { combineComponents } from './combineComponents'
import { DataLayerContextProvider } from './DataLayerContext'
import { SessionContextProvider } from './SessionContext'
import { UserContextProvider } from './UserContext'
import { WebsiteContextProvider } from './WebsiteContext'

const providers = [
  CartContextProvider,
  UserContextProvider,
  ApiContextProvider,
  WebsiteContextProvider,
  SessionContextProvider,
  DataLayerContextProvider
]

const AppContextProvider = combineComponents(...providers)

export default AppContextProvider
