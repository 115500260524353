import { cloneDeep, compact, get, isEmpty, min, minBy, set, toString } from 'lodash'

export const getCurrentProductOffer = (product, type = 'STANDARD', channelId, currentUser) => {
  const variantsOffer = get(product, 'variants', []).map((v) =>
    getCurrentVariantOffer(v, type, channelId)
  )
  const offer = minBy(variantsOffer, (v) => v.price)
  const renewPrice = min(
    get(product, 'variants', []).map((v) => get(v, 'customFields.renewPrice') || 0)
  )
  if (offer && renewPrice && currentUser && get(currentUser, 'customFields.loyaltyIsRenew')) {
    const newOffer = cloneDeep(offer)
    set(newOffer, 'price', renewPrice)
    set(
      newOffer,
      'discount',
      Math.round(
        (100 * (get(newOffer, 'standardPrice') - renewPrice)) / get(newOffer, 'standardPrice')
      )
    )
    set(newOffer, 'discountType', 'PERCENTAGE')
    return newOffer
  }
  return offer || {}
}

export const getCurrentVariantOffer = (
  productVariant,
  type = 'STANDARD',
  channelId,
  currentUser
) => {
  const currentDate = new Date().getTime()
  const offers = get(productVariant, 'customFields.offers', [])
  if (offers.length > 0) {
    const offer =
      typeof offers === 'string'
        ? JSON.parse(offers).find(
            (o) =>
              o.type === type &&
              o.startDate <= currentDate &&
              o.endDate >= currentDate &&
              toString(o.channel) === toString(channelId)
          )
        : offers.find(
            (o) =>
              o.type === type &&
              o.startDate <= currentDate &&
              o.endDate >= currentDate &&
              toString(o.channel) === toString(channelId)
          )

    const renewPrice = get(productVariant, 'customFields.renewPrice', []) || 0
    if (offer && renewPrice && currentUser && get(currentUser, 'customFields.loyaltyIsRenew')) {
      const newOffer = cloneDeep(offer)
      set(newOffer, 'price', renewPrice)
      set(
        newOffer,
        'discount',
        Math.round(
          (100 * (get(newOffer, 'standardPrice') - renewPrice)) / get(newOffer, 'standardPrice')
        )
      )
      set(newOffer, 'discountType', 'PERCENTAGE')
      return newOffer
    }
    if (offer) {
      return offer
    }
  }
  if (type !== 'STANDARD') {
    return getCurrentVariantOffer(productVariant, 'STANDARD', channelId)
  }
  return {}
}

export const removeNoOfferProduct = (product) => {
  const currentOffer = getCurrentProductOffer(
    product,
    'STANDARD',
    process.env.GATSBY_API_CHANNEL_ID
  )
  if (!(currentOffer && currentOffer.standardPrice > 0 && !isEmpty(product.variants))) {
    return null
  }
  return product
}

export const removeNoOfferProducts = (products = []) => {
  return compact(products.map(removeNoOfferProduct))
}

//TODO Handle differents currency label
export const getCurrencyLabel = () => {
  return '€'
}
