export const RATINGS_MODE = {
  FULL: 1,
  MIN: 2,
  RATE: 3
}

export const AVIS_VERIFIES_MODE = {
  NORMAL: 1,
  MIN: 2,
  FULL: 3,
  FOOTER: 4
}

export const LATEST_REVIEWS_MODE = {
  BLUE: 1,
  GREY: 2,
  STRETCH: 3
}
