import { CircularProgress, Flex, Picto } from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import React, { memo, useContext, useState } from 'react'

import { ApiContext, DataLayerUtils, WebsiteContext } from '../context'

const windowGlobal = typeof window !== 'undefined' && window

const LogoutButton = (props) => {
  const { style = { marginTop: '5px' } } = props
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)
  const [loading, setLoading] = useState(false)

  const logout = async () => {
    setLoading(true)
    DataLayerUtils.addEvent({
      event: 'qwampEvent',
      eventCat: 'Compte client',
      eventAct: 'Déconnexion compte client'
    })
    await apiContext?.AccountApi?.logout()
    windowGlobal?.sessionStorage?.clear()
    navigate('/')
  }

  return (
    <Flex
      direction="row"
      align="center"
      justify="center"
      style={{
        width: '125px',
        cursor: 'pointer',
        height: '22px',
        backroundColor: 'transparent',
        ...style
      }}
      onClick={logout}>
      {loading ? (
        <CircularProgress size="22px" isIndeterminate color={websiteContext?.mainColor} />
      ) : (
        <>
          <Picto className="logout-trigger" icon="logout" color="#999999" width="22px" height="22px" />
          <span className="logout-trigger" style={{ marginLeft: '10px', color: '#999999', fontSize: '14px' }}>
            Se déconnecter
          </span>
        </>
      )}
    </Flex>
  )
}

export default memo(LogoutButton)
