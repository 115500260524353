const windowGlobal = typeof window !== 'undefined' && window

const addEvent = (event) => {
  if (windowGlobal?.dataLayer) {
    windowGlobal.dataLayer.push(event)
    console.log('add event dataLayer ', windowGlobal.dataLayer)
  }
}

export { addEvent }
