import { Flex, Link, Picto, Text } from '@stars-ecom/shared-atoms-ui'
import isFunction from 'lodash/isFunction'
import replace from 'lodash/replace'
import PropTypes from 'prop-types'
import React from 'react'

import { DataLayerUtils } from '../context'

const windowGlobal = typeof window !== 'undefined' && window

const SocialShareButton = (props) => {
  const { icon, label, color, linkTemplate, link, onClick } = props

  const popupCenter = (url, title, w, h) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft =
      windowGlobal.screenLeft !== undefined ? windowGlobal?.screenLeft : windowGlobal?.screenX
    const dualScreenTop =
      windowGlobal.screenTop !== undefined ? windowGlobal?.screenTop : windowGlobal?.screenY

    const width = windowGlobal?.innerWidth
      ? windowGlobal?.innerWidth
      : windowGlobal?.document?.documentElement?.clientWidth
      ? windowGlobal?.document?.documentElement?.clientWidth
      : windowGlobal?.screen?.width
    const height = windowGlobal?.innerHeight
      ? windowGlobal?.innerHeight
      : windowGlobal?.document?.documentElement?.clientHeight
      ? windowGlobal?.document?.documentElement?.clientHeight
      : windowGlobal?.screen?.height

    const systemZoom = width / windowGlobal?.screen?.availWidth
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = windowGlobal?.open(
      url,
      title,
      `scrollbars=no,status=no,location=no,toolbar=no,menubar=no,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (windowGlobal?.focus) newWindow.focus()
  }

  const openPopup = () => {
    if (isFunction(onClick)) {
      onClick()
    }
    popupCenter(replace(linkTemplate || '', '%link%', link), '', 600, 300)
    return false
  }
  return (
    <Link
      target="_blank"
      //href={replace(linkTemplate || '', '%link%', link)}
      rel="noreferrer"
      style={{ cursor: 'pointer', textDecoration: 'none' }}
      onClick={openPopup}>
      <Flex
        transition="all 0.2s ease-in-out"
        _hover={{ transform: 'translateY(-4px)' }}
        alignItems="center"
        justifyContent="center"
        height="20px"
        style={{ backgroundColor: color, borderRadius: 2 }}
        pr="8px">
        <Picto icon={icon} width="20px" height="20px" color="#fff" />
        <Text
          color="#fff"
          fontSize="10.5px"
          fontFamily="Helvetica, PT Sans, Arial, sans-serif"
          pl="2.5px">
          {label}
        </Text>
      </Flex>
    </Link>
  )
}
const SocialShare = (props) => {
  const {
    label = 'Partagez sur :',
    link,
    useFacebook = true,
    useTwitter = true,
    usePinterest = true,
    product
  } = props

  const onClick = () => {
    DataLayerUtils.addEvent({
      event: 'socialInt',
      socialNet: 'RS',
      socialAct: 'Partage',
      socialTarget: 'Fiche produit',
      eventLab: product?.customFields?.externalCode
    })
  }

  return (
    <Flex alignItems="center" gridGap={1}>
      {(useFacebook || useTwitter || usePinterest) && (
        <Text color="#000" fontSize="14px" fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif">
          {label}
        </Text>
      )}
      {useFacebook && (
        <SocialShareButton
          color="#3b5998"
          icon="facebookShare"
          label="Facebook"
          link={link}
          onClick={onClick}
          linkTemplate="https://www.facebook.com/sharer/sharer.php?u=%link%"
        />
      )}
      {useTwitter && (
        <SocialShareButton
          color="#1d9bf0"
          icon="twitterShare"
          label="Twitter"
          link={link}
          onClick={onClick}
          linkTemplate="https://twitter.com/share?url=%link%"
        />
      )}
      {usePinterest && (
        <SocialShareButton
          color="#cb2128"
          icon="pinterestShare"
          label="Pinterest"
          link={link}
          onClick={onClick}
          linkTemplate="https://pinterest.com/pin/create/button/?url=%link%"
        />
      )}
    </Flex>
  )
}

SocialShare.defaultProps = {
  label: 'Partagez sur :',
  link: '/',
  useFacebook: true,
  useTwitter: true,
  usePinterest: true
}

SocialShare.propTypes = {
  label: PropTypes.string,
  link: PropTypes.string,
  useFacebook: PropTypes.bool,
  useTwitter: PropTypes.bool,
  usePinterest: PropTypes.bool
}
export { SocialShare }
