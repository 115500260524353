import {
  Box,
  chroma,
  CircularProgress,
  Flex,
  GatsbyImage,
  Image,
  Modal,
  Text,
  Tooltip,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, CartContext, UserContext } from '../context'
import { OfferUtils, priceFormat } from '../utils'
import AddBasketIcon from './addBasket.png'

const LoyaltyHeader = (props) => {
  const { loyaltyPage } = props
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [isTooltipOpen, setIsTooltipOpen] = useState(false)

  const { isOpen, onOpen, onClose } = useDisclosure()

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTooltipOpen(false)
    }, 3000)
    return () => clearTimeout(timer)
  }, [isTooltipOpen])

  const {
    standardPrice = 2999,
    price = 2999,
    discount = 0,
    discountType = 'PERCENTAGE',
    currency = 'EUR'
  } = OfferUtils.getCurrentProductOffer(
    loyaltyPage?.loyalty_card,
    'STANDARD',
    process.env.GATSBY_API_CHANNEL_ID,
    currentUser
  )

  const addItemToOrder = async () => {
    const productVariantId = get(loyaltyPage?.loyalty_card, 'variants.0.id')
    if (productVariantId) {
      const hasAlreadyFid = get(currentCart, 'lines')?.find(
        (ol) => ol?.productVariant?.id === productVariantId
      )
      if (!hasAlreadyFid) {
        feedback()
        setLoading(true)
        await apiContext?.OrderApi?.addItemToOrder(productVariantId, 1)
        setLoading(false)
      } else {
        setIsTooltipOpen(true)
      }
    } else {
      alert("Une erreur s'est produite")
    }
  }

  const feedback = () => {
    onOpen()
    setTimeout(onClose, 5000)
  }

  return (
    <Flex direction="column" justify="space-around" align="center" w="100vw">
      <Flex
        h={{ base: 'auto', md: '270px' }}
        w="100%"
        justify="space-around"
        background={{
          base: 'transparent',
          md: `linear-gradient(to right, ${loyaltyPage?.bgColor} 0%, ${chroma(loyaltyPage?.bgColor)
            .brighten()
            .hex()} 30%, ${loyaltyPage?.bgColor} 50%)`
        }}>
        <Flex
          direction="row"
          w={{ base: '100%', md: '1000px' }}
          pl={{ base: '10px', md: 0 }}
          pr={{ base: '10px', md: 0 }}
          align="center"
          justify="flex-start">
          <Flex direction="column">
            <Text
              as="h1"
              w={{ base: '100%', md: '380px' }}
              fontFamily={{
                base: 'PT Sans, Arial, sans-serif',
                md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
              }}
              fontSize={{ base: '27px', md: '44px' }}
              lineHeight={{ base: '40px', md: '44px' }}
              fontWeight={{ base: 700, md: 400 }}
              mt={{ base: '10px', md: 0 }}
              mb={{ base: '0', md: '20px' }}
              color={{ base: loyaltyPage?.secondaryColor, md: '#333333' }}>
              {loyaltyPage?.title}
            </Text>
            <Text
              w={{ base: '100%', md: '380px' }}
              fontSize={{ base: '14px', md: '18px' }}
              fontFamily={{
                base: 'PT Sans, Arial, sans-serif',
                md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
              }}
              lineHeight={{ base: '21px', md: '27px' }}
              mt={{ base: '14px', md: 0 }}
              mb={{ base: '14px', md: 0 }}>
              {loyaltyPage?.subtitle}
            </Text>
            <Box
              display={{ base: 'none', md: 'block' }}
              w="65px"
              h="4px"
              bgColor="#fff"
              mt="15px"
            />
          </Flex>
          <Flex flexGrow="1" justify="flex-end" display={{ base: 'none', md: 'flex' }} height="205px">
            <GatsbyImage
              style={{ width: '325px', height: 'auto' }}
              image={loyaltyPage?.loyalty_card_image?.image?.childImageSharp?.gatsbyImageData}
              alt="Carte Fidélité"
              loading="eager"
            />
          </Flex>
        </Flex>
      </Flex>
      {(currentUser?.customFields?.loyaltyIsRenew || !currentUser?.customFields?.loyaltyStatus) && (
          <Flex
            w={{ base: '100%', md: '1000px' }}
            mt={{ base: 0, md: '-20px' }}
            h={{ md: "88px" }}
            pl={{ base: '10px', md: 0 }}
            pr={{ base: '10px', md: 0 }}
            direction={{ base: 'column', md: 'row' }}
            justifyContent="space-between">
              <Flex
                w={{ base: '100%', md: '745px' }}
                color={{ base: '#333333', md: '#fff' }}
                bgColor={{ base: 'transparent', md: '#242a30' }}
                borderRadius="5px"
                align="center">
                <Text
                  ml={{ base: 0, md: '85px' }}
                  w={{ base: '100%', md: '490px' }}
                  fontFamily={{
                    base: 'PT Sans, Arial, sans-serif',
                    md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
                  }}
                  lineHeight={{ base: '21px', md: '24px' }}
                  fontSize={{ base: '14px', md: '24px' }}
                  fontWeight={{ base: 400, md: 700 }}>
                  {currentUser?.customFields?.loyaltyIsRenew
                    ? loyaltyPage?.renewCardText
                    : loyaltyPage?.buyCardText}
                </Text>
              </Flex>
              <Flex
                justifyContent={{ base: 'center', md: 'space-around' }}
                position="relative"
                alignItems="center"
                w={{ base: '100%', md: '243px' }}
                mt={{ base: '20px', md: 0 }}
                bgColor="#242a30"
                color="#fff"
                verticalAlign="middle"
                textAlign="center"
                borderRadius={{ base: 0, md: '5px' }}
                fontWeight="bold">
                {discount !== 0 && (
                  <Flex
                    position="absolute"
                    top="-12px"
                    left="10px"
                    backgroundColor="#d5e301"
                    borderRadius="5px"
                    justify="center"
                    align="center"
                    pl="8px"
                    pr="8px"
                    pt="4px"
                    pb="4px">
                    <Text fontWeight={700} fontSize="14px" color="#333333">
                      {discountType === 'PERCENTAGE'
                        ? `-${discount}%`
                        : `-${priceFormat(discount, currency)}`}
                    </Text>
                  </Flex>
                )}
                <Flex direction="column" align="flex-start">
                  <Text
                    fontSize="36px"
                    fontWeight={700}
                    fontFamily="PT Sans Narrow"
                    m="0"
                    p="0"
                    ml="10px">
                    {priceFormat(price, currency)}
                  </Text>
                  {standardPrice !== price && (
                    <Flex direction="row" m="0" p="0" mt="-10px">
                      <Text ml="10px" fontSize="12px">
                        au lieu de
                      </Text>
                      <Text fontSize="12px" textDecoration="line-through" ml="5px">
                        {`${priceFormat(standardPrice, currency)}`}
                      </Text>
                    </Flex>
                  )}
                </Flex>
                {loading ? (
                  <CircularProgress />
                ) : (
                  <Tooltip
                    hasArrow
                    label="La carte de fidélité a déjà été ajoutée à votre panier"
                    bg="#FFF"
                    color="#000"
                    border="1px solid #999"
                    p="10px"
                    isOpen={isTooltipOpen}
                    arrowShadowColor="#999">
                    <Box onClick={addItemToOrder} cursor="pointer">
                      <Image
                        src={AddBasketIcon}
                        alt="ajout Panier Carte Fidelite"
                        p={{ base: '0.5em', md: 0 }}
                      />
                    </Box>
                  </Tooltip>
                )}
              </Flex>
          </Flex>
      )}

      <Modal isOpen={isOpen}>
        <Box
          w="300px"
          h="75px"
          bg="#929EAD"
          borderRadius="5px"
          position="absolute"
          top="70px"
          right="20px"
          zIndex="999">
          <Text
            fontFamily="PT Sans"
            fontSize="13px"
            fontWeight="900"
            p="20px"
            w="100%"
            color="#FFF">
            Votre carte de fidélité a bien été ajoutée à votre panier
          </Text>
        </Box>
      </Modal>
    </Flex>
  )
}

export default LoyaltyHeader
