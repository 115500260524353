import { useEnvironment } from '@chakra-ui/react-env'
import { isBrowser } from '@chakra-ui/utils'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'

const useSafeLayoutEffect = isBrowser ? useLayoutEffect : useEffect

const useMediaQuery = (query) => {
  const env = useEnvironment()
  const queries = Array.isArray(query) ? query : [query]
  const isSupported = isBrowser && 'matchMedia' in env.window

  const [matches, setMatches] = useState(
    queries.map((queryItem) => (isSupported ? !!env.window.matchMedia(queryItem).matches : false))
  )
  const matchesRef = useRef(matches)

  useSafeLayoutEffect(() => {
    if (!isSupported) return undefined

    const mediaQueryList = queries.map((queryItem) => env.window.matchMedia(queryItem))

    const listenerList = mediaQueryList.map(() => {
      const listener = () => {
        const isEqual = (prev, curr) =>
          prev.length === curr.length && prev.every((elem, idx) => elem === curr[idx])

        const currentMatches = mediaQueryList.map((mediaQuery) => mediaQuery.matches)

        if (!isEqual(matchesRef.current, currentMatches)) {
          matchesRef.current = currentMatches
          setMatches(currentMatches)
        }
      }

      env.window.addEventListener('resize', listener)

      return listener
    })

    return () => {
      mediaQueryList.forEach((_, index) => {
        env.window.removeEventListener('resize', listenerList[index])
      })
    }
  }, [query])

  return matches
}

export default useMediaQuery
