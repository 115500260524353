import { gsap } from 'gsap'
import identity from 'lodash/identity'
import isFunction from 'lodash/isFunction'
import times from 'lodash/times'
import uniq from 'lodash/uniq'
import React, { useEffect, useRef } from 'react'

import { List } from '../dataDisplay/List'
import { Button } from '../forms/Button'
import { useState } from '../hooks'
import useBreakpointValue from '../hooks/useBreakpointValue'
import Box from '../layout/Box'
import Flex from '../layout/Flex'

const events = {
  start: ['mousedown', 'touchstart'],
  end: ['mouseup', 'touchend']
}

const Carousel = (props) => {
  const {
    children = [],
    style,
    slidesToShow = 4,
    slidesToScroll = 4,
    direction = 'row',
    gap = 0,
    gapOffset = 0,
    duration = 0.5,
    slideWidth,
    slideHeight,
    displayArrow = true,
    hasScrollX = false,
    hideNullableArrow = false,
    nextArrow,
    prevArrow,
    displayDots = true,
    dot,
    justify = 'center',
    slideBelowMd = true,
    isUpsell = false,
    isProductPage = false,
    isPopinTvShow = false,
    unmountInvisible = false,
    tvShow,
    carouselId,
    isMobile = false,
    dataLayerFunction = () => {},
    clickableSlide = false
  } = props
  const width = slidesToShow * (slideWidth + gap) - gap
  const height = slidesToShow * (slideHeight + gap) - gap + (direction === 'column' ? 3 : 0)
  const totalWidth = children?.length * (slideWidth + gap) - gap
  const totalHeight = children?.length * (slideHeight + gap) - gap
  const [activeSlide, setActiveSlide] = useState(0)
  const rendered = useRef(times(slidesToShow, identity))
  const [touchStart, setTouchStart] = useState()
  const [touchEnd, setTouchEnd] = useState()
  const windowGlobal = typeof window !== 'undefined' && window

  const wrapperRef = useRef()
  const portionRef = useRef()

  const onDotHandler = (slide) => {
    if (dataLayerFunction && isFunction(dataLayerFunction)) {
      dataLayerFunction({ eventLab: 'Puce' })
    }
    setActiveSlide(slide)
  }

  const onNextHandler = () => {
    if (dataLayerFunction && isFunction(dataLayerFunction)) {
      dataLayerFunction({ eventLab: 'Fléche suivante' })
    }
    if (slidesToShow < children.length) {
      const nextSlide = activeSlide + slidesToScroll
      if (nextSlide < children.length) {
        setActiveSlide(nextSlide)
      } else {
        setActiveSlide(0)
      }
    }
  }

  const onPrevHandler = () => {
    if (dataLayerFunction && isFunction(dataLayerFunction)) {
      dataLayerFunction({ eventLab: 'Fléche précédente' })
    }
    if (slidesToShow < children.length) {
      const nextSlide = activeSlide - slidesToScroll
      if (nextSlide >= 0) {
        setActiveSlide(nextSlide)
      } else {
        if (children.length % slidesToShow === 0) {
          setActiveSlide(children.length - slidesToShow)
        } else {
          setActiveSlide(Math.floor(children.length / slidesToShow) * slidesToShow)
        }
      }
    }
  }

  const isVisible = (index) => {
    const currentlyVisible = index >= activeSlide && index < activeSlide + slidesToShow + 1
    if (currentlyVisible) {
      rendered.current = uniq([...rendered.current, index])
    } else if (unmountInvisible) {
      rendered.current = rendered?.current?.filter((i) => i !== index)
    }
    if (rendered.current.includes(index)) {
      return true
    }
    return false
  }

  useEffect(() => {
    if (!isUpsell) {
      setActiveSlide(0)
    }
    if (isMobile && hasScrollX && !isUpsell) {
      wrapperRef.current.scrollLeft = 0
    }
  }, [tvShow])

  useEffect(() => {
    if (!hasScrollX) {
      const size = direction === 'row' ? slideWidth : slideHeight
      const newOffset = -activeSlide * (size + gap - gapOffset)
      const options = { duration }
      if (direction === 'row') {
        options.x = `${newOffset}px`
      } else {
        options.y = `${newOffset}px`
      }
      gsap.to(portionRef.current, options)
    }
  }, [activeSlide])

  useEffect(() => {
    if (!hasScrollX && !clickableSlide) {
      const target = wrapperRef.current
      const moveStart = (e) => {
        if (windowGlobal) {
          events.end.forEach((eventEnd) =>
            windowGlobal?.addEventListener(eventEnd, moveEnd, { once: true, passive: true })
          )
        } else {
          events.end.forEach((eventEnd) =>
            target?.addEventListener(eventEnd, moveEnd, { once: true, passive: true })
          )
        }

        setTouchStart(e.changedTouches ? e.changedTouches[0] : e)
      }

      const moveEnd = (e) => {
        setTouchEnd(e.changedTouches ? e.changedTouches[0] : e)
      }

      events.start.forEach((eventStart) =>
        target?.addEventListener(eventStart, moveStart, { passive: true })
      )

      return () => {
        events.start.forEach((eventStart) =>
          target?.addEventListener(eventStart, moveStart, { passive: true })
        )
      }
    }
  }, [wrapperRef])

  useEffect(() => {
    if (!hasScrollX && isMobile) {
      if (touchStart && touchEnd) {
        const deltaX = touchEnd?.clientX - touchStart?.clientX
        const deltaY = touchEnd?.clientY - touchStart?.clientY
        if ((direction === 'row' && deltaX < -50) || (direction === 'column' && deltaY < -50)) {
          onNextHandler()
        }
        if ((direction === 'row' && deltaX > 50) || (direction === 'column' && deltaY > 50)) {
          onPrevHandler()
        }
      }
    }
  }, [touchEnd])

  const RenderPrevArrow = ({ ...props }) => {
    const display = useBreakpointValue({
      base: !slideBelowMd,
      md: true
    })
    if (!display || !displayArrow || children.length <= slidesToShow) {
      return null
    }
    if (hideNullableArrow) {
      const nextSlide = activeSlide - slidesToScroll
      if (nextSlide < 0) {
        return null
      }
    }

    if (prevArrow) {
      return prevArrow({ ...props, activeSlide: activeSlide, onClick: onPrevHandler })
    }
    return <Button onClick={onPrevHandler}>Prev</Button>
  }

  const RenderNextArrow = ({ ...props }) => {
    const display = useBreakpointValue({
      base: !slideBelowMd,
      md: true
    })
    if (!display || !displayArrow || children.length <= slidesToShow) {
      return null
    }
    if (hideNullableArrow) {
      const nextSlide = activeSlide + slidesToScroll
      if (nextSlide >= children.length) {
        return null
      }
    }
    if (nextArrow) {
      return nextArrow({ ...props, activeSlide: activeSlide, onClick: onNextHandler })
    }
    return <Button onClick={onNextHandler}>Prev</Button>
  }

  const RenderDot = ({ slide, ...props }) => {
    if (!displayDots || children.length <= slidesToShow) {
      return null
    }
    if (dot) {
      return dot({ ...props, active: slide === activeSlide, onClick: () => onDotHandler(slide) })
    }
    return (
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '5px',
          backgroundColor: slide === activeSlide ? 'blue' : 'white'
        }}
        onClick={() => onDotHandler(slide)}
      />
    )
  }
  const lazyDisplay = useBreakpointValue({
    base: !slideBelowMd,
    md: true
  })

  return (
    <Box w="100%">
      <Flex direction="column" justify="center" align="center">
        <Flex
          direction={direction}
          justify={justify}
          align="center"
          style={{ width: '100%', height: '100%', ...style }}>
          <RenderPrevArrow />
          <Box
            ref={wrapperRef}
            overflowX={{
              base: slideBelowMd && direction === 'row' ? 'scroll' : 'hidden',
              md: 'hidden'
            }}
            overflowY={{
              base: slideBelowMd && direction === 'column' ? 'scroll' : 'hidden',
              md: 'hidden'
            }}
            width={{
              base:
                direction === 'row'
                  ? isUpsell
                    ? '75vw'
                    : '100vw'
                  : slideBelowMd
                  ? slideWidth + 20
                  : slideWidth,
              md: direction === 'row' ? (isPopinTvShow ? '100vw' : width) : slideWidth
            }}
            height={{
              base: direction === 'row' ? (slideBelowMd ? slideHeight + 20 : slideHeight) : 'auto',
              md: direction === 'row' ? slideHeight : height
            }}>
            <List
              id={carouselId}
              ref={portionRef}
              margin={
                isUpsell || isPopinTvShow
                  ? 'auto'
                  : isProductPage
                  ? '3px 0 0'
                  : { base: '3px 0 0 5px', md: '3px 0 0' }
              }
              style={{
                display: 'flex',
                flexDirection: direction,
                align: children?.length > 1 ? 'space-between' : 'center',
                width: direction === 'row' ? totalWidth : slideWidth,
                height: direction === 'row' ? slideHeight : totalHeight
              }}>
              {children.map((c, i) => (
                <li
                  key={`slide_${i}`}
                  style={{
                    width: slideWidth,
                    height: slideHeight,
                    listStyleType: 'none',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer'
                  }}>
                  {!lazyDisplay || isVisible(i) ? c : null}
                </li>
              ))}
            </List>
          </Box>
          <RenderNextArrow />
        </Flex>
        {direction === 'row' && displayDots && (
          <Flex direction="row" mt={isUpsell || isProductPage ? '10px' : '30px'} gridGap={2}>
            {times(
              children.length % slidesToShow === 0
                ? children.length / slidesToShow
                : Math.floor(children.length / slidesToShow) + 1,
              (i) => i * slidesToShow
            ).map((i) => (
              <RenderDot key={`carousel_dot_${i}`} slide={i} />
            ))}
          </Flex>
        )}
      </Flex>
    </Box>
  )
}

export default React.memo(Carousel)
