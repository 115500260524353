import {
  Alert,
  AlertIcon,
  Box,
  CircularProgress,
  CloseButton,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { priceFormat } from '../utils'

dayjs.extend(customParseFormat)

const Subscription = (props) => {
  const { subscription } = props
  const websiteContext = useContext(WebsiteContext)
  const [expanded, setExpanded] = useState(false)

  const getStatus = () => {
    switch (subscription.status) {
      case 'CANCELLED':
        return (
          <Text color="#bc150d" fontWeight={600} fontSize="14px">
            Annulé
          </Text>
        )
      case 'VALIDATED':
        return (
          <Text color="green" fontWeight={600} fontSize="14px">
            Validé
          </Text>
        )
      case 'CREATED':
        return (
          <Text color="green" fontWeight={600} fontSize="14px">
            Créé
          </Text>
        )
      case 'STOPPED':
        return (
          <Text color="#bc150d" fontWeight={600} fontSize="14px">
            Arrêté
          </Text>
        )
      case 'SOLDED':
        return (
          <Text color="#bc150d" fontWeight={600} fontSize="14px">
            Soldé
          </Text>
        )
      default:
        return null
    }
  }

  const Item = ({ label, value }) => {
    return (
      <Flex direction="row" flexWrap="wrap">
        <Text fontWeight={700} mr="5px" width={{ base: 'auto', md: '240px' }}>{`${label} :`}</Text>
        <Text>{value}</Text>
      </Flex>
    )
  }

  const getFrequency = () => {
    if (subscription?.frequency * 1 < 1) {
      return 'par mois'
    } else if (subscription?.frequency * 1 === 1) {
      return 'mensuel'
    } else {
      return `tous les ${parseInt(subscription?.frequency)} mois`
    }
  }

  const getDuration = () => {
    if (subscription?.duration * 1 === 12) {
      return '12 mois'
    } else if (subscription?.duration * 1 === 24) {
      return '24 mois'
    } else {
      return `sans engagement, résiliable à tout moment`
    }
  }

  return (
    <Flex
      backgroundColor="#f6f3f1"
      fontFamily={websiteContext?.fontFamily}
      direction="column"
      w="100%"
      fontSize="14px"
      p="15px">
      <Flex direction="row" w="100%" flexWrap="wrap">
        <Flex direction="row" width={{ base: '100%', md: '40%' }}>
          <Flex
            width="45px"
            height="45px"
            justify="center"
            align="center"
            backgroundColor="#ffffff">
            {subscription?.product?.featuredAsset?.smallImage?.childImageSharp?.gatsbyImageData && (
              <GatsbyImage
                alt={subscription?.product?.name}
                image={
                  subscription?.product?.featuredAsset?.smallImage?.childImageSharp?.gatsbyImageData
                }
              />
            )}
          </Flex>
          <Text fontWeight="700" maxWidth="150px" ml={{ base: '5px', md: '10px' }}>
            {subscription?.product?.name}
          </Text>
        </Flex>
        <Text
          display={{ base: 'none', md: 'block' }}
          fontSize="13px"
          color="#333333"
          w={{ base: '100%', md: '10%' }}
          fontWeight={700}>
          {priceFormat(subscription.price, 'EUR')}
        </Text>
        <Text
          display={{ base: 'none', md: 'block' }}
          w={{ base: '100%', md: '25%' }}
          fontSize="13px"
          color="#333333"
          fontWeight={700}>
          {dayjs(subscription?.nextPayment).format('DD MMMM YYYY')}
        </Text>
        <Text
          w={{ base: '50%', md: '15%' }}
          pl={{ base: '50px', md: 0 }}
          pt={{ base: '15px', md: 0 }}
          fontSize="13px"
          fontWeight={700}>
          {getStatus()}
        </Text>
        <Flex
          w={{ base: '50%', md: 'auto' }}
          direction="row"
          justify="flex-end"
          pt={{ base: '15px', md: 0 }}
          style={{ cursor: 'pointer' }}
          onClick={() => setExpanded(!expanded)}>
          <Text
            fontSize="14px"
            lineHeight="21px"
            textTransform={{ base: 'uppercase', md: 'initial' }}
            fontWeight={700}
            color="#333"
            _hover={{ color: websiteContext?.mainColor }}>
            {expanded ? 'Moins' : 'Plus'}
          </Text>
          <Box borderRadius="10px" ml="10px">
            <Picto
              icon={expanded ? 'minusCercle' : 'plusCercle'}
              width="16px"
              height="16px"
              color="#000"
            />
          </Box>
        </Flex>
      </Flex>
      {expanded && (
        <Flex direction="column" w="100%" mt="15px" pt="15px" borderTop="2px solid white">
          <Item label="Nom du produit" value={subscription?.product?.name} />
          <Item label="Numéro d'abonnement" value={subscription?.subscribtionNumber} />
          <Item label="Fréquence" value={getFrequency()} />
          <Item label="Prix" value={priceFormat(subscription?.price, 'EUR')} />
          <Item
            label="Date du prochain prélévement"
            value={dayjs(subscription?.nextPayment).format('DD MMMM YYYY')}
          />
          <Item
            label="Date de début de l'abonnement"
            value={dayjs(subscription?.startDate).format('DD MMMM YYYY')}
          />
          <Item label="Durée de l'abonnement" value={getDuration()} />
        </Flex>
      )}
    </Flex>
  )
}

const AccountSubscription = () => {
  const [alert, setAlert] = useState()
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const currentUser = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getData = async () => {
    setLoading(true)
    const result = await apiContext?.AccountApi?.getSubscriptions()
    if (result) {
      setData(result)
    }
    setLoading(false)
  }

  useEffect(() => {
    debounce(getData, 200)()
  }, [currentUser])

  return (
    <Flex direction="column" width="100%" h="100%" position="relative" mb={{ md: '20px' }}>
      <Flex width="100%" h={{ md: '100%' }} position="relative" backgroundColor="#ffffff">
        <Flex direction="column" width="100%" padding={{ base: '15px', md: '40px' }}>
          <Text
            as="h2"
            fontFamily={websiteContext?.fontFamilyNarrow}
            fontSize="26px"
            color="#333333"
            borderBottom={{ base: '1px solid #c1c1c1', md: 'none' }}
            fontWeight={700}
            mb="20px">
            Mes abonnements
          </Text>
          {isEmpty(data) && !loading && (
            <Text mb="40px" fontFamily="PT Sans, Arial, sans-serif" fontSize="14px" color="#333333">
              Vous n&acute;avez souscrit à aucun abonnement.
            </Text>
          )}
          {!isEmpty(data) && !loading && (
            <Flex direction="column" w="100%" fontFamily={websiteContext?.fontFamily}>
              {!websiteContext?.isMobile && (
                <Flex direction="row" w="100%" p="15px">
                  <Text fontWeight={800} w="40%" fontSize="13px" color="#333333">
                    Produit
                  </Text>
                  <Text fontWeight={800} w="10%" fontSize="13px" color="#333333">
                    Prix
                  </Text>
                  <Text fontWeight={800} w="25%" fontSize="13px" color="#333333">
                    Prochaine échéance
                  </Text>
                  <Text fontWeight={800} w="15%" fontSize="13px" color="#333333">
                    État
                  </Text>
                </Flex>
              )}
              {data.map((subscription, i) => (
                <Subscription key={`subscription_${i}`} subscription={subscription} />
              ))}
            </Flex>
          )}
          {alert && (
            <Alert status={alert.status} mb="20px">
              <AlertIcon />
              {alert.message}
              <CloseButton
                position="absolute"
                right="8px"
                top="8px"
                onClick={() => {
                  setAlert(null)
                }}
              />
            </Alert>
          )}
        </Flex>

        {loading && (
          <Flex
            position="absolute"
            width="100%"
            height="100%"
            justify="center"
            paddingTop="90px"
            style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
            <CircularProgress isIndeterminate color={websiteContext?.mainColor}/>
          </Flex>
        )}
      </Flex>
    </Flex>
  )
}

export default AccountSubscription
