import '../../checkout/hipay.css'

import {
  Alert,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Text,
  useState
} from '@stars-ecom/shared-atoms-ui'
import { isEmpty } from 'lodash'
import React, { memo, useContext, useEffect, useRef } from 'react'

import { WebsiteContext } from '../../context'
import { getHipayConfig } from '../../utils'

const CardAddForm = (props) => {
  const { onClose = () => {}, saveCard = () => {}, currentUser = {} } = props
  const [hipayErrorMessage, setHipayErrorMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [CVCHelpMessage, setCVCHelpMessage] = useState('')
  const websiteContext = useContext(WebsiteContext)
  const windowGlobal = typeof window !== 'undefined' && window
  const formRef = useRef()
  const cvcRef = useRef()

  useEffect(() => {
    if (windowGlobal?.HiPay) {
      const hipayInstance = windowGlobal?.HiPay({
        username: process.env.GATSBY_API_HIPAY_USERNAME,
        password: process.env.GATSBY_API_HIPAY_PASSWORD,
        environment: process.env.GATSBY_API_HIPAY_ENV,
        lang: 'fr'
      })

      const config = getHipayConfig({
        suffix: 'default',
        defaultFirstname: `${currentUser?.firstName}`,
        defaultLastname: `${currentUser?.lastName}`
      })

      const cardInstance = hipayInstance.create('card', config)

      cardInstance.on('change', (event) => {
        setHipayErrorMessage('')
        if (event.error) {
          /* Display error(s), if any */
          setHipayErrorMessage(event.error)
        }
        /* Enable / disable submit button */
        setIsDisabled(!event.valid)
      })

      cardInstance.on('helpButtonToggled', (event) => {
        if (event?.element === 'cvc' && event?.message) {
          helpButtonToggle(event?.message)
        } else {
          helpButtonToggle('')
        }
      })

      if (formRef && formRef.current) {
        let cardForm = formRef.current
        const cardFormSubmitListener = (event) => {
          setIsLoading(true)
          event.preventDefault()
          cardInstance.setMultiUse(true)
          /* Tokenize your card information when the submit button is clicked */
          cardInstance.getPaymentData().then(
            async (response) => {
              await saveCard(response)
              setIsLoading(false)
            },
            (errors) => {
              setHipayErrorMessage(errors[0].error)
              setIsLoading(false)
            }
          )
        }
        cardForm.addEventListener('submit', cardFormSubmitListener)
      }
    }
  }, [windowGlobal?.HiPay])

  const helpButtonToggle = (msg) =>
    isEmpty(cvcRef.current) ? setCVCHelpMessage(msg) : setCVCHelpMessage('')

  return (
    <>
      {
        // isLoading ? (
        //   <Flex width="100%" height="200" align="center" justify="center">
        //     <CircularProgress isIndeterminate />
        //   </Flex>
        // ) :
        <>
          <Flex mb="20px" p="5px" border="1px solid #fcde72" bg="#fafaec">
            <Text
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize="10px"
              color="#333333">{`Pour sécuriser l'enregistrement de votre carte bancaire, une empreinte de 1 euro sera effectuée. Il ne s'agit pas d'un débit réel mais d'une préautorisation de paiement, ce montant n'étant pas débité`}</Text>
          </Flex>
          <Text
            as="h3"
            fontFamily={websiteContext?.fontFamilyNarrow}
            fontSize="26px"
            color="#333333"
            fontWeight={700}
            mb="20px">
            Ajouter une carte
          </Text>
          <form className="hipay-form" ref={formRef}>
            {hipayErrorMessage && (
              <Box p="5px">
                <Text fontFamily="PT Sans, Arial, sans-serif" color="#333333">
                  {hipayErrorMessage}
                </Text>
              </Box>
            )}
            <FormControl display="flex" flexDirection={{ base: 'column', md: 'row' }} my="20px">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                w="100%"
                h={{ md: '40px' }}>
                <Flex
                  w={{ base: '100%', md: '50%' }}
                  className="hostedfield"
                  id="hipay-card-holder_default">
                  {/* Cardholder field will be inserted here */}
                </Flex>
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'gray.200',
                    fontWeight: 'bold'
                  }}>
                  NOM SUR LA CARTE
                </FormLabel>
              </Flex>
            </FormControl>

            <FormControl display="flex" flexDirection={{ base: 'column', md: 'row' }} my="20px">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                w="100%"
                h={{ md: '40px' }}>
                <Flex
                  w={{ base: '100%', md: '50%' }}
                  className="hostedfield"
                  id="hipay-card-number_default">
                  {/*  Card number field will be inserted here */}
                </Flex>
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    color: 'gray.800'
                  }}>
                  NUMÉRO DE CARTE DE CRÉDIT
                </FormLabel>
              </Flex>
            </FormControl>

            <FormControl display="flex" flexDirection={{ base: 'column', md: 'row' }} my="20px">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                w="100%"
                h={{ md: '40px' }}>
                <Flex
                  w={{ base: '100%', md: '50%' }}
                  className="hostedfield"
                  id="hipay-expiry-date_default">
                  {/* Expiry date field will be inserted here */}
                </Flex>
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>{`DATE D'EXPIRATION`}</FormLabel>
              </Flex>
            </FormControl>

            <FormControl display="flex" flexDirection={{ base: 'column', md: 'row' }} my="20px">
              <Flex
                direction={{ base: 'column', md: 'row' }}
                justify="space-between"
                w="100%"
                h={{ md: '40px' }}>
                <Flex
                  w={{ base: '100%', md: '50%' }}
                  className="hostedfield"
                  id="hipay-cvc_default">
                  {/* CVC field will be inserted here */}
                </Flex>
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  CRYPTOGRAMME
                </FormLabel>
              </Flex>
            </FormControl>
            {!isEmpty(CVCHelpMessage) && (
              <Alert status="warning" p="0px 12px" mb="10px">
                <Text
                  ref={cvcRef}
                  style={{
                    fontSize: '14px',
                    marginTop: '4px',
                    marginBottom: '5px',
                    color: '#856404'
                  }}>
                  {CVCHelpMessage}
                </Text>
              </Alert>
            )}

            <Flex
              direction={{ base: 'column-reverse', md: 'row' }}
              width="100%"
              justifyContent="space-between"
              align="center"
              my="10px">
              <Button
                onClick={onClose}
                disabled={isLoading}
                variant="solid"
                w={{ base: '100%', md: 'auto' }}
                style={{
                  borderRadius: 0,
                  color: '#fff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  fontSize: '18px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  fontWeight: 400,
                  fontFamily: 'PT Sans,Arial,sans-serif',
                  boxShadow: 'none'
                }}
                backgroundColor="#c1c1c1"
                _hover={{ backgroundColor: '#c1c1c1' }}>
                Annuler
              </Button>
              <Button
                type="submit"
                variant="solid"
                w={{ base: '100%', md: 'auto' }}
                mb={{ base: '12px', md: '0' }}
                style={{
                  borderRadius: 0,
                  color: '#fff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  fontSize: '18px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  fontWeight: 400,
                  fontFamily: 'PT Sans,Arial,sans-serif',
                  boxShadow: 'none'
                }}
                backgroundColor={websiteContext.mainColor}
                _hover={{ backgroundColor: websiteContext.darkColor }}
                disabled={isDisabled}
                isLoading={isLoading}>
                Ajouter
              </Button>
            </Flex>
          </form>
        </>
      }
    </>
  )
}

export default memo(CardAddForm)
