import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  Box,
  Collapse,
  Flex,
  Picto,
  Radio,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import { isEmpty } from 'lodash'
import React, { useContext } from 'react'

import { PaymentContext, WebsiteContext } from '../context'

const PaymentModeItem = (props) => {
  const { head, children, paymentMethod: _paymentMethod, selected, message, idPledgButton } = props
  const { setPaymentMethod } = useContext(PaymentContext)
  const { isOpen, onToggle } = useDisclosure()
  const websiteContext = useContext(WebsiteContext)

  return (
    <Accordion index={selected ? 0 : -1}>
      <AccordionItem
        borderWidth="1px"
        borderStyle="solid"
        borderColor={selected ? websiteContext?.mainColor : '#ced4e1'}
        mb="10px">
        <AccordionButton onClick={() => setPaymentMethod(_paymentMethod)} id={idPledgButton}>
          <Flex textAlign="left" align="center">
            <Radio
              color={websiteContext?.mainColor}
              isChecked={selected}
              py="10px"
              isFullWidth={true}>
              <Flex w="100%" justifyContent="flex-start">
                {head}
              </Flex>
            </Radio>
            {!isEmpty(message) && (
              <Picto
                style={{ marginLeft: '10px' }}
                icon="what"
                width={15}
                height={15}
                color={websiteContext?.mainColor}
                onClick={onToggle}
              />
            )}
          </Flex>
        </AccordionButton>

        {!isEmpty(message) && (
          <Collapse in={isOpen} animateOpacity>
            <Alert status="warning" p="8px 12px" w="100%">
              <Text
                style={{
                  fontSize: '14px',
                  color: '#856404'
                }}>
                {message}
              </Text>
            </Alert>
          </Collapse>
        )}
        <AccordionPanel>{selected && <Box>{children}</Box>}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  )
}

export default PaymentModeItem
