import { Box, Flex, GatsbyImage, Link, Picto, SimpleGrid, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

const Tile = (props) => {
  const { title, link, image, color } = props

  return (
    <Link to={link}>
      <Flex flexDirection="column">
        <GatsbyImage image={image} alt={title} />
        <Flex
          justifyContent="center"
          h="50px"
          alignItems="center"
          p="0 20px"
          bg={color}
          position="relative">
          <Text
            textAlign="center"
            color="#FFFFFF"
            fontSize="12px"
            fontFamily="PT Sans, Arial, sans-serif">
            {title}
          </Text>
          <Picto
            icon="chevronRight"
            width="16px"
            height="16px"
            color="#FFFFFF"
            style={{ position: 'absolute', right: '5px' }}
          />
        </Flex>
      </Flex>
    </Link>
  )
}

const CollectionSpace = (props) => {
  const { universeMobile } = props

  return (
    <Box display={{ base: 'block', md: 'none' }}>
      <Text
        as="h2"
        fontSize="21px"
        fontFamily="PT Sans, Arial, sans-serif"
        pt="20px"
        style={{
          width: '100%',
          fontWeight: 400,
          color: 'black',
          textAlign: 'center',
          lineHeight: 1.5,
          textTransform: 'uppercase'
        }}>
        <strong>Nos</strong> Univers
      </Text>
      <SimpleGrid columns={2} p="10px">
        {universeMobile?.map((universe, i) => (
          <Tile
            key={`collection_space_${i}`}
            title={universe?.name}
            link={universe?.path}
            image={
              universe?.customFields?.mobileUniverseImage?.image?.childImageSharp?.gatsbyImageData
            }
            color={universe?.customFields?.color}
          />
        ))}
      </SimpleGrid>
    </Box>
  )
}

export default memo(CollectionSpace)
