import './review.css'

import {
  Box,
  Button,
  Center,
  Collapse,
  Divider,
  Flex,
  Paginate,
  Picto,
  Popover,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  replaceSpecialChar,
  Select,
  SkeletonText,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { get, isArray, isInteger, last, split, times } from 'lodash'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { ApiContext, WebsiteContext } from '../context'
import { RatingSummary, StarBox } from '../rating'
import DiscussIcon from './comment.png'

dayjs.extend(customParseFormat)

export const RATINGS_MODE = {
  FULL: 'full',
  MIN: 'min',
  RATE: 'rate',
  NONE: 'NONE',
  NB_REVIEWS: 'NB_REVIEWS',
  RATING: 'RATING'
}

export const AVIS_VERIFIES_MODE = {
  NORMAL: 'normal',
  MIN: 'min'
}

const ReviewBox = (props) => {
  const { reviews, loading } = props
  if (!loading) {
    return (
      <>
        {isArray(reviews) &&
          reviews?.map((review, i) => (
            <Box
              key={`review_${i}`}
              style={{ paddingLeft: '20px', paddingRight: '20px' }}
              fontFamily="PT Sans, Arial, sans-serif">
              <Review review={review} />
              <Divider />
            </Box>
          ))}
      </>
    )
  } else {
    return (
      <>
        {times(NB_REVIEWS_PER_PAGE).map((r, i) => (
          <Box key={`review_skeleton_${i}`} style={{ padding: '20px' }}>
            <SkeletonText mt="4" noOfLines={3} spacing="5" />
            <Divider />
          </Box>
        ))}
      </>
    )
  }
}
const Discussion = (props) => {
  const { discussion, isOpen } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Collapse
      style={{
        padding: '0px 30px 15px 30px',
        backgroundColor: '#f4f4f4',
        margin: '10px 20px 20px 20px',
        fontSize: '15px',
        borderLeftStyle: 'solid',
        borderLeftWidth: '3px',
        borderColor: websiteContext?.mainColor,
        fontWeight: '500',
        color: '#2a2a2a'
      }}
      in={isOpen}
      animateOpacity>
      <Box
        style={{
          padding: '20px 0px 0px 0px',
          fontSize: '15px'
        }}>
        <strong>Commentaire de </strong>{' '}
        {discussion?.customerName === 'website' ? websiteContext?.title : discussion?.customerName}{' '}
        le
        <span style={{ fontSize: '13px' }}>
          {' '}
          {dayjs(discussion?.date, 'YYYY-MM-DDTHH:mm:ssZ')?.format('DD/MM/YYYY')}{' '}
        </span>
        <Box>{discussion?.comment}</Box>
      </Box>
    </Collapse>
  )
}
const Review = (props) => {
  const { review } = props
  const { isOpen, onToggle } = useDisclosure()

  return (
    <Box padding={{ base: '20px 0px', md: '20px' }}>
      <Box
        fontSize={{ base: '16px', md: '13px' }}
        fontWeight={{ base: '500', md: '400' }}
        color="#333333"
        mb={{ base: '10px', md: '0px' }}>
        <span style={{ fontWeight: 700, color: 'rgb(51,51,51)' }}>{review.customerName}</span>{' '}
        publié le {dayjs(review?.date, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY')} suite à une
        commande du {dayjs(review?.orderDate, 'YYYY-MM-DDTHH:mm:ssZ').format('DD/MM/YYYY')}
      </Box>
      <Flex direction="row" align="center">
        <StarBox rating={review?.rate} size={16} />
        <p style={{ marginLeft: '10px' }}>
          <b>{`${review?.rate}/5`}</b>
        </p>
      </Flex>
      <Box
        fontSize={{ base: '19px', md: '15px' }}
        mt={{ base: '15px', md: '10px' }}
        fontWeight={{ base: '500', md: '400' }}
        lineHeight="20px"
        color="#2a2a2a">
        {replaceSpecialChar(review?.comment)}
      </Box>
      {review?.discussion.length > 0 &&
        review?.discussion?.map((discuss, i) => (
          <Discussion key={`discussion_${i}`} discussion={discuss} isOpen={isOpen} />
        ))}
      {review?.discussion.length > 0 && (
        <Flex direction="row" justify="flex-end" width="100%">
          <Button
            onClick={onToggle}
            justifyContent="right"
            bgColor="transparent"
            fontWeight="normal"
            padding="unset"
            border="none"
            _hover={{ bg: 'transparent', border: 'none' }}
            _focus={{ outline: 'none' }}>
            <img src={DiscussIcon} />{' '}
            <span style={{ paddingLeft: '8px', fontSize: '14px' }}>
              {isOpen ? 'Masquer les échanges' : 'Afficher les échanges'}
            </span>
          </Button>
        </Flex>
      )}
    </Box>
  )
}

const NB_REVIEWS_PER_PAGE = 5

const PopoverReview = () => {
  return (
    <Popover placement="bottom-end">
      <PopoverTrigger>
        <Flex flexDirection="row" cursor="pointer">
          <Text
            color="rgb(150, 160, 170)"
            fontSize="13px"
            lineHeight="20px"
            fontWeight={400}
            _hover={{ color: 'rgb(47,47,47)' }}
            mr="6px">
            Avis soumis à un contrôle
          </Text>
          <Picto icon="what" width={15} color="rgb(150, 160, 170)" />
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        p="10px"
        bg="#E8E8E8"
        style={{
          width: '414px',
          marginRight: 0,
          marginTop: -45,
          borderRadius: '3px',
          boxShadow: '0 0 2px rgb(0 0 0 / 50%)'
        }}>
        <Flex direction="row" justify="space-between" h="15px">
          <Picto icon="what" width={15} color="rgb(150, 160, 170)" />
          <PopoverCloseButton _hover={{ bg: 'transparent' }} />
        </Flex>
        <div
          style={{
            fontSize: '14px',
            color: '#000000',
            fontFamily: 'PT Sans,Arial,sans-serif',
            paddingTop: '15px',
            paddingBottom: '10px'
          }}>
          <p>
            - Pour plus d&acute;informations sur les caractéristiques du contrôle des avis et la
            possibilité de contacter l&acute;auteur de l&acute;avis, merci de consulter nos{' '}
            <a
              href="https://www.avis-verifies.com/index.php?page=mod_conditions_utilisation"
              target="_blank"
              style={{ textDecoration: 'underline' }}
              rel="noreferrer">
              CGU
            </a>
            .
          </p>
          <br />
          <p>- Aucune contrepartie n&acute;a été fournie en échange des avis.</p>
          <br />
          <p>- Les avis sont publiés et conservés pendant une durée de cinq ans.</p>
          <br />
          <p>
            - Les avis ne sont pas modifiables : si un client souhaite modifier son avis, il doit
            contacter Avis Verifiés afin de supprimer l&acute;avis existant, et en publier un
            nouveau.
          </p>
          <br />
          <p>
            - Les motifs de suppression des avis sont disponibles{' '}
            <a
              href="https://www.avis-verifies.com/index.php?page=mod_conditions_utilisation#Rejet_de_lavis_de_consommateur"
              target="_blank"
              style={{ textDecoration: 'underline' }}
              rel="noreferrer">
              ici
            </a>
            .
          </p>
        </div>
      </PopoverContent>
    </Popover>
  )
}

const Reviews = (props) => {
  const { product, reviewLink } = props
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)
  const defaultOrderBy = 'date|DESC'
  const [nbReviews, setNbReviews] = useState(product?.reviewAvg?.nbReviews || 0)
  const [reviews, setReviews] = useState([])
  const [page, setPage] = useState(0)
  const [orderBy, setOrderBy] = useState(defaultOrderBy)
  const [filter, setFilter] = useState()
  const [loading, setLoading] = useState(false)
  const topReview = useRef(null)
  const firstReview = useRef(null)

  const handlePageChange = async (e) => {
    topReview?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
    setPage(e.selected)
  }

  /**
   * Get product reviews details from gastby review services
   */
  const getData = async () => {
    setLoading(true)
    const productGroupCode = product?.customFields?.productGroupCode
    const result = await apiContext?.ReviewApi?.getReviewDetails(
      productGroupCode,
      page,
      NB_REVIEWS_PER_PAGE,
      orderBy,
      filter
    )
    setReviews(get(result, '0', []) || [])
    setNbReviews(get(result, '1', 0) || 0)
    setLoading(false)
    //topReview.current.scrollIntoView()
  }

  useEffect(() => {
    handlePageChange({ selected: 0 })
  }, [])

  useEffect(() => {
    getData()
  }, [orderBy, page, filter])

  const handleSort = (e) => {
    setPage(0)
    //Filtre par note
    if (isInteger(parseInt(last(split(e, '|'))))) {
      setFilter(e)
      setOrderBy(defaultOrderBy)
    }
    //Tri par date/note
    else {
      setOrderBy(e)
      setFilter()
    }
  }

  return (
    <Flex
      w="100%"
      bg="#f3f4f9"
      flexDirection="column"
      justifyContent="space-around"
      className="review_tab">
      {product?.reviewAvg?.nbReviews > 0 && (
        <Box>
          <Text
            pt={{ base: '30px', md: '0' }}
            style={{
              fontSize: '23px',
              textAlign: 'center',
              color: 'rgb(51,51,51)',
              marginTop: '5px',
              marginBottom: '30px',
              fontWeight: 400,
              fontFamily: 'PT Sans, Arial, Helvetica'
            }}>
            <Text
              as="span"
              display={{ base: 'block', md: 'inline' }}
              fontWeight="700"
              mr={{ md: '6px' }}>
              LES DERNIERS AVIS
            </Text>
            SUR CET ARTICLE
          </Text>
          <Flex
            flexDirection={{ base: 'column', md: 'row' }}
            w="100%"
            mb="20px"
            pl={{ base: '30px', md: '40px' }}
            pr={{ base: '30px', md: '40px' }}
            justifyContent="space-between"
            ref={topReview}>
            <RatingSummary
              rating={product?.reviewAvg?.rate}
              nbReviews={product?.reviewAvg?.nbReviews}
              starSize={24}
              mode={AVIS_VERIFIES_MODE.NORMAL}
            />
            <Flex direction="column" alignItems="flex-end" justify="space-between">
              <Flex direction="column" alignItems="flex-end" display={{ base: 'none', md: 'flex' }}>
                <a
                  style={{
                    textAlign: 'right',
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: '"PT Sans", Arial, Helvetica, sans-serif',
                    color: '#333333',
                    cursor: 'pointer'
                  }}
                  href={reviewLink}
                  target="_blank"
                  rel="noreferrer">
                  {`Voir l'attestation de confiance`}
                </a>
                <PopoverReview />
              </Flex>
              <Flex
                direction="row"
                w="100%"
                justifyContent={{ base: 'space-between', md: 'flex-end' }}
                mt={{ base: '15px', md: 0 }}
                alignItems="center"
                ref={firstReview}>
                <Text
                  mr={{ base: '5px', md: '20px' }}
                  fontSize={{ base: '16px', md: '13px' }}
                  textAlign="left"
                  lineHeight={{ base: '24px', md: '21px' }}>
                  Trier l&apos;affichage des avis :
                </Text>
                <Select
                  color="#333333"
                  fontSize={{ base: '17px', md: '14px' }}
                  borderRadius="0"
                  bg="white"
                  w={{ base: '100%', md: '160px' }}
                  h={{ base: '43px', md: '38px' }}
                  border="1px solid"
                  borderColor={{ base: '#22222261', md: '#333333' }}
                  lineHeight={{ base: '22px', md: '15px' }}
                  // style={{ width: isMobile ? '176px' : '100%' }}
                  onChange={(e) => handleSort(e.target.value)}>
                  <optgroup label="Général">
                    <option value="date|DESC">Les plus récents</option>
                    <option value="date|ASC">Les plus anciens</option>
                    <option value="rate|DESC">Note la plus élevée</option>
                    <option value="rate|ASC">Note la plus faible</option>
                  </optgroup>
                  <optgroup label="Note">
                    <option value="rate|1">1 étoile</option>
                    <option value="rate|2">2 étoiles</option>
                    <option value="rate|3">3 étoiles</option>
                    <option value="rate|4">4 étoiles</option>
                    <option value="rate|5">5 étoiles</option>
                  </optgroup>
                </Select>
              </Flex>
            </Flex>
          </Flex>
          <Flex
            flexDirection="column"
            gridGap="10px"
            style={{
              marginLeft: '20px',
              backgroundColor: '#ffffff',
              marginRight: '20px',
              marginBottom: '20px'
            }}>
            <ReviewBox reviews={reviews} loading={loading} />
            {Math.ceil(nbReviews / NB_REVIEWS_PER_PAGE) > 1 && (
              <Center style={{ marginTop: '5px', marginBottom: '15px' }}>
                <Paginate
                  previousLabel={'<'}
                  nextLabel={'>'}
                  breakLabel={'...'}
                  breakClassName={'break-me'}
                  pageCount={Math.ceil(nbReviews / NB_REVIEWS_PER_PAGE)}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  onPageChange={handlePageChange}
                  containerClassName={'pagination'}
                  activeClassName={'active'}
                  forcePage={page}
                  color={websiteContext?.mainColor}
                />
              </Center>
            )}
          </Flex>
        </Box>
      )}
      {nbReviews < 1 && (
        <Box>
          <Box
            flexDirection={{ base: 'column', md: 'row' }}
            style={{
              width: '100%',
              marginBottom: '5px',
              padding: '20px 40px',
              textAlign: 'center'
            }}
            justifyContent="space-between"
            ref={topReview}>
            {!filter && (
              <>
                <RatingSummary
                  rating={product?.reviewAvg?.rate}
                  nbReviews={product?.reviewAvg?.nbReviews}
                  starSize={24}
                  mode={AVIS_VERIFIES_MODE.NORMAL}
                />
                <a
                  style={{
                    textAlign: 'right',
                    fontSize: '14px',
                    fontWeight: 600,
                    fontFamily: '"PT Sans", Arial, Helvetica, sans-serif',
                    color: '#333333',
                    cursor: 'pointer'
                  }}
                  href={reviewLink}
                  target="_blank"
                  rel="noreferrer">
                  {`Voir l'attestation de confiance`}
                </a>
              </>
            )}
          </Box>
          <Flex
            flexDirection="column"
            gridGap="10px"
            style={{
              marginLeft: '20px',
              backgroundColor: '#ffffff',
              marginRight: '20px',
              marginBottom: '20px'
            }}>
            <Flex textAlign="center" padding="30px" margin="auto" color="#868788" fontSize="14px">
              <h3>Aucun avis n&apos;a encore été laissé sur ce produit.</h3>
            </Flex>
          </Flex>
        </Box>
      )}
    </Flex>
  )
}

export default ReviewBox
export { ReviewBox, Reviews }
