import { Box, ChakraIcons, Flex, Link, Text } from '@stars-ecom/shared-atoms-ui'
import PropTypes from 'prop-types'
import React from 'react'

const ServiceItem = (props) => {
  const { title, text, link } = props
  return (
    <Box
      w={['100%', '100%', '30%']}
      border="1px solid #c0c0c0"
      px="5"
      pt="10"
      pb="15"
      pl="7"
      pr="7"
      color="#333"
      background="linear-gradient(to bottom right,#eef4ff 24%,transparent 0)"
      backgroundSize="61% 100%"
      backgroundRepeat="no-repeat">
      <ChakraIcons.InfoOutlineIcon color="#0671b1" fontSize="2em" />
      <Flex flexDirection="column" alignItems="center">
        <Text fontSize="1.2em" fontWeight="bold" h="50">
          {title}
        </Text>
        <Text h="47" fontSize="0.9em" style={{ textAlign: 'center' }}>
          {text}
        </Text>
        <Link
          href={link}
          pt="7"
          pr="20"
          pb="7"
          pl="20"
          mb="5"
          style={{ fontSize: '0.9em', textTransform: 'uppercase', border: '1px solid #c0c0c0' }}>
          En Savoir Plus
        </Link>
      </Flex>
    </Box>
  )
}

ServiceItem.propTypes = {
  icone: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string
}

ServiceItem.defaultProps = {
  title: 'Garantie 2 ans',
  text: 'Pour encore plus de sérénité',
  link: '/faq#quelle-est-la-duree-de-la-garantie'
}

const ServiceList = (props) => {
  const { title = 'NOS SERVICES INCLUS', services = [] } = props

  return (
    <Box {...props} className="servicesContainer" w="1000px">
      <Text
        className="serviceTitle"
        style={{ fontWeight: 'bold', fontSize: '1.8em', textAlign: 'center' }}
        mb="1em">
        {title}
      </Text>
      <Flex justifyContent="space-between">
        {services.map((service, index) => (
          <ServiceItem
            key={`service_${index}`}
            title={'ok' + service.title}
            text={service.text}
            link={service.link}
          />
        ))}
      </Flex>
    </Box>
  )
}

export { ServiceItem, ServiceList }
