import PropTypes from 'prop-types'
import React from 'react'

import IconPath from './iconPath'

const getIconsNames = Object.keys(IconPath)

const Picto = ({ width, height, color, icon, className, style, onClick }) => {
  return (
    <svg
      onClick={onClick}
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox={IconPath[icon]?.viewBox}
      xmlnsXlink="http://www.w3.org/1999/xlink"
      xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" fill={color} d={IconPath[icon]?.path} />
    </svg>
  )
}

Picto.defaultProps = {
  color: '#000000',
  icon: 'user',
  style: {},
  className: '',
  onClick: () => {}
}

Picto.prototypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string.isRequired,
  icon: PropTypes.oneOf(getIconsNames).isRequired,
  style: PropTypes.shape(PropTypes.object),
  className: PropTypes.string,
  onClick: PropTypes.func
}

export { Picto, IconPath }
