import { Box, Carousel, Flex, Picto, useBreakpointValue } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { WebsiteContext } from '../context'
import VideoThumbnail from './VideoThumbnail'

const VideoSlide = (props) => {
  const { videos, updateMainVideo, mainVideo, arrowColor = '#ffffff', videosToDisplay = 7 } = props
  const slidesToShow = useBreakpointValue({ base: 2, sm: 2, md: 4, lg: 4 })
  const displayArrow = useBreakpointValue({ base: false, sm: false, md: true, lg: true })
  const websiteContext = useContext(WebsiteContext)
  const PrevArrow = (props) => {
    const { onClick } = props
    return (
      <Box
        onClick={onClick}
        style={{
          cursor: 'pointer',
          backgroundColor: '#2f2f2f',
          borderRadius: '50%',
          width: '52px',
          height: '52px',
          zIndex: '1',
          padding: '0.8%',
          marginRight: '-10px',
          marginTop: '-45px',
          fontWeight: '100',
          position: 'absolute',
          left: '-25px'
        }}>
        <Picto
          icon="chevronLeft"
          width="16px"
          color={arrowColor}
          style={{ marginLeft: '12px', marginTop: '8px' }}
        />
      </Box>
    )
  }

  const NextArrow = (props) => {
    const { onClick } = props
    return (
      <Box
        style={{
          cursor: 'pointer',
          backgroundColor: '#2f2f2f',
          borderRadius: '50%',
          width: '52px',
          height: '52px',
          zIndex: '1',
          padding: '0.8%',
          marginLeft: '-10px',
          marginTop: '-45px',
          position: 'absolute',
          right: '-25px'
        }}
        onClick={onClick}>
        <Picto
          icon="chevronRight"
          width="16px"
          color={arrowColor}
          style={{ marginLeft: '15px', marginTop: '7px' }}
        />
      </Box>
    )
  }

  return (
    <Flex direction="row" justify="center" align="center" position="relative" m={{ md: '3px 20px 0' }}>
      {videos?.length > 0 && (
        <Carousel
          isMobile={websiteContext?.isMobile}
          slideWidth={172}
          slideHeight={135}
          direction="row"
          slidesToShow={slidesToShow}
          slidesToScroll={slidesToShow}
          nextArrow={NextArrow}
          prevArrow={PrevArrow}
          displayDots={false}
          displayArrow={displayArrow}
          hideNullableArrow={true}
          gapOffset={42}
          isPopinTvShow={true}
          hasScrollX={websiteContext?.isMobile ? true : false}>
          {videos?.slice(0, videosToDisplay)?.map((video) => (
            <VideoThumbnail
              key={`video_${video.id}`}
              video={video}
              updateMainVideo={updateMainVideo}
              mainVideo={mainVideo === video?.id}
            />
          ))}
        </Carousel>
      )}
    </Flex>
  )
}

export default VideoSlide
