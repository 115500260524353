import { extendTheme } from '@stars-ecom/shared-atoms-ui'

const activeLabelStyles = {
  transform: 'scale(0.85) translateY(-24px)',
  background: 'linear-gradient(0deg, transparent 0%, #ffffff 40%)'
}

const theme = {
  fonts: {
    heading: '"PT Sans Narrow","PT Sans",Arial,sans-serif',
    body: '"PT Sans Narrow","PT Sans",Arial,sans-serif',
    mono: '"PT Sans Narrow","PT Sans",Arial,sans-serif'
  },
  components: {
    Form: {
      variants: {
        floating: {
          container: {
            _focusWithin: {
              label: {
                ...activeLabelStyles
              }
            },
            'input:not(:placeholder-shown) + label, .chakra-select__wrapper + label': {
              ...activeLabelStyles
            },
            label: {
              top: 0,
              left: 0,
              zIndex: 2,
              position: 'absolute',
              backgroundColor: 'white',
              pointerEvents: 'none',
              mx: 3,
              px: 0,
              my: 2,
              transformOrigin: 'left top'
            }
          }
        }
      }
    }
  }
}

export default extendTheme(theme)
