import { get, head, isNil, set, trim } from 'lodash'

import { CustomerHelper } from '../utils'

const getHasHeavyProduct = (currentCart) => {
  const eligibleLines =
    currentCart?.lines?.filter((ol) =>
      ['PDT', 'FID'].includes(ol?.productVariant?.product?.customFields?.productType)
    ) || []
  for (const orderLine of eligibleLines) {
    if (orderLine?.productVariant?.product?.customFields?.isHeavyProduct) {
      return true
    }
  }
  return false
}

const getHasLightProduct = (currentCart) => {
  const eligibleLines =
    currentCart?.lines?.filter((ol) =>
      ['PDT', 'FID'].includes(ol?.productVariant?.product?.customFields?.productType)
    ) || []
  for (const orderLine of eligibleLines) {
    if (!orderLine?.productVariant?.product?.customFields?.isHeavyProduct) {
      return true
    }
  }
  return false
}

const getCartShippingMethod = (currentCart, eligibleShippingMethods) => {
  const cartShippingMethod = head(currentCart?.shippingLines)?.shippingMethod
  if (cartShippingMethod && eligibleShippingMethods) {
    const check = eligibleShippingMethods.find((sm) => sm.code === cartShippingMethod.code)
    if (check) {
      return check
    }
  } else if (cartShippingMethod) {
    return cartShippingMethod
  }
  return null
}

const getCustomerAddressFromCart = (currentCart, currentUser) => {
  return currentUser?.addresses?.find(
    (a) => a.id === currentCart?.customFields?.idAddress && !a?.customFields?.isDeleted
  )
}

const getRelayAddressFromCart = (currentCart, eligibleShippingMethods) => {
  const cartShippingMethod = getCartShippingMethod(currentCart, eligibleShippingMethods)
  if (cartShippingMethod?.customFields?.addressType === 'RELAY') {
    return {
      type: currentCart?.customFields?.relayType,
      id: currentCart?.customFields?.relayCode,
      customFields: {
        addressLine1: currentCart?.customFields?.relayName,
        addressLine2: currentCart?.customFields?.relayAddress
      },
      streetLine1: currentCart?.customFields?.relayName,
      streetLine2: currentCart?.customFields?.relayAddress,
      city: currentCart?.customFields?.relayCity,
      postalCode: currentCart?.customFields?.relayPostalCode,
      schedule: JSON.parse(currentCart?.customFields?.relaySchedule || '[]')
    }
  }
  return null
}

const getDefaultShippingMethod = (eligibleShippingMethods) => {
  const mondialRelayShippingMethod = getShippingMethodByCode(
    'mondial-relay',
    eligibleShippingMethods
  )
  const standardShippingMethod = getShippingMethodByCode('standard', eligibleShippingMethods)
  return mondialRelayShippingMethod || standardShippingMethod
}

const calculateShippingPrice = (currentCart, shippingMethod, isHeavyProduct) => {
  if (shippingMethod?.priceWithTax === 0) {
    return 0
  }
  if (shippingMethod?.priceWithTax === shippingMethod?.customFields?.applicationFee) {
    return shippingMethod?.customFields?.applicationFee
  }
  const applicationFee = shippingMethod?.customFields?.applicationFee || 0
  let result = 0
  currentCart?.lines
    ?.filter((ol) => !!ol?.productVariant?.product?.customFields?.isHeavyProduct === isHeavyProduct)
    .forEach((ol) => {
      result = result + (ol?.customFields?.totalShippingFee || 0)
    })
  return result + applicationFee
}

const getShippingMethodByCode = (shippingMethodCode, eligibleShippingMethods = []) => {
  return eligibleShippingMethods?.find((sm) => sm.code === shippingMethodCode)
}

const getHeavyShippingAddress = (shipping, currentUser, currentCart) => {
  return (
    shipping?.shippingAddress?.heavy ||
    getCustomerAddressFromCart(currentCart, currentUser) ||
    CustomerHelper.getDefaultShippingAddress(currentUser)
  )
}

const getLightShippingAddress = (
  shipping,
  currentUser,
  currentCart,
  shippingMethod,
  eligibleShippingMethods
) => {
  const relayAddressFromShipping = shipping?.shippingAddress?.light?.type
    ? shipping?.shippingAddress?.light
    : null
  const customerAddressFromShipping = shipping?.shippingAddress?.light?.type
    ? null
    : shipping?.shippingAddress?.light
  if (shippingMethod?.customFields?.addressType === 'RELAY') {
    return relayAddressFromShipping || getRelayAddressFromCart(currentCart, eligibleShippingMethods)
  } else if (shippingMethod?.customFields?.addressType === 'CUSTOMER') {
    return (
      customerAddressFromShipping ||
      getCustomerAddressFromCart(currentCart, currentUser) ||
      shipping?.shippingAddress?.heavy ||
      CustomerHelper.getDefaultShippingAddress(currentUser)
    )
  }
}

const checkDisplay = (step, currentCart) => {
  const hasLightProduct = getHasLightProduct(currentCart)
  const hasHeavyProduct = getHasHeavyProduct(currentCart)
  if (!step || step < 1) {
    return {
      showLightShipping: hasLightProduct,
      showHeavyShipping: hasHeavyProduct
    }
  } else if (step === 1) {
    return {
      showLightShipping: !hasHeavyProduct,
      showHeavyShipping: hasHeavyProduct
    }
  } else {
    return {
      showLightShipping: true,
      showHeavyShipping: true
    }
  }
}
const getShipping = (currentCart, currentUser, shipping) => {
  const eligibleShippingMethods = shipping?.eligibleShippingMethods || []
  const hasLightProduct = getHasLightProduct(currentCart)
  const hasHeavyProduct = getHasHeavyProduct(currentCart)
  const cartShippingMethod = getCartShippingMethod(currentCart, eligibleShippingMethods)
  const heavyShippingMethod = !hasHeavyProduct
    ? null
    : getShippingMethodByCode('standard', eligibleShippingMethods)
  const lightShippingMethod = !hasLightProduct
    ? null
    : shipping?.shippingMethod?.light ||
      cartShippingMethod ||
      getDefaultShippingMethod(eligibleShippingMethods)

  const heavyShippingAddress = getHeavyShippingAddress(shipping, currentUser, currentCart)
  const lightShippingAddress = getLightShippingAddress(
    shipping,
    currentUser,
    currentCart,
    lightShippingMethod
  )

  const newShipping = {
    isLoading: shipping?.isLoading ?? true,
    step: shipping?.step,
    ...checkDisplay(shipping?.step, currentCart),
    hasHeavyProduct,
    hasLightProduct,
    shippingMethod: {
      heavy: heavyShippingMethod,
      light: lightShippingMethod
    },
    shippingPrice: {
      heavy: !hasHeavyProduct ? 0 : calculateShippingPrice(currentCart, heavyShippingMethod, true),
      light: !hasLightProduct ? 0 : calculateShippingPrice(currentCart, lightShippingMethod, false)
    },
    shippingAddress: {
      heavy: !hasHeavyProduct ? null : heavyShippingAddress,
      light: !hasLightProduct ? null : lightShippingAddress
    },
    phone: !isNil(shipping?.phone)
      ? shipping?.phone
      : trim(currentCart?.customFields?.phone) ||
        heavyShippingAddress?.phoneNumber ||
        lightShippingAddress?.phoneNumber,
    cellPhone: !isNil(shipping?.cellPhone)
      ? shipping?.cellPhone
      : trim(shipping?.cellPhone) ||
        trim(currentCart?.customFields?.cellPhone) ||
        heavyShippingAddress?.customFields?.cellPhoneNumber ||
        lightShippingAddress?.customFields?.cellPhoneNumber,
    eligibleShippingMethods
  }

  return newShipping
}

const updateShipping = (
  {
    step,
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false,
    eligibleShippingMethods,
    isLoading = false
  },
  currentCart,
  currentUser,
  shipping
) => {
  const newShipping = getShipping(currentCart, currentUser, shipping)
  set(newShipping, `isLoading`, isLoading)
  if (step) {
    set(newShipping, `step`, step)
  }
  if (eligibleShippingMethods) {
    set(newShipping, `eligibleShippingMethods`, eligibleShippingMethods)
  }
  if (shippingMethod) {
    set(newShipping, `shippingMethod.${isHeavy ? 'heavy' : 'light'}`, shippingMethod)
  }
  if (!isNil(phone)) {
    set(newShipping, 'phone', phone)
  }
  if (!isNil(cellPhone)) {
    set(newShipping, 'cellPhone', cellPhone)
  }
  if (
    shippingCustomerAddress &&
    get(newShipping, `shippingMethod.${isHeavy ? 'heavy' : 'light'}`)?.customFields?.addressType ===
      'CUSTOMER'
  ) {
    set(newShipping, `shippingAddress.${isHeavy ? 'heavy' : 'light'}`, shippingCustomerAddress)
    set(newShipping, 'phone', shippingCustomerAddress.phoneNumber)
    set(newShipping, 'cellPhone', shippingCustomerAddress?.customFields?.cellPhoneNumber)
    if (
      get(newShipping, `shippingMethod.${!isHeavy ? 'heavy' : 'light'}`)?.customFields
        ?.addressType === 'CUSTOMER'
    ) {
      set(newShipping, `shippingAddress.${!isHeavy ? 'heavy' : 'light'}`, shippingCustomerAddress)
    }
  }
  if (
    shippingRelayAddress &&
    get(newShipping, `shippingMethod.${isHeavy ? 'heavy' : 'light'}`)?.customFields?.addressType ===
      'RELAY'
  ) {
    set(newShipping, `shippingAddress.${isHeavy ? 'heavy' : 'light'}`, shippingRelayAddress)
  }
  return getShipping(currentCart, currentUser, newShipping)
}

export { getHasHeavyProduct, getHasLightProduct, getShipping, updateShipping }
