import { Box, Button, Flex, Input } from '@stars-ecom/shared-atoms-ui'
import isNil from 'lodash/isNil'
import React, { useContext, useState } from 'react'
import * as Yup from 'yup'

import { AccountConstants } from '../constants'
import { WebsiteContext } from '../context'
const AccountResetPassword = ({ resetPassword }) => {
  const websiteContext = useContext(WebsiteContext)
  const [errorValidation, setErrorValidation] = useState({ state: true })
  const [passwords, setPasswords] = useState({})

  let schema = Yup.object().shape({
    pass: Yup.string()
      .required('pass-required')
      .matches(AccountConstants.regExpasswd, 'error-regex'),
    confirmPass: Yup.string()
      .oneOf([Yup.ref('pass'), null], 'pass-not-matched')
      .when('pass', {
        is: (pass) => !isNil(pass),
        then: Yup.string().required('confirm-required')
      })
  })
  const checkValidation = () => {
    schema
      .validate({ pass: passwords.pass, confirmPass: passwords.confirmPass })
      .then(async function () {
        setErrorValidation({ state: false })
      })
      .catch(function (err) {
        if (err.errors.toString() === 'pass-required' || err.errors.toString() === 'error-regex') {
          setErrorValidation({ state: true, input: 'pass', message: err.errors.toString() })
        } else {
          setErrorValidation({ state: true, input: 'confirmPass', message: err.errors.toString() })
        }
      })
  }
  const handleSubmit = async (event) => {
    event.preventDefault()
    try {
      checkValidation()
      if (!errorValidation.state) {
        await resetPassword(passwords.pass)
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleChange = (event) => {
    const name = event.target.name
    const value = event.target.value
    setPasswords((values) => ({ ...values, [name]: value }))
  }
  return (
    <Box pb="80px">
      <Box p={{ base: '10px', md: '40px' }} bgColor={{ base: 'initial', md: '#fff' }}>
        <h2
          style={{
            fontWeight: 'bold',
            fontSize: '1.5em',
            lineHeight: '1',
            marginBottom: '15px'
          }}>
          Définir un nouveau mot de passe
        </h2>
        <form onSubmit={handleSubmit}>
          <Flex mb={{ base: '5px', md: '20px' }} flexDirection={{ base: 'column', md: 'row' }}>
            <label
              htmlFor="password"
              style={{
                color: errorValidation && errorValidation?.input === 'pass' ? '#ea412e' : '#999',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '1.071em',
                width: websiteContext?.isMobile ? 'auto' : '26%',
                marginBottom: '5px'
              }}>
              NOUVEAU MOT DE PASSE *
            </label>
            <Box width={{ base: 'auto', md: '36%' }}>
              <Input
                id="pass"
                name="pass"
                type="password"
                onChange={handleChange}
                onBlur={checkValidation}
                borderWidth={errorValidation && errorValidation?.input === 'pass' ? '2px' : '1px'}
                borderColor={
                  errorValidation && errorValidation?.input === 'pass' ? '#ea412e' : '#c1c1c1'
                }
                className={errorValidation && errorValidation?.input === 'pass' ? 'bounce' : ''}
                bg="white"
              />
              {errorValidation && errorValidation?.message == 'error-regex' && (
                <Box fontSize="11px" pt="5px">
                  <b>Votre mot de passe doit contenir au moins 4 des 5 types suivants:</b>
                  <ul style={{ paddingLeft: '15px' }}>
                    <li>Minimum 8 caractères (obligatoire)</li>
                    <li>Une minuscule</li>
                    <li>Une majuscule</li>
                    <li>Un chiffre</li>
                    <li>Un caractère spécial</li>
                  </ul>
                </Box>
              )}
            </Box>
          </Flex>
          <Flex mb={{ base: '5px', md: '20px' }} flexDirection={{ base: 'column', md: 'row' }}>
            <label
              htmlFor="confirmPass"
              style={{
                color:
                  errorValidation && errorValidation?.input === 'confirmPass' ? '#ea412e' : '#999',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '1.071em',
                width: websiteContext?.isMobile ? 'auto' : '26%',
                marginBottom: '5px'
              }}>
              CONFIRMER LE NOUVEAU MOT DE PASSE *
            </label>
            <Input
              id="confirmPass"
              name="confirmPass"
              type="password"
              width={{ base: 'auto', md: '36%' }}
              onChange={handleChange}
              onBlur={checkValidation}
              borderWidth={
                errorValidation && errorValidation?.input === 'confirmPass' ? '2px' : '1px'
              }
              borderColor={
                errorValidation && errorValidation?.input === 'confirmPass' ? '#ea412e' : '#c1c1c1'
              }
              bg="white"
              className={
                errorValidation && errorValidation?.input === 'confirmPass' ? 'bounce' : ''
              }
            />
          </Flex>
          <Flex justifyContent="right" flexDirection={{ base: 'column', md: 'row' }}>
            <Button
              type="submit"
              w={['100%', '100%', 'auto', 'auto']}
              mt={{ base: '10px', md: '15px' }}
              padding="5px 30px"
              bg={websiteContext?.darkColor}
              color="#fff"
              fontSize={{ base: '14px', md: '1rem' }}
              fontWeight={{ base: 'normal', md: '400' }}
              _hover={{ backgroundColor: websiteContext?.lightColor }}
              borderRadius="0">
              Réinitialiser votre mot de passe
            </Button>
          </Flex>
        </form>
      </Box>
    </Box>
  )
}
export default AccountResetPassword
