import React from 'react'

import Flex from '../layout/Flex'
import { Picto } from '../media'
import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '../overlay/Modal'
import Text from '../typography/Text'

const ErrorModal = (props) => {
  const { isOpen, onClose, icon, message, color = '#000000' } = props

  return (
    <Flex>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, .7)" />
        <ModalContent
          style={{
            borderRadius: '0',
            maxWidth: '330px',
            boxShadow: '0 10px 25px rgb(0 0 0 / 50%)'
          }}>
          <Flex justifyContent="center" alignItems="center" pt="35px">
            <Picto icon={icon} width="50px" height="50px" color={color} />
          </Flex>
          <ModalCloseButton
            _focus={{ borderStyle: 'none' }}
            _active={{ backgroundColor: 'none' }}
            style={{ right: '0', top: '0' }}
          />
          <ModalBody padding="15px">
            <Text align="center" fontFamily="PT Sans, Arial, sans-serif" fontSize="14px">
              {message}
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
}

export default ErrorModal
