import { axios } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'
const getExternalData = async (url, params) => {
  try {
    const result = await axios.post(
      `external-services/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getExternalData`,
      { url, params }
    )
    return get(result, 'data')
  } catch (e) {
    console.error(e)
    return {}
  }
}
const getGEDocument = async (numLiv) => {
  // In the GED DocumindMaster, numLiv are stored with min 7 digits
  numLiv = String(numLiv).padStart(7, '0')

  try {
    const response = await axios.get(
      `external-services/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getGEDocument?numLiv=${numLiv}`,
      { responseType: 'json' }
    )

    // Extract the base64-encoded PDF
    const pdfBase64 = response.data.body

    // Convert base64 string to binary data
    const byteCharacters = atob(pdfBase64)
    const byteNumbers = new Array(byteCharacters.length)
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i)
    }
    const byteArray = new Uint8Array(byteNumbers)

    const pdfBlob = new Blob([byteArray], { type: 'application/pdf' })
    const pdfUrl = URL.createObjectURL(pdfBlob)
    window.open(pdfUrl)
  } catch (e) {
    console.error('Document not found on GED', e)
    alert("Votre bon de livraison n'a pas été trouvé dans notre système.")
  }
}

const getCurrentChannel = async () => {
  try {
    const channel = await axios.get(
      `${axios.defaults.baseURL}/external-services/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getCurrentChannel`
    )

    return get(channel, 'data')
  } catch (e) {
    console.error(e)
  }
}

export { getExternalData, getGEDocument, getCurrentChannel }
