import React from 'react'

const windowGlobal = typeof window !== 'undefined' && window

const xmlParser = (string) => {
  try {
    if (windowGlobal) {
      const parser = new windowGlobal.DOMParser()
      const xmlDoc = parser.parseFromString(string, 'text/xml')
      return xmlDoc
    }
  } catch (err) {
    console.error('ivalid svg', err)
  }
  return null
}

const Svg = (props) => {
  try {
    const { width, height, color, data, style } = props
    if (!data || data?.trim() === '') {
      return null
    }
    const xml = xmlParser(data)
    if (xml) {
      if (width) {
        xml.getElementsByTagName('svg')[0]?.setAttribute('width', width)
      }
      if (height) {
        xml.getElementsByTagName('svg')[0]?.setAttribute('height', height)
      }
      if (color) {
        xml.querySelectorAll('path').forEach((node) => {
          node?.setAttribute('fill', color)
        })
      }
      if (style) {
        xml.getElementsByTagName('svg')[0]?.setAttribute('style', style)
      }
      return (
        <div
          style={{ width: width, height: height }}
          dangerouslySetInnerHTML={{ __html: new XMLSerializer().serializeToString(xml) }}
        />
      )
    }
  } catch (err) {
    console.error('Invalid SVG', err)
  }
  return null
}
export default Svg
