import { Button, Flex, Image, Input, Picto, Radio, Spacer, Text } from '@stars-ecom/shared-atoms-ui'
import { find, get, isEmpty } from 'lodash'
import React, { memo, useContext, useEffect, useState } from 'react'

import { americanExpress, cb, mastercard, visa } from '../checkout/icones'
import { WebsiteContext } from '../context'

const OneClickForm = (props) => {
  const {
    selectedCard,
    hipayInstance,
    cards = [],
    setHipayErrorMessage = () => {},
    showForm = () => {},
    isShowForm = false,
    setIsValid = false,
    setSelectedCard = () => {}
  } = props

  const websiteContext = useContext(WebsiteContext)

  useEffect(() => {
    const defaultCard = find(cards, (c) => get(c, 'isDefault'))
    if (defaultCard) {
      setSelectedCard(defaultCard)
    } else {
      setSelectedCard(cards[0])
    }
  }, [cards])

  const [cardsResp, setCardsResp] = useState([])
  const isCVCUpdated = (card) => !isEmpty(cardsResp?.filter((c) => c.id === get(card, 'card_id')))

  useEffect(() => {
    if (!isEmpty(cardsResp) && selectedCard && get(selectedCard, 'card_id') !== '-1') {
      const updated = cardsResp?.filter((c) => c.id === get(selectedCard, 'card_id'))
      const newMetadata = { ...selectedCard, ...updated }
      setSelectedCard(newMetadata)
      setIsValid(isCVCUpdated(newMetadata))
    } else {
      setIsValid(false)
    }
  }, [cardsResp])

  useEffect(() => {
    if (!isEmpty(cardsResp) && selectedCard && get(selectedCard, 'card_id') !== '-1') {
      setIsValid(isCVCUpdated(selectedCard))
    }
  }, [selectedCard])

  const [isLoading, setIsLoading] = useState(false)

  const updateToken = (paymentCard, cvc) => {
    if (hipayInstance) {
      setIsLoading(true)
      const params = {
        request_id: 0,
        card_token: get(paymentCard, 'token'),
        card_expiry_month: get(paymentCard, 'card_expiry_month'),
        card_expiry_year: get(paymentCard, 'card_expiry_year'),
        card_holder: get(paymentCard, 'card_holder'),
        cvc: cvc
      }

      hipayInstance.updateToken(params).then(
        (response) => {
          setCardsResp([...cardsResp, { id: get(paymentCard, 'card_id'), response }])
          setIsLoading(false)
        },
        (error) => {
          console.error(error)
          setHipayErrorMessage('Une erreur est survenue lors de la mise à jour du cryptogramme!')
          setIsLoading(false)
        }
      )
    }
  }

  const getIcon = (paymentCard) => {
    if (paymentCard) {
      const brand = get(paymentCard, 'brand')

      switch (brand) {
        case 'VISA':
          return visa
        case 'MASTERCARD':
          return mastercard
        case 'cb':
          return cb
        case 'AMERICAN EXPRESS':
          return americanExpress
        default:
          return undefined
      }
    }
  }

  return (
    <>
      <Flex flexDirection="column" align="center">
        <Flex justify="center" w="100%" direction="column" align="center">
          {!isEmpty(cards) &&
            cards.map((paymentCard) => (
              <Flex
                key={`card-container-${get(paymentCard, 'card_id')}`}
                p="10px"
                mb="5px"
                border="1px solid #ccc"
                justifyContent={'space-between'}
                w="85%">
                <Flex
                  justifyContent={'space-between'}
                  w="100%"
                  flexDirection={{ base: 'column', md: 'row' }}>
                  <Radio
                    color={websiteContext?.mainColor}
                    onChange={() => {
                      setSelectedCard(paymentCard)
                      showForm(false)
                    }}
                    isChecked={get(paymentCard, 'card_id') === get(selectedCard, 'card_id')}
                    //value={`${get(paymentCard, 'card_id')}`}
                    name="card-input"
                    data-token-card={`${get(paymentCard, 'token')}`}>
                    <Flex justifyContent={'space-between'}>
                      {getIcon(paymentCard) && (
                        <Image
                          src={getIcon(paymentCard)}
                          w="40px"
                          mr="10px"
                          display={{ base: 'none', md: 'unset' }}
                        />
                      )}
                      <Flex direction="column">
                        <Text fontSize="14px" fontFamily={websiteContext.fontFamily} as="span">
                          {`${get(paymentCard, 'card_holder')}`}
                        </Text>
                        <Text
                          fontSize={{ base: '12px', md: '14px' }}
                          fontFamily={websiteContext.fontFamily}
                          as="span">
                          {`${get(paymentCard, 'pan')}`} -
                          {`${get(paymentCard, 'card_expiry_month')}/${get(
                            paymentCard,
                            'card_expiry_year'
                          )}`}
                        </Text>
                      </Flex>
                    </Flex>
                  </Radio>
                  <Spacer />
                  <CVCForm
                    selected={get(paymentCard, 'card_id') === get(selectedCard, 'card_id')}
                    isLoading={isLoading}
                    paymentCard={paymentCard}
                    updateToken={updateToken}
                    isCVCUpdated={isCVCUpdated}
                  />
                </Flex>
              </Flex>
            ))}
        </Flex>
        <Flex w="100%" justify="center">
          <Flex w="85%">
            {!isShowForm && (
              <Button
                fontFamily={websiteContext.fontFamily}
                borderRadius="4px"
                fontSize="12px"
                h="30px"
                maxW="110px"
                mb="40px"
                _hover={{ bg: websiteContext.darkColor }}
                bg={websiteContext.mainColor}
                color="#FFF"
                mt={{ base: '10px', md: '0' }}
                onClick={() => showForm(true)}>
                Ajouter une carte
              </Button>
            )}
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

const _CVCForm = (props) => {
  const {
    paymentCard,
    updateToken = () => {},
    isCVCUpdated = () => {},
    isLoading = false,
    selected = false
  } = props

  const websiteContext = useContext(WebsiteContext)
  const [cvc, setCvc] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)

  return (
    <>
      {isCVCUpdated(paymentCard) ? (
        <Flex direction="column" align="center" justifyContent="center" px="10px">
          <Picto icon="check" width="20px" height="20px" color="green" />
        </Flex>
      ) : (
        <>
          {selected && (
            <Flex
              alignItems="center"
              justifyContent={{ base: 'start', md: 'space-between' }}
              mt={{ base: '10px', md: '0' }}>
              <Input
                h="30px"
                w={{ base: 'unset', md: '70px' }}
                key={`cvc-input"-${get(paymentCard, 'card_id')}`}
                name="cvc-input"
                placeholder="123"
                autoComplete="off"
                value={cvc}
                borderRadius="0"
                maxLength={get(paymentCard, 'brand') === 'AMERICAN EXPRESS' ? '4' : '3'}
                onKeyUp={(e) => setIsDisabled(!/^[0-9]{3,4}$/.test(e.target.value))}
                onKeyPress={(e) => {
                  if (/\D+/.test(e.key)) {
                    e.preventDefault()
                  }
                }}
                onChange={(e) => {
                  setCvc(e.target.value)
                }}
              />
              <Button
                ml="5px"
                h="30px"
                fontSize="14px"
                disabled={isDisabled}
                isLoading={isLoading}
                fontFamily={websiteContext.fontFamily}
                _hover={{ bg: websiteContext.darkColor }}
                borderRadius="0"
                bg={websiteContext.mainColor}
                color="#FFF"
                onClick={() => updateToken(paymentCard, cvc)}>
                OK
              </Button>
            </Flex>
          )}
        </>
      )}
    </>
  )
}

const CVCForm = memo(_CVCForm)
export default memo(OneClickForm)
