import './simplePage.css'

import { Flex, rewriteMediaUrl, Text } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { memo, useContext } from 'react'

const SimplePageLayout = (props) => {
  const { simplePage } = props
  const websiteContext = useContext(WebsiteContext)
  const contentWithMediaUrls = simplePage ? rewriteMediaUrl(simplePage.content) : ''
  return (
    <Flex
      direction="column"
      maxW="1000px"
      width="100%"
      pl={{ base: '14px', md: '10px' }}
      pr={{ base: '14px', md: '10px' }}>
      <Text
        as="h1"
        color="#333"
        fontSize={{ base: '28px', md: '60px' }}
        lineHeight={{ base: '28px', md: '60px' }}
        fontWeight={700}
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        mb={{ base: '30px', md: '40px' }}
        pt={{ base: '30px', md: '50px' }}>
        {simplePage?.title}
      </Text>
      {contentWithMediaUrls&& 
      <Flex
        direction="column"
        fontFamily="PT Sans, Arial, sans-serif"
        className="simple-page-content"
        dangerouslySetInnerHTML={{ __html: contentWithMediaUrls }}
        pb={{ base: '10px', md: '20px' }}
        sx={{
          '& a': {
            color: websiteContext?.mainColor,
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }}
      />}
    </Flex>
  )
}

export default memo(SimplePageLayout)
