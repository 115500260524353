import { Box, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { WebsiteContext } from '../context'
import { AccountMenu, AccountMenuMobile } from './AccountMenu'
import LogoutButton from './LogoutButton'
const AccountLayout = (props) => {
  const { children, isCheckout = false } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      direction="column"
      justify="space-between"
      width="100%"
      maxWidth={{ base: '100%', md: '1000px' }}
      pl={{ base: '0', md: '15px' }}
      mt="40px">
      {!isCheckout && (
        <>
          <Flex
            direction="row"
            align="flex-end"
            justify="space-between"
            flexWrap="wrap"
            mb={{ base: '0px', md: '40px' }}
            padding={{ base: '0px 15px', md: 'auto' }}>
            <Text
              as="h1"
              fontFamily={websiteContext?.fontFamilyNarrow}
              fontSize={{ base: '32px', md: '60px' }}
              lineHeight="60px"
              fontWeight={700}>
              Mon compte {websiteContext?.title}
            </Text>
            {!websiteContext?.isMobile && <LogoutButton />}
          </Flex>
          <Flex direction="row" gridGap="20px">
            <AccountMenu />
            <Box maxWidth={{ base: '100%', md: '745px' }} width="100%">
              <AccountMenuMobile />
              {children}
            </Box>
          </Flex>
        </>
      )}
      {isCheckout && <>{children}</>}
    </Flex>
  )
}

export default AccountLayout
