import { Box, Flex, GatsbyImage, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { DataLayerUtils } from '../../context'

const Catalog = (props) => {
  const { title, link, subTitle, buttonText, image } = props
  return (
    <Flex
      direction="column"
      width={{ base: '100%', md: '230px' }}
      align={{ base: 'center', md: 'flex-start' }}
      borderTop={{ base: '1px solid #cfd5e3', md: 'none' }}
      pt={{ base: '20px', md: '0' }}
      pb={{ md: '20px' }}>
      <Text
        color="#333333"
        fontWeight={600}
        fontSize="20px"
        fontFamily="PT Sans, Arial, sans-serif"
        ml={{ base: 0, md: '20px' }}
        mb={{ base: '0px', md: '20px' }}>
        {title}
      </Text>
      <Flex direction={{ base: 'column', md: 'row' }} align={{ base: 'center', md: 'flex-start' }}>
        <Link
          to={link}
          style={{ width: '75px', height: '109px', order: 1 }}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEventLab',
              eventCat: 'Footer',
              eventAct: 'Départ catalogue',
              eventLab: "[Image / J'y vais]"
            })
          }>
          <GatsbyImage
            alt={title}
            image={image?.image?.childImageSharp?.gatsbyImageData}
            loading="eager"
            width="75px"
            height="109px"
          />
        </Link>
        <Flex
          flexDirection="column"
          fontFamily="PT Sans, Arial, sans-serif"
          color="#333333"
          fontSize="14px"
          ml={{ base: 0, md: '14px' }}
          width="135px"
          order={{ base: 0, md: 2 }}>
          <Text pt="14px" pb="14px" textAlign={{ base: 'center', md: 'left' }}>
            {subTitle}
          </Text>
          <Box display={{ base: 'none', md: 'block' }}>
            <Link
              to={link}
              style={{ textDecoration: 'underline' }}
              onClick={() =>
                DataLayerUtils.addEvent({
                  event: 'qwampEventLab',
                  eventCat: 'Header',
                  eventAct: 'Départ téléphone'
                })
              }>
              {buttonText}
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Catalog
