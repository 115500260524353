import { Box, Flex, Picto, Select, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { WebsiteContext } from '../context'

const ContactCategories = (props) => {
  const { category, subCategory, handleCategoryChange, handleSubCategoryChange } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <>
      <Flex
        justify="center"
        mt="20px"
        mb={{ base: '10px', md: '0' }}
        padding={{ md: '30px 0' }}
        backgroundColor={{ base: 'transparent', md: 'white' }}>
        <Flex
          w={{ base: '100%', md: '80%' }}
          justifyContent="space-around"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Text
            as="label"
            color="#999"
            fontSize={{ base: '15px', md: '14px' }}
            fontWeight={700}
            width="215px"
            mt="10px"
            mb={{ base: '5px', md: '0px' }}
            style={{ textTransform: 'uppercase' }}
            fontFamily={websiteContext?.fontFamily}>
            RUBRIQUE *
          </Text>
          <Box
            width={{ base: '100%', md: '510px' }}
            sx={{
              svg: {
                width: '12px !important',
                height: '12px !important'
              }
            }}>
            <Select
              placeholder="Sélectionnez la catégorie de votre demande"
              icon={<Picto icon="arrowUpDown" />}
              fontSize="14px"
              backgroundColor="white"
              border="1px solid"
              borderColor="#c1c1c1"
              width="auto"
              h="36px"
              onChange={(e) => handleCategoryChange(e)}
              fontFamily={websiteContext?.fontFamily}>
              {category?.map((categories) => (
                <option key={categories?.category?.id} value={categories?.category?.id}>
                  {categories?.category?.title}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Flex>
      <Flex
        justify="center"
        mt={{ md: '10px' }}
        mb="10px"
        padding={{ md: '30px 0' }}
        backgroundColor={{ base: 'transparent', md: 'white' }}>
        <Flex
          w={{ base: '100%', md: '80%' }}
          justifyContent="space-around"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Text
            as="label"
            color="#999999"
            fontSize="14px"
            fontWeight={700}
            width="215px"
            mt="10px"
            mb={{ base: '5px', md: '0px' }}
            style={{ textTransform: 'uppercase' }}
            fontFamily={websiteContext?.fontFamily}>
            SOUS-RUBRIQUE *
          </Text>
          <Box
            width={{ base: '100%', md: '510px' }}
            sx={{
              svg: {
                width: '12px !important',
                height: '12px !important'
              }
            }}>
            <Select
              placeholder="Sélectionnez la nature de votre demande"
              icon={<Picto icon="arrowUpDown" />}
              fontSize="14px"
              backgroundColor="white"
              border="1px solid "
              borderColor="#c1c1c1"
              width="auto"
              h="36px"
              onChange={(e) => handleSubCategoryChange(e)}
              fontFamily={websiteContext?.fontFamily}>
              {subCategory?.map((categ) => (
                <option key={categ?.id} value={categ?.externalCode}>
                  {categ?.title}
                </option>
              ))}
            </Select>
          </Box>
        </Flex>
      </Flex>
    </>
  )
}

export default ContactCategories
