import './productThumbnail.css'

import {
  Button,
  ErrorModal,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Text,
  Tooltip,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import take from 'lodash/take'
import React, { useContext, useEffect, useState } from 'react'

import MaxProductModal from '../cart/MaxProductModal'
import { ApiContext, CartContext, DataLayerUtils, WebsiteContext } from '../context'
import { StarBox } from '../rating'
import ThumbnailPrice from './price/ThumbnailPrice'
import ProductTag from './ProductTag'
import ProductVariantSelector from './ProductVariantSelector'

const ProductThumbnail = (props) => {
  const {
    product,
    style = {},
    hasBorder = true,
    isLarge = false,
    isList = false,
    isUpsell = false,
    listName,
    position,
    productCategory1,
    productCategory2
  } = props
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const [alreadyAdded, setAlreadyAdded] = useState(false)
  const [isClicked, setIsClicked] = useState(false)
  const {
    isOpen: isOpenOrderLimitErrorModal,
    onOpen: onOpenOrderLimitErrorModal,
    onClose: onCloseOrderLimitErrorModal
  } = useDisclosure()
  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal
  } = useDisclosure()

  const addItemToOrder = async () => {
    if (!productVariant) {
      return
    }
    const result = await apiContext?.OrderApi?.addItemToOrder(productVariant.id, 1, 'UPSELL')
    DataLayerUtils.addToCart({
      orderCode: currentCart?.code,
      product,
      productVariant,
      offerType: 'UPSELL'
    })
    if (result && result?.__typename === 'Order') {
      setAlreadyAdded(true)
    } else if (result && result?.__typename === 'OrderLimitError') {
      onOpenOrderLimitErrorModal()
    } else {
      onOpenErrorModal()
    }
  }
  const [productVariant, setProductVariant] = useState(
    product?.variants?.length === 1 ? product.variants[0] : null
  )

  const onProductVariantChange = (pv) => {
    setProductVariant(pv)
  }

  const handleProductVariantChange = (event) => {
    const productVariantId = event?.target?.value
    onProductVariantChange(product.variants?.find((pv) => pv.id === productVariantId))
  }

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const [reviews, setReviews] = useState({})

  const getCrossSellReviews = async (product) => {
    try {
      if (product.reviewAvg) {
        setReviews(product.reviewAvg)
      } else {
        const code = product?.customFields?.productGroupCode
          ? product?.customFields?.productGroupCode
          : product?.customFields?.externalCode
        const result = await apiContext?.ReviewApi?.getReviewAvg(code)
        if (result) {
          setReviews(result)
        }
      }
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    getCrossSellReviews(product)
  }, [product])

  const ThumbnailContent = () => {
    return (
      <Flex direction="column">
        <Flex gridGap="3px" w="100%" minH="30px" flexWrap="wrap" p="3px">
          {take(
            product?.facetValues?.filter(
              (f) => f?.facet?.code === 'product-tag-channel' && f?.customFields?.visible
            ),
            2
          ).map((f, i) => (
            <ProductTag
              key={`product_tag-${i}`}
              label={f?.name}
              color={f?.customFields?.color}
              fontSize="15px"
              height="21px"
            />
          ))}
        </Flex>
        <Flex
          align="flex-start"
          justify="center"
          width={{
            base: isUpsell ? '85%' : isLarge ? '250px' : isList ? '104px' : '100%',
            md: isUpsell ? '85%' : 'unset'
          }}
          m="auto"
          height={{ base: 'auto', md: '215px' }}>
          <Flex
            align="center"
            justify="center"
            style={{
              overflow: 'hidden'
            }}>
            {product?.featuredAsset && (
              <GatsbyImage
                image={
                  isLarge
                    ? product?.featuredAsset?.largeImage?.childImageSharp?.gatsbyImageData
                    : product?.featuredAsset?.image?.childImageSharp?.gatsbyImageData
                }
                loading="eager"
                alt={product?.name}
                title={product?.name}
                className="product_thumbnail_zoom"
              />
            )}
          </Flex>
        </Flex>
        <Flex
          position="absolute"
          bottom="0"
          left="0"
          bg="#fff"
          direction="column"
          alignSelf="center"
          justifyContent="space-between"
          width="100%"
          alignItems="center">
          <Flex
            direction="column"
            justify="center"
            fontFamily="PT Sans, Arial, sans-serif"
            alignItems="center"
            maxWidth={!isUpsell && { base: '100%', md: '90%' }}
            width={{ base: '100%', md: 'unset' }}
            fontSize={{
              base: isUpsell ? '12px' : isList ? '13px' : '16px',
              md: isUpsell ? '12px' : '16px'
            }}>
            <Text
              fontFamily="PT Sans, Arial, sans-serif"
              as="h3"
              textTransform={isUpsell ? 'uppercase' : 'initial'}
              style={{
                fontColor: '#333',
                fontWeight: 700,
                textAlign: 'center',
                lineHeight: '1',
                padding: '0 4px',
                whiteSpace: 'normal',
                wordWrap: 'break-word'
              }}>
              {product?.name}
            </Text>
            {websiteContext.showReviews && (
              <Flex
                direction="row"
                align="center"
                justify="center"
                height="12px"
                marginTop={{
                  base: isList && !isUpsell ? '5px' : '10px',
                  md: '10px'
                }}>
                {reviews?.rate ? (
                  <>
                    <StarBox rating={reviews?.rate || 0} size={12} gap={3} />
                    <Text
                      ml="5px"
                      color="#999999"
                      fontFamily="PT Sans, Arial, sans-serif"
                      fontSize="12px">{`(${reviews?.nbReviews || 0})`}</Text>
                  </>
                ) : (
                  <Flex height="12px" />
                )}
              </Flex>
            )}
          </Flex>
          <ThumbnailPrice
            product={product}
            type={isUpsell ? 'UPSELL' : ''}
            height={isUpsell ? '36px' : 'auto'}
            isUpsell={isUpsell ? true : false}
            isList={isList}
          />
          {isUpsell &&
            (alreadyAdded ? (
              <Flex mt="10px" mb="10px">
                <Picto icon="checkLight" width="18px" color={websiteContext?.mainColor} />
                <Text
                  fontFamily="PT Sans, Arial, Sans-serif"
                  fontSize="12px"
                  textTransform="uppercase"
                  color={websiteContext?.mainColor}
                  ml="6px"
                  lineHeight="1"
                  alignSelf="flex-end">
                  Ajouté
                </Text>
              </Flex>
            ) : (
              <Flex w="100%" justify="center" gap="4px" p="0 5px 5px">
                {!isEmpty(get(product, 'variants.0.options', [])) && (
                  <Flex
                    w="50%"
                    sx={{
                      select: {
                        padding: '0 4px'
                      },
                      svg: {
                        marginLeft: '16px'
                      }
                    }}>
                    <ProductVariantSelector
                      product={product}
                      productVariant={productVariant}
                      onChange={handleProductVariantChange}
                      isClicked={isClicked}
                      isUpsell={isUpsell}
                    />
                  </Flex>
                )}
                <Tooltip
                  hasArrow
                  label={`Merci de choisir votre ${product?.variants[0]?.options[0]?.group?.name.toLowerCase()}`}
                  bg="#FFF"
                  color="#000"
                  border="1px solid #999"
                  p="10px"
                  isOpen={isTooltipOpen}
                  arrowShadowColor="#999">
                  <Button
                    onClick={() => {
                      addItemToOrder()
                      setIsClicked(true)
                    }}
                    variant="solid"
                    style={{
                      borderRadius: 0,
                      width: '50%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '30px',
                      fontSize: '12px',
                      fontWeight: 400,
                      fontFamily: 'PT Sans,Arial,sans-serif',
                      marginTop: '6px',
                      marginBottom: '6px',
                      boxShadow: 'none'
                    }}
                    border="1px solid"
                    borderColor={websiteContext.mainColor}
                    color={websiteContext.mainColor}
                    backgroundColor="#fff"
                    onMouseEnter={() =>
                      !productVariant ? setIsTooltipOpen(true) : setIsTooltipOpen(false)
                    }
                    onMouseLeave={() => setIsTooltipOpen(false)}
                    _hover={{ backgroundColor: websiteContext.mainColor, color: '#fff' }}>
                    AJOUTER
                  </Button>
                </Tooltip>
              </Flex>
            ))}
          <MaxProductModal
            isOpen={isOpenOrderLimitErrorModal}
            onOpen={onOpenOrderLimitErrorModal}
            onClose={onCloseOrderLimitErrorModal}
          />
          <ErrorModal
            isOpen={isOpenErrorModal}
            onOpen={onOpenErrorModal}
            onClose={onCloseErrorModal}
            icon="informationCercle"
            message="Une erreur est survenue lors de l'ajout au panier!"
            color={websiteContext?.mainColor}
          />
        </Flex>
      </Flex>
    )
  }

  const thumbnailClick = () => {
    DataLayerUtils.addProductClick({
      product,
      productCategory1,
      productCategory2,
      position,
      offerType: isUpsell ? 'UPSELL' : 'STANDARD',
      listName
    })
  }

  return (
    <>
      {isUpsell ? (
        <Flex
          className="product-thumbnail"
          scrollMarginTop={{
            base: 'unset',
            md: '50px'
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            border: hasBorder ? '1px solid #e7e7e7' : 'none',
            overflow: 'hidden',
            width: '180px',
            height: '300px',
            padding: '5px 6px 10px',
            marginRight: '5px',
            ...style
          }}>
          <ThumbnailContent />
        </Flex>
      ) : (
        <Flex
          className="product-thumbnail"
          scrollMarginTop={{
            base: 'unset',
            md: '50px'
          }}
          style={{
            display: 'flex',
            flexDirection: 'column',
            position: 'relative',
            alignItems: 'center',
            backgroundColor: '#ffffff',
            border: hasBorder ? '1px solid #e7e7e7' : 'none',
            overflow: 'hidden',
            padding: '10px',
            ...style
          }}
          width={{
            base: isLarge ? '100%' : isList ? '100%' : '235px',
            md: isLarge ? '490px' : isList ? '100%' : '235px'
          }}
          height={{
            base: isLarge ? '340px' : isList ? '240px' : '340px',
            md: isLarge ? '490px' : '340px'
          }}>
          <Link
            style={{ width: '100%' }}
            to={`/${product?.customFields?.urlKey}`}
            onClick={thumbnailClick}>
            <ThumbnailContent />
          </Link>
        </Flex>
      )}
    </>
  )
}

export default ProductThumbnail
