import { ChakraIcons, Flex, Skeleton, Text } from '@stars-ecom/shared-atoms-ui'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

const ViewCounter = (props) => {
  const { api, productId, nbViews: _nbViews = 0, display = {} } = props
  const [nbViews, setNbViews] = useState(_nbViews)
  const [loading, setLoading] = useState(isFunction(api))

  useEffect(() => {
    if (api && isFunction(api)) {
      setLoading(true)
      api(productId)
        .then((result) => {
          setNbViews(result)
          setLoading(false)
        })
        .catch((e) => {
          console.error(e)
          setNbViews(0)
          setLoading(false)
        })
    }
  }, [_nbViews])
  if (nbViews || loading) {
    return (
      <Skeleton isLoaded={!loading} display={display}>
        <Flex
          shrink="1"
          alignItems="center"
          justifyContent="center"
          gridGap="5"
          style={{
            backgroundColor: '#ff0001',
            borderRadius: '8px',
            height: '40px',
            padding: '10px'
          }}>
          <ChakraIcons.TimeIcon color="#FFFFFF" boxSize="20px" />
          <Text color="#FFFFFF" fontSize="11px" fontWeight="bold">{`${nbViews} personne${
            nbViews > 1 ? 's ont' : ' a'
          } consulté ce produit aujourd’hui`}</Text>
        </Flex>
      </Skeleton>
    )
  } else {
    return null
  }
}

ViewCounter.propTypes = {
  api: PropTypes.func,
  productId: PropTypes.string,
  nbViews: PropTypes.number
}
export { ViewCounter }
