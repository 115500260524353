import './watchTVPopin.css'

import {
  Box,
  GatsbyImage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text
} from '@stars-ecom/shared-atoms-ui'
import head from 'lodash/head'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, DataLayerUtils, WebsiteContext } from '../context'
import VideoSlide from './VideoSlide'

const WatchTVPopin = (props) => {
  const { isOpen, onClose, embedUrl, legalMention, replayUrl, bannerImage } = props
  const [mainVideo, setMainVideo] = useState()
  const [videoList, setVideoList] = useState([])
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)

  const getData = async () => {
    const result = await apiContext?.TVShowApi?.getVideos(replayUrl)
    setVideoList(result?.data?.teleshopping?.replays?.items || [])
  }

  useEffect(() => {
    if (replayUrl) getData()
  }, [])

  useEffect(() => {
    setMainVideo(head(videoList))
  }, [videoList])

  const updateMainVideo = (video) => {
    setMainVideo(video)
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="xl" scrollBehavior="inside">
      <ModalOverlay bg="rgba(0, 0, 0, .9)" />
      <ModalContent
        w={{ base: '90%', md: '100%' }}
        mt="20px"
        mb="20px"
        maxW="826px"
        borderRadius="0"
        maxH="calc(100% - 2rem)"
        className="modal-content">
        <ModalCloseButton
          _focus={{ outline: 'none' }}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEvent',
              eventCat: 'Header',
              eventAct: 'Fermeture popin émissions tv'
            })
          }
          fontSize="14px"
          top="0 !important"
          right="0 !important"
          outline="none"
        />
        <ModalBody className="video-emission-body">
          <Box className="video-emission-content">
            <Box>
              <GatsbyImage image={bannerImage} alt="bannière" loading="eager" />
              <iframe
                className="video-emission-iframe"
                src={`${embedUrl}/${mainVideo?.id}`}
                style={{ background: '#FFF', width: '100%' }}
              />
              <Text
                as="h2"
                className="more-videos-title"
                fontFamily="PT Sans, Arial, Sans-serif"
                lineHeight={{ base: '58px', md: '36px' }}>
                Plus de vidéos <strong>{websiteContext?.title}</strong>
              </Text>
              <VideoSlide
                videos={videoList}
                updateMainVideo={updateMainVideo}
                mainVideo={mainVideo?.id}
              />
              <Box
                fontFamily="PT Sans, Arial, Sans-serif"
                fontSize="10px"
                lineHeight="12px"
                fontStyle="italic"
                mt="7px"
                color="#a9a49d"
                dangerouslySetInnerHTML={{ __html: legalMention }}></Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default WatchTVPopin
