import {
  Box,
  CircularProgress,
  Collapse,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { navigate } from 'gatsby'
import capitalize from 'lodash/capitalize'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import merge from 'lodash/merge'
import startsWith from 'lodash/startsWith'
import React, { memo, useContext, useEffect, useState } from 'react'

import { ApiContext, getSession, UserContext, WebsiteContext } from '../context'
import { ProductAttachments } from '../product'
import { priceFormat } from '../utils/price'
dayjs.extend(customParseFormat)

const windowGlobal = typeof window !== 'undefined' && window

const getSereneoDigest = async (customerCode, storeId = '1001', idCommande, numPos, timestamp) => {
  const enc = new TextEncoder() // always utf-8
  let key = await crypto.subtle.digest('SHA-256', enc.encode('3sH75|-|Sr1\\15e1fc4rE'))
  key = Array.from(new Uint8Array(key))
  key = key.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
  let result = await crypto.subtle.digest(
    'SHA-256',
    enc.encode(
      `Selfcare${customerCode}${storeId}SuiviCommande${idCommande}${numPos}${timestamp}${key}`
    )
  )
  result = Array.from(new Uint8Array(result))
  result = result.map((bytes) => bytes.toString(16).padStart(2, '0')).join('')
  return result
}

const OrderLine = (props) => {
  const { orderLine, details, order, isLoading, trackings, warrantyExtension } = props
  const { isOpen, onToggle } = useDisclosure()
  const [sereneoUrl, setSereneoUrl] = useState()
  const [trackingUrl, setTrackingUrl] = useState()
  const [chevronOpen, setChevronOpen] = useState(true)
  const timestamp = dayjs().unix()
  const apiContext = useContext(ApiContext)
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)
  const getTrackingLink = () => {
    if (isNil(details?.customFields?.trackingcode)) {
      setTrackingUrl(null)
    }
    const transporter = trackings.find((transp) => transp?.code === details?.customFields?.transp)
    if (isNil(transporter)) {
      setTrackingUrl(null)
    }

    if (details?.customFields?.transp === 'GIR') {
      setTrackingUrl(`${transporter?.url_pattern}${details?.customFields?.trackingcode}.html`)
    } else if (details?.customFields?.transp === 'MLR') {
      setTrackingUrl(
        `${transporter?.url_pattern}${details?.customFields?.trackingcode}&language=FR`
      )
    } else {
      setTrackingUrl(`${transporter?.url_pattern}${details?.customFields?.trackingcode}`)
    }
  }

  const getSereneoUrl = async () => {
    const idCommande = order?.customFields?.externalCode
      ? order?.customFields?.externalCode
      : order?.code

    const digest = await getSereneoDigest(
      currentUser?.customFields?.externalCode,
      process.env.GATSBY_API_CODSOC,
      idCommande,
      orderLine?.customFields?.numPos,
      timestamp
    )
    const environment = process.env.GATSBY_API_STAGE === 'prod' ? '' : 'Preprod-'
    const result = `https://${process.env.GATSBY_SELFCARE_STORE_NAME}.sereneo.com/${environment}Selfcare.kha?contact.canal=Selfcare&contact.selfcare.idClient=${currentUser?.customFields?.externalCode}&contact.selfcare.enseigne=${process.env.GATSBY_API_CODSOC}&contact.selfcare.origine=SuiviCommande&contact.selfcare.idCommande=${idCommande}&contact.selfcare.numeroPoste=${orderLine?.customFields?.numPos}&contact.selfcare.timestamp=${timestamp}&contact.selfcare.key=${digest}&contact.selfcare.pays.code=${process.env.GATSBY_API_COUNTRY_CODE}`
    setSereneoUrl(result)
  }
  useEffect(async () => {
    if (details) {
      getTrackingLink()
    }
  }, [details])

  const returnProducts = async (externalCode) => {
    const result = await apiContext.AccountApi.getOrderReturnDetail(
      order?.customFields?.externalCode
    )
    const detailsBifrost = result?.lines?.find(
      (product) => product?.customFields?.externalCode == externalCode
    )
    merge(details, detailsBifrost)
    navigate('/productreturn/form', {
      state: { orderLine, details, order }
    })
  }

  const getStatus = (status) => {
    if (isLoading) {
      return (
        <Flex align="center">
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )
    }
    switch (status?.toLowerCase()) {
      case 'annulé':
      case 'annulé : article épuisé':
        return (
          <Flex direction="row" align="center">
            <Picto icon="cross" width="20px" height="20px" color="#e41919" />
            <Text color="#e41919" fontWeight={600} ml="10px" fontSize="11px" lineHeight="15px">
              Annulé
            </Text>
          </Flex>
        )
      case 'expédié':
      case 'retourné':
        return (
          <Flex direction="row" align="center">
            <Picto icon="checkLight" width="20px" height="20px" fontWeight="100" color="#3da42f" />
            <Text color="#3da42f" fontWeight={600} ml="10px" fontSize="11px" lineHeight="15px">
              {capitalize(status)}
            </Text>
          </Flex>
        )
      case 'en cours de traitement':
      case 'en cours de préparation':
      case 'en cours de réapprovisionnement':
      case 'en attente de règlement':
      case 'retour en cours de traitement':
        return (
          <Flex direction="row" align="center">
            <Picto icon="clock" width="20px" height="20px" color="rgb(255, 162, 0)" />
            <Text
              color="rgb(255, 162, 0)"
              fontWeight={600}
              fontSize="11px"
              lineHeight="15px"
              ml="10px">
              {capitalize(status)}
            </Text>
          </Flex>
        )
      case 'en attente de retour':
        return (
          <Flex direction="row" align="center">
            <Picto icon="check" width="20px" height="20px" color="#3da42f" />
            <Text color="#3da42f" fontWeight={600} ml="10px" fontSize="11px" lineHeight="15px">
              Nous vous avertirons dès que le colis nous sera parvenu.
            </Text>
          </Flex>
        )
      default:
        return (
          <Flex direction="row" align="center">
            <Picto icon="check" width="20px" height="20px" color="#3da42f" />
            <Text color="#3da42f" fontWeight={600} ml="10px" fontSize="11px" lineHeight="15px">
              {capitalize(status)}
            </Text>
          </Flex>
        )
    }
  }

  const getDeliveryDoc = async () => {
    if (details?.customFields?.numLiv) {
      await apiContext?.ExternalServicesApi.getGEDocument(details?.customFields?.numLiv)
    }
  }

  return (
    <Box gridGap="2px">
      <Flex
        direction={{ base: 'column', md: 'row' }}
        width="100%"
        backgroundColor="#f6f3f1"
        p="20px"
        justify="space-between">
        <Flex direction="column" width={{ base: '100%', md: '50%' }}>
          <Text
            fontWeight={700}
            mb="20px"
            fontSize="14px"
            lineHeight="30px"
            color="#333"
            display={{ base: 'none', md: 'block' }}>
            Produit
          </Text>
          <Flex direction="row">
            <Flex
              key={`ol_${orderLine?.id}`}
              width="100px"
              height="100px"
              justify="center"
              align="center"
              backgroundColor="#ffffff">
              {orderLine?.productVariant?.product?.featuredAsset?.smallImage?.childImageSharp
                ?.gatsbyImageData ? (
                <GatsbyImage
                  alt={orderLine?.productVariant?.name}
                  image={
                    orderLine?.productVariant?.product?.featuredAsset?.smallImage?.childImageSharp
                      ?.gatsbyImageData
                  }
                />
              ) : (
                orderLine?.productVariant?.customFields?.externalCode
              )}
            </Flex>
            <Flex direction="column" ml="10px">
              <Text
                as="h3"
                fontWeight="700"
                textTransform="uppercase"
                fontSize="14px"
                color="#333"
                lineHeight="20px">
                {orderLine?.productVariant?.product?.name}
              </Text>
              {!isNil(details?.customFields?.offerCode) && (
                <Text
                  fontSize="12px"
                  lineHeight="18px"
                  color="#000">{`Référence : ${details?.customFields?.offerCode}`}</Text>
              )}
              <Text
                fontSize="12px"
                lineHeight="18px"
                color="#000">{`Qté : ${orderLine?.quantity}`}</Text>
              {!isEmpty(warrantyExtension) &&
                warrantyExtension?.map((we) => (
                  <Flex key={we?.productVariant?.customFields?.externalCode}>
                    <Text
                      mt="3px"
                      mb="3px"
                      as="h3"
                      fontWeight="700"
                      textTransform="uppercase"
                      fontSize="12px"
                      color="#333"
                      maxWidth="150px"
                      lineHeight="18px">
                      {` ${we?.productVariant?.product?.name} (${priceFormat(
                        we?.linePriceWithTax
                      )}) `}
                    </Text>
                  </Flex>
                ))}
              <Text
                fontWeight="700"
                textTransform="uppercase"
                fontSize="18px"
                color="#333"
                lineHeight="27px"
                fontFamily="PT Sans narrow, arial, sans-serif">
                {priceFormat(
                  orderLine?.linePriceWithTax + details?.customFields?.ecopart / details?.quantity
                )}
              </Text>
              {!isNil(details?.customFields?.ecopart) && details?.customFields?.ecopart > 0 && (
                <Text fontSize="12px" lineHeight="18px" color="#000" mt="10px">
                  Dont éco-participation : <br />
                  {`${priceFormat(details?.customFields?.ecopart / details?.quantity)}`}
                </Text>
              )}
              <ProductAttachments
                attachments={orderLine?.productVariant?.product?.attachments}
                labelStyle={{
                  fontFamily: 'PT Sans',
                  fontSize: '11px',
                  fontWeight: 300
                }}
              />
            </Flex>
          </Flex>
        </Flex>
        <Flex
          direction="column"
          width={{ base: '100%', md: '20%' }}
          alignItems={{ base: 'center', md: 'normal' }}>
          <Text
            fontWeight={700}
            marginBlockEnd="20px"
            fontSize="14px"
            lineHeight="30px"
            color="#333"
            display={{ base: 'none', md: 'block' }}>
            Statut
          </Text>
          {getStatus(details?.customFields?.orderStatus)}
        </Flex>
        <Flex
          direction="column"
          width={{ base: '100%', md: '28%' }}
          mt={{ base: '10px', md: '0px' }}>
          <Text
            fontWeight={700}
            mb="20px"
            fontSize="14px"
            lineHeight="30px"
            color="#333"
            display={{ base: 'none', md: 'block' }}>
            Action
          </Text>
          <Flex direction="column" gridGap="5px" mr={{ base: '0', md: '10px' }}>
            {(details?.customFields?.orderStatus === 'Expédié' ||
              details?.customFields?.orderStatus === 'Retourné') && (
              <>
                <Flex
                  direction="row"
                  justifyContent={{ base: 'center', md: 'left' }}
                  p="5px"
                  pl="10px"
                  align="center"
                  cursor="pointer"
                  _hover={{ backgroundColor: 'white' }}
                  height="30px"
                  borderRadius="3px"
                  border="2px solid #dedede"
                  onClick={getDeliveryDoc}>
                  <Picto icon="fileText" width="15px" height="22px" />
                  <Text ml="10px" fontSize="11px">
                    Voir le bon de livraison
                  </Text>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent={{ base: 'center', md: 'left' }}
                  align="center"
                  p="5px"
                  pl="10px"
                  height="30px"
                  cursor="pointer"
                  _hover={{ backgroundColor: 'white' }}
                  borderRadius="3px"
                  border="2px solid #dedede">
                  <Link
                    target="_blank"
                    //onClick={() => track(trackingUrl)}
                    to={trackingUrl}
                    style={{ display: 'flex' }}>
                    <Picto icon="delivery" width="20px" height="20px" />
                    <Text ml="10px" fontSize="11px">
                      Suivre la livraison
                    </Text>
                  </Link>
                </Flex>
                <Flex
                  direction="row"
                  justifyContent={{ base: 'center', md: 'left' }}
                  onClick={() =>
                    returnProducts(orderLine?.productVariant?.customFields?.externalCode)
                  }
                  p="5px"
                  pl="10px"
                  align="center"
                  height="30px"
                  cursor="pointer"
                  _hover={{ backgroundColor: 'white' }}
                  borderRadius="3px"
                  border="2px solid #dedede">
                  <Picto icon="reply" width="15px" height="15px" />
                  <Text ml="10px" fontSize="11px">
                    Retourner un produit
                  </Text>
                </Flex>
              </>
            )}
            {details?.customFields?.orderStatus !== 'en cours de traitement' && (
              <Flex
                direction="row"
                justifyContent={{ base: 'center', md: 'left' }}
                p="5px"
                pl="10px"
                align="center"
                height="30px"
                cursor="pointer"
                _hover={{ backgroundColor: 'white' }}
                borderRadius="3px"
                onClick={() => {
                  onToggle()
                  setChevronOpen(!chevronOpen)
                  getSereneoUrl()
                }}
                border="2px solid #dedede">
                <Picto
                  icon={chevronOpen ? 'chevronDown' : 'chevronUp'}
                  width="15px"
                  height="15px"
                />
                <Text ml="10px" fontSize="11px">
                  Où est mon colis ?
                </Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
      <Box padding="20px" pt="0px" backgroundColor="#f6f3f1">
        <Collapse in={isOpen} animateOpacity backgroundColor="#f6f3f1">
          <Box rounded="md" shadow="md">
            {sereneoUrl ? (
              <iframe width="100%" height="100%" src={sereneoUrl} title="Suivi commande" />
            ) : (
              <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
            )}
          </Box>
        </Collapse>
      </Box>
    </Box>
  )
}

export default memo(OrderLine)
