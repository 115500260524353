import { Box, Flex, Link, List, ListItem, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'
import { getMultipaymentData } from '@stars-ecom/shared-organisms-ui/src/utils/payment'
import get from 'lodash/get'
import { priceFormat } from '../../utils'

import { WebsiteContext } from '../../context'

const PaymentLegalNotice = (props) => {
  const { data } = props
  const websiteContext = useContext(WebsiteContext)
  const multipaymentData = getMultipaymentData(get(data, 'vendure.paymentMethods.items', []))

  return (
    <Flex
      direction={'column'}
      width="100%"
      maxWidth="1000px"
      margin="auto"
      mt={{ base: '20px', md: '0' }}
      pt={{ base: '0', md: '40px' }}>
      <Text as="p" fontSize="14px" color="#333333" fontFamily="PT Sans, Arial, sans-serif" my="5px">
        *En cas de souscription à la carte de fidélité
      </Text>

      <Box>
        <Text as="p" fontSize="14px" color="#333333" fontFamily="PT Sans, Arial, sans-serif">
          ¹ montant moyen des échéances.
        </Text>
        <Text as="p" fontSize="14px" color="#333333" fontFamily="PT Sans, Arial, sans-serif">
          ² prélevés uniquement sur la première échéance.
        </Text>
      </Box>

      <Text
        as="span"
        my="5px"
        fontSize="14px"
        color="#5e5e5e"
        fontFamily="PT Sans, Arial, sans-serif">
        Pour tout paiement par carte bancaire, vous pouvez régler votre commande en plusieurs fois
        (paiement fractionné) :
        <List>
          {multipaymentData?.map((item, i) => (
            <ListItem key={`item_${i}`}>
              . en {item.count} fois dès {priceFormat(item.min, 'EUR')} d&acute;achat
            </ListItem>
          ))}
        </List>
      </Text>

      <Text
        textAlign="justify"
        as="p"
        my="5px"
        fontSize="14px"
        color="#5e5e5e"
        fontFamily="PT Sans, Arial, sans-serif">
        Ce service vous sera facturé 6,99 € pour participation aux frais de dossier et traitement.
        Le paiement des mensualités s&apos;organise comme suit : sur la 1ère mensualité, nous
        prélevons l&apos;intégralité des frais de port de votre commande, des frais de dossier ainsi
        que le montant des articles expédiés divisés par le nombre de mensualités choisies.
        L&apos;échéancier mensuel débute à la date d&apos;expédition de chaque colis, le nombre
        d&apos;échéances étant défini en fonction de la durée proposée.
      </Text>

      <Text
        textAlign="justify"
        as="p"
        my="5px"
        fontSize="11px"
        color="#5e5e5e"
        fontFamily="PT Sans, Arial, sans-serif">
        La transaction s’effectue par paiement sécurisé standard SSL de notre partenaire HiPay. Vous
        pouvez donc effectuer votre paiement en toute confiance.
      </Text>

      <Box
        mt="20px"
        mb="10px"
        fontSize="11px"
        fontFamily={websiteContext.fontFamily}
        color="#5e5e5e"
        align="justify"
        sx={{
          '& a': {
            color: websiteContext?.mainColor,
            '&:hover': {
              textDecoration: 'underline',
              color: websiteContext?.darkColor
            }
          }
        }}
        dangerouslySetInnerHTML={{ __html: websiteContext?.legalMention }}
      />

      <Text
        textAlign="justify"
        as="p"
        my="5px"
        fontSize="11px"
        color="#5e5e5e"
        fontFamily="PT Sans, Arial, sans-serif">
        Lorsque vous cochez la case « Enregistrer ces informations pour faciliter mes prochains
        achats », vous consentez à ce que vos données de carte bancaire soient conservées pour
        faciliter vos futurs achats, et ne pas ressaisir votre numéro de carte bancaire lors de vos
        prochaines commandes. Les coordonnées de votre compte bancaire sont transmises et conservées
        de manière sécurisée par notre partenaire de confiance HiPay sur des serveurs informatiques
        situés en EUROPE. Vous pouvez modifier ou supprimer les cartes bancaires enregistrées en
        vous connectant à votre Espace Client, rubrique « Mes Cartes Bancaires ».
      </Text>
    </Flex>
  )
}

export default memo(PaymentLegalNotice)
