import './blog-article.css'
import './blog-push-thumbnail.css'

import { Box, Flex, GatsbyImage, Heading, Link } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import slugify from '../utils/slugify'

const BlogPushThumbnail = (props) => {
  const { blogArticle, backgroundColor = '#ffffff', height, backLink } = props
  const websiteContext = useContext(WebsiteContext)
  const windowGlobal = typeof window !== 'undefined' && window

  const onClick = () => {
    if (backLink) {
      windowGlobal?.history?.replaceState({}, null, backLink)
    }
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Homepage',
      eventAct: 'Départ bloc blog',
      eventLab: `/article/${blogArticle.path || slugify(blogArticle?.title)}`
    })
  }
  return (
    <Flex
      w={{ base: '208px', md: '320px' }}
      h={{ base: '331px', md: 'auto' }}
      direction="column"
      justify="space-between"
      outline="#dce3f3 solid 1px;">
      <Flex
        direction="column"
        alignItems="center"
        w="100%"
        height={{ base: '100%', md: '190px' }}
        bg="#dce3f3">
        <Link
          to={`/article/${blogArticle.path || slugify(blogArticle?.title)}`}
          style={{ height: '100%', width: '100%' }}
          onClick={onClick}>
          <GatsbyImage
            image={blogArticle?.thumbnail_image?.image?.childImageSharp?.gatsbyImageData}
            loading="eager"
            alt={blogArticle?.title}
            style={{ zIndex: 10, display: 'block', height: '100%', width: '100%' }}
          />
        </Link>
        {blogArticle?.blog_taxonomy && (
          <Flex
            justify="center"
            align="center"
            alignSelf="center"
            w="auto"
            h={{ base: '33px', md: '43px' }}
            fontSize={{ base: '18px', md: '20px' }}
            backgroundColor={websiteContext?.mainColor}
            mt={{ base: '-20px', md: '-23px' }}
            color="#fff"
            p="10px 20px"
            mb="-22px"
            zIndex="11"
            fontFamily="Satisfy, cursive">
            {blogArticle?.blog_taxonomy?.title || 'Not set'}
          </Flex>
        )}
      </Flex>
      <Flex
        direction="column"
        position="relative"
        w={{ base: '207px', md: 'auto' }}
        justifyContent="flex-end"
        bg={backgroundColor}
        ml={{ md: '-.5px', lg: '0' }}
        p={{ base: '0 15px 15px', md: '15px' }}
        mt={{ base: '9px', md: '2px' }}>
        <Link to={`/article/${blogArticle.path || slugify(blogArticle?.title)}`} onClick={onClick}>
          <Heading
            as="h3"
            width="100%"
            fontSize={{ base: '18px', md: '18px' }}
            lineHeight={{ base: '20px', md: '1.4' }}
            mb="15px"
            mt={{ base: blogArticle?.blog_taxonomy ? '20px' : '0', md: '15px' }}
            style={{
              width: '100%',
              backgroundColor: '#ffffff',
              fontFamily: 'PT Sans, Arial, sans-serif',
              fontweight: 700,
              textAlign: 'left',
              color: '#333',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              WebkitLineClamp: 3,
              display: '-webkit-box'
            }}
            className="blog-push-thumbnail">
            {blogArticle?.title}
          </Heading>
        </Link>
        <Box
          w={{ base: '178px', md: 'unset' }}
          maxH={{ base: '60px', md: 'unset' }}
          height={height ? height : 'unset'}
          fontSize="14px"
          lineHeight="15px"
          fontFamily="PT Sans, Arial, sans-serif"
          zIndex={90}
          style={{
            border: 'none',
            marginBottom: '20px',
            color: '#333333',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            WebkitLineClamp: 4,
            display: '-webkit-box',
            overflowWrap: 'unset'
          }}
          className="blog-push-thumbnail">
          {blogArticle?.hook}
        </Box>

        <Flex
          position={{ base: 'relative', md: 'initial' }}
          bottom={{ base: '10px', md: '0' }}
          zIndex={100}>
          <Link to={`/article/${blogArticle.path || slugify(blogArticle?.title)}`}>
            <span
              style={{
                width: '100%',
                backgroundColor: '#ffffff',
                textDecoration: 'underline',
                cursor: 'pointer',
                fontSize: '14px',
                color: '#333',
                fontFamily: 'PT Sans, Arial, sans-serif'
              }}>
              Lire la suite
            </span>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default BlogPushThumbnail