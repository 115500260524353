import get from 'lodash/get'
import React, { useEffect, useRef, useState } from 'react'

import { Box, Flex } from '../layout'
import * as DataLayerUtils from '../utils/datalayer'
import GatsbyImage from './GatsbyImage'

const Media = (props) => {
  const {
    alt,
    asset,
    defaultImage,
    autoplay = false,
    style = {},
    className,
    imageAttribute = 'image',
    overlayColor = '#000000',
    overlaySize = 100,
    zoomable = false,
    zoom,
    analyticData
  } = props
  const [videoVisible, setVideoVisible] = useState(autoplay)
  const [isPlaying, setIsPlaying] = useState(false)
  const videoId = useRef()
  const showVideo = () => {
    if (asset?.mimeType === 'video/mp4') {
      if (!videoVisible) {
        setVideoVisible(true)
      } else {
        play()
      }
    }
  }

  const VideoWrapper = ({ children }) =>
    asset?.mimeType === 'video/mp4' ? (
      <Box onClick={showVideo} display={videoVisible ? 'none' : 'block'}>
        {children || null}
      </Box>
    ) : (
      children || null
    )

  const onPlay = () => {
    setIsPlaying(true)
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Pages produit',
      eventAct: 'Lancer vidéo',
      eventLab: analyticData || alt
    })
  }
  const onPause = () => {
    setIsPlaying(false)
  }
  const onEnded = () => {
    setIsPlaying(false)
  }

  const play = () => {
    if (videoId) {
      videoId?.current?.play()
      setIsPlaying(true)
    }
  }

  const stop = () => {
    if (videoId) {
      videoId?.current?.pause()
      setIsPlaying(true)
    }
  }

  useEffect(() => {
    if (videoVisible) {
      play()
    }
  }, [videoVisible])
  useEffect(() => {
    stop()
    setVideoVisible(false)
    setIsPlaying(false)
  }, [asset])

  return (
    <Flex
      position="relative"
      justify="center"
      align="center"
      width="100%"
      height="100%"
      backgroundColor={videoVisible ? '#000000' : 'transparent'}>
      <VideoWrapper>
        {asset &&
          get(
            asset,
            `${imageAttribute}.childImageSharp.gatsbyImageData`,
            get(defaultImage, `${imageAttribute}.childImageSharp.gatsbyImageData`)
          ) && (
            <GatsbyImage
              onClick={zoomable ? zoom : undefined}
              image={get(
                asset,
                `${imageAttribute}.childImageSharp.gatsbyImageData`,
                get(defaultImage, `${imageAttribute}.childImageSharp.gatsbyImageData`)
              )}
              className={className}
              loading="eager"
              alt={alt}
              title={alt}
              style={{ ...style, zIndex: 1 }}
            />
          )}
      </VideoWrapper>
      {videoVisible && (
        <video
          ref={videoId}
          controls
          className={className}
          onPlay={onPlay}
          onPause={onPause}
          onEnded={onEnded}
          style={{ maxHeight: '360px' }}>
          <source src={asset?.customFields?.sourceOrigin} type={asset.mimeType} />
        </video>
      )}
      {asset?.mimeType === 'video/mp4' && !isPlaying && (
        <Box
          width={overlaySize}
          height={overlaySize}
          onTouchStart={showVideo}
          onClick={showVideo}
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: -overlaySize / 2,
            marginLeft: -overlaySize / 2,
            cursor: 'pointer',
            zIndex: 100
          }}>
          <svg
            width={overlaySize}
            height={overlaySize}
            viewBox="0 0 28 28"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              fill={overlayColor}
              d="M1.71429 14C1.71429 7.21479 7.21479 1.71429 14 1.71429C20.7852 1.71429 26.2857 7.21479 26.2857 14C26.2857 20.7852 20.7852 26.2857 14 26.2857C7.21479 26.2857 1.71429 20.7852 1.71429 14ZM14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0ZM11.1429 20.03L18.8571 14.5864L11.1429 9.14285L11.1429 20.03Z"
            />
          </svg>
        </Box>
      )}
    </Flex>
  )
}
export default Media
