import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Image,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import trim from 'lodash/trim'
import React, { memo, useContext } from 'react'

import { WebsiteContext } from '../context'
import mrLogo from '../shipping/mondialRelay/assets/mr-64.png'

const OrderLineShippingDetails = (props) => {
  const {
    address,
    orderLines = [],
    shippingMethodCode,
    index,
    isMultiple,
    phoneNumber,
    cellPhoneNumber
  } = props
  const websiteContext = useContext(WebsiteContext)

  const getProductName = () => {
    return orderLines?.map((orderLine) => orderLine?.productVariant?.name)?.join(' / ')
  }

  const DeliveryLabel = () => {
    return (
      <Flex w="100%" align="center">
        <Box w={{ base: '20%', md: '15%' }} align="center" mr="10px">
          {shippingMethodCode == 'mondial-relay' ? (
            <Image src={mrLogo} w="40px" h="40px" />
          ) : (
            <Picto
              icon={shippingMethodCode === 'standard' ? 'shipping' : 'shippingExpress'}
              width="40px"
              height="40px"
            />
          )}
        </Box>
        <Text fontSize="16px" w="80%">
          <strong>Votre commande</strong> «{' '}
          <Text as="span" color={websiteContext?.mainColor} fontWeight={700}>
            {getProductName()}
          </Text>{' '}
          » vous sera livrée{' '}
          {shippingMethodCode === 'mondial-relay' ? 'dans votre point relais.' : 'à domicile.'}
        </Text>
      </Flex>
    )
  }

  return (
    <Box
      m="20px"
      w={['unset', '460px']}
      shadow="md"
      p="20px"
      border="1px solid #eef0f5"
      position="relative">
      {isMultiple && (
        <Box
          w="112px"
          bg={websiteContext.mainColor}
          color="#FFFFFF"
          textAlign="center"
          borderRadius="10px"
          fontSize="14px"
          p="3px"
          position="absolute"
          fontFamily={websiteContext.fontFamily}
          top="-12px"
          left={{ base: '105px', md: '175px' }}>
          LIVRAISON {index}
        </Box>
      )}
      <Flex w="100%">
        <DeliveryLabel />
      </Flex>
      {address && (
        <Accordion mt="10px" allowToggle w="250px">
          <AccordionItem border="0">
            {({ isExpanded }) => (
              <>
                <h2>
                  <AccordionButton _focus={{ boxShadow: 'none' }}>
                    <Box flex="1" textAlign="left">
                      <Text fontSize={'15px'}>{`Voir l'adresse de livraison`}</Text>
                    </Box>
                    {isExpanded ? (
                      <Picto icon="chevronUp" width="15px" />
                    ) : (
                      <Picto icon="chevronDown" width="15px" />
                    )}
                  </AccordionButton>
                </h2>

                <AccordionPanel p="0">
                  <Flex direction="column" ml="20px" pl="10px" borderLeft="1px solid grey">
                    <Text fontSize={'14px'}>{`${get(address, 'customFields.firstName', '')} ${get(
                      address,
                      'customFields.lastName',
                      ''
                    )}`}</Text>
                    <Text fontSize={'14px'}> {get(address, 'streetLine1', '')}</Text>
                    <Text fontSize={'14px'}> {get(address, 'streetLine2', '')}</Text>
                    <Text fontSize={'14px'}>{`${get(address, 'postalCode', '')} ${get(
                      address,
                      'city',
                      ''
                    )}`}</Text>
                    {shippingMethodCode !== 'mondial-relay' && trim(phoneNumber) && (
                      <Flex
                        direction="row"
                        borderRadius={12}
                        border="1px solid grey"
                        align="center"
                        pl="7px"
                        pr="7px"
                        maxWidth="130px">
                        <Picto
                          icon="telephone"
                          color="grey"
                          width="12px"
                          height="12px"
                          style={{ marginRight: '5px' }}
                        />
                        <span>{phoneNumber}</span>
                      </Flex>
                    )}
                    {shippingMethodCode !== 'mondial-relay' && trim(cellPhoneNumber) && (
                      <Flex
                        direction="row"
                        borderRadius={12}
                        border="1px solid grey"
                        align="center"
                        pl="7px"
                        pr="7px"
                        maxWidth="130px">
                        <Picto
                          icon="telephone"
                          color="grey"
                          width="12px"
                          height="12px"
                          style={{ marginRight: '5px' }}
                        />
                        <span>{cellPhoneNumber}</span>
                      </Flex>
                    )}
                  </Flex>
                </AccordionPanel>
              </>
            )}
          </AccordionItem>
        </Accordion>
      )}
    </Box>
  )
}

export default memo(OrderLineShippingDetails)
