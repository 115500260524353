import {
  BackgroundImage,
  Box,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Svg,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import head from 'lodash/head'
import React, { memo, useState } from 'react'

import { DataLayerUtils } from '../context'
import ProductSlide from '../product/ProductSlide'

dayjs.extend(customParseFormat)

const TVProducts = (props) => {
  const { push = {}, tvShows = [], tvShowSettings = [] } = props

  const [tvShow, setTvShow] = useState(head(tvShows))
  const isDaily = tvShow?.type === 'PER_DAY' ? true : false

  const { isOpen, onOpen, onClose } = useDisclosure()
  const title = `${push?.title?.replace('<p>', '').replace('</p>', '')} ${
    isDaily ? dayjs(tvShow?.code, 'YYYY-MM-DD').format('DD MMMM YYYY') : ''
  }`
  const changeTVShow = (tv) => {
    setTvShow(tv)
    onClose()
  }

  const onMoreClick = () => {
    DataLayerUtils.addEvent({
      event: 'qwampEvent',
      eventCat: 'Homepage',
      eventAct: `Départ produits ${title?.replace(/(<([^>]+)>)/gi, '')}`
    })
    onOpen()
  }

  const additionalSlides = isDaily
    ? [
        <Link
          key="additional_tvShow_slide"
          to={`/les-emissions/la-selection-du-${dayjs(tvShow?.code, 'YYYY-MM-DD').format('DD-MM')}`}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEvent',
              eventCat: 'Homepage',
              eventAct: "Départ tous les produits de l'émission du jour"
            })
          }>
          <Flex
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: '#FFFFFF',
              width: '235px',
              height: '340px'
            }}>
            <GatsbyImage
              image={tvShowSettings?.tvShowPushImage?.image?.childImageSharp?.gatsbyImageData}
              alt="Tous les produits"
              loading="eager"
              style={{ width: '233px', height: '338px' }}
            />
          </Flex>
        </Link>
      ]
    : []

  const dataLayerFunction = (event = {}) => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Homepage',
      eventAct: `Défiler slider ${title?.replace(/(<([^>]+)>)/gi, '')}`,
      ...event
    })
  }

  return (
    <Flex height={{ base: 'auto', md: '495px' }} width="100%" overflow="hidden" position="relative">
      <BackgroundImage
        backgroundImage={push?.backgroundImage?.image}
        backgroundColor={push?.backgroundColor}
        className={isOpen ? 'blurred' : ''}
        style={{
          width: '100%',
          height: '100%',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 0
        }}>
        <Flex
          maxWidth="1000px"
          direction="column"
          justify="center"
          align="center"
          margin="auto"
          position="relative">
          <Text
            as="h2"
            fontSize={{ base: '21px', md: '30px' }}
            fontFamily="PT Sans, Arial, sans-serif"
            mt={{ base: '20px', md: '0' }}
            p="0 21px"
            color={push?.textColor}
            display={{ base: 'flex', md: 'unset' }}
            flexDirection={{ base: 'column', md: 'unset' }}
            style={{
              width: '100%',
              fontWeight: 400,
              color: push?.color,
              textAlign: 'center',
              lineHeight: 1.5,
              textTransform: 'uppercase',
              marginBottom: '10px'
            }}
            dangerouslySetInnerHTML={{
              __html: title
            }}
          />
          <ProductSlide
            listName={title?.replace(/(<([^>]+)>)/gi, '')}
            productSlideId="tvProductsCarousel"
            products={tvShow?.products}
            additionalSlides={additionalSlides}
            arrowColor={push?.moreTextColor}
            tvShow={tvShow}
            dataLayerFunction={dataLayerFunction}
          />
          {isDaily && (push?.moreText || push?.morePicto) && (
            <Flex
              position={{ base: 'relative', md: 'absolute' }}
              direction="row"
              justify={{ base: 'center', md: 'flex-end' }}
              bottom={{ base: 'unset', md: '-8px' }}
              width={{ base: '100%', md: 'auto' }}
              p={{ base: '10px', md: 0 }}
              style={{ zIndex: 200, right: 0 }}>
              <Flex
                onClick={onMoreClick}
                direction="row"
                align="center"
                justify="center"
                style={{
                  cursor: 'pointer',
                  pointerEvents: 'all',
                  color: push?.moreTextColor || '#ffffff'
                }}
                backgroundColor={{ base: '#e3f1fa', md: 'transparent' }}
                width={{ base: '100%', md: 'auto' }}
                height={{ base: '90px', md: 'auto' }}>
                {push?.morePicto && (
                  <Flex display={{ base: 'none', md: 'block' }}>
                    <Svg
                      data={push?.morePicto}
                      width="30px"
                      height="30px"
                      color={push?.moreTextColor || '#ffffff'}
                    />
                  </Flex>
                )}
                <Flex display={{ base: 'block', md: 'none' }} mt="8px">
                  <GatsbyImage
                    image={
                      tvShowSettings?.lastTvShowsImage?.image?.childImageSharp?.gatsbyImageData
                    }
                    alt="Animateurs"
                    loading="eager"
                    style={{ width: '90px', height: '80px' }}
                  />
                </Flex>
                <Text
                  as="span"
                  color={{ base: '#000', md: push?.moreTextColor || '#ffffff' }}
                  lineHeight={{ base: '21px', md: '18px' }}
                  fontSize={{ base: '14px', md: '16px' }}
                  w={{ base: '145px', md: '175px' }}
                  mr={{ base: '10px', md: '0' }}
                  style={{
                    fontFamily: 'PT Sans, Arial, sans-serif',
                    paddingLeft: '10px',
                    height: '36px'
                  }}>
                  {push?.moreText}
                </Text>
                <Box color={{ base: '#000', md: push?.moreTextColor || '#ffffff' }}>
                  <Picto
                    icon="arrowRight"
                    width="10px"
                    height="10px"
                    color={push?.moreTextColor || '#ffffff'}
                    className="picto-arrow-tv"
                  />
                </Box>
              </Flex>
            </Flex>
          )}
        </Flex>
      </BackgroundImage>
      <Box
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          backgroundColor: '#000000',
          opacity: 0.7,
          width: '100%',
          height: '100%',
          display: isOpen ? 'block' : 'none'
        }}
      />
      {isDaily && (
        <Flex
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            backgroundColor: 'transparent',
            width: '100%',
            height: '100%',
            zIndex: 10,
            display: isOpen ? 'flex' : 'none'
          }}
          align="center"
          direction="column">
          <Flex
            align="center"
            justify="center"
            direction="column"
            maxWidth="1000px"
            position="relative">
            <Text
              fontSize={{ base: '18px', sm: '18px', md: '24px', lg: '30px' }}
              style={{
                textTransform: 'uppercase',
                color: '#FFFFFF',
                fontWeight: 700,
                marginBottom: '25px',
                marginTop: '45px',
                fontFamily: 'PT Sans'
              }}
              as="h2">
              Choisissez une date d&acute;émission
            </Text>
            <div
              onClick={onClose}
              style={{
                position: 'absolute',
                top: '20px',
                right: '20px',
                cursor: 'pointer',
                width: '14px',
                height: '14px'
              }}>
              <Picto icon="cross" color="#FFFFFF" width="14px" height="14px" />
            </div>
            <Flex direction="row" wrap="wrap" align="center" justify="center" maxWidth="980px">
              {tvShows.map((tv, index) => (
                <Flex
                  direction="column"
                  align="center"
                  justify="center"
                  key={`show_${index}`}
                  width={{ base: '120px', sm: '150px', md: '180px', lg: '240px' }}
                  height={{ base: '120px', sm: '110px', md: '130px', lg: '160px' }}
                  mb={{ base: '10px', md: '30px' }}
                  style={{
                    position: 'relative',
                    paddingTop: '8px',
                    cursor: 'pointer',
                    fontFamily: 'PT Sans, Arial, sans-serif'
                  }}
                  onClick={() => changeTVShow(tv)}>
                  <Picto
                    icon="calendarThin"
                    width="100%"
                    height="100%"
                    color="#FFFFFF"
                    style={{ position: 'absolute', top: 0, left: 0 }}
                  />
                  <Text
                    mt={{ base: '10px', lg: '24px' }}
                    fontSize={{ base: '11.5px', md: '12px', lg: '14px' }}
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 700,
                      color: '#FFFFFF'
                    }}>
                    {dayjs(tv?.code, 'YYYY-MM-DD').format('dddd')}
                  </Text>
                  <Text
                    fontSize={{ base: '14px', sm: '17px', md: '21px', lg: '28px' }}
                    lineHeight={{ base: 'normal', lg: '33px' }}
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 700,
                      color: '#FFFFFF'
                    }}>
                    {dayjs(tv?.code, 'YYYY-MM-DD').format('DD')}
                  </Text>
                  <Text
                    fontSize={{ base: '11.5px', md: '12px', lg: '14px' }}
                    style={{
                      textTransform: 'uppercase',
                      fontWeight: 700,
                      color: '#FFFFFF'
                    }}>
                    {dayjs(tv?.code, 'YYYY-MM-DD').format('MMMM')}
                  </Text>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

export default memo(TVProducts)
