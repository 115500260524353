import { Flex } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import HelpHeader from './HelpHeader'
import HelpItem from './HelpItem'

const Help = (props) => {
  const { help } = props
  return (
    <Flex direction="column" width="100%" align="center">
      <HelpHeader help={help} />
      <Flex
        maxWidth="1000px"
        mt="20px"
        width={{ base: '100%', md: '1000px' }}
        direction="row"
        flexWrap="wrap"
        justify="space-between"
        gridGap="20px"
        mb="20px">
        {help?.boxPicto?.map((boxPicto, i) => (
          <HelpItem key={`box_picto_${i}`} boxPicto={boxPicto} />
        ))}
      </Flex>
    </Flex>
  )
}

export default memo(Help)
