import { Flex, Picto } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

const ProductInventory = (props) => {
  const { stockLevel, isCheckout = false, style = {} } = props

  return (
    <Flex
      direction="row"
      align="center"
      m={isCheckout ? '7px 0 0' : '16px 0 6px'}
      fontFamily="PT Sans, Arial, Sans-serif"
      fontSize={isCheckout ? '12px' : '14px'}
      style={{ fontWeight: 700, color: '#000', ...style }}>
      {stockLevel !== 'OUT_OF_STOCK' && (
        <>
          <Picto
            icon="check"
            color="#39a132"
            width="16px"
            height="16px"
            style={{ marginRight: '7px' }}
          />
          EN STOCK
        </>
      )}
      {stockLevel === 'OUT_OF_STOCK' && isCheckout && 'Expédié sous 1 à 2 semaines'}
    </Flex>
  )
}

export default memo(ProductInventory)
