import './price.css'

import { Flex, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { DataLayerUtils } from '../../context'
import { OfferUtils, priceFormat } from '../../utils'
import ProductVariantSelector from '../ProductVariantSelector'
import { PopoverEcopart } from './Ecopart'
import { PopoverMultiPayment } from './MultiPayment'

const RedPoint = () => (
  <Flex direction="column" align="flex-end" style={{ paddingTop: '15px' }}>
    <Flex direction="row" align="center">
      <div
        style={{
          backgroundColor: '#b30f16',
          borderRadius: '5px',
          width: '10px',
          height: '10px',
          marginRight: '5px'
        }}
      />
      <span
        style={{
          fontSize: '14px',
          fontFamily: 'PT Sans, Arial, Helvetica',
          lineHeight: '16px',
          fontWeight: 700,
          color: '#b30f16'
        }}>
        POINT ROUGE
      </span>
    </Flex>
    <span
      style={{
        marginTop: '5px',
        fontSize: '14px',
        lineHeight: '16px',
        fontWeight: 400,
        color: '#000',
        textAlign: 'right',
        fontFamily: 'PT Sans, Arial, Helvetica'
      }}>
      Ce produit ne bénéficie pas
      <br />
      de la remise fidélité
    </span>
  </Flex>
)

const LoyaltyAdvantage = (props) => {
  const { price, deee = 0, ecomob = 0, currency = 'EUR', product } = props
  return (
    <Flex direction="column" align="flex-end" style={{ paddingTop: '20px' }}>
      <span
        style={{
          fontFamily: 'PT Sans, Arial, Helvetica',
          fontSize: '14px',
          fontWeight: '700',
          color: 'rgb(51,51,51)'
        }}>
        -10% supplémentaires
      </span>
      <span style={{ fontSize: '14px', color: 'rgb(51,51,51)' }}>
        avec la{' '}
        <Link
          to="/fidelite"
          target="_blank"
          style={{ textDecoration: 'underline' }}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEventLab',
              eventCat: 'Pages produit',
              eventAct: 'Départ carte de fidélité',
              eventLab: product?.customFields?.externalCode
            })
          }>
          carte de fidélité
        </Link>{' '}
        soit{' '}
        <span style={{ fontWeight: '700' }}>{`${priceFormat(
          (price - (deee || 0) - (ecomob || 0)) * 0.9 + (deee || 0) + (ecomob || 0),
          currency
        )}`}</span>
      </span>
    </Flex>
  )
}

const FullPrice = (props) => {
  const {
    product,
    productVariant,
    type = 'STANDARD',
    quantity = 1,
    orderLine = {},
    isLoyalty,
    isClicked,
    onProductVariantChange = () => {},
    multipaymentData = []
  } = props

  const {
    standardPrice = 0,
    price = 0,
    discount = 0,
    discountType = 'PERCENTAGE',
    deee = 0,
    ecomob = 0,
    currency = '€',
    advantageType
  } = productVariant
    ? OfferUtils.getCurrentVariantOffer(productVariant, type, process.env.GATSBY_API_CHANNEL_ID)
    : OfferUtils.getCurrentProductOffer(product, type, process.env.GATSBY_API_CHANNEL_ID)

  const handleProductVariantChange = (event) => {
    const productVariantId = event?.target?.value
    onProductVariantChange(product.variants?.find((pv) => pv.id === productVariantId))
  }
  return (
    <Flex direction="column" w="100%">
      <Flex direction="row" width="100%" justify="space-between">
        {discount === 0 && <div />}
        {discount !== 0 && (
          <Flex
            bgColor="#d5e301"
            justify="center"
            align="center"
            w="58px"
            h="35px"
            borderRadius="3px">
            <Text
              as="span"
              fontWeight={700}
              lineHeight="25px"
              fontSize="25px"
              color="#242a30"
              fontFamily="PT Sans Narrow, Arial, sans-serif">
              {discountType === 'PERCENTAGE'
                ? `-${discount}`
                : `-${priceFormat(discount, currency)}`}
            </Text>
            <Text
              as="sup"
              top="0"
              fontSize="17px"
              fontWeight={400}
              lineHeight={0}
              textAlign="center"
              color="#242a30"
              paddingBottom="10px"
              fontFamily="PT Sans Narrow, Arial, sans-serif">
              {`${discountType === 'PERCENTAGE' ? '%' : ''}`}
            </Text>
          </Flex>
        )}
        <Flex direction="row">
          {discount !== 0 && (
            <Text
              as="span"
              alignSelf={{ base: 'start', md: 'end' }}
              fontSize="20px"
              textDecoration="line-through"
              color="#999"
              fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
              mr="5px">{`${priceFormat(quantity * standardPrice, currency)}`}</Text>
          )}
          <Text
            as="span"
            fontSize="33px"
            lineHeight="30px"
            fontWeight={700}
            fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
            color="rgb(51,51,51)">{`${priceFormat(
            discount ? price : standardPrice,
            currency
          )}`}</Text>
        </Flex>
      </Flex>
      <PopoverEcopart
        price={price}
        deee={deee}
        ecomob={ecomob}
        quantity={quantity}
        currency={currency}
      />
      <PopoverMultiPayment
        price={price}
        currency={currency}
        product={product}
        multipaymentData={multipaymentData}
      />

      {advantageType === 'PTRG' ? (
        <RedPoint />
      ) : (
        <LoyaltyAdvantage
          price={price}
          deee={deee}
          ecomob={ecomob}
          currency={currency}
          product={product}
        />
      )}
      {!isLoyalty && orderLine?.customFields?.isLoyaltyEligible && (
        <Flex direction="column" align={['flex-start', 'flex-end']} style={{ paddingTop: '20px' }}>
          <Text style={{ fontSize: '16px' }}>Prix fidélité : -10%</Text>
          <Text>
            soit{' '}
            <span style={{ fontSize: '16px', fontWeight: '600' }}>{` ${priceFormat(
              quantity * (price - orderLine?.customFields?.loyaltyDiscount),
              currency
            )}`}</span>
          </Text>
        </Flex>
      )}
      <ProductVariantSelector
        product={product}
        productVariant={productVariant}
        onChange={handleProductVariantChange}
        isClicked={isClicked}
      />
    </Flex>
  )
}

export default memo(FullPrice)
