import { Box, Flex, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const HelpItem = (props) => {
  const { boxPicto } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Box width={{ base: '100%', md: '490px' }} backgroundColor="#fff">
      <Flex
        direction="column"
        justify="space-between"
        height="100%"
        width="100%"
        pl="27px"
        pr="27px"
        pt="18px"
        pb="18px">
        <Flex direction="column" width="100%">
          <Link to={boxPicto?.link}>
            <Flex direction="row" borderBottom="1px solid #333333" pb="20px">
              <Box>
                <Flex
                  width="135px"
                  height="135px"
                  backgroundColor={websiteContext.mainColor}
                  justify="center"
                  align="center"
                  p="20px">
                  <Svg color="#fff" data={boxPicto?.picto} width="100%" height="66px" />
                </Flex>
              </Box>
              <Box
                maxWidth={{ base: '100%', md: '287px' }}
                as="h2"
                pl="15px"
                fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                fontSize={{ base: '28px', md: '45px' }}
                lineHeight="1"
                color="#333333"
                fontWeight={700}
                dangerouslySetInnerHTML={{ __html: boxPicto?.title }}>
              </Box>
            </Flex>
          </Link>
          <Box
            width="100%"
            as="span"
            pt="20px"
            pb="20px"
            fontFamily="PT Sans, Arial, sans-serif"
            fontSize="11px"
            lineHeight="17px"
            color="#333333"
            fontWeight={400}
            dangerouslySetInnerHTML={{ __html: boxPicto?.content }}
          />
        </Flex>
        <Link
          to={boxPicto?.link}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEventLab',
              eventCat: 'Aide',
              eventAct: 'Départ bloc réassurance',
              eventLab: boxPicto?.link
            })
          }>
          <Flex
            justify="center"
            align="center"
            backgroundColor={websiteContext?.mainColor}
            width={{ base: '100%', md: '175px' }}
            height="34px"
            color="#ffffff">
            {boxPicto?.textButton}
          </Flex>
        </Link>
      </Flex>
    </Box>
  )
}

export default memo(HelpItem)
