//import { RadioGroup } from '@chakra-ui/react'
import React from 'react'

import { useRadio, useRadioGroup } from '../hooks'
import { Flex, Stack } from '../layout'
import { Picto } from '../media'

const Radio = (props) => {
  const { color = '#017dc7', isReadOnly } = props
  const { getInputProps, getCheckboxProps } = useRadio(props)

  const input = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Flex as="label" align="center">
      <input {...input} name={props.name} />
      <Flex
        {...checkbox}
        cursor={isReadOnly ? 'not-allowed' : 'pointer'}
        borderWidth="1px"
        borderRadius="50%"
        w="22px"
        h="22px"
        _checked={{
          bg: color,
          color: '#FFF'
        }}
        px={1}
        py={1}>
        {input?.checked ? (
          <Picto icon="check" color="#FFF" width="12px" height="12px" />
        ) : (
          <Flex w="12px" h="12px" />
        )}
      </Flex>
      <Flex fontFamily="PT Sans" margin="0px 5px" fontSize="14px">
        {props.children}
      </Flex>
    </Flex>
  )
}

// Step 2: Use the `useRadioGroup` hook to control a group of custom radios.
const RadioGroup = (props) => {
  const {
    name,
    defaultValue,
    onChange = () => {},
    options = [],
    children,
    color,
    direction = 'row',
    readOnly = false
  } = props

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: name,
    defaultValue: defaultValue,
    onChange: onChange
  })

  const group = getRootProps()
  if (children) {
    return <>{children}</>
  }
  return (
    <Stack direction={direction} {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value })
        return (
          <Radio key={value} color={color} isReadOnly={readOnly} disabled {...radio}>
            {value}
          </Radio>
        )
      })}
    </Stack>
  )
}

export { Radio, RadioGroup }
