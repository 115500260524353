import './blog-article.css'

import { BackgroundImage, Flex, Link, Text, useHasMounted } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import BlogPushThumbnail from './BlogPushThumbnail'

const BlogPush = (props) => {
  const { blogPush, push } = props

  const hasMounted = useHasMounted()
  if (!hasMounted) {
    return <div style={{ display: 'none' }} />
  }

  return (
    <Flex direction="column" align="center">
      <Flex
        direction="row"
        style={{ fontFamily: 'satisfy, cursive', marginTop: '20px' }}
        fontSize={{ base: '25px', md: '40px' }}
        dangerouslySetInnerHTML={{ __html: push?.title }}></Flex>
      <Flex
        direction="row"
        width={{ base: '100%', md: '1040px' }}
        justify={{ base: 'flex-start', md: 'center' }}
        pl={{ base: '20px', md: 0 }}
        pr={{ base: '20px', md: 0 }}
        overflowX={{ base: 'scroll', md: 'hidden' }}
        align="flex-start"
        margin="auto"
        style={{
          paddingTop: '20px',
          paddingBottom: '20px',
          overflowY: 'clip'
        }}
        gridGap="20px">
        <BackgroundImage
          width={{ base: '220px', md: '321px' }}
          height={{ base: '340px', md: '660px' }}
          backgroundImage={blogPush?.backgroundImage?.image}
          backgroundColor="#FFFFFF"
          style={{
            backgroundPosition: 'center top',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            textAlign: 'center',
            transition: 'all .4s',
            height: '100%',
            minWidth: '220px',
            zIndex: 0
          }}>
          <Flex
            direction="column"
            justify="space-between"
            align="center"
            height={{ base: '330px', md: '660px' }}
            p={{ base: '10px', md: '20px' }}
            style={{ width: '100%' }}>
            <Text
              as="h3"
              fontSize={{ base: '19px', md: '28px' }}
              lineHeight={{ base: '23px', md: '33px' }}
              style={{
                fontFamily: 'Satisfy, cursive',
                color: '#333333',
                textAlign: 'center',
                width: '100%',
                fontWeight: 500
              }}>
              {blogPush?.title}
            </Text>
            <Flex
              direction="column"
              align="center"
              p={{ base: '10px', md: '20px' }}
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize={{ base: '12px', md: '14px' }}
              lineHeight={{ base: '14px', md: '21px' }}
              style={{
                backgroundColor: 'rgba(255, 255, 255, 0.85)',
                textAlign: 'left'
              }}>
              {blogPush.hook}
              <Flex width="100%" marginTop="15px">
                <a
                  href="/blog"
                  fontFamily="PT Sans, Arial, sans-serif"
                  style={{
                    backgroundColor: '#333333',
                    color: '#ffffff',
                    textTransform: 'uppercase',
                    padding: '10px',
                    width: '100%',
                    fontSize: '14px'
                  }}>
                  <Text align="center" justify="center">
                    {blogPush.buttonText}
                  </Text>
                </a>
              </Flex>
            </Flex>
          </Flex>
        </BackgroundImage>
        <Flex
          direction={{ base: 'row', md: 'column' }}
          width={{ base: 'auto', md: '660px' }}
          gridGap="20px"
          flexWrap={{ md: 'wrap' }}
          alignContent="flex-start"
          height={{ base: '330px', md: '860px' }}
          sx={{
            '.blog_article_0': {
              '@media (min-width: 768px)': {
                order: '0'
              }
            },
            '.blog_article_2': {
              '@media (min-width: 768px)': {
                order: '1'
              }
            },
            '.blog_article_1': {
              '@media (min-width: 768px)': {
                order: '2'
              }
            },
            '.blog_article_3': {
              '@media (min-width: 768px)': {
                order: '3'
              }
            }
          }}>
          {blogPush.blogArticles.map((blogArticle, i) => (
            <BlogPushThumbnail
              key={`blog_article_${blogArticle.id}`}
              blogArticle={blogArticle}
              classname={`blog_article_${i}`}
            />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default memo(BlogPush)
