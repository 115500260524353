import { get, isEmpty, isFunction, isObject, set, trim } from 'lodash'
import React, { memo, useContext } from 'react'

import Text from '../../typography/Text'
import Field from './Field'
import { FormContext } from './FormContext'
import SectionWrapper from './SectionWrapper'

const Section = (props) => {
  const {
    section,
    hasNext,
    nextFunction: _nextFunction = () => {},
    backFunction,
    isCurrentStep = false
  } = props
  const { value, originValue } = useContext(FormContext)
  const validateSection = async () => {
    let errors = []
    for (const field of section?.fields) {
      try {
        if (field?.validator && isFunction(field?.validator)) {
          await field?.validator(originValue)(value).validate(get(value, field?.attrKey), {
            abortEarly: false
          })
        } else if (field?.validator && isObject(field?.validator)) {
          const { label: validatorLabel = '', rules = [] } = field?.validator
          const result = { label: validatorLabel }
          let score = 0
          let i = 0
          for (const rule of rules) {
            const { label: ruleLabel, validator: ruleValidator, weight } = rule
            set(result, `rules.${i}`, { label: ruleLabel, isValid: true })
            try {
              await ruleValidator(originValue)(value).validate(get(value, field?.attrKey), {
                abortEarly: false
              })
              score = score + weight
            } catch (ruleErr) {
              set(result, `rules.${i}`, { label: ruleLabel, isValid: false })
            }
            i++
          }
          if (score < 1 && trim(get(value, field?.attrKey))) {
            errors.push('passwordError')
          }
        }
      } catch (err) {
        errors.push(...err?.errors)
      }
    }
    return isEmpty(errors)
  }

  const triggerValidation = async () => {
    for (const field of section?.fields) {
      if (isFunction(field?.triggerValidation)) {
        await field?.triggerValidation(originValue)(value)()
      }
    }
  }

  const nextFunction = async () => {
    const result = await validateSection()
    await triggerValidation()
    if (result) {
      _nextFunction()
    }
  }

  return (
    <SectionWrapper
      title={section?.title}
      hasNext={hasNext}
      nextFunction={nextFunction}
      backFunction={backFunction}
      isCurrentStep={isCurrentStep}>
      {section?.topContent && (
        <Text
          as="span"
          fontStyle="italic"
          fontSize="14px"
          fontFamily="PT Sans, Arial, sans-serif"
          mb="15px">
          {section?.topContent}
        </Text>
      )}
      {section?.fields?.map((field, i) => (
        <Field key={`field_${i}`} field={field} />
      ))}
      {section?.bottomContent && (
        <Text
          as="span"
          fontStyle="italic"
          fontSize="14px"
          fontFamily="PT Sans, Arial, sans-serif"
          mb="15px">
          {section?.bottomContent}
        </Text>
      )}
    </SectionWrapper>
  )
}

export default memo(Section)
