import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { priceFormat } from '../utils'

const Phone = (props) => {
  const { price = 0, phoneNumber = '', style = {}, height = '25px', fontSize = '13px', color } = props

  return (
    <Flex
      direction="row"
      alignItems="center"
      style={{ height, marginLeft: '5px', fontFamily: 'arial', ...style }}
      className="phone-row">
      <Flex
        align="center"
        justify="center"
        color={color}
        style={{
          height,
          fontSize,
          fontWeight: 700,
          backgroundColor: '#fff',
          paddingLeft: '5px',
          paddingRight: '5px',
          borderLeft: '1px solid #BBB',
          borderTop: '1px solid #BBB',
          borderBottom: '1px solid #BBB'
        }}>
        {phoneNumber}
      </Flex>
      <Flex
        _before={{
          content: `""`,
          position: 'absolute',
          display: 'block',
          border: '6px solid transparent',
          borderLeftColor: '#fff',
          marginLeft: '-10px'
        }}
        align="center"
        backgroundColor={color}
        fontSize="9px"
        h="100%"
        fontWeight="700"
        color="#fff"
        lineHeight="12px"
        paddingLeft={{ base: '9px', md: '10px' }}
        paddingRight="5px">
        <Flex direction="column">
          {price > 0 && (
            <Text>{`Service ${priceFormat(price, 'EUR')} / min`}</Text>
          )}
          {!price && (
            <Text>Service gratuit</Text>
          )}
          <Text>
            + prix appel
          </Text>
        </Flex>
      </Flex>
      <Flex
        align="center"
        justify="center"
        style={{
          height,
          width: '5px',
          borderRight: '1px solid #BBB',
          borderTop: '1px solid #BBB',
          borderBottom: '1px solid #BBB',
          backgroundColor: '#ffffff'
        }}
      />
    </Flex>
  )
}

export default Phone
