import { Flex, GatsbyImage, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { DataLayerUtils } from '../../context'

const LegalDisclaimer = (props) => {
  const { legalLinks, legalMention, legalMentionLink, legalMentionImage } = props

  return (
    <Flex flexDirection="column" width="100%" maxW="1000px" margin="auto" mt="20px">
      <Flex direction="row" width="100%" justify="center">
        {legalLinks?.map((l, i) => (
          <Flex
            key={`legal_link_${i}`}
            fontFamily="PT Sans, Arial, sans-serif"
            fontSize={{ base: '12px', md: '14px' }}
            color="#333333"
            _hover={{ textDecoration: 'underline' }}
            borderLeft="1px solid gray"
            textAlign="center"
            align="center"
            justify="center"
            pl="10px"
            pr="10px"
            height="14px"
            _first={{ borderLeft: 'none' }}
            mb={{ base: '15px', sm: '10px', md: '5px' }}>
            <Link
              to={l?.link}
              onClick={() =>
                DataLayerUtils.addEvent({
                  event: 'qwampEventLab',
                  eventCat: 'Footer',
                  eventAct: 'Départ lien',
                  eventLab: l?.link
                })
              }>
              {l?.label}
            </Link>
          </Flex>
        ))}
      </Flex>
      <Flex direction="row" justify="center" align="center">
        <GatsbyImage
          alt="Mentions légales"
          image={legalMentionImage?.image?.childImageSharp?.gatsbyImageData}
          loading="eager"
        />
        <Link to={legalMentionLink} style={{ marginLeft: '5px' }} target="_blank">
          <Text
            fontFamily="PT Sans, Arial, sans-serif"
            fontSize="9px"
            color="#5E5E5E"
            _hover={{ textDecoration: 'underline' }}>
            {legalMention}
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}

export default LegalDisclaimer
