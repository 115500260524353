import { Button, Flex, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext, useState } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import slugify from '../utils/slugify'
import telephone from './telephone.jpg'

const AccessLink = (props) => {
  const { link } = props

  if (link?.phoneNumber) {
    return (
      <Flex direction="row" mt="5px" mb="5px">
        <img
          src={telephone}
          alt="telephone"
          width="21px"
          height="21px"
          style={{ marginRight: '8px' }}
        />
        {link?.phoneNumber}
      </Flex>
    )
  }
  return (
    <Link to={link?.link}>
      <Flex
        direction="row"
        width="100%"
        align="flex-start"
        mt="5px"
        mb="5px"
        sx={{
          '& div:hover ~ p': {
            textDecoration: link?.link ? 'underline' : ''
          }
        }}>
        <Flex height="24px" width="auto" mr="5px" pt="4px" minWidth="24px">
          <Svg data={link?.picto} height="100%" width="auto" />
        </Flex>
        <Text
          flex="1"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
          fontSize="18px"
          color={link?.link ? '#3B5997' : ''}
          _hover={{ textDecoration: link?.link ? 'underline' : '' }}
          fontWeight={400}>
          {link?.label}
        </Text>
      </Flex>
    </Link>
  )
}
const Store = (props) => {
  const websiteContext = useContext(WebsiteContext)
  const [expanded, setExpanded] = useState(false)
  const { store } = props

  const toggle = () => {
    if (!expanded) {
      DataLayerUtils.addEvent({
        event: 'qwampEventLab',
        eventCat: 'Page points de vente',
        eventAct: "Consultation plan d'accès",
        eventLab: store?.shortName
      })
    }
    setExpanded(!expanded)
  }
  return (
    <Flex
      id={`${slugify(store?.shortName || 'store_' + store?.id)}`}
      direction="column"
      width={{ base: '100%', md: '480px' }}
      height={{ base: 'auto', md: expanded ? 'auto' : '400px' }}
      align="center"
      pt="40px"
      pl="20px"
      pr="20px"
      pb="20px"
      backgroundColor="#ffffff"
      style={{ float: 'left' }}>
      <Flex direction="column" width="100%" align="center">
        <Text
          as="h3"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
          fontSize="24px"
          fontWeight={700}
          color="#333333">
          {store?.name}
        </Text>
        <Text
          as="span"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
          fontSize="18px"
          fontWeight={400}
          color="#333333"
          textAlign="center">
          {store?.address1}
        </Text>
        <Text
          as="span"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
          fontSize="18px"
          fontWeight={400}
          textAlign="center"
          color="#333333">
          {store?.address2}
        </Text>
        <Flex
          display={{ base: 'none', md: 'flex' }}
          direction="row"
          width="100%"
          height="100%"
          mt="30px">
          <Flex direction="column" width="150px" mr="20px" height="100%" justify="space-between">
            <Flex direction="column" width="100%">
              <Text
                as="h4"
                fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
                fontSize="18px"
                textTransform="uppercase"
                fontWeight={700}
                color="#333333"
                mb="10px">
                {store?.scheduleTitle}
              </Text>
              {store?.schedule?.map((schedule, i) => (
                <Text
                  key={`schedul_${i}`}
                  fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
                  fontSize="18px"
                  fontWeight={400}
                  color="#333333">
                  {schedule.label}
                </Text>
              ))}
            </Flex>
            {store?.mapUrl && (
              <Button
                onClick={toggle}
                variant="solid"
                style={{
                  borderRadius: 0,
                  color: '#ffffff',
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '32px',
                  fontSize: '12px',
                  fontWeight: 400,
                  fontFamily: 'PT Sans,Arial,sans-serif',
                  marginTop: '10px',
                  boxShadow: 'none'
                }}
                backgroundColor={websiteContext.mainColor}
                _hover={{ backgroundColor: websiteContext.darkColor }}>
                Voir le plan d&acute;accès
              </Button>
            )}
          </Flex>
          <Flex direction="column" width="270px" justify="flex-start">
            <Text
              as="h4"
              fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
              fontSize="18px"
              textTransform="uppercase"
              fontWeight={700}
              color="#333333"
              mb="10px">
              {store?.accessTitle}
            </Text>
            {store?.accesses?.map((access, i) => (
              <AccessLink key={`access_${i}`} link={access} />
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Flex
        mt="5px"
        width="100%"
        height={{ base: '0', md: 'auto' }}
        visibility={expanded ? 'visible' : 'hidden'}
        style={{
          overflow: 'hidden',
          transition: 'height 0.2s ease-in-out'
        }}>
        <iframe src={store?.mapUrl} width="100%" height="546px"></iframe>
      </Flex>
    </Flex>
  )
}

const StoreLocatorContent = (props) => {
  const { storeLocator } = props

  return (
    <Flex
      width="full"
      pt={{ base: '0px', md: '20px' }}
      pb={{ base: '20px' }}
      pl="15px"
      justifyContent="center">
      <Flex
        direction="column"
        width="100%"
        maxWidth="1000px"
        justify="flex-start"
        pr={{ base: '10px', md: 0 }}>
        <Text
          as="h2"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
          fontSize={{ base: '29px', md: '40px' }}
          fontWeight={700}
          color="#333333"
          mb="10px">
          {storeLocator?.ourStoreTitle}
        </Text>
        <Flex direction="row" flexWrap="wrap" gridGap="15px">
          {storeLocator?.ourStores?.map(({ store }) => (
            <Store key={`store_${store.id}`} store={store} />
          ))}
        </Flex>
        <Text
          as="h2"
          fontFamily="PT Sans Narrow,PT Sans,Arial,sans-serif"
          fontSize={{ base: '29px', md: '40px' }}
          fontWeight={700}
          color="#333333"
          mt="30px"
          mb="10px">
          {storeLocator?.partnerStoreTitle}
        </Text>
        <Flex direction="row" flexWrap="wrap" gridGap="15px">
          {storeLocator?.partnerStores?.map(({ store }) => (
            <Store key={`store_${store.id}`} store={store} />
          ))}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default StoreLocatorContent
