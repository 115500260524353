import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  CircularProgress,
  CloseButton,
  Flex,
  Form,
  Text
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import React, { useContext, useRef, useState } from 'react'

import { ApiContext, WebsiteContext } from '../context'
import accountCreate from './forms/accountCreate.schema'

const AccountCreate = (props) => {
  const { isCheckout = false } = props
  const currentUser = {
    title: '',
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
    emailAddress: '',
    confirmEmailAddress: '',
    customFields: {
      birthdate: ''
    }
  }
  const billingAddress = {
    phoneNumber: '',
    postalCode: '',
    city: '',
    country: { name: process.env.GATSBY_API_COUNTRY },
    streetLine1: '',
    streetLine2: '',
    customFields: {
      addressLine3: '',
      addressLine4: '',
      cellPhoneNumber: ''
    },
    defaultBillingAddress: true
  }
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState()
  const ref = useRef(null)
  const validateFunction = async (val) => {
    const result = await apiContext?.AccountApi?.createUser(
      {
        ...val?.currentUser,
        addresses: [val?.billingAddress]
      },
      val?.password,
      {
        email: val?.optinEmail === 'Oui' ? 1 : 0,
        partnerEmail: val?.optinPartnerEmail === 'Oui' ? 1 : 0
      }
    )
    if (result?.error === 'registerEmailError') {
      setAlert({
        status: 'error',
        message:
          'E-mail déjà associé à un compte client : merci de vous connecter ou de saisir un autre e-mail.'
      })

      return { error: result?.error }
    } else if (result) {
      setAlert({
        status: 'success',
        message: 'Votre compte a été créé'
      })
      setTimeout(() => {
        if (isCheckout) {
          navigate('/checkout/shipping')
        } else {
          navigate(-1)
        }
      }, 200)

      return result
    } else {
      setAlert({
        status: 'error',
        message: "Une erreur s'est produite. Veuillez réessayer plus tard"
      })
      return { error: 'GenericError' }
    }
  }

  return (
    <Flex direction="column" width="100%" align="center" position="relative" ref={ref}>
      {alert && (
        <Alert status={alert.status}>
          <AlertIcon />
          <AlertDescription display="block" width={{ base: '80%', md: 'auto' }}>
            {alert.message}
          </AlertDescription>
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => {
              setAlert(null)
            }}
          />
        </Alert>
      )}
      <Text
        as="h1"
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        fontSize="32px"
        fontWeight={700}
        color="#333333"
        mt="15px"
        mb="15px">
        Créer votre compte
      </Text>
      <Form
        parentRef={ref}
        formSettings={accountCreate}
        maxWidth="780px"
        originValue={{
          currentUser,
          billingAddress,
          confirmEmail: '',
          password: '',
          confirmPassword: '',
          optinEmail: '',
          optinEmailPartner: ''
        }}
        validateFunction={validateFunction}
        validateText="Créer mon compte"
        formOptions={{
          lightColor: websiteContext?.lightColor,
          mainColor: websiteContext?.mainColor,
          darkColor: websiteContext?.darkColor,
          isMobile: websiteContext?.isMobile,
          fontFamily: websiteContext?.fontFamily,
          isFloating: true,
          sectionType: 'wizardAccordion'
        }}
        setLoading={setLoading}
      />

      <Box
        mt="20px"
        mb="10px"
        fontSize="11px"
        ml={{ md: '20px' }}
        fontFamily="PT Sans Narrow"
        color="#5e5e5e"
        align="justify"
        sx={{
          '& a': {
            color: websiteContext?.mainColor,
            '&:hover': {
              textDecoration: 'underline',
              color: websiteContext?.darkColor
            }
          }
        }}
        dangerouslySetInnerHTML={{ __html: websiteContext?.legalMention }}
      />
      {loading && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          justify="center"
          paddingTop="90px"
          zIndex="100"
          style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )}
    </Flex>
  )
}

export default AccountCreate
