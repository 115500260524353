import { Box, ChakraIcons, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import floor from 'lodash/floor'
import times from 'lodash/times'
import PropTypes from 'prop-types'
import React from 'react'

import { AVIS_VERIFIES_MODE } from '../constants/ratings'
import imageAvisVerifie from './avis_verifies.png'

const DISPLAY_MODE = {
  NONE: 'NONE',
  NB_REVIEWS: 'NB_REVIEWS',
  RATING: 'RATING',
  FULL: 'FULL'
}

const StarBox = (props) => {
  const {
    nbStars = 5,
    size = 10,
    activeColor = '#ffca43',
    unactiveColor = '#CDCDCD',
    gap = 0,
    maxRatingValue = 5,
    rating,
    mode = 3
  } = props
  const widthBox = (size + gap) * nbStars
  const widthActiveBox = floor(widthBox * (rating / maxRatingValue))

  return (
    <Box width={`${widthBox}px`} position="relative">
      <Flex position="absolute" zIndex="0">
        <Flex
          direction="row"
          gridGap={`${gap}px`}
          sx={{
            path: {
              stroke: mode === AVIS_VERIFIES_MODE.FOOTER ? activeColor : 'transparent'
            }
          }}>
          {times(Math.floor(nbStars)).map((i) => (
            <ChakraIcons.StarIcon
              key={`star_inactive_${i}`}
              w={size / 4}
              h={size / 4}
              color={unactiveColor}
            />
          ))}
        </Flex>
      </Flex>
      <Box position="relative" width={`${widthActiveBox}px`} overflow="hidden">
        <Flex direction="row" gridGap={`${gap}px`}>
          {times(Math.floor(nbStars)).map((i) => (
            <ChakraIcons.StarIcon key={`star_${i}`} w={size / 4} h={size / 4} color={activeColor} />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}

const RatingSummary = (props) => {
  const { rating, nbReviews, mode = DISPLAY_MODE.FULL, starSize = 24 } = props

  return (
    <Flex
      direction="row"
      justify={{
        base: nbReviews > 0 ? 'space-between' : 'center',
        md: nbReviews > 0 ? 'flex-start' : 'center'
      }}>
      <Flex direction="column" align="center" mb="10px" w={{ base: '70%', md: 'auto' }}>
        <img
          src={imageAvisVerifie}
          alt="Avis vérifiés"
          placeholder="blurred"
          layout="fixed"
          width={104}
          height={100}
          style={{ marginBottom: '8px' }}
        />
        {nbReviews < 1 && <StarBox rating="5" size={starSize} />}
      </Flex>
      {nbReviews > 0 && (
        <Flex
          direction="column"
          w={mode === AVIS_VERIFIES_MODE.MIN ? '120px' : ''}
          ml={{ md: '25px' }}>
          <Flex direction="row">
            <Text
              lineHeight="30px"
              verticalAlign="top"
              as="span"
              fontSize={mode === AVIS_VERIFIES_MODE.MIN ? '45' : '52px'}
              fontWeight={100}>
              {rating?.toFixed(1) || '-'}
            </Text>
            <Text
              as="span"
              fontSize={mode == AVIS_VERIFIES_MODE.MIN ? '27' : '30'}
              style={{ marginLeft: '5px' }}>
              /5
            </Text>
          </Flex>
          <StarBox rating={rating} size={starSize} />
          {nbReviews && (
            <Text
              mt="2"
              fontSize={{ base: '15px', md: '14px' }}
              textAlign="left"
              color="rgb(51,51,51)"
              lineHeight={{ base: '23px', md: '21px' }}>
              Calculé à partir de {nbReviews} avis client(s)
            </Text>
          )}
        </Flex>
      )}
    </Flex>
  )
}

RatingSummary.propTypes = {
  rating: PropTypes.number,
  nbReviews: PropTypes.number,
  mode: PropTypes.string,
  starSize: PropTypes.number
}

export { RatingSummary, StarBox }
