import { Flex, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { DataLayerUtils } from '../../context'

const CountryLinks = (props) => {
  const { countries = [] } = props
  return (
    <Flex
      direction="row"
      justify={{ base: 'center', md: 'flex-start' }}
      mb="20px"
      mt={{ base: '20px', md: '28px' }}
      mr={{ base: '0', md: '-12px' }}>
      {countries.map((c, i) => (
        <Link
          key={`country_${i}`}
          to={c?.link}
          display="flex"
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEventLab',
              eventCat: 'Footer',
              eventAct: 'Départ autre pays',
              eventLab: c?.link
            })
          }>
          <Flex mr="12px" p="0" direction="row" align="center">
            <Svg data={c?.picto} width="21px" height="14px" />
            <Text
              ml="4px"
              fontFamily="PT Sans, Arial, sans-serif"
              color="#5f5f5d"
              fontSize="12px"
              _hover={{ textDecoration: 'underline' }}>
              {c?.label}
            </Text>
          </Flex>
        </Link>
      ))}
    </Flex>
  )
}

export default CountryLinks
