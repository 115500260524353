import React from 'react'

const ShippingContext = React.createContext()

const ShippingContextProvider = (props) => {
  const { children, value } = props

  return <ShippingContext.Provider value={value}>{children}</ShippingContext.Provider>
}

ShippingContextProvider.displayName = 'ShippingContextProvider'

export { ShippingContextProvider, ShippingContext }
