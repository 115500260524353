import { ChakraIcons } from '@stars-ecom/shared-atoms-ui'
import React, { useEffect, useState } from 'react'
import styled, { css, keyframes } from 'styled-components'

import { DataLayerUtils } from '../context'

const animate = keyframes`
    from {
        top: -2px;
    }
    to {
        top: -8px;
    }
`
const Button = styled.button`
  position: fixed;
  bottom: 60px;
  right: 60px;
  z-index: 9999;
  font-size: 1.125rem;
  cursor: pointer;
  padding: 0.9375rem;
  width: 40px;
  height: 40px;
  background-position: center;
  right: 20px;
  background-color: #9e9e9e;
  opacity: 0.6;
  color: #fff;
  ${(props) =>
    css`
      display: ${props.visible ? 'inline' : 'none'};
    `};
  &:hover {
    opacity: 1;
  }
`

const ChevronUp = styled(ChakraIcons.ChevronUpIcon)`
   {
    position: absolute;
    top: -2px;
    left: -2px;
    font-weight: 900;
    font-size: 2.5em;
    &:hover {
      animation: ${animate};
      animation-duration: 0.5s;
      transition-property: all;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
  }
`

const ScrollToTop = (props) => {
  const { containerRef: _containerRef, threshold = 300 } = props
  const [visible, setVisible] = useState(false)
  const windowGlobal = typeof window !== 'undefined' && window

  const goToTop = () => {
    const container = _containerRef?.current || window
    container?.scrollTo({ top: 0, behavior: 'smooth' })
    DataLayerUtils.addEvent({
      event: 'qwampEvent',
      eventCat: 'Footer',
      eventAct: 'Retour haut de page'
    })
  }

  const toggleVisible = () => {
    const scrollTop = _containerRef
      ? _containerRef?.current?.scrollTop || 0
      : document?.body?.scrollTop || document?.documentElement?.scrollTop
    setVisible(scrollTop > threshold)
  }

  useEffect(() => {
    windowGlobal?.addEventListener('scroll', toggleVisible, { passive: true })
    return () => {
      windowGlobal?.removeEventListener('scroll', toggleVisible, { passive: true })
    }
  }, [])

  return (
    <Button onClick={goToTop} visible={visible}>
      <ChevronUp />
    </Button>
  )
}

export default ScrollToTop
