import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Link,
  ListItem,
  Text,
  UnorderedList
} from '@stars-ecom/shared-atoms-ui'
import keys from 'lodash/keys'
import React from 'react'

import { DataLayerUtils } from '../../context'

const Quicklinks = (props) => {
  const { categoryLabel, itemsList = [] } = props

  return (
    <Flex flexDirection="column" display={{ base: 'none', md: 'flex' }} maxWidth="150px">
      <Text
        color="#333333"
        style={{
          fontFamily: 'PT Sans, Arial, sans-serif',
          fontWeight: 600,
          fontSize: '14px',
          color: '#333333',
          textTransform: 'uppercase',
          marginBottom: '20px'
        }}>
        {categoryLabel}
      </Text>
      <UnorderedList ml="10px">
        {keys(itemsList)?.map((key, i) => (
          <ListItem key={i} fontSize="14px">
            <Flex>
              <Link
                to={itemsList[key].link}
                style={{
                  fontFamily: 'PT Sans, Arial, sans-serif',
                  position: 'relative',
                  right: '4px'
                }}
                onClick={() =>
                  DataLayerUtils.addEvent({
                    event: 'qwampEventLab',
                    eventCat: 'Footer',
                    eventAct: 'Démarrage lien',
                    eventLab: itemsList[key].link
                  })
                }
                underlineOnHover>
                <Box as="p" dangerouslySetInnerHTML={{ __html: itemsList[key].label }}></Box>
              </Link>
            </Flex>
          </ListItem>
        ))}
      </UnorderedList>
    </Flex>
  )
}

const QuicklinksAccordion = (props) => {
  const { categoryLabel, itemsList = [] } = props

  return (
    <AccordionItem>
      <AccordionButton height="43px" style={{ boxShadow: 'none' }}>
        <Box
          flex="1"
          textAlign="left"
          fontSize="14px"
          color="#333333"
          fontWeight={600}
          textTransform="uppercase">
          {categoryLabel}
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel pb={4}>
        <UnorderedList>
          {keys(itemsList)?.map((key, i) => (
            <ListItem ml={{ base: '22px', md: '32px' }} key={i}>
              <Link to={itemsList[key].link}>
                <Box as="p" dangerouslySetInnerHTML={{ __html: itemsList[key].label }}></Box>
              </Link>
            </ListItem>
          ))}
        </UnorderedList>
      </AccordionPanel>
    </AccordionItem>
  )
}

export { Quicklinks, QuicklinksAccordion }
