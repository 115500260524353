import { ApiContext } from '@stars-ecom/shared-organisms-ui'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'

const RewriteMediaUrl = (text = '') => {
  const apiContext = useContext(ApiContext)
  const [currentChannel, setCurrentChannel] = useState(null)
  const [isMounted, setIsMounted] = useState(true)
  const env = process.env.GATSBY_API_STAGE

  useEffect(() => {
    const fetchCurrentChannel = async () => {
      try {
        const result = await apiContext?.ExternalServicesApi.getCurrentChannel()

        if (isMounted) {
          setCurrentChannel(result)
        }
      } catch (error) {
        console.error(error)
      }
    }

    setIsMounted(true)
    fetchCurrentChannel()

    return () => {
      setIsMounted(false)
    }
  }, [apiContext, isMounted])

  let site = ''
  switch (currentChannel?.filePrefix) {
    case 'tsh_web_fr':
      site = 'https://www.teleshopping.fr'
      break
    case 'tsh_web_be':
      site = 'https://www.teleshopping.be'
      break
    case 'esh_web_fr':
      site = 'https://www.euroshopping.fr'
      break
    case 'esh_web_be':
      site = 'https://www.euroshopping.be'
      break
    case 'lbo_web_be':
      site = 'https://www.laboutique.be'
      break
    case 'm6b_web_fr':
      site = 'https://www.m6boutique.com'
      break
    case 'm6b_web_be':
      site = 'https://www.m6boutique.be'
      break
    default:
      site = ''
  }

  let REWRITE_RULES
  if (env === 'dev') {
    REWRITE_RULES = {
      [`https://stars-ecom${env}-web-datasync.s3.eu-west-3.amazonaws.com/`]: `https://media-${currentChannel?.dns}/`,
      [`${site}/media/catalog/product/jpg/`]: `https://media-${currentChannel?.dns}/eurojpg/`,
      ['/media/catalog/product/jpg/']: `https://media-${currentChannel?.dns}/eurojpg/`,
      [`${site}/skin/market/rsd/`]: `https://media-${currentChannel?.dns}/skin/market/rsd/`,
      ['/skin/market/rsd/']: `https://media-${currentChannel?.dns}/skin/market/rsd/`
    }
  } else if (env === 'pprd') {
    if (site.includes('boutique')) {
      REWRITE_RULES = {
        [`https://stars-ecom${env}-web-datasync.s3.eu-west-3.amazonaws.com/`]: `https://media-${currentChannel?.dns}/`,
        [`${site}/media/catalog/product/jpg/`]: `https://media-${currentChannel?.dns}/eurojpg/`,
        ['/media/catalog/product/jpg/']: `https://media-${currentChannel?.dns}/eurojpg/`,
        [`${site}/skin/market/rsd/`]: `https://media-${currentChannel?.dns}/skin/market/rsd/`,
        ['/skin/market/rsd/']: `https://media-${currentChannel?.dns}/skin/market/rsd/`
      }
    } else {
      REWRITE_RULES = {
        [`https://stars-ecom${env}-web-datasync.s3.eu-west-3.amazonaws.com/`]: `https://media.${currentChannel?.dns}/`,
        [`${site}/media/catalog/product/jpg/`]: `https://media.${currentChannel?.dns}/eurojpg/`,
        ['/media/catalog/product/jpg/']: `https://media.${currentChannel?.dns}/eurojpg/`,
        [`${site}/skin/market/rsd/`]: `https://media.${currentChannel?.dns}/skin/market/rsd/`,
        ['/skin/market/rsd/']: `https://media.${currentChannel?.dns}/skin/market/rsd/`
      }
    }
  } else {
    REWRITE_RULES = {
      [`https://stars-ecom${env}-web-datasync.s3.eu-west-3.amazonaws.com/`]: `https://media.${currentChannel?.dns.replace(
        'www.',
        ''
      )}/`,
      [`${site}/media/catalog/product/jpg/`]: `https://media.${currentChannel?.dns.replace(
        'www.',
        ''
      )}/eurojpg/`,
      ['/media/catalog/product/jpg/']: `https://media.${currentChannel?.dns.replace(
        'www.',
        ''
      )}/eurojpg/`,
      [`${site}/skin/market/rsd/`]: `https://media.${currentChannel?.dns.replace(
        'www.',
        ''
      )}/skin/market/rsd/`,
      ['/skin/market/rsd/']: `https://media.${currentChannel?.dns.replace(
        'www.',
        ''
      )}/skin/market/rsd/`
    }
  }

  let finalResult = text || ''

  Object.keys(REWRITE_RULES).forEach((rule) => {
    const regexp = new RegExp(`(href="|src=")(${_.escapeRegExp(rule)})([^"]*"[^>]*>)`, 'g')
    finalResult = finalResult.replace(regexp, `$1${REWRITE_RULES[rule]}$3`)
  })

  return finalResult
}

export default RewriteMediaUrl
