import { GatsbyImage as _GastbyImage } from 'gatsby-plugin-image'
import React, { memo } from 'react'

const GatsbyImage = (props) => {
  const { image } = props
  if (!image) {
    return null
  }
  return <_GastbyImage {...props} />
}

export default memo(GatsbyImage)
