import { axios } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'

const sendForm = async (formData) => {
  try {
    const result = await axios.post(
      `sereneo/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/sendForm`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    )
    return get(result, 'data', {})
  } catch (e) {
    console.error(e)
    return {}
  }
}
export { sendForm }
