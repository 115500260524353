import { Box, Flex, Link, Text } from '@stars-ecom/shared-atoms-ui'
import isEmpty from 'lodash/isEmpty'
import React from 'react'

import { DataLayerUtils } from '../context'
import slugify from '../utils/slugify'

const StoreLocatorHeader = (props) => {
  const { storeLocator, location } = props

  return (
    <Flex
      width="full"
      backgroundColor={{ base: 'transparent', md: storeLocator?.backgroundColor }}
      pt={{ base: '20px', md: '40px' }}
      pb={{ base: '20px', md: 0 }}
      pl="15px"
      justifyContent="center"
      height={{ base: 'auto', md: '270px' }}>
      <Flex direction="column" width="100%" maxWidth="1000px" justify="flex-start">
        <Text
          as="h1"
          fontFamily={{
            base: 'PT Sans, Arial, sans-serif',
            md: 'PT Sans Narrow,PT Sans,Arial,sans-serif'
          }}
          fontSize={{ base: '28px', md: '44px' }}
          fontWeight={{ base: 700, md: 400 }}
          color={{ base: storeLocator?.backgroundColor, md: storeLocator?.textColor }}>
          {storeLocator?.title}
        </Text>
        <Flex direction="row" display={{ base: 'none', md: 'flex' }} mb="10px">
          {!isEmpty(storeLocator?.ourStores) && (
            <Flex direction="column" minWidth="130px" mr="50px">
              <Text
                fontFamily="PT Sans, arial, sans-serif"
                fontSize="16px"
                fontWeight={700}
                mb="5px"
                color={storeLocator?.textColor}>
                {storeLocator?.ourStoreTitle}
              </Text>
              <Flex direction="column" wrap="wrap">
                {storeLocator?.ourStores?.map(({ store }) =>
                  store?.shortName ? (
                    <Link
                      key={`store_${store.id}`}
                      to={`${location.pathname}#${slugify(store?.shortName)}`}
                      onClick={() =>
                        DataLayerUtils.addEvent({
                          event: 'qwampEventLab',
                          eventCat: 'Page points de vente',
                          eventAct: 'Ancre point de vente',
                          eventLab: store?.shortName
                        })
                      }>
                      <Text
                        fontFamily="PT Sans, Arial, sans-serif"
                        color={storeLocator?.textColor}
                        fontWeight={400}
                        fontSize="14px"
                        _hover={{ textDecoration: 'underline' }}>
                        {store?.shortName}
                      </Text>
                    </Link>
                  ) : null
                )}
              </Flex>
            </Flex>
          )}
          <Flex direction="column">
            <Text
              fontFamily="PT Sans, arial, sans-serif"
              fontSize="16px"
              fontWeight={700}
              mb="5px"
              color={storeLocator?.textColor}>
              {storeLocator?.partnerStoreTitle}
            </Text>
            <Flex direction="column" wrap="wrap" maxHeight="65px">
              {storeLocator?.partnerStores?.map(({ store }) =>
                store?.shortName ? (
                  <Link
                    key={`store_${store.id}`}
                    to={`${location.pathname}#${slugify(store?.shortName)}`}
                    target="_self"
                    onClick={() =>
                      DataLayerUtils.addEvent({
                        event: 'qwampEventLab',
                        eventCat: 'Page points de vente',
                        eventAct: 'Ancre point de vente',
                        eventLab: store?.shortName
                      })
                    }>
                    <Text
                      fontFamily="PT Sans, Arial, sans-serif"
                      color={storeLocator?.textColor}
                      fontWeight={400}
                      fontSize="14px"
                      mr="45px"
                      _hover={{ textDecoration: 'underline' }}>
                      {store?.shortName}
                    </Text>
                  </Link>
                ) : null
              )}
            </Flex>
          </Flex>
        </Flex>
        <Box
          display={{ base: 'none', md: 'block' }}
          mt="20px"
          backgroundColor={storeLocator?.textColor}
          w="70px"
          h="3px"
        />
      </Flex>
    </Flex>
  )
}

export default StoreLocatorHeader
