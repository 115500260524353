import { axios } from '@stars-ecom/shared-organisms-ui'

const getMarketingPreference = async (idClient) => {
  try {
    const result = await axios.get(
      `marketing/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/${idClient}`
    )
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const upsertMarketingPreference = async (idClient, preference) => {
  try {
    const result = await axios.post(
      `marketing/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/${idClient}/upsertMarketingPreference`,
      preference
    )
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}
const addNewSubscriber = async (email) => {
  try {
    const result = await axios.post(
      `marketing/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/addNewSubscriber`,
      { codsoc: process.env.GATSBY_API_CODSOC, email }
    )
    return result.data
  } catch (e) {
    console.error(e)
    return e
  }
}
export { getMarketingPreference, upsertMarketingPreference, addNewSubscriber }
