import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useState
} from '@stars-ecom/shared-atoms-ui'
import React, { useContext, useEffect } from 'react'

import { CartContext, ShippingContext, UserContext, WebsiteContext } from '../../context'
import { ShippingUtils } from '../../utils'

const ContactForm = (props) => {
  const {
    validateShipping: _validateShipping = () => {},
    isValid: _isValid,
    isLoading = false,
    shippingMethod = {}
  } = props
  const { shipping, setShipping } = useContext(ShippingContext)
  const currentUser = useContext(UserContext)
  const currentCart = useContext(CartContext)

  const [isValid, setIsValid] = useState(
    _isValid && (shipping?.phone || shipping?.cellPhone) && currentUser.addresses?.length
  )
  const websiteContext = useContext(WebsiteContext)

  const updateShipping = ({
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false,
    eligibleShippingMethods
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy,
        eligibleShippingMethods
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  const [phone, setPhone] = useState(shipping?.phone)
  const [cellPhone, setCellPhone] = useState(shipping?.cellPhone)

  const validateShipping = () => {
    _validateShipping()
  }

  useEffect(() => {
    setIsValid(_isValid && (shipping.phone || shipping.cellPhone) && currentUser.addresses?.length)
  }, [_isValid, shipping])

  useEffect(() => {
    setPhone(shipping.phone)
    setCellPhone(shipping.cellPhone)
  }, [shipping.phone, shipping.cellPhone])

  const handlePhoneChange = (type) => (number) => {
    if (type === 'cellPhone') {
      setCellPhone(number)
      updateShipping({ cellPhone: number })
    } else if (type === 'phone') {
      setPhone(number)
      updateShipping({ phone: number })
    }
  }
  return (
    <Flex direction="column">
      <Box py="20px" maxWidth="370px">
        <Text
          ml="40px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="18px"
          fontWeight="bold"
          pb="20px"
          display={{ md: 'none' }}>
          Contact de livraison
        </Text>
        <Text
          fontSize={{ base: '18px', md: '16px' }}
          fontFamily="PT Sans, Arial, sans-serif"
          ml={{ base: '40px', md: '0' }}
          mb={{ base: '30px', md: '40px' }}>
          {
            "Merci de vérifier l'exactitude du numéro de téléphone, indispensable pour l'expédition de votre commande"
          }
        </Text>

        <FormControl
          isRequired={false}
          mb={{ base: '30px', md: '20px' }}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          color="#1A202C"
          variant="floating"
          sx={{
            '& label': {
              fontFamily: '"PT Sans", Arial, Helvetica, sans-serif',
              padding: '0 12px',
              color: '#777'
            }
          }}>
          <Input
            maxWidth="370px"
            name="phone"
            fontFamily="PT Sans, Arial, Helvetica, sans-serif"
            placeholder=" "
            value={phone || ''}
            style={{ borderRadius: '0' }}
            onChange={(e) => handlePhoneChange('phone')(e.target.value)}
          />
          <FormLabel>{'Téléphone fixe'}</FormLabel>
        </FormControl>

        <FormControl
          isRequired={false}
          direction="row"
          alignItems="center"
          flexWrap="wrap"
          color="#1A202C"
          variant="floating"
          sx={{
            '& label': {
              fontFamily: '"PT Sans", Arial, Helvetica, sans-serif',
              padding: '0 12px',
              color: '#777'
            }
          }}>
          <Input
            maxWidth="370px"
            name="cellPhone"
            fontFamily="PT Sans, Arial, Helvetica, sans-serif"
            style={{ borderRadius: '0' }}
            placeholder=" "
            value={cellPhone || ''}
            onChange={(e) => handlePhoneChange('cellPhone')(e.target.value)}
          />
          <FormLabel>{'Téléphone mobile'}</FormLabel>
        </FormControl>
      </Box>
      <Box textAlign="center" p={{ base: '20px 0', md: '20px 100px 20px 0' }}>
        <Button
          id={`validateShippingButtong_${shippingMethod?.code}`}
          onClick={validateShipping}
          disabled={!isValid}
          isLoading={isLoading}
          fontFamily={websiteContext.fontFamily}
          fontWeight="normal"
          w={{ base: '100%', md: 'auto' }}
          borderRadius="0"
          h={{ base: 'auto', md: '50px' }}
          p={{ base: '15px', md: '0 32px' }}
          bg={websiteContext.mainColor}
          _hover={{ bg: websiteContext.darkColor }}
          _focus={{ border: 'none' }}
          color="#FFF">
          VALIDER MA LIVRAISON
        </Button>
      </Box>
    </Flex>
  )
}

export default ContactForm
