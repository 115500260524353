import { isFunction, set } from 'lodash'
import React, { useEffect, useReducer } from 'react'

const windowGlobal = typeof window !== 'undefined' && window

const CartContext = React.createContext()

const nexus = {}

const cartReducer = (_, action) => {
  return action
}

const initialState = windowGlobal?.localStorage?.getItem('currentCart')
  ? JSON.parse(windowGlobal?.localStorage?.getItem('currentCart'))
  : {}

const CartContextProvider = (props) => {
  const { children } = props
  const [cart, dispatch] = useReducer(cartReducer, initialState)
  const storage = windowGlobal?.localStorage

  set(nexus, 'updateOrder', (c) => dispatch(c))

  useEffect(() => {
    storage?.setItem('currentCart', JSON.stringify(cart))
  }, [cart])

  return <CartContext.Provider value={cart}>{children}</CartContext.Provider>
}

const updateOrder = (cart) => {
  if (isFunction(nexus.updateOrder)) {
    nexus.updateOrder(cart)
  }
}

CartContextProvider.displayName = 'CartContextProvider'

export { CartContextProvider, CartContext, updateOrder }
