import { chroma } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

const WebsiteContext = React.createContext({
  mainColor: '#017dc7',
  darkColor: '#0671b1',
  lightColor: '#008ef2',
  showChannels: true,
  showReviews: true
})

const WebsiteContextProvider = (props) => {
  const { children, value } = props

  const websiteContext = {
    ...value,
    darkColor: chroma(value?.mainColor || '#FFFFFF')
      .darken(0.3)
      .hex(),
    lightColor: chroma(value?.mainColor || '#FFFFFF')
      .brighten()
      .hex(),
    fontFamily: 'PT Sans, Arial, sans-serif',
    fontFamilyNarrow: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
  }

  return <WebsiteContext.Provider value={websiteContext}>{children}</WebsiteContext.Provider>
}

WebsiteContextProvider.displayName = 'WebsiteContextProvider'

export { WebsiteContextProvider, WebsiteContext }
