import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { OfferUtils, priceFormat } from '../../utils'

const _ThumbnailPrice = (props) => {
  const { product, type = 'STANDARD', isList = false, isUpsell = false } = props

  const {
    standardPrice = 0,
    price = 0,
    discount = 0,
    discountType = 'PERCENTAGE',
    currency = 'EUR'
  } = OfferUtils.getCurrentProductOffer(product, type, process.env.GATSBY_API_CHANNEL_ID)

  return (
    <Flex
      width="100%"
      direction="row"
      justify={discount ? (type === 'UPSELL' ? 'space-around' : 'space-between') : 'center'}
      align="center"
      marginTop="10px"
      mb="6px"
      p={
        isUpsell
          ? { base: '0 5px', md: '0 10px' }
          : '0 10px'
      }
      h={type === 'UPSELL' ? '50px !important' : 'auto'}
      flexWrap="wrap">
      {discount !== 0 && (
        <Flex
          bgColor="#d5e301"
          justify="center"
          align="center"
          w={type === 'UPSELL' ? '41px' : isList ? { base: '38px', md: '49px' } : '49px'}
          h="26px"
          borderRadius="3px">
          <Text
            as="span"
            fontWeight={700}
            lineHeight="26px"
            fontSize={type === 'UPSELL' ? '17px' : isList ? { base: '15px', md: '22px' } : '22px'}
            color="#242a30"
            fontFamily="PT Sans Narrow, Arial, sans-serif">
            {discountType === 'PERCENTAGE' ? `-${discount}` : `-${priceFormat(discount, currency)}`}
          </Text>
          <Text
            as="sup"
            top={isList ? { base: '2px', md: '0' } : '0'}
            fontSize={type === 'UPSELL' ? '11px' : isList ? { base: '11px', md: '14px' } : '14px'}
            fontWeight={700}
            lineHeight={0}
            textAlign="center"
            color="#242a30"
            paddingBottom="10px"
            fontFamily="PT Sans Narrow, Arial, sans-serif">
            {`${discountType === 'PERCENTAGE' ? '%' : ''}`}
          </Text>
        </Flex>
      )}

      {standardPrice == '0' ? (
        <Text
          justifyContent="center"
          border="1px solid silver"
          bgColor="#f3f4f9"
          p="10px 15px"
          fontSize="15px"
          fontWeight="bold">
          Produit indisponible
        </Text>
      ) : (
        <Text
          as="span"
          lineHeight={type === 'UPSELL' ? '33px' : isList ? { base: '1', md: '42px' } : '42px'}
          fontSize={type === 'UPSELL' ? '22px' : { base: '24px', md: '28px' }}
          color="#333"
          fontFamily="PT Sans Narrow, Arial, sans-serif"
          fontWeight={700}>{`${priceFormat(discount ? price : standardPrice, currency)}`}</Text>
      )}

      {discount !== 0 && (
        <Flex
          direction={type === 'UPSELL' ? 'row' : isList ? { md: 'column' } : 'column'}
          align={type === 'UPSELL' ? 'center' : 'flex-end'}
          justify={{ base: 'flex-end', md: 'center' }}
          w={type === 'UPSELL' ? '100%' : isList ? { base: '100%', md: 'auto' } : 'auto'}>
          <Text
            as="span"
            color="#333"
            fontSize="14px"
            fontFamily="PT Sans Narrow, Arial, sans-serif"
            lineHeight={isList ? '21px' : '14px'}
            fontWeight={400}>
            au lieu de
          </Text>
          <Text
            as="span"
            fontSize="14px"
            textDecoration="line-through"
            ml="4px"
            color="#999999"
            fontFamily="PT Sans Narrow, Arial, sans-serif"
            fontWeight={400}>
            {`${priceFormat(standardPrice, currency)}`}
          </Text>
        </Flex>
      )}
    </Flex>
  )
}

const ThumbnailPrice = memo(_ThumbnailPrice)

export default ThumbnailPrice
