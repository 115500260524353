import { useEffect, useRef, useState as _useState } from 'react'

const useState = (initValue) => {
  const isMounted = useRef(false)

  useEffect(() => {
    isMounted.current = true
    return () => (isMounted.current = false)
  })

  const [value, setValue] = _useState(initValue)

  const _setValue = (v) => {
    if (isMounted.current) {
      setValue(v)
    }
  }

  return [value, _setValue]
}

export default useState
