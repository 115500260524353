import { Box, Flex, GatsbyImage, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
const EventCard = (props) => {
  const { event, position } = props
  const websiteContext = useContext(WebsiteContext)

  const getWidth = (size = 'SMALL') => {
    switch (size) {
      case 'MEDIUM':
        return '645'
      case 'LARGE':
        return '980'
      case 'SMALL':
      default:
        return '320'
    }
  }
  const bgColor = event?.backgroundColor ? event?.backgroundColor?.toLowerCase()?.trim() : '#f6f3f1'

  return (
    <Flex
      direction={{ base: event?.size === 'SMALL' ? 'row' : 'column', md: 'column' }}
      position="relative"
      bg={event?.backgroundColor ?? '#f6f3f1'}
      alignItems="center"
      color={event?.textColor}
      width={{ base: '100%', md: getWidth(event?.size)+'px' }}
      maxWidth={{ base: '100%', md: (getWidth(event?.size)-1)+'px' }}
      height={{ base: 'auto', md: '450px' }}
      m={{ base: '5px', md: 0 }}
      justify="flex-end">
      <Box
        position={{ base: 'relative', md: 'absolute' }}
        w={{ base: event.size === 'SMALL' ? '44%' : 'auto', md: 'auto' }}
        height="auto"
        style={{ top: 0, left: 0 }}>
        <Link
          to={event?.link}
          target="_self"
          style={{ cursor: 'pointer' }}
          onClick={() => {
            DataLayerUtils.addPromotionClick({
              promotionName: event.title,
              promotionCreative: event?.backgroundImage?.url,
              promotionPosition: position,
              promotionId: event.id
            })
          }}>
          <GatsbyImage
            image={event?.backgroundImage?.image?.childImageSharp?.gatsbyImageData}
            loading="eager"
            alt={event?.title}
          />
        </Link>
      </Box>
      <Flex
        direction="column"
        align="center"
        w={{ base: event?.size === 'SMALL' ? '56%' : '100%', md: '100%' }}
        justify="space-between">
        <Flex
          direction="column"
          p={
            event?.size === 'SMALL' ? { base: '0 5px', md: '0' } : { base: '0 30px', md: '0 20px' }
          }
          height={{ base: 'auto', md: '100px' }}
          width="100%"
          style={{ position: 'relative' }}>
          <Box
            display={{ base: 'none', md: 'block' }}
            style={{
              position: 'absolute',
              background: `linear-gradient(to top, ${bgColor} 22%,transparent 100%)`,
              height: '30px',
              width: '100%',
              zIndex: 0,
              top: '-15px',
              left: '0'
            }}
          />
          <Text
            style={{
              zIndex: 10,
              fontFamily: 'PT Sans, Arial, sans-serif',
              fontWeight: 700,
              textAlign: 'center',
              textTransform: 'uppercase'
            }}
            mt={{ base: '2px', md: '0' }}
            lineHeight="1.4"
            fontSize={
              event?.size === 'SMALL' ? { base: '10px', md: '17px' } : { base: '15px', md: '17px' }
            }
            as="h3">
            {event?.title}
          </Text>
          <Text
            margin={{ base: '10px 0 0', md: '0 auto' }}
            style={{
              fontFamily: 'PT Sans, Arial, sans-serif',
              fontWeight: 400,
              textAlign: 'center'
            }}
            p={event?.size === 'SMALL' ? { md: '0 20px' } : { base: '0 30px', md: '0 20px' }}
            lineHeight={event?.size === 'SMALL' ? { base: '1.2', md: '1.5' } : '1.5'}
            fontSize={
              event?.size === 'SMALL' ? { base: '9px', md: '14px' } : { base: '11px', md: '14px' }
            }
            as="p"
            dangerouslySetInnerHTML={{ __html: event?.description }}></Text>
        </Flex>
        <Flex
          w={event?.size === 'SMALL' ? { base: '85%', md: '100%' } : '100%'}
          justify="center"
          p={
            event?.size === 'SMALL'
              ? { base: '0 10px', md: '0 20px' }
              : { base: '0 30px', md: '0 20px' }
          }>
          <Link
            to={event?.link}
            target="_self"
            style={{
              width: '100%'
            }}>
            <Flex
              bg={event?.moreButtonColor ?? '#ffffff'}
              borderColor="rgb(207, 213, 227)"
              borderWidth="1px"
              cursor="pointer"
              color={event?.moreTextColor}
              w="100%"
              height={{ base: '30px', md: '46px' }}
              m={event?.size === 'SMALL' ? { base: '8px 0', md: '10px 0' } : '10px 0'}
              align="center"
              justify="center">
              <Text
                style={{ textAlign: 'center', textTransform: 'uppercase' }}
                fontSize={{ base: '11px', md: '15px' }}
                fontFamily={websiteContext.fontFamily}
                as="span">
                {event?.moreText}
              </Text>
            </Flex>
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default EventCard
