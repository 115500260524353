import { formatter } from '@stars-ecom/shared-atoms-ui'

import { getCurrencyLabel } from './offerUtils'

const priceFormat = (integerPrice = 0, currency = 'EUR', textOnZero) => {
  if (!integerPrice && textOnZero) {
    return textOnZero
  }
  const currencyLabel = getCurrencyLabel(currency)
  return formatter({
    suffix: ` ${currencyLabel}`,
    decimal: ',',
    round: 2,
    padRight: 2,
    separator: ''
  })(integerPrice / 100)
}

export { priceFormat }
