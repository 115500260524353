import { Box, Button, CircularProgress, Flex, Picto, Text } from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import debounce from 'lodash/debounce'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import take from 'lodash/take'
import React, { memo, useContext, useEffect, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import Order from './Order'

dayjs.extend(customParseFormat)

const windowGlobal = typeof window !== 'undefined' && window
const documentGlobal = typeof document !== 'undefined' && document

const OrderHistory = (props) => {
  const { trackings, orderCode } = props
  const [orders, setOrders] = useState([])
  const [loading, setLoading] = useState(false)
  const PAGE_SIZE = 10
  const [limitToDisplay, setLimitToDisplay] = useState(3)
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)

  const getData = async () => {
    setLoading(true)
    windowGlobal?.scrollTo({ top: 0, behavior: 'smooth' })
    const result = await apiContext?.AccountApi?.getOrders()
    if (result) {
      setOrders(orderBy(result, ['orderPlacedAt', 'code'], ['desc', 'desc']) || [])
      setLimitToDisplay(result?.length > PAGE_SIZE ? PAGE_SIZE : result?.length)
    }
    setLoading(false)
  }

  const paginate = () => {
    if (limitToDisplay < (orders?.length || 0)) {
      setLimitToDisplay(Math.floor((limitToDisplay + PAGE_SIZE) / PAGE_SIZE) * PAGE_SIZE)
    }
  }

  useEffect(() => {
    if (currentUser) {
      debounce(getData, 200)()
    }
  }, [currentUser])

  useEffect(() => {
    if (orderCode && !isEmpty(orders)) {
      const index = findIndex(orders, (o) => o.code === orderCode?.replace('#', ''))
      if (index) {
        setLimitToDisplay(Math.floor((index + PAGE_SIZE) / PAGE_SIZE) * PAGE_SIZE)
      }
    } else {
      setLimitToDisplay(3)
    }
  }, [orders, orderCode])

  useEffect(() => {
    if (orderCode && !isEmpty(orders) && windowGlobal) {
      setTimeout(() => {
        documentGlobal?.querySelector(orderCode)?.scrollIntoView({
          behavior: 'smooth'
        })
      }, 500)
    }
  }, [limitToDisplay])

  const ShowMore = ({ paginate }) => {
    const [iconColor, setIconColor] = useState('#000')
    const handleMouseEnter = () => {
      setIconColor(websiteContext?.mainColor)
    }
    const handleMouseLeave = () => {
      setIconColor('#000')
    }

    return (
      <Button
        mt="15px"
        height="48px"
        fontSize="14px"
        lineHeight="44px"
        rightIcon={<Picto icon="plusCercle" width="16px" height="16px" color={iconColor} />}
        _hover={{ color: websiteContext?.mainColor }}
        onMouseEnter={handleMouseEnter}
        onClick={paginate}
        onMouseLeave={handleMouseLeave}
        fontFamily={websiteContext?.fontFamily}
        borderColor="#c1c1c1"
        textTransform="uppercase"
        variant="outline">
        Afficher plus de commandes
      </Button>
    )
  }

  return (
    <Flex direction="column">
      {currentUser?.customFields?.externalCode &&
        websiteContext?.websiteSettings?.webloyaltyAccount && (
          <Box id={websiteContext?.websiteSettings?.webloyaltyAccount} />
        )}

      <Flex
        direction="column"
        width="100%"
        minWidth={{ base: 'auto', md: '700px' }}
        padding={{ base: '15px', md: '40px' }}
        backgroundColor="#ffffff"
        minHeight={{ base: 'auto', md: '400px' }}>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          align={{ base: 'left', md: 'center' }}
          justify="space-between">
          <Text
            as="h2"
            borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
            mb={{ base: '15px', md: '0px' }}
            pb={{ base: '5px', md: '0px' }}
            style={{
              fontSize: '26px',
              lineHeight: '26px',
              color: '#333',
              fontFamily: websiteContext?.fontFamilyNarrow,
              fontWeight: 700
            }}>
            Mes {limitToDisplay === 3 && !loading && 'trois dernières'} commandes
          </Text>
          {!loading && orders?.length > limitToDisplay && limitToDisplay === 3 && (
            <Text
              color={websiteContext?.mainColor}
              fontSize="12px"
              lineHeight="18px"
              onClick={() => setLimitToDisplay(limitToDisplay + PAGE_SIZE)}
              fontFamily={websiteContext?.fontFamily}
              style={{ cursor: 'pointer' }}>
              Afficher toutes les commandes
            </Text>
          )}
        </Flex>

        {loading && (
          <Flex width="100%" height="200" align="center" justify="center">
            <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
          </Flex>
        )}

        {!loading && (orders?.length || 0) > 0 && (
          <Flex direction="column" width="100%" gridGap="20px" mt={{ base: '20px', md: '40px' }}>
            {take(orders, limitToDisplay)?.map((order) => (
              <Order
                key={`order_${order.code}`}
                order={order}
                trackings={trackings}
                hash={orderCode}
              />
            ))}
            {limitToDisplay < (orders?.length || 0) && limitToDisplay > 3 && (
              <ShowMore paginate={paginate} />
            )}
          </Flex>
        )}

        {!loading && isEmpty(orders) && (
          <Text
            as="span"
            fontFamily={websiteContext?.fontFamily}
            fontSize="14px"
            fontWeight={400}
            mt="15px">
            Vous n&acute;avez pas de commande.
          </Text>
        )}
      </Flex>
    </Flex>
  )
}
export default memo(OrderHistory)
