const pages = {
  forgottenPasswordPage: {
    title: 'Mot de passe oublié',
    component: 'ForgottenPasswordPage',
    path: 'customer/account/forgotpassword'
  },
  ResetPasswordPage: {
    title: 'Mot de passe oublié',
    component: 'ResetPasswordPage',
    path: 'customer/account/changeforgotten/'
  },
  accountEditPage: {
    title: 'Mes informations personnelles',
    component: 'AccountEditPage',
    path: 'customer/account/edit',
    mustBeConnected: true
  },
  accountAddressesPage: {
    title: 'Mes adresses',
    component: 'AccountAddressesPage',
    path: 'customer/address',
    mustBeConnected: true
  },
  accountPaymentCardsPage: {
    title: 'Mes cartes de crédit',
    component: 'AccountPaymentCardsPage',
    path: 'customer/cards',
    mustBeConnected: true
  },
  accountOrderHistoryPage: {
    title: 'Mes commandes',
    component: 'AccountOrderHistoryPage',
    path: 'customer/account',
    useTracking: true,
    mustBeConnected: true,
    needLoyaltyCard: true
  },
  accountLoyaltyCardPage: {
    title: 'Ma carte de fidélité',
    component: 'AccountLoyaltyCardPage',
    path: 'customer/account/fidelity',
    needLoyaltyCard: true,
    mustBeConnected: true
  },
  accountReturnPage: {
    title: 'Mes retours',
    component: 'AccountReturnPage',
    path: 'productreturn/list',
    mustBeConnected: true
  },
  accountReturnFormPage: {
    title: 'Retourner un produit',
    component: 'AccountReturnFormPage',
    path: 'productreturn/form',
    mustBeConnected: true
  },
  accountSubscriptionPage: {
    title: 'Mes abonnements',
    component: 'AccountSubscriptionPage',
    path: 'abonnement',
    mustBeConnected: true
  },
  accountPreferencesPage: {
    title: 'Mes préférences',
    component: 'AccountPreferencesPage',
    path: 'customer/newsletter/edit',
    mustBeConnected: true
  },
  ShoppingCartPage: {
    title: 'Mon panier',
    component: 'ShoppingCartPage',
    path: 'checkout/onepage',
    mustBeConnected: false,
    needLoyaltyCard: true,
    isCheckout: true
  },
  checkoutCreateAccount: {
    title: 'Créer un compte',
    noBreadcrumb: true,
    component: 'CreateAccountPage',
    path: 'checkout/account',
    headerType: 'CHECKOUT',
    footerType: 'CHECKOUT',
    headerStep: 2,
    mustBeConnected: false,
    isCheckout: true
  },
  checkoutCreateBillingAdr: {
    title:
      'Merci de bien vouloir compléter vos informations personnelles pour finaliser votre commande',
    noBreadcrumb: true,
    component: 'AccountEditPage',
    path: 'checkout/account/edit',
    headerType: 'CHECKOUT',
    footerType: 'CHECKOUT',
    headerStep: 2,
    mustBeConnected: true,
    isCheckout: true
  },
  accountCreateAccount: {
    title: 'Créer un compte',
    noBreadcrumb: true,
    component: 'CreateAccountPage',
    path: 'customer/account/create',
    mustBeConnected: false
  },
  ShippingPage: {
    title: 'Livraison',
    noBreadcrumb: true,
    component: 'ShippingPage',
    path: 'checkout/shipping',
    headerType: 'CHECKOUT',
    footerType: 'CHECKOUT',
    headerStep: 3,
    mustBeConnected: true,
    isCheckout: true
  },
  PaymentPage: {
    title: 'Paiement',
    component: 'PaymentPage',
    path: 'checkout/payment',
    headerType: 'CHECKOUT',
    footerType: 'CHECKOUT',
    headerStep: 4,
    mustBeConnected: true,
    isCheckout: true,
    isPayment: true
  },
  PaymentFailurePage: {
    title: 'Echec du Paiement',
    component: 'PaymentFailurePage',
    path: 'checkout/payment/failure',
    headerType: 'CHECKOUT',
    footerType: 'CHECKOUT',
    headerStep: 4,
    mustBeConnected: true,
    isCheckout: true,
    isPayment: true
  },
  PaymentInvalidStatePage: {
    title: 'Paiement en statut invalide',
    component: 'PaymentInvalidStatePage',
    path: 'checkout/payment/invalidstate',
    headerType: 'CHECKOUT',
    footerType: 'CHECKOUT',
    headerStep: 4,
    mustBeConnected: true,
    isCheckout: true,
    isPayment: true
  },
  OrderConfirmationPage: {
    title: 'Confirmation de commande',
    component: 'OrderConfirmationPage',
    path: 'checkout/order-confirmation',
    isCheckout: true
    // mustBeConnected: true
  }
}

export default pages
