import './faqQuestion.css'

import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Flex,
  Picto,
  rewriteMediaUrl,
  Text
} from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import slugify from '../utils/slugify'

const FaqQuestion = (props) => {
  const { faqQuestion, selected } = props
  const websiteContext = useContext(WebsiteContext)
  const windowGlobal = typeof window !== 'undefined' && window

  const goToAnchorFaq = (id) => {
    let top = document.getElementById(id)?.offsetTop || 0
    top = websiteContext?.isMobile ? top : top + 210
    windowGlobal?.scrollTo({ top, behavior: 'smooth' })
  }
  const contentWithMediaUrls = faqQuestion ? rewriteMediaUrl(faqQuestion.answer) : ''
  return (
    <Accordion
      allowMultiple
      defaultIndex={selected?.replace('#', '') === slugify(faqQuestion?.question) ? [0] : null}
      id={slugify(faqQuestion?.question)}
      width="100%">
      <AccordionItem
        border="none"
        width="100%"
        onClick={() => {
          goToAnchorFaq(slugify(faqQuestion?.question))
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Faq',
            eventAct: 'Clic question',
            eventLab: faqQuestion?.question
          })
        }}>
        {({ isExpanded }) => (
          <>
            <AccordionButton
              pt="0"
              pl="0"
              pr="0"
              pb="10px"
              border="none"
              style={{ boxShadow: 'none' }}
              _hover={{ background: 'none' }}>
              <Flex direction="row" width="100%" justify="space-between" alignItems="flex-start">
                <Text
                  as="h3"
                  fontWeight="bold"
                  fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                  fontSize={{ base: '15px', md: '17px' }}
                  lineHeight="24px"
                  textAlign="left"
                  mr="12px">
                  {faqQuestion?.question}
                </Text>
                <Box mt="5px">
                  {isExpanded ? (
                    <Picto icon="minusCercle" width="16" height="16" />
                  ) : (
                    <Picto icon="plusCercle" width="16" height="16" />
                  )}
                </Box>
              </Flex>
            </AccordionButton>
            <AccordionPanel pr="0px" pl="0px">
            {contentWithMediaUrls&& 
              <Box
                fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                fontSize={{ base: '14px', md: '18px' }}
                dangerouslySetInnerHTML={{ __html: contentWithMediaUrls }}
                className="faq_question"
                sx={{
                  '&& a': {
                    color: websiteContext?.mainColor
                  }
                }}
              />}
            </AccordionPanel>
          </>
        )}
      </AccordionItem>
    </Accordion>
  )
}

export default FaqQuestion
