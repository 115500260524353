import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbSeparator,
  chakra,
  useStyles
} from '@chakra-ui/react'
import { Link } from 'gatsby'
import React, { forwardRef } from 'react'

import * as DataLayerUtils from '../utils/datalayer'

const BreadcrumbLink = forwardRef((props, ref) => {
  const { isCurrentPage, as, className, href, ...rest } = props
  const styles = useStyles()

  const sharedProps = {
    ref,
    as,
    className: ['chakra-breadcrumb__link', className],
    ...rest
  }

  if (isCurrentPage) {
    return <chakra.span aria-current="page" __css={styles.link} {...sharedProps} />
  }

  return (
    <Link
      to={href}
      {...sharedProps}
      onClick={() => {
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Search',
          eventAct: 'Breadcrumb',
          eventLab: href
        })
      }}
    />
  )
})

export { Breadcrumb, BreadcrumbItem, BreadcrumbLink, BreadcrumbSeparator }
