import { isFunction, set } from 'lodash'
import React, { useEffect, useReducer } from 'react'

const windowGlobal = typeof window !== 'undefined' && window

const UserContext = React.createContext()

const initialState = windowGlobal?.localStorage?.getItem('currentUser')
  ? JSON.parse(windowGlobal?.localStorage?.getItem('currentUser'))
  : null

const nexus = {}

const userReducer = (_, action) => {
  return action
}

const UserContextProvider = (props) => {
  const { children } = props
  const [user, dispatch] = useReducer(userReducer, initialState)
  const storage = windowGlobal?.localStorage

  set(nexus, 'setUser', (u) => dispatch(u))
  set(nexus, 'getUser', () => user)

  useEffect(() => {
    storage?.setItem('currentUser', JSON.stringify(user))
  }, [user])

  return <UserContext.Provider value={user}>{children}</UserContext.Provider>
}

const setUser = (user) => {
  if (isFunction(nexus.setUser)) {
    nexus.setUser(user)
  }
}

const getUser = () => {
  if (isFunction(nexus.getUser)) {
    return nexus.getUser()
  }
}
UserContextProvider.displayName = 'UserContextProvider'

export { UserContextProvider, UserContext, getUser, setUser }
