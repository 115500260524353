import './paginate/paginate.css'

import React from 'react'

import PaginationBoxView from './paginate/PaginationBoxView'

const Paginate = (props) => {
  return <PaginationBoxView {...props} />
}

export default Paginate
