import 'react-datepicker/dist/react-datepicker.css'

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  Input,
  Picto,
  Radio,
  RadioGroup,
  Stack,
  Text
} from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'
import DatePicker from 'react-datepicker'

import { WebsiteContext } from '../../context'

const Section = (props) => {
  const {
    title,
    subTitle,
    content,
    formik,
    updateUser,
    maxDate,
    birthdate,
    birthdateFunction,
    displayLabel = true,
    user
  } = props
  return (
    <>
      <h3>{title}</h3>
      {subTitle && <p className="subTitle">{subTitle}</p>}
      {content.map((field, key) => (
        <Field
          key={`field-${key}`}
          nameInput={field?.name}
          title={field?.title}
          formik={formik}
          classN={field?.classN}
          typeInput={field?.typeInput}
          values={field?.values}
          onChangeInput={updateUser}
          backgroundInput={field?.background}
          placeholderInput={field?.placeholderInput}
          disabled={field?.disabled ? true : false}
          dateFormat={field?.dateFormat ? field?.dateFormat : ''}
          maxDate={maxDate ? maxDate : ''}
          birthdate={birthdate ? birthdate : ''}
          text={field?.text}
          birthdateFunction={birthdateFunction}
          displayLabel={displayLabel}
          user={user}
        />
      ))}
      <Divider mt="40px" opacity="0" />
    </>
  )
}
const Field = (props) => {
  const {
    nameInput,
    title,
    typeInput,
    classN,
    formik,
    backgroundInput,
    disabled,
    placeholderInput,
    values,
    onChangeInput,
    birthdate,
    maxDate,
    dateFormat,
    birthdateFunction,
    text,
    displayLabel = true,
    user
  } = props
  return (
    <Flex className="field" w="100%">
      {typeInput === 'radio' ? (
        <>
          {displayLabel && (
            <label htmlFor="lastName" className="label-create-account">
              {title}
            </label>
          )}
          <RadioElement
            nameInput={nameInput}
            formik={formik}
            classN={classN}
            backgroundInput={backgroundInput}
            disabled={disabled}
            values={values}
            onChangeInput={onChangeInput}
          />
        </>
      ) : typeInput === 'checkbox' ? (
        <CheckboxElement
          nameInput={nameInput}
          formik={formik}
          classN={classN}
          disabled={disabled}
          onChangeInput={onChangeInput}
          text={text}
          user={user}
        />
      ) : typeInput === 'datePicker' ? (
        <>
          <label className="label-create-account" htmlFor="lastName">
            {title}
          </label>
          <PickerDate
            nameInput={nameInput}
            formik={formik}
            classN={classN}
            disabled={disabled}
            birthdate={birthdate}
            maxDate={maxDate}
            dateFormat={dateFormat}
            onChangeInput={birthdateFunction}
          />
        </>
      ) : (
        <>
          {displayLabel && (
            <label className="label-create-account" htmlFor="lastName">
              {title}
            </label>
          )}
          <InputElement
            nameInput={nameInput}
            typeInput={typeInput}
            formik={formik}
            classN={classN}
            placeholderInput={placeholderInput}
            backgroundInput={backgroundInput}
            disabled={disabled}
            onChangeInput={onChangeInput}
          />
        </>
      )}
    </Flex>
  )
}

const InputElement = (props) => {
  const {
    nameInput,
    typeInput,
    classN,
    formik,
    disabled,
    backgroundInput,
    placeholderInput,
    onChangeInput
  } = props
  const errorForm = formik?.touched[nameInput] && formik?.errors[nameInput]
  const classNameInput = (classN ? classN : '') + (errorForm ? ' error-vlidation' : '')
  return (
    <div>
      <Input
        id={nameInput}
        name={nameInput}
        value={formik?.values && formik?.values[nameInput]}
        type={typeInput}
        onChange={(e) => {
          formik?.handleChange(e)
          onChangeInput && onChangeInput(e)
        }}
        onBlur={(e) => {
          formik?.handleBlur(e)
          onChangeInput && onChangeInput(e)
        }}
        backgroundColor={backgroundInput ? backgroundInput : ''}
        isDisabled={disabled}
        className={classNameInput}
        placeholder={placeholderInput}
      />
      {errorForm ? (
        <div style={{ color: 'red', fontSize: '11px', lineHeight: 1.7 }}>
          {formik?.errors[nameInput]}
          {nameInput === 'password' && (
            <>
              <p style={{ color: 'black' }}>
                Votre mot de passe doit contenir au moins 4 des 5 types suivants:
              </p>
              <ul>
                <li>Minimum 8 caractères (obligatoire)</li>
                <li>Une minuscule</li>
                <li>Une majuscule</li>
                <li>Un chiffre</li>
                <li>Un caractère spécial</li>
              </ul>
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

const RadioElement = (props) => {
  const { nameInput, classN, formik, disabled, values, onChangeInput } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <RadioGroup
      id={nameInput}
      name={nameInput}
      className={classN}
      isDisabled={disabled ? true : false}
      color={websiteContext?.mainColor}
      defaultValue={formik?.values && formik?.values[nameInput]}
      direction="row"
      options={values.map((item) => item.name)}
      onChange={(e) => {
        formik?.handleChange(e)
        onChangeInput &&
          onChangeInput({
            target: {
              name: nameInput,
              value: e
            }
          })
      }}
      onBlur={(e) => {
        formik?.handleBlur(e)
        onChangeInput && onChangeInput(e)
      }}
    />
  )
}

const PickerDate = (props) => {
  const { birthdate, maxDate, dateFormat, formik, onChangeInput, nameInput } = props
  return (
    <DatePicker
      name={nameInput}
      id={nameInput}
      dateFormat={dateFormat}
      selected={birthdate}
      maxDate={maxDate}
      onChange={(date) => {
        formik?.setFieldValue(nameInput, date)
        onChangeInput && onChangeInput(date)
      }}
    />
  )
}
const CheckboxElement = (props) => {
  const { text, formik, onChangeInput, nameInput, user } = props
  const websiteContext = useContext(WebsiteContext)
  const getType = () => {
    if (nameInput === 'newsletter') {
      return user.newsletter == 1 ? true : false
    } else if (nameInput === 'targeting') {
      return user.targeting == 1 ? true : false
    }
  }

  return (
    <div>
      <label className="label-create-account-checkbox">
        <Checkbox
          name={nameInput}
          onChange={(e) => {
            formik?.handleChange(e)
            onChangeInput && onChangeInput(e)
          }}
          onBlur={(e) => {
            formik?.handleBlur(e)
            onChangeInput && onChangeInput(e)
          }}
          checked={getType()}
          color="#ffffff"
          backgroundColorChecked={websiteContext?.mainColor}
        />
        <Text ml="15px">{text}</Text>
      </label>
    </div>
  )
}
const Card = (props) => {
  const { object, editAction, deleteAction } = props
  const windowGlobal = typeof window !== 'undefined' && window
  return (
    <Box
      maxW="sm"
      backgroundColor="#f6f3f1"
      padding
      overflow="hidden"
      p="20px 15px 15px 15px"
      minH="190px"
      mb="20px"
      w="325px"
      position="relative">
      <h5 style={{ fontWeight: 'bold', fontSize: '14px' }}>{object?.title}</h5>
      <Box className="subTitle">
        {object?.description.map((element, key) => {
          return element && <p key={`p-${key}`}>{element}</p>
        })}
      </Box>
      <Flex className="buttons">
        <Button
          mr="20px"
          borderRadius="0"
          type="submit"
          onClick={() => editAction(object.address)}
          colorScheme="blue">
          Editer
        </Button>
        <Button
          borderRadius="0"
          type="submit"
          onClick={() => {
            if (windowGlobal?.confirm('Êtes-vous sûr de vouloir effacer cette adresse ?'))
              deleteAction(object.address)
          }}
          colorScheme="red">
          Supprimer
        </Button>
      </Flex>
    </Box>
  )
}

const NewCard = (props) => {
  const { newForm } = props
  return (
    <Box
      backgroundColor="#f6f3f1"
      overflow="hidden"
      p="33px 15px"
      minH="190px"
      mb="20px"
      textAlign="center"
      cursor="pointer"
      onClick={() => {
        newForm()
      }}>
      <Box mt="30px">
        <Picto icon="plusCercleBorder" width="64px" height="64px" color="grey" margin="auto" />
        <h2>Ajouter une carte</h2>
      </Box>
    </Box>
  )
}
export { Field, InputElement, RadioElement, Section, Card, NewCard }
