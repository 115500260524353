import {
  Alert,
  AlertIcon,
  CircularProgress,
  CloseButton,
  Flex,
  Form,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import React, { useContext, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { CustomerHelper } from '../utils'
import Address from './Address'
import addressCreate from './forms/addressCreate.schema'
import addressEdit from './forms/addressEdit.schema'

const AccountAddresses = () => {
  const currentUser = useContext(UserContext)
  const addresses = CustomerHelper.getShippingAddresses(currentUser)
  const [currentAddress, setCurrentAddress] = useState()
  const [alert, setAlert] = useState()
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [loading, setLoading] = useState(false)
  const windowGlobal = typeof window !== 'undefined' && window
  const scrollToTop = () => windowGlobal.scrollTo({ top: 0, behavior: 'smooth' })

  const selectAddress = (address) => {
    setAlert(null)
    setCurrentAddress(address)
  }

  const onCancel = () => {
    setCurrentAddress(null)
    setAlert(null)
    scrollToTop()
  }

  const deleteAddress = async (address) => {
    if (confirm('Êtes-vous sûr de vouloir effacer cette adresse ?')) {
      if (address.defaultShippingAddress) {
        const newDefault = addresses.find((a) => a.id !== address.id)
        if (newDefault) {
          apiContext?.AccountApi?.updateCustomerAddress({
            ...newDefault,
            defaultShippingAddress: true
          })
        }
      }
      const result = await apiContext?.AccountApi?.updateCustomerAddress({
        ...address,
        customFields: { isDeleted: true }
      })
      if (result) {
        setAlert({ status: 'success', message: "L'adresse a été effacée" })
      } else {
        setAlert({
          status: 'error',
          message: 'Erreur de suppression, veuillez réessayer plus tard.'
        })
      }
    }
  }

  const saveAddress = async (address) => {
    setLoading(true)
    if (address?.id) {
      const result = await apiContext?.AccountApi?.updateCustomerAddress(address)
      if (result) {
        setAlert({
          status: 'success',
          message: "L'adresse a été sauvegardée"
        })
        setCurrentAddress(null)
        return
      }
    } else {
      const result = await apiContext?.AccountApi?.createCustomerAddress(address)
      if (result) {
        setAlert({
          status: 'success',
          message: "L'adresse a été créée"
        })
        setCurrentAddress(null)
        return
      }
    }
    setAlert({
      status: 'error',
      message: "Une erreur d'enregistrement s'est produite, veuillez réessayer plus tard "
    })
    setLoading(false)
  }

  /*const onResult = (alert) => {
    onCancel()
    setAlert(alert)
  }*/

  return (
    <Flex
      width="100%"
      h={{ md: '100%' }}
      direction="column"
      position="relative"
      backgroundColor="#ffffff">
      {alert && (
        <Alert status={alert.status}>
          <AlertIcon />
          {alert.message}
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => {
              setAlert(null)
            }}
          />
        </Alert>
      )}
      {!currentAddress && (
        <Flex direction="column" padding={{ base: '15px', md: '40px' }}>
          <Text
            as="h2"
            fontFamily={websiteContext?.fontFamilyNarrow}
            fontSize="26px"
            color="#333333"
            borderBottom={{ base: '1px solid #c1c1c1', md: 'none' }}
            fontWeight={700}
            mb="20px">
            Mes adresses de livraison
          </Text>
          <Flex direction="row" flexWrap="wrap" gridGap="15px" mb="40px">
            {addresses?.map((address) => (
              <Address
                key={`address_${address.id}`}
                address={address}
                onEdit={selectAddress}
                onDelete={deleteAddress}
              />
            ))}
            <Flex
              width={{ base: '100%', md: '325px' }}
              minHeight="175px"
              direction="column"
              backgroundColor="#f6f3f1"
              align="center"
              justify="center"
              cursor="pointer"
              onClick={() => selectAddress({ country: { name: process.env.GATSBY_API_COUNTRY } })}>
              <Picto
                icon="plusCercleBorder"
                width="64px"
                height="64px"
                color="grey"
                margin="auto"
              />
              <Text
                as="span"
                fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                fontSize="26px"
                fontWeight={700}
                color="#333333">
                Ajouter une adresse
              </Text>
            </Flex>
          </Flex>
        </Flex>
      )}
      {currentAddress && (
        <Flex padding={{ base: '15px', md: '40px' }} width="100%">
          <Form
            formSettings={currentAddress?.id ? addressEdit : addressCreate}
            originValue={currentAddress}
            validateFunction={saveAddress}
            cancelFunction={onCancel}
            formOptions={{ mainColor: websiteContext?.mainColor }}
            setLoading={setLoading}
          />
          {/*<AddressForm
            address={currentAddress}
            onCancel={onCancel}
            onResult={onResult}
            setLoading={setLoading}
      />*/}
        </Flex>
      )}

      {loading && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          justify="center"
          paddingTop="90px"
          style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )}
    </Flex>
  )
}

export default AccountAddresses
