import endsWith from 'lodash/endsWith'
import has from 'lodash/has'
import lowerFirst from 'lodash/lowerFirst'
import React from 'react'

const getContextValue = (Component, rest) => {
  const name = Component.displayName || Component.name || ''

  if (endsWith(name, 'Provider')) {
    const key = lowerFirst(name?.slice(0, -8))
    if (has(rest, key)) {
      return rest[key]
    }
    return null
  }
  throw new Error('Your context provider must be name [type]Provider')
}

const Wrapper = ({ children }) => {
  return <>{children}</>
}
export const combineComponents = (...components) => {
  return components.reduce(
    (AccumulatedComponents, CurrentComponent) => {
      const result = ({ children, ...rest }) => {
        return (
          <AccumulatedComponents {...rest}>
            <CurrentComponent value={getContextValue(CurrentComponent, rest)}>
              {children}
            </CurrentComponent>
          </AccumulatedComponents>
        )
      }
      return result
    },
    ({ children, ...rest }) => <Wrapper {...rest}>{children}</Wrapper>
  )
}
