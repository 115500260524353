import { Flex, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../../context'
const Partner = (props) => {
  const { title, link, description, picto } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      direction="column"
      width={{ base: '100%', md: '272px' }}
      align="center"
      borderTop={{ base: '1px solid #cfd5e3', md: 'none'}}
      pt={{ base: '30px', md: '0' }}
      pb="20px">
      <Text
        color={websiteContext?.mainColor}
        fontWeight={600}
        fontSize="20px"
        fontFamily="PT Sans, Arial, sans-serif"
        mb="20px">
        {title}
      </Text>
      <Flex
        direction="column"
        align="center"
        sx={{
          svg: {
            borderStyle: 'solid',
            borderWidth: '2px',
            borderColor: websiteContext?.mainColor,
            borderRadius: '36px'
          },
          'svg:hover': {
            backgroundColor: websiteContext?.mainColor
          },
          'svg:hover path': {
            fill: '#fff'
          }
        }}>
        <Link
          to={link}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEventLab',
              eventCat: 'Footer',
              eventAct: 'Départ partner',
              eventLab: "[Image / J'y vais]"
            })
          }>
          <Svg data={picto} color={websiteContext?.mainColor} />
        </Link>
        <Text
          fontSize="12px"
          fontFamily="PT Sans, Arial, sans-serif"
          pt="12px"
          pb="12px"
          textAlign="center"
          dangerouslySetInnerHTML={{ __html: description }}></Text>
      </Flex>
    </Flex>
  )
}
export default Partner
