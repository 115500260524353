import { Flex, Link, /*Svg,*/ Picto, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../../context'

const Newsletter = () => {
  //const { title, subTitle, buttonText, link, picto } = props
  const websiteContext = useContext(WebsiteContext)
  //const svgData = `<svg xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg" class="icon" width="36px" height="36px" viewBox="0 0 50 50"><path fill-rule="evenodd" clip-rule="evenodd" fill="#333333" d="M 0 7 L 0 43 L 50 43 L 50 7 Z M 2 9 L 48 9 L 48 11.53125 L 25 29.71875 L 2 11.53125 Z M 2 14.09375 L 24.375 31.78125 C 24.742188 32.074219 25.257813 32.074219 25.625 31.78125 L 48 14.09375 L 48 41 L 2 41 Z"></path></svg>`
  return (
    <Flex
      justify={{ base: 'center', md: 'space-between' }}
      align="center"
      width={{ base: '100%', md: '1000px' }}
      ml={{ base: 0, md: 'auto' }}
      mr={{ base: 0, md: 'auto' }}
      mb="30px"
      mt="30px"
      pl={{ base: '20px', md: '112px' }}
      pr={{ base: '20px', md: '112px' }}
      flexWrap="wrap"
      gridGap="20px">
      <Flex direction="row" align="center" justify="center">
        {/*<Svg data={svgData} width="36px" height="36px" color="#333333" />*/}
        <Picto icon="mail" width="36px" height="36px" color="#333333" />
        <Flex direction="column" ml="15px">
          <Text
            fontFamily="PT Sans, Arial, sans-serif"
            fontSize="18px"
            color="#333333"
            fontWeight={600}>
            Inscrivez-vous à la newsletter
          </Text>
          <Text fontFamily="PT Sans, Arial, sans-serif" fontSize="14px" color="#333333">
            Nouveautés, bons plans, offres personnalisées...
          </Text>
        </Flex>
      </Flex>
      <Link
        to="/customer/newsletter/subscribe"
        onClick={() =>
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Footer',
            eventAct: 'Départ inscription',
            eventLab: 'Footer'
          })
        }>
        <Flex
          bg={websiteContext?.mainColor}
          _hover={{ backgroundColor: websiteContext?.darkColor }}
          pl="24px"
          pr="24px"
          pt="8px"
          pb="8px"
          borderRadius="3px"
          color="#fff"
          align="center"
          justify="center"
          w="auto"
          style={{ cursor: 'pointer' }}>
          Je m&acute;inscris
        </Flex>
      </Link>
    </Flex>
  )
}

export default Newsletter
