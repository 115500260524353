import { attributesToUpperCase } from '@stars-ecom/shared-atoms-ui'
import { axios, sessionLogin, sessionLogout, setUser } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'

const windowGlobal = typeof window !== 'undefined' && window

const login = async (login, password) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/login`,
      { login, password }
    )
    if (get(result, 'data.0.id')) {
      sessionLogin(get(result, 'data.0'), get(result, 'data.1'))
    }
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const logout = async () => {
  try {
    await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/logout`
    )
    sessionLogout()
    return true
  } catch (e) {
    console.error(e)
    return {}
  }
}

const getCurrentUser = async () => {
  try {
    const isConnected = JSON.parse(windowGlobal?.localStorage?.getItem('session'))?.isConnected

    const result = await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/${isConnected}/getCurrentUser`
    )
    if (get(result, 'data.0')) {
      sessionLogin(get(result, 'data.0'), get(result, 'data.1'))
    } else if (
      !get(result, 'data.0') &&
      JSON.parse(windowGlobal?.localStorage?.getItem('session'))?.isConnected
    ) {
      logout()
      return {}
    }
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const updateUser = async (user, billingAddress, isCheckout = false) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/updateCustomer`,
      {
        user: attributesToUpperCase(user, [
          'title',
          'emailAddress',
          'currentPassword',
          'newPassword',
          'confirmNewPassword'
        ]),
        billingAddress: attributesToUpperCase(billingAddress),
        isCheckout
      }
    )

    if (result.data) {
      setUser(result.data)
      return result.data
    }
    return false
  } catch (e) {
    console.error(e)
    if (e.response?.data) {
      console.error(e.response?.data)
      return { error: e.response.data }
    }
    return {}
  }
}

const updateCustomerAddress = async (address) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/updateCustomerAddress`,
      { address: attributesToUpperCase(address) }
    )
    setUser(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const createCustomerAddress = async (address) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/createCustomerAddress`,
      { address: attributesToUpperCase(address) }
    )
    setUser(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const deleteCustomerAddress = async (address) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/deleteCustomerAddress`,
      { address }
    )
    setUser(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const createUser = async (user, password, preference) => {
  try {
    let result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/createCustomer`,
      { user: attributesToUpperCase(user, ['emailAddress', 'title']), password, preference }
    )
    if (result) {
      if (get(result, 'data.0.id')) {
        sessionLogin(get(result, 'data.0'), get(result, 'data.1'))
      }
      return get(result, 'data.0')
    } else {
      return { error: 'registerEmailError' }
    }
  } catch (e) {
    console.error(e)
    return { error: 'registerEmailError' }
  }
}

const getOrders = async () => {
  try {
    const result = await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getOrders`
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const getOrder = async (externalCode) => {
  try {
    const result = await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getOrder/${externalCode}`
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const getOrderReturnDetail = async (externalCode) => {
  try {
    const result = await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getOrderReturnDetail/${externalCode}`
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const getSubscriptions = async () => {
  try {
    const result = await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getSubscriptions`
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const getReturns = async () => {
  try {
    const result = await axios.get(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getReturns`
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const requestPasswordReset = async (email) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/requestPasswordReset`,
      { email }
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const resetPassword = async (token, password) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/resetPassword`,
      { token, password }
    )
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const updateCustomerCards = async (cards) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/updateCustomerCards`,
      { cards }
    )
    setUser(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

const addCardToCustomer = async (metadata) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/customer/cards`,
      { metadata }
    )
    setUser(result.data)
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const validateCustomerCard = async (token) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/customer/cards/${token}/validate`
    )
    setUser(result?.data?.currentUser)
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const deleteCustomerCard = async (token) => {
  try {
    const result = await axios.delete(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/customer/cards/${token}`
    )
    setUser(result?.data?.currentUser)
    return result.data
  } catch (e) {
    console.error(e)
    return []
  }
}

const createReturn = async (request, formEmail) => {
  try {
    const result = await axios.post(
      `account/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/createReturn`,
      { request, formEmail }
    )
    return result.data
  } catch (e) {
    console.error('error', e?.message)
    return {}
  }
}

export {
  login,
  logout,
  getCurrentUser,
  updateUser,
  updateCustomerAddress,
  deleteCustomerAddress,
  createCustomerAddress,
  createUser,
  getOrders,
  getOrder,
  getOrderReturnDetail,
  requestPasswordReset,
  resetPassword,
  getReturns,
  getSubscriptions,
  updateCustomerCards,
  addCardToCustomer,
  deleteCustomerCard,
  validateCustomerCard,
  createReturn
}
