import { Box, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useContext } from 'react'

import { UserContext, WebsiteContext } from '../context'
import userPicto from './user.png'

const LoyaltyCard = ({ expireDate }) => {
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex mt="20px">
      <Flex width={{ base: '134px', md: '142px' }} height="auto">
        <GatsbyImage
          image={websiteContext?.loyaltyCardImage?.image?.childImageSharp?.gatsbyImageData}
          alt="Carte de fidélité"
          loading="eager"
        />
      </Flex>
      <Text as="p" ml="15px" fontSize="14px">
        Vous disposez de la carte {websiteContext?.title}
        <br />
        Expire le {expireDate}
      </Text>
    </Flex>
  )
}
const AccountWelcome = (props) => {
  const { loyaltyCard } = props
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)
  const expireDate =
    currentUser?.customFields?.loyaltyEndDate &&
    dayjs(currentUser?.customFields?.loyaltyEndDate).isValid()
      ? dayjs(currentUser?.customFields?.loyaltyEndDate, 'YYYY/MM/DD')
      : null
  console.log('wc', websiteContext)
  return (
    <Box
      direction="row"
      padding={{ base: '15px', md: '40px' }}
      width="100%"
      backgroundColor="#ffffff">
      <Flex>
        <Box width="65px" height="65px" backgroundColor="#f6f3f1" mr="20px">
          <img src={userPicto} />
        </Box>
        <Box>
          <Text
            fontFamily={websiteContext?.fontFamilyNarrow}
            color="#333333"
            fontSize="26px"
            fontWeight={700}>{`Bonjour ${currentUser?.firstName?.toUpperCase()}`}</Text>
          {currentUser?.customFields?.externalCode && (
            <Text as="p">{`Mon numéro de client : ${currentUser?.customFields?.externalCode}`}</Text>
          )}
          {currentUser?.customFields?.loyaltyStatus && (
            <LoyaltyCard expireDate={expireDate?.format('D/MM/YYYY')} loyaltyCard={loyaltyCard} />
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export default AccountWelcome
