import {
  Alert,
  AlertIcon,
  Box,
  CircularProgress,
  CloseButton,
  Flex,
  Picto,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import { compact, debounce, endsWith, findIndex, get, includes, isEmpty } from 'lodash'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { getExpiredCards } from '../utils'
import { CardAddForm, CardEditForm, PaymentCard } from './paymentCard'

const windowGlobal = typeof window !== 'undefined' && window

const AccountPaymentCards = (props) => {
  const { location } = props
  const currentUser = useContext(UserContext)
  const [currentPaymentCard, setCurrentPaymentCard] = useState()
  const [paymentCards, setPaymentCards] = useState([])
  const [expiredCards, setExpiredCards] = useState([])
  const [displayCards, setDisplayCards] = useState([])
  const [alert, setAlert] = useState()
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [isLoading, setIsLoading] = useState(true)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const content = useRef()

  const errorGeneric = {
    status: 'error',
    message: `Une erreur est survenue lors de l'enregistrement de la carte.`
  }
  const saveSuccess = { status: 'success', message: `Votre carte a été enregistrée avec succès` }
  const deleteSuccess = { status: 'success', message: `Votre carte a été supprimée avec succès.` }

  const close = () => {
    onClose()
    scrollToTop()
  }

  const getCurrentPath = () => {
    const url = location?.href?.split('?')[0]
    return endsWith(url, '/') ? url : `${url}/`
  }

  const addCard = async (cardResp) => {
    try {
      setAlert(null)
      const url = getCurrentPath()
      const result = await apiContext.AccountApi.addCardToCustomer({
        ...cardResp,
        acceptUrl: `${url}?resp=accept`,
        declineUrl: `${url}?resp=error`
      })

      if (result?.success) {
        if (result?.forwardURL) {
          navigate(result?.forwardURL)
        } else {
          setPaymentCards(result?.cards)
          setAlert(saveSuccess)
          close()
        }
      } else {
        let message = errorGeneric?.message
        if (result?.message === 'TOKEN_ALREADY_EXISTS') {
          message = `La carte que vous essayez d'ajouter existe déja!`
        }

        setAlert({
          status: 'error',
          message
        })
        close()
      }
    } catch (e) {
      console.error(e)
      setAlert(errorGeneric)
      close()
    }
  }

  const updateCard = async (cardResp) => {
    let cbList = paymentCards
    const index = findIndex(cbList, { card_id: get(cardResp, 'card_id') })
    cbList?.splice(index, 1, cardResp)
    await save(compact(cbList))
    close()
    setCurrentPaymentCard(null)
  }

  const save = async (cbList) => {
    setAlert(null)
    try {
      await apiContext?.AccountApi?.updateCustomerCards(cbList.map((c) => JSON.stringify(c)))
      setPaymentCards(cbList)
      setAlert(saveSuccess)
    } catch (e) {
      console.error(e)
      setAlert(errorGeneric)
    }
  }

  const pushHistory = () => windowGlobal?.history?.pushState({}, '', getCurrentPath())

  const check3DSecure = async () => {
    const params = new URLSearchParams(location?.search) || {}
    const resp = params.get('resp')
    const state = params.get('state')

    if (resp === 'accept' && state === 'completed') {
      const result = await apiContext?.AccountApi?.validateCustomerCard(params.get('cardtoken'))
      if (result && result?.success) {
        setPaymentCards(result?.cards)
        setAlert(saveSuccess)
        pushHistory()
        close()
      } else {
        setAlert(errorGeneric)
      }
    } else if (resp === 'error') {
      await deletePaymentCard(params.get('cardtoken'))
      setAlert(errorGeneric)
    }
  }

  const checkExpiredCards = (cards) => {
    const exp = getExpiredCards(cards, 1)
    setExpiredCards(exp)
  }

  const check = async () => {
    setIsLoading(true)
    await debounce(check3DSecure, 200)()
    setIsLoading(false)
  }

  const getPaymentCards = async () => {
    setIsLoading(true)
    const cardsMetadata = get(currentUser, 'customFields.cardsMetadata', [])
    if (!isEmpty(cardsMetadata)) {
      setPaymentCards(cardsMetadata.map((c) => JSON.parse(c)))
    }
    setIsLoading(false)
  }

  useEffect(() => {
    if (currentUser?.id) {
      getPaymentCards()
    }
  }, [currentUser?.id])

  useEffect(() => {
    if (location?.search) {
      check()
    }
  }, [location?.search])

  useEffect(() => {
    if (!isEmpty(paymentCards)) {
      checkExpiredCards(paymentCards)
    }
    setDisplayCards(paymentCards?.filter((c) => c?.status === 1))
  }, [paymentCards])

  const selectPaymentCard = (paymentCard) => {
    setCurrentPaymentCard(paymentCard)
    scrollToTop()
  }

  const onCancel = () => {
    setAlert(null)
    setCurrentPaymentCard(null)
    scrollToTop()
  }

  const deletePaymentCard = async (token) => {
    setAlert(null)
    const result = await apiContext.AccountApi.deleteCustomerCard(token)
    if (result && result?.success) {
      setPaymentCards(result?.cards)
      setAlert(deleteSuccess)
    } else {
      setAlert(errorGeneric)
    }
    scrollToTop()
  }

  const scrollToTop = () =>
    content?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })

  return (
    <>
      <Flex width="100%" h={{ md: '100%' }} position="relative" ref={content}>
        {isLoading ? (
          <Flex position="absolute" width="100%" height="100%" justify="center" paddingTop="90px">
            <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
          </Flex>
        ) : (
          <>
            {currentPaymentCard ? (
              <CardEditForm
                paymentCard={currentPaymentCard}
                onClose={onCancel}
                onSave={updateCard}
              />
            ) : (
              <Flex direction="column" padding={{ base: '15px', md: '40px' }} width="100%">
                {isOpen ? (
                  <Box w="100%" alignItems="center">
                    <CardAddForm saveCard={addCard} onClose={close} currentUser={currentUser} />
                  </Box>
                ) : (
                  <>
                    {alert && (
                      <Alert status={alert.status} mb="20px">
                        {alert.status === 'error' && <AlertIcon />}
                        {alert.message}
                        <CloseButton
                          position="absolute"
                          right="8px"
                          top="8px"
                          onClick={() => {
                            setAlert(null)
                          }}
                        />
                      </Alert>
                    )}
                    <Text
                      as="h3"
                      fontFamily={websiteContext?.fontFamilyNarrow}
                      fontSize="26px"
                      colo
                      borderBottom={{ base: '1px solid #c1c1c1', md: 'none' }}
                      r="#333333"
                      fontWeight={700}
                      mb="20px">
                      Mes cartes bancaires
                    </Text>

                    {isEmpty(displayCards) && (
                      <Text
                        mt="15px"
                        mb="15px"
                        as="span"
                        fontFamily="PT Sans, Arial, sans-serif"
                        fontSize="14px"
                        color="#333333">
                        Vous n&acute;avez enregistré aucune carte.
                      </Text>
                    )}
                    <Flex direction="row" flexWrap="wrap" gridGap="15px" mb="40px">
                      {displayCards &&
                        displayCards?.map((paymentCard) => (
                          <PaymentCard
                            isExpired={includes(expiredCards, paymentCard)}
                            key={`card_${get(paymentCard, 'card_id')}`}
                            paymentCard={paymentCard}
                            onEdit={selectPaymentCard}
                            onDelete={deletePaymentCard}
                          />
                        ))}

                      <Flex
                        width={{ base: '100%', md: '325px' }}
                        minHeight="175px"
                        backgroundColor="#f6f3f1"
                        align="center"
                        justify="center"
                        cursor="pointer"
                        direction="column"
                        onClick={() => {
                          onOpen()
                          scrollToTop()
                        }}>
                        <Picto
                          icon="plusCercleBorder"
                          width="64px"
                          height="64px"
                          color="grey"
                          margin="auto"
                        />
                        <Text
                          as="span"
                          fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
                          fontSize="26px"
                          fontWeight={700}
                          color="#333333">
                          Ajouter une carte
                        </Text>
                      </Flex>
                    </Flex>
                  </>
                )}
              </Flex>
            )}
          </>
        )}
      </Flex>
    </>
  )
}

export default memo(AccountPaymentCards)
