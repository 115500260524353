import './productLayout.css'

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Carousel,
  ErrorModal,
  Flex,
  HTMLEllipsis,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Picto,
  rewriteMediaUrl,
  Text,
  useBreakpointValue,
  useDisclosure,
  useState
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
//import { get, isFunction } from 'lodash'
import capitalize from 'lodash/capitalize'
import compact from 'lodash/compact'
import difference from 'lodash/difference'
import head from 'lodash/head'
import isEmpty from 'lodash/isEmpty'
import isNumber from 'lodash/isNumber'
import map from 'lodash/map'
import take from 'lodash/take'
import trim from 'lodash/trim'
import uniq from 'lodash/uniq'
import React, { memo, useContext, useEffect } from 'react'

import BlogPushThumbnail from '../blog/BlogPushThumbnail'
import AddToCartButton from '../cart/AddToCartButton'
import AddToCartPopin from '../cart/AddToCartPopin'
import MaxProductModal from '../cart/MaxProductModal'
import { ApiContext, CartContext, DataLayerUtils, WebsiteContext } from '../context'
import { Reviews } from '../review'
import { Service } from '../services'
import { SocialShare } from '../social'
import { InventoryUtils, OfferUtils } from '../utils'
import JsonLd from '../utils/jsonLd'
import FullPrice from './price/FullPrice'
import ProductAttachments from './ProductAttachments'
import ProductCrossSell from './ProductCrossSell'
import ProductImage from './ProductImage'
import ProductInventory from './ProductInventory'
import ProductShipping from './ProductShipping'
import ProductTag from './ProductTag'
import ProductTitle from './ProductTitle'
dayjs.extend(customParseFormat)

const windowGlobal = typeof window !== 'undefined' && window

const ProductLayout = (props) => {
  const {
    isOldProduct = false,
    product,
    collection,
    tvShow = {},
    loyaltyCardImage,
    productLayout,
    location,
    websiteTitle,
    reviewLink,
    multipaymentData
  } = props

  const [descExpanded, setDescExpanded] = useState(false)
  const [openAccordionIndex, setOpenAccordionIndex] = useState([0, 2])
  const [productVariant, setProductVariant] = useState(
    product?.variants?.length === 1 ? product.variants[0] : null
  )
  const [screenWidth, setWidthState] = useState(windowGlobal?.innerWidth)
  const [loading, setLoading] = useState(false)
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const responsiveSlidesToShow = useBreakpointValue({ base: 1, md: 2 })
  const isMobile = useBreakpointValue({ base: true, md: false })
  const [cart, setCart] = useState()
  const [isClicked, setIsClicked] = useState(false)
  const [stockLevel, setStockLevel] = useState()
  const [buyable, setBuyable] = useState(true)
  const [hasOffer, setHasOffer] = useState(true)
  const [jsonNotLoaded, setJsonNotLoaded] = useState(true)
  //const addThisRef = useRef()
  //const [showSocialNetwork, setShowSocialNetwork] = useState(false)
  const contentWithMediaUrls = product ? rewriteMediaUrl(product.description) : ''
  const {
    isOpen: isOpenCartModal,
    onOpen: onOpenCartModal,
    onClose: onCloseCartModal
  } = useDisclosure()
  const {
    isOpen: isOpenOrderLimitErrorModal,
    onOpen: onOpenOrderLimitErrorModal,
    onClose: onCloseOrderLimitErrorModal
  } = useDisclosure()
  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal
  } = useDisclosure()

  const getInventoryData = async () => {
    const [directStockLevel, promiseStockLevel] = await InventoryUtils.getStockLevel(
      product,
      productVariant,
      apiContext?.InventoryApi
    )
    setStockLevel(directStockLevel)

    promiseStockLevel
      ?.then((result) => {
        setStockLevel(result.stockLevel)
        DataLayerUtils.addProductDetail({ product, stockLevel: result.stockLevel })
        setBuyable(result.buyable)
      })
      ?.catch((err) => {
        console.error(err)
      })
  }

  const checkHasOffers = () => {
    if (product.variants.length == 1 && product.variants[0].customFields.offers.length > 0) {
      setHasOffer(true)
    } else if (product.variants.length > 1) {
      const hasOffersFunc = (pv) => pv.customFields.offers.length > 0
      setHasOffer(product.variants.some(hasOffersFunc))
    } else {
      setHasOffer(false)
    }
  }

  const setWidth = () => {
    setWidthState(windowGlobal?.innerWidth)
  }

  useEffect(() => {
    windowGlobal?.addEventListener('resize', setWidth)

    return () => {
      windowGlobal?.removeEventListener('resize', setWidth)
    }
  }, [screenWidth])

  useEffect(() => {
    checkHasOffers()
    getInventoryData()
  }, [productVariant])

  useEffect(() => {
    setJsonNotLoaded(false)
  }, [])

  const onProductVariantChange = (pv) => {
    setProductVariant(pv)
  }

  const addItemToOrder = async () => {
    setIsClicked(true)
    if (!productVariant) {
      return
    }
    setLoading(true)
    DataLayerUtils.addToCart({
      orderCode: currentCart?.code,
      product,
      productVariant,
      stockLevel,
      offerType: 'STANDARD'
    })
    const result = await apiContext?.OrderApi?.addItemToOrder(productVariant?.id, 1)
    setLoading(false)

    if (result && result?.__typename === 'Order') {
      setCart(result)
      onOpenCartModal()
    } else if (result && result?.__typename === 'OrderLimitError') {
      onOpenOrderLimitErrorModal()
    } else {
      onOpenErrorModal()
    }
  }

  const getWarranty = () => {
    if (!trim(product?.customFields?.warranty) || trim(product?.customFields?.warranty) === '-') {
      return false
    }
    if (isNumber(product?.customFields?.warranty)) {
      if (product?.customFields?.warranty === 0) {
        return 'Satisfait ou remboursé'
      } else if (product?.customFields?.warranty % 12 === 0) {
        return product?.customFields?.warranty / 12 === 1
          ? '1 an'
          : `${product?.customFields?.warranty / 12} ans`
      } else {
        return `${product?.customFields?.warranty} mois`
      }
    } else {
      return trim(product?.customFields?.warranty)
    }
  }
  /*useEffect(() => {
    //setTimeout(() => {
    console.log(addThisRef?.current?.firstChild)
    if (addThisRef?.current?.firstChild) {
      setShowSocialNetwork(true)
    }
    //}, 1200)
  }, [addThisRef?.current?.firstChild])*/

  /*useEffect(() => {
    const addThisRefresh = get(windowGlobal, 'addthis.layers.refresh')
    if (isFunction(addThisRefresh)) {
      addThisRefresh()
    }
  }, [product])*/

  // Carousel blog article
  const Dot = (props) => {
    const { onClick, active } = props
    return (
      <div
        style={{
          width: '14px',
          height: '14px',
          borderRadius: '7px',
          backgroundColor: active ? websiteContext?.mainColor : 'white',
          border: '1px solid #9c9c9c',
          cursor: active ? 'default' : 'pointer'
        }}
        onClick={active ? null : onClick}
      />
    )
  }

  const PrevArrow = (props) => {
    const { onClick } = props
    return (
      <Picto
        icon="chevronLeft"
        width="14px"
        onClick={onClick}
        color="#000000"
        style={{ marginRight: '10px', cursor: 'pointer' }}
      />
    )
  }

  const NextArrow = (props) => {
    const { onClick } = props
    return (
      <Picto
        icon="chevronRight"
        width="14px"
        onClick={onClick}
        color="#000000"
        style={{ marginLeft: '10px', cursor: 'pointer' }}
      />
    )
  }

  const onAccordionChange = (e) => {
    const newIndex = head(difference(e, openAccordionIndex))
    if (isNumber(newIndex)) {
      let eventLab
      if (newIndex === 0) {
        eventLab = 'Descriptif détaillé'
      } else if (newIndex === 1) {
        eventLab = 'Services inclus'
      } else if (newIndex === 2) {
        eventLab = 'Avis clients'
      }
      if (eventLab) {
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Pages produit',
          eventAct: 'Navigation volet contenu',
          eventLab
        })
      }
    }
    setOpenAccordionIndex(e)
  }

  const jsonLdData = {
    '@context': 'https://schema.org',
    '@type': 'Product',
    gtin8: product?.customFields?.barCode,
    sku: product?.customFields?.externalCode,
    image: product?.assets.map((item) => item?.source),
    name: product?.name + ' - ' + product?.customFields?.hook,
    identifier: productVariant?.customFields?.offers[0]?.offerCode,
    description: product?.description.replace(/<[^>]*>?/gm, ''),
    aggregateRating: {
      '@type': 'AggregateRating',
      ratingValue: product?.reviewAvg?.rate.toFixed(1),
      reviewCount: product?.reviewAvg?.nbReviews
    },
    // TODO : ajouter les 5 premiers avis clients
    brand: {
      '@type': 'brand',
      name: process.env.GATSBY_API_WEBSITE
    },
    offers: {
      '@type': 'offer',
      price:
        productVariant?.customFields?.offers[0]?.standardPrice >=
        productVariant?.customFields?.offers[0]?.price
          ? productVariant?.customFields?.offers[0]?.price / 100
          : productVariant?.customFields?.offers[0]?.standardPrice / 100,
      priceCurrency: productVariant?.customFields?.offers[0]?.currency,
      priceValidUntil: dayjs(productVariant?.customFields?.offers[0]?.endDate).format('YYYY-MM-DD'),
      category: collection?.name,
      url: location?.href,
      availability:
        productVariant?.stockLevel == 'OUT_OF_STOCK'
          ? 'http://schema.org/OutOfStock'
          : 'http://schema.org/InStock'
    }
  }

  return (
    <Flex direction="column" align="center">
      {jsonNotLoaded && <JsonLd jsonLdData={jsonLdData} />}
      <Flex
        direction={{ base: 'column', md: 'row' }}
        width={{ base: '100%', md: '1000px' }}
        maxWidth={{ base: '420px', md: '1000px' }}
        paddingLeft={{ base: '10px', md: '15px' }}
        paddingRight={{ base: '10px', md: '15px' }}
        align="center"
        justify="space-between"
        style={{ marginTop: '10px' }}>
        <Flex
          direction="column"
          width={{ base: '100%', md: '640px' }}
          align={['center', 'center', 'flex-start', 'flex-start']}>
          <Flex
            direction="row"
            flexWrap="wrap"
            gridGap="3px"
            order={{ base: 3, md: 0 }}
            width="100%"
            justify="flex-start"
            sx={{
              '& div': {
                '@media (max-width: 768px)': {
                  margin: '5px 5px 0 0'
                }
              }
            }}>
            {take(
              product?.facetValues?.filter(
                (f) => f?.facet?.code === 'product-tag-channel' && f?.customFields?.visible
              ),
              2
            ).map((f, i) => (
              <ProductTag key={`product_tag-${i}`} label={f?.name} color={f?.customFields?.color} />
            ))}
          </Flex>
          <ProductTitle
            product={product}
            openReviews={() => {
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Pages produit',
                eventAct: 'Départ consultation avis',
                eventLab: product?.customFields?.externalCode
              })
              setOpenAccordionIndex(uniq([...openAccordionIndex, 2]))
            }}
            openDescription={() => {
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Pages produit',
                eventAct: 'Départ consultation descriptif détaillé',
                eventLab: product?.customFields?.externalCode
              })
              setOpenAccordionIndex(uniq([...openAccordionIndex, 0]))
            }}
            order={{ base: 3, md: 1 }}
          />
          <ProductImage product={product} collection={collection} order={{ base: 0, md: 2 }} />

          <Flex
            style={{ width: '100%' }}
            justify="center"
            align="center"
            order={{ base: 1, md: 3 }}
            p={{ base: '15px 0 15px 0', md: '0 0 15px 85px' }}>
            <SocialShare link={windowGlobal?.location?.href} product={product} />
            {/*<Text
              as="span"
              fontSize="14px"
              fontFamily="PT Sans Narrow, PT Sans, Arial,sans-serif"
              pb="5px">
              Partagez sur :
            </Text>
          <Flex ml="5px" data-title={product?.name} className="addthis_inline_share_toolbox" />*/}
          </Flex>
        </Flex>
        <Flex
          direction="column"
          w={{ base: '100%', md: '330px' }}
          justify={{ base: 'center', md: 'flex-end' }}
          align="center"
          alignSelf={buyable ? '' : 'flex-start'}
          mt={buyable ? '0' : { md: '115px' }}>
          <Flex direction="column" width="100%">
            {product?.customFields?.specialMentions?.map((specialMention, i) => (
              <Flex
                key={`specialMention_${i}`}
                style={{
                  width: '100%',
                  paddingTop: '5px',
                  paddingBottom: '5px',
                  paddingLeft: '20px',
                  paddingRight: '20px',
                  backgroundColor: specialMention?.color,
                  fontFamily: 'PT Sans',
                  marginBottom: '5px',
                  textAlign: 'right',
                  fontSize: '14px',
                  color: '#ffffff'
                }}
                dangerouslySetInnerHTML={{ __html: specialMention?.content?.trim() }}
              />
            ))}
          </Flex>

          {!isOldProduct && hasOffer && buyable ? (
            <Flex
              direction="column"
              w={{ base: '100%', md: '330px' }}
              style={{
                padding: '30px',
                backgroundColor: '#eef5ff'
              }}>
              <FullPrice
                product={product}
                productVariant={productVariant}
                onProductVariantChange={onProductVariantChange}
                isClicked={isClicked}
                collection={collection}
                multipaymentData={multipaymentData}
              />

              <ProductInventory
                stockLevel={stockLevel}
                style={{
                  justifyContent: 'flex-end'
                }}
              />
              <AddToCartButton
                product={product}
                addItemToOrder={addItemToOrder}
                loading={loading}
                productVariant={productVariant}
              />
            </Flex>
          ) : (
            <Flex
              id="unbuyabled"
              direction="column"
              w={{ base: '100%', md: '330px' }}
              mb={buyable ? '0' : { base: '16px', md: '0' }}
              alignSelf="center">
              <Flex
                justifyContent="center"
                border="1px solid silver"
                bgColor="#f3f4f9"
                p="20px"
                fontSize="17px"
                fontWeight="bold">
                Produit actuellement indisponible
              </Flex>
              {collection && (
                <Link
                  to={collection?.path}
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    fontSize: '14px',
                    marginTop: '15px',
                    padding: '10px 15px',
                    border: '1px solid silver'
                  }}>
                  <Text>
                    Voir tout notre choix en
                    <span
                      style={{
                        display: 'block',
                        fontWeight: 'bold'
                      }}>
                      {collection?.name}
                    </span>
                  </Text>
                  <Picto icon="chevronRight" width="10px" />
                </Link>
              )}
            </Flex>
          )}

          {!isOldProduct && buyable && hasOffer && (
            <Flex direction="column" style={{ padding: '20px', width: '100%' }}>
              <ProductShipping
                product={product}
                productVariant={productVariant}
                stockLevel={stockLevel}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <Box
        direction="row"
        w="full"
        maxW={{ base: 'full', md: 'full' }}
        style={{ marginBottom: '15px' }}>
        <ProductCrossSell
          container="Page produit"
          products={product?.customFields?.crossSell}
          title1="Vous aimerez"
          title2="aussi..."
        />
      </Box>
      <Box
        direction="row"
        w="full"
        maxW={{ base: 'full', md: '1000px' }}
        m={{ base: '0px 16px', md: '0px auto' }}
        style={{ marginBottom: '15px' }}>
        <Accordion index={openAccordionIndex} onChange={onAccordionChange} allowMultiple>
          <AccordionItem>
            <h2 id="description">
              <AccordionButton
                bg={websiteContext?.mainColor}
                color="white"
                _hover={{ bg: websiteContext?.mainColor, color: 'white' }}
                style={{ boxShadow: 'none' }}>
                <Box
                  flex="1"
                  style={{
                    textAlign: 'left',
                    fontFamily: 'PT Sans, Arial, Helvetica',
                    fontSize: '17px',
                    fontWeight: 700
                  }}>
                  Descriptif détaillé
                </Box>
                <AccordionIcon size={8} />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <Text
                as="h2"
                pl={{ base: '0px', md: '290px' }}
                textTransform="uppercase"
                fontSize="24px"
                textAlign={{ base: 'center', md: 'left' }}
                marginBottom="10px"
                fontWeight="700"
                fontFamily={websiteContext?.fontFamily}>
                Description de {capitalize(product?.name)}
              </Text>
              <Flex direction={{ base: 'column', md: 'row' }}>
                <Flex
                  direction="column"
                  style={{
                    width: '300px',
                    paddingRight: '40px'
                  }}>
                  {!isEmpty(compact(map(product?.customFields?.argContext, trim))) && (
                    <Box
                      style={{
                        borderBottom: '1px solid silver',
                        paddingBottom: '20px',
                        marginBottom: '20px'
                      }}>
                      <h3
                        style={{
                          color: websiteContext?.mainColor,
                          fontSize: '15px',
                          fontWeight: 700,
                          fontFamily: 'Arial,helvetica,sans-serif',
                          marginBottom: '5px'
                        }}>
                        ON AIME
                      </h3>
                      {compact(map(product?.customFields?.argContext, trim)).map((a, i) => {
                        if (trim(a) !== '') {
                          return (
                            <Flex
                              key={`argContext_${i}`}
                              direction="row"
                              align="center"
                              justify="flex-start">
                              <Picto
                                icon="check"
                                width="16px"
                                height="16px"
                                color={websiteContext?.mainColor}
                              />
                              <span
                                style={{
                                  fontSize: '13px',
                                  fontWeight: 700,
                                  lineHeight: 1.8,
                                  marginLeft: '10px',
                                  fontFamily: 'Arial,helvetica,sans-serif'
                                }}>
                                {capitalize(a)}
                              </span>
                            </Flex>
                          )
                        } else {
                          return null
                        }
                      })}
                    </Box>
                  )}

                  <Box
                    direction="column"
                    style={{
                      borderBottom: '1px solid silver',
                      paddingBottom: '20px',
                      marginBottom: '20px'
                    }}>
                    <h3
                      style={{
                        color: websiteContext?.mainColor,
                        fontSize: '15px',
                        fontWeight: 700,
                        fontFamily: 'Arial,helvetica,sans-serif',
                        marginBottom: '5px'
                      }}>
                      INFORMATIONS UTILES
                    </h3>
                    {product?.customFields?.legalMentions?.map((legalMention, i) => (
                      <div
                        key={`legalMention_${i}`}
                        style={{ fontSize: '12px', fontFamily: 'Arial,helvetica,sans-serif' }}
                        dangerouslySetInnerHTML={{
                          __html: legalMention?.content?.trim()
                        }}
                      />
                    ))}
                    <p style={{ fontSize: '13px' }}>
                      <span
                        style={{
                          fontWeight: 700,
                          color: '#333333',
                          fontFamily: 'Arial,helvetica,sans-serif'
                        }}>
                        Référence :{' '}
                      </span>
                      {OfferUtils.getCurrentVariantOffer(
                        productVariant,
                        'STANDARD',
                        process.env.GATSBY_API_CHANNEL_ID
                      )?.offerCode ||
                        OfferUtils.getCurrentProductOffer(
                          product,
                          'STANDARD',
                          process.env.GATSBY_API_CHANNEL_ID
                        )?.offerCode}
                    </p>
                    {getWarranty() && (
                      <p style={{ fontSize: '13px' }}>
                        <span
                          style={{
                            fontWeight: 700,
                            color: '#333333',
                            fontFamily: 'Arial,helvetica,sans-serif'
                          }}>
                          Garantie :{' '}
                        </span>
                        {getWarranty()}
                      </p>
                    )}
                    <ProductAttachments attachments={product?.customFields?.attachments} />
                  </Box>
                  {collection?.path && (
                    <Box style={{ fontSize: '13px' }}>
                      <h3
                        style={{
                          fontWeight: 700,
                          fontSize: '12px',
                          color: '#333333',
                          fontFamily: 'Arial,helvetica,sans-serif'
                        }}>
                        Voir aussi :{' '}
                      </h3>
                      &gt;&nbsp;
                      <Link
                        to={`${collection?.path}`}
                        style={{
                          textDecoration: 'underline',
                          fontFamily: 'Arial,helvetica,sans-serif'
                        }}
                        onClick={() => {
                          DataLayerUtils.addEvent({
                            event: 'qwampEventLab',
                            eventCat: 'Pages produit',
                            eventAct: 'Départ catégorie',
                            eventLab: collection?.path
                          })
                        }}>
                        {collection?.name}
                      </Link>
                    </Box>
                  )}
                </Flex>
                <Box width={{ base: '100%', md: '700px' }}>
                  <div
                    style={{
                      width: '100%',
                      height: 'auto',
                      marginBottom: '20px',
                      fontSize: '14px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontFamily: 'PT Sans, Arial, Sans-serif'
                    }}
                    className={
                      descExpanded ? 'product_description animateExpand' : 'product_description'
                    }
                    sx={{
                      '& a': {
                        color: websiteContext?.mainColor,
                        '&:hover': {
                          textDecoration: 'underline',
                          color: websiteContext?.darkColor
                        }
                      }
                    }}>
                    {contentWithMediaUrls && (
                      <HTMLEllipsis
                        unsafeHTML={contentWithMediaUrls}
                        maxLine={descExpanded ? 1000 : 8}
                        ellipsis="..."
                        basedOn="words"
                        onReflow={(e) => setDescExpanded(!e.clamped)}
                      />
                    )}
                  </div>
                  {!descExpanded && (
                    <Button
                      w={{ base: '100%', md: 214 }}
                      onClick={() => {
                        DataLayerUtils.addEvent({
                          event: 'qwampEventLab',
                          eventCat: 'Pages produit',
                          eventAct: 'Afficher description complète',
                          eventLab: product?.customFields?.externalCode
                        })
                        setDescExpanded(true)
                      }}
                      variant="unstyled"
                      mt="20px"
                      fontSize={{ base: '20px', md: '14px' }}
                      h={{ base: '52px', md: '37px' }}
                      _focus={{ outline: 'none' }}
                      style={{
                        border: '1px solid #C0C0C0',
                        borderRadius: 0,
                        fontFamily: 'PT Sans, Arial, Sans-serif',
                        fontWeight: 400
                      }}>
                      VOIR PLUS DE DESCRIPTION
                    </Button>
                  )}
                </Box>
              </Flex>
            </AccordionPanel>
          </AccordionItem>
          {productLayout?.services && (
            <AccordionItem>
              <h2>
                <AccordionButton
                  bg={websiteContext?.mainColor}
                  _hover={{ bg: websiteContext?.mainColor, color: 'white' }}
                  color="white"
                  style={{ boxShadow: 'none' }}>
                  <Box
                    flex="1"
                    style={{
                      textAlign: 'left',
                      fontFamily: 'PT Sans, Arial, Helvetica',
                      fontSize: '17px',
                      fontWeight: 700
                    }}>
                    Services inclus
                  </Box>
                  <AccordionIcon size={8} />
                </AccordionButton>
              </h2>
              <AccordionPanel>
                <h2
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '24px',
                    textAlign: 'center',
                    marginBottom: '10px'
                  }}>
                  NOS SERVICES INCLUS
                </h2>
                <Flex
                  direction={{ base: 'column', md: 'row' }}
                  gridGap="10px"
                  justifyContent="space-evenly"
                  align="center">
                  {productLayout?.services?.map((d, i) => (
                    <Service
                      key={i}
                      icon={`${d.picto}`}
                      title={`${d.title}`}
                      hook={`${d.description}`}
                      link={`${d.link}`}
                    />
                  ))}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          )}

          {websiteContext?.showReviews && (
            <AccordionItem>
              <h2 id="reviews">
                <AccordionButton
                  bg={websiteContext?.mainColor}
                  color="white"
                  _hover={{ bg: websiteContext?.mainColor, color: 'white' }}
                  style={{ boxShadow: 'none' }}
                  onClick={() =>
                    DataLayerUtils.addEvent({
                      event: 'qwampEventLab',
                      eventCat: 'Pages produit',
                      eventAct: 'Départ consultation avis',
                      eventLab: product?.customFields?.externalCode
                    })
                  }>
                  <Box
                    flex="1"
                    style={{
                      textAlign: 'left',
                      fontFamily: 'PT Sans, Arial, Helvetica',
                      fontSize: '17px',
                      fontWeight: 700
                    }}>
                    Avis clients
                    {product?.reviewAvg?.nbReviews > 0 && (
                      <span
                        style={{
                          textAlign: 'left',
                          fontWeight: 400,
                          marginLeft: '5px'
                        }}>{`(${product?.reviewAvg?.nbReviews})`}</span>
                    )}
                  </Box>
                  <AccordionIcon size={8} />
                </AccordionButton>
              </h2>
              <AccordionPanel p={{ base: '0', md: '8px 16px 20px' }}>
                <Reviews product={product} reviewLink={reviewLink} />
              </AccordionPanel>
            </AccordionItem>
          )}
        </Accordion>
      </Box>
      {!isEmpty(tvShow) && !isEmpty(tvShow?.products) && (
        <Box
          direction="row"
          w="full"
          maxW={{ base: 'full', md: 'full' }}
          style={{ marginBottom: '15px' }}>
          <ProductCrossSell
            container="Page produit"
            products={tvShow?.products}
            title1="Les produits de l'émission"
            title2={
              tvShow?.type == 'PER_DAY'
                ? `du ${dayjs(tvShow?.code, 'YYYY-MM-DD').format('DD MMMM YYYY')}`
                : websiteTitle
            }
          />
        </Box>
      )}
      {!isEmpty(product?.blogArticles) && (
        <Flex direction="column" alignItems="center" m="20px 0" id="blog">
          <Text
            as="h3"
            mb="5px"
            color={websiteContext?.mainColor}
            fontFamily="Satisfy, Arial, sans-serif"
            fontSize="30px">
            {websiteContext?.title} <span style={{ color: '#333' }}> &amp; vous</span>
          </Text>
          <Text fontFamily="Courgette, Arial, sans-serif" fontSize="20px">
            Consultez les articles associés à
          </Text>
          <Text fontWeight="bold" fontSize="20px">
            {product?.name}
          </Text>
          {product?.blogArticles.length == 1 || (product?.blogArticles.length < 2 && !isMobile) ? (
            <Flex
              flexDirection="row"
              flexWrap="wrap"
              justify="space-evenly"
              gridGap="10px"
              mt="20px">
              {product?.blogArticles?.map((blogArticle, i) => (
                <BlogPushThumbnail
                  key={`blog_article_${i}`}
                  blogArticle={blogArticle}
                  height="60px"
                  backLink={`${location?.pathname}#blog`}
                />
              ))}
            </Flex>
          ) : (
            <Flex>
              <Carousel
                isMobile={websiteContext?.isMobile}
                isProductPage={true}
                direction={'row'}
                hasScrollX={websiteContext?.isMobile}
                slideWidth={websiteContext?.isMobile ? screenWidth : 330}
                slideHeight={websiteContext?.isMobile ? 346 : 426}
                slidesToShow={Math.min(responsiveSlidesToShow, product?.blogArticles?.length)}
                slidesToScroll={responsiveSlidesToShow}
                nextArrow={NextArrow}
                prevArrow={PrevArrow}
                displayDots={true}
                slideBelowMd={websiteContext?.isMobile ? true : false}
                displayArrow={websiteContext?.isMobile ? false : true}
                dot={Dot}
                gap={0}>
                {product?.blogArticles?.map((blogArticle, i) => (
                  <BlogPushThumbnail
                    key={`blog_article_${i}`}
                    blogArticle={blogArticle}
                    classname="blog_article"
                    height="60px"
                    backLink={`${location?.pathname}#blog`}
                  />
                ))}
              </Carousel>
            </Flex>
          )}
        </Flex>
      )}
      <Modal
        isCentered
        isOpen={isOpenCartModal}
        onClose={onCloseCartModal}
        size="4xl"
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          w={{ base: '90%', md: '100%' }}
          h={{ base: '90%', md: 'auto' }}
          maxH="calc(100% - 2rem)"
          overflow={{ base: 'auto', md: 'hidden' }}
          margin="0">
          <ModalCloseButton
            style={{ boxShadow: 'none' }}
            right={{ base: '0', md: '-4px' }}
            _hover={{ background: 'none' }}
            sx={{
              '@media (max-height: 714px)': {
                right: '12px'
              }
            }}
            onClick={() => {
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Pages produit',
                eventAct: 'Popin ajout panier',
                eventLab: 'Fermeture'
              })
            }}
          />
          <ModalBody p="0">
            <AddToCartPopin
              product={product}
              productVariant={productVariant}
              cart={cart}
              onClose={onCloseCartModal}
              loyaltyCardImage={loyaltyCardImage}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
      <MaxProductModal
        isOpen={isOpenOrderLimitErrorModal}
        onOpen={onOpenOrderLimitErrorModal}
        onClose={onCloseOrderLimitErrorModal}
      />
      <ErrorModal
        isOpen={isOpenErrorModal}
        onOpen={onOpenErrorModal}
        onClose={onCloseErrorModal}
        icon="informationCercle"
        message="Une erreur est survenue lors de l'ajout au panier!"
        color={websiteContext?.mainColor}
      />
    </Flex>
  )
}

export default memo(ProductLayout)
