import {
  FormControl as _FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel
} from '@chakra-ui/react'
import React from 'react'

const FormControl = (props) => {
  return (
    <_FormControl
      sx={{
        'input:not(:placeholder-shown)+label,  .chakra-select__wrapper+label': {
          background: '#FFF'
        }
      }}
      {...props}
    />
  )
}

export { FormControl, FormErrorMessage, FormHelperText, FormLabel }
