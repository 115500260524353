import { Box, Flex, GatsbyImage, Media, Text } from '@stars-ecom/shared-atoms-ui'
import head from 'lodash/head'
import React, { memo, useContext } from 'react'

import { WebsiteContext } from '../context'

const CollectionHeader = (props) => {
  const { collection, type, tvShowBannerImage = {} } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      className="collection-header-wrapper"
      w={{ base: '100%', md: '1000px' }}
      overflow="hidden"
      borderBottom={{ base: 'none', md: '3px #fff solid' }}
      height={{ base: 'auto', md: '270px' }}>
      <Flex
        id={websiteContext?.isMobile ? 'top' : ''}
        className="collection-header-desc-wrapper"
        sx={{
          '& a': {
            color: websiteContext?.mainColor,
            '&:hover': {
              textDecoration: 'underline'
            }
          }
        }}
        p={{ base: '16px', md: '20px 0 0' }}
        height={{ base: 'auto', md: '270px' }}>
        <Flex direction="column" maxWidth="400px">
          {collection?.parent && (
            <span
              style={{
                fontFamily: 'PT Sans Narrow, Arial, sans-serif',
                fontSize: '18px',
                fontWeight: 'bold',
                color: collection?.customFields?.color,
                marginBottom: '5px'
              }}>
              {collection?.parent?.name}
            </span>
          )}

          {type == 'allProductPage' && (
            <span
              style={{
                fontFamily: 'PT Sans Narrow, Arial, sans-serif',
                fontSize: '18px',
                fontWeight: 'bold',
                color: '#333',
                marginBottom: '5px'
              }}>
              Tous les produits
            </span>
          )}

          <Text as="h1"
            maxWidth="400px"
            fontSize={{ base:'32px', md:'44px' }}
            fontWeight="400"
            color="#333"
            lineHeight="1"
            fontFamily="PT Sans Narrow, Arial, sans-serif">
            {collection?.name}
          </Text>
          <Text
            as="p"
            fontSize={{ base: '16px', md: '20px' }}
            maxWidth="400px"
            fontFamily="PT Sans, Arial, sans-serif"
            mt="4px"
            mb="8px"
            color="#666">{`${collection?.totalProducts} produit${
            collection?.totalProducts > 1 ? 's' : ''
          }`}</Text>
          <Box
            maxWidth="400px"
            fontSize="12.5px"
            fontFamily="PT Sans Narrow, Arial, sans-serif"
            textAlign="justify"
            lineHeight={{ base: '22px', md: '16px' }}
            fontWeight={400}
            color="#333"
            dangerouslySetInnerHTML={{ __html: collection?.description }}
          />
          <Box
            display={{ base: 'none', md: 'initial' }}
            h="4px"
            w="65px"
            mt="12px"
            backgroundColor={collection?.customFields?.color}
          />
        </Flex>
      </Flex>
      <Flex display={{ base: 'none', md: 'flex' }} position="absolute" right="0">
          {type == 'tvShowPage' &&
            <GatsbyImage
                image={tvShowBannerImage}
                alt={collection?.name}
                loading="eager"
              /> 
          }
          {type == 'collectionPage' && (
            <Media asset={head(collection?.assets)} alt={collection?.name} />
          )}
        </Flex>
    </Flex>
  )
}

export default memo(CollectionHeader)