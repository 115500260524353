import './header.css'

import { Box, Divider, Flex, Link, Picto, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext, useEffect, useState } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import { Logo } from '../logo'

const HeaderOrder = (props) => {
  const { maxContentWidth = 1000, headerStep = 0 } = props
  const windowGlobal = typeof window !== 'undefined' && window
  const homeLink = windowGlobal?.location?.origin || '/'
  const onLogoClick = () => {
    DataLayerUtils.addEvent({ event: 'qwampEvent', eventCat: 'Header', eventAct: 'Clic logo' })
  }
  return (
    <Flex
      direction="column"
      align="center"
      m="0"
      px="0"
      pt={{ base: '10px', md: '20px' }}
      pb={{ base: '10px', md: '20px' }}
      bg="#F6F5FA">
      <Flex
        w="100%"
        maxWidth={{ base: '100vw', md: maxContentWidth }}
        direction={{ base: 'column', md: 'row' }}
        alignItems="center"
        justifyContent={{ base: 'center', md: 'space-between' }}>
        <Link to={homeLink} onClick={onLogoClick} target="_self">
          <Logo isCheckout={true} />
        </Link>
        <Box mt={{ base: '20px', md: '10px' }}>
          <Steps headerStep={headerStep} />
        </Box>
      </Flex>
    </Flex>
  )
}

const Steps = ({ headerStep }) => (
  <Flex>
    <StepsItem
      isActive={headerStep > 1}
      isCurrent={headerStep === 1}
      label="Panier"
      icon="bag"
      link="/checkout/onepage"
    />
    <Divider
      display={['none', 'flex']}
      w={{ base: '40px', md: '80px' }}
      borderColor="gray.400"
      variant={headerStep > 1 ? 'solid' : 'dashed'}
      mt="22px"
    />
    <StepsItem
      display={['none', 'flex']}
      isActive={headerStep > 2}
      isCurrent={headerStep === 2}
      label="Identification"
      icon="user"
    />
    <Divider
      w={{ base: '40px', md: '80px' }}
      borderColor="gray.400"
      variant={headerStep > 2 ? 'solid' : 'dashed'}
      mt="22px"
    />
    <StepsItem
      isActive={headerStep > 3}
      isCurrent={headerStep === 3}
      label="Livraison"
      link="/checkout/shipping"
      icon="shipping"
      width="56px"
      height="42px"
    />
    <Divider
      w={{ base: '40px', md: '80px' }}
      borderColor="gray.400"
      variant={headerStep > 3 ? 'solid' : 'dashed'}
      mt="22px"
    />
    <StepsItem
      isActive={headerStep > 4}
      isCurrent={headerStep === 4}
      label="Paiement"
      icon="creditCard"
    />
  </Flex>
)
const StepsItem = (props) => {
  const {
    isCurrent = false,
    isActive = false,
    icon,
    label = '',
    display = 'flex',
    link,
    width = '32px',
    height = '32px'
  } = props
  const [color, setColor] = useState('#cccccc')
  const websiteContext = useContext(WebsiteContext)
  useEffect(() => {
    if (isCurrent) {
      setColor(websiteContext.mainColor)
    } else if (isActive) {
      setColor('#939393')
    } else {
      setColor('#cccccc')
    }
  }, [isCurrent, isActive])

  return (
    <Link
      to={isActive && !isCurrent ? link : undefined}
      onClick={() =>
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Tunnel',
          eventAct: 'Navigation étape',
          eventLab: label
        })
      }>
      <Flex direction="column" px="10px" alignItems="center" display={display}>
        <Box w={width} h="32px" position="relative">
          {isActive && !isCurrent && (
            <Picto
              icon={'checkCercle'}
              width="22px"
              height="22px"
              color={'#6EB86C'}
              style={{ position: 'absolute', top: '5px', left: '-10px' }}
            />
          )}
          <Picto icon={icon} width={width} height={height} color={color} />
        </Box>
        <Text
          fontSize="12px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontWeight={600}
          mt="8px"
          color={color}>
          {label}
        </Text>
      </Flex>
    </Link>
  )
}

export default HeaderOrder
