import { get, head } from 'lodash'

const getDefaultShippingAddress = (currentUser) => {
  return (
    currentUser?.addresses?.find((a) => a.defaultShippingAddress && !a.customFields?.isDeleted) ||
    head(
      currentUser?.addresses?.filter((a) => !a.defaultBillingAddress && !a.customFields?.isDeleted)
    )
  )
}
const getDefaultBillingAddress = (currentUser) => {
  return currentUser?.addresses?.find((a) => a.defaultBillingAddress && !a?.customFields?.isDeleted)
}

const getShippingAddresses = (currentUser) => {
  return get(currentUser || {}, 'addresses', []).filter(
    (a) => !a.defaultBillingAddress && !a?.customFields?.isDeleted
  )
}

export { getDefaultShippingAddress, getDefaultBillingAddress, getShippingAddresses }
