import { Box, Countdown, Flex, Link, Picto } from '@stars-ecom/shared-atoms-ui'
import padStart from 'lodash/padStart'
import React, { useEffect, useState } from 'react'

import { TimedContent } from '../utils'

const HeaderBannerRenderer = (props) => {
  const { headerBanner, onClose, opacity } = props

  const RenderFigure = ({ figure }) => {
    return (
      <Flex
        align="center"
        justify="center"
        width={{ base: '18px', md: '28px' }}
        height={{ base: '26px', md: '32px' }}
        fontSize={{ base: '18px', md: '22px' }}
        style={{
          backgroundColor: headerBanner?.countdownBackgroundColor,
          color: headerBanner?.countdownNumberColor,
          fontWeight: 600
        }}>
        {figure}
      </Flex>
    )
  }

  const RenderNumber = ({ number, pad = 2 }) => {
    return (
      <Flex direction="row" gridGap="2px">
        {padStart(number?.toString(), pad, '0')
          .split('')
          .map((f, i) => (
            <RenderFigure key={`figure_${i}`} figure={f} />
          ))}
      </Flex>
    )
  }

  const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
      return null
    } else {
      return (
        <Flex direction="row" style={{ marginLeft: '30px' }}>
          {days > 0 && (
            <>
              <RenderNumber number={days} pad={0} />
              <Flex
                justify="center"
                align="center"
                height={{ base: '28px', md: '32px' }}
                fontSize={{ base: '12px', md: '22px' }}
                style={{
                  color: headerBanner?.countdownTextColor,
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  fontWeight: 600,
                  marginLeft: '3px',
                  marginRight: '5px'
                }}>
                j
              </Flex>
            </>
          )}
          {(days > 0 || hours > 0) && (
            <>
              <RenderNumber number={hours} />
              <Flex
                justify="center"
                align="center"
                height={{ base: '28px', md: '32px' }}
                fontSize={{ base: '12px', md: '22px' }}
                style={{
                  color: headerBanner?.countdownTextColor,
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  fontWeight: 600,
                  marginLeft: '3px',
                  marginRight: '5px'
                }}>
                h
              </Flex>
            </>
          )}
          {(days > 0 || hours > 0 || minutes > 0) && (
            <>
              <RenderNumber number={minutes} />
              <Flex
                justify="center"
                align="center"
                height={{ base: '28px', md: '32px' }}
                fontSize={{ base: '12px', md: '22px' }}
                style={{
                  color: headerBanner?.countdownTextColor,
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  fontWeight: 600,
                  marginLeft: '3px',
                  marginRight: '5px'
                }}>
                m
              </Flex>
            </>
          )}
          {days <= 0 && (hours > 0 || minutes > 0 || seconds > 0) && (
            <>
              <RenderNumber number={seconds} />
              <Flex
                justify="center"
                align="center"
                height={{ base: '28px', md: '32px' }}
                fontSize={{ base: '12px', md: '22px' }}
                style={{
                  color: headerBanner?.countdownTextColor,
                  paddingLeft: '3px',
                  paddingRight: '3px',
                  fontWeight: 600,
                  marginLeft: '3px'
                }}>
                s
              </Flex>
            </>
          )}
        </Flex>
      )
    }
  }

  return (
    <Flex
      width="100%"
      minHeight="50"
      direction={{ base: 'column', md: 'row' }}
      align="center"
      justify="center"
      display="flex"
      color={headerBanner?.textColor}>
      <Flex
        align="center"
        maxWidth="1000px"
        direction="row"
        width="100%"
        marginRight={{ base: '6px', md: '15px' }}
        opacity={opacity}
        style={{ transition: 'opacity 0.3s ease-in-out' }}>
        <Link
          to={headerBanner?.link}
          target="_self"
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%'
          }}>
          <Box
            style={{ color: headerBanner?.textColor, fontWeight: 600 }}
            fontFamily="PT Sans, Arial, sans-serif"
            ml="12px"
            mr={{ md: '6px' }}
            lineHeight={{ base: '18px', md: 'initial' }}
            textAlign={{ base: 'center', md: 'initial' }}
            fontSize={{ base: '14px', md: '18px' }}
            dangerouslySetInnerHTML={{ __html: headerBanner?.content }}
          />
          {headerBanner?.hasCountdown && (
            <Box>
              <Countdown
                daysInHours={false}
                date={headerBanner?.endDate}
                renderer={countdownRenderer}
              />
            </Box>
          )}
        </Link>
        <Picto
          icon="cross"
          width="12"
          height="12"
          color={
            headerBanner?.textColor === headerBanner?.countdownBackgroundColor
              ? headerBanner?.textColor
              : headerBanner?.countdownBackgroundColor
          }
          style={{ cursor: 'pointer' }}
          onClick={() => onClose(headerBanner?.id)}
        />
      </Flex>
    </Flex>
  )
}

const HeaderBanner = (props) => {
  const { headerBanners = [] } = props
  const [contents, setContents] = useState([])
  const windowGlobal = typeof window !== 'undefined' && window

  const [closed, setClosed] = useState(
    windowGlobal?.sessionStorage?.getItem('headerBannerClosed') || []
  )

  const initContents = () => {
    setContents(
      TimedContent.filter(headerBanners, {
        additionalFilter: (banner) => !closed?.includes(banner.id)
      })
    )
  }
  useEffect(() => {
    initContents()
  }, [closed])

  const [headerIndex, setHeaderIndex] = useState(0)

  useEffect(() => {
    let timer

    if (contents.length > 1) {
      timer = setInterval(() => {
        if (headerIndex < contents.length - 1) {
          setHeaderIndex(headerIndex + 1)
        }
        if (headerIndex == contents.length - 1) {
          setHeaderIndex(0)
        }
      }, 4000)
    }
    return () => timer && clearInterval(timer)
  }, [headerIndex, contents])

  const onClose = (id) => {
    setClosed([...closed, id])
    setHeaderIndex(0)
  }

  useEffect(() => {
    windowGlobal?.sessionStorage?.setItem('headerBannerClosed', closed)
    initContents()
  }, [closed])

  return (
    <>
      {contents.length > 0 && (
        <Box minHeight="50" width="100%" overflow="hidden">
          <Flex
            direction="column"
            minHeight={`50px`}
            width={`100%`}
            backgroundColor={contents[headerIndex]?.backgroundColor}
            style={{ transition: 'background-color 0.5s ease-in-out' }}>
            <HeaderBannerRenderer
              key={`header_banner-${contents[headerIndex]?.id}`}
              headerBanner={contents[headerIndex]}
              onClose={onClose}
              opacity={1}
            />
          </Flex>
        </Box>
      )}
    </>
  )
}

export default HeaderBanner
