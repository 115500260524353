import { Button, CircularProgress, Flex, Picto, Tooltip } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { memo, useContext, useState } from 'react'

import { WebsiteContext } from '../context'

const AddToCartButton = (props) => {
  const { product, productVariant, addItemToOrder, loading } = props

  const [isTooltipOpen, setIsTooltipOpen] = useState(false)
  const websiteContext = useContext(WebsiteContext)

  return (
    <Tooltip
      hasArrow
      label={`Merci de choisir votre ${get(
        product,
        'variants.0.options.0.group.name',
        ''
      ).toLowerCase()}`}
      bg="#FFF"
      color="#000"
      border="1px solid #999"
      p="10px"
      isOpen={isTooltipOpen}
      arrowShadowColor="#999">
      <Button
        id="productAddToCart"
        onClick={addItemToOrder}
        variant="solid"
        disabled={loading}
        style={{
          borderRadius: 0,
          color: '#ffffff',
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '48px',
          fontSize: '17px',
          fontWeight: 400,
          fontFamily: 'PT Sans,Arial,sans-serif',
          marginTop: '10px',
          boxShadow: 'none'
        }}
        backgroundColor={websiteContext.mainColor}
        onMouseEnter={() => (!productVariant ? setIsTooltipOpen(true) : setIsTooltipOpen(false))}
        onMouseLeave={() => setIsTooltipOpen(false)}
        _hover={{ backgroundColor: websiteContext.darkColor }}>
        <Picto
          icon="bagBold"
          color="white"
          width="24px"
          height="29px"
          style={{ marginBottom: '1px', marginRight: '10px' }}
        />
        AJOUTER AU PANIER
        {loading && (
          <Flex
            position="absolute"
            w="100%"
            h="100%"
            justify="center"
            align="center"
            background="rgba(0,0,0,0.1)">
            <CircularProgress
              isIndeterminate={true}
              size="20px"
              color={websiteContext?.mainColor}
            />
          </Flex>
        )}
      </Button>
    </Tooltip>
  )
}

export default memo(AddToCartButton)
