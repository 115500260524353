import { Flex, Picto } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { OfferUtils, priceFormat } from '../utils'

const ProductShipping = (props) => {
  const { product, stockLevel, style } = props
  const { shippingFee, currency = 'EUR' } = OfferUtils.getCurrentProductOffer(
    product,
    'STANDARD',
    process.env.GATSBY_API_CHANNEL_ID
  )

  return (
    <Flex direction="column" justifyContent="flex-start" style={style}>
      <Flex direction="row" align="center" justifyContent="flex-start">
        <Picto icon="deliveryCreditCard" width="24px" height="24px" />
        <span
          style={{
            fontSize: '14px',
            color: 'rgb(21,124,193)',
            fontWeight: 700,
            marginLeft: '5px'
          }}>
          LIVRAISON
        </span>
      </Flex>
      <ul
        style={{
          color: 'rgb(21,124,193)',
          fontSize: '14px',
          fontFamily: 'PT Sans Narrow, Arial, sans-serif'
        }}>
        <li>
          <span style={{ color: 'black', fontWeight: 400 }}>
            Expédié sous{' '}
            <span style={{ fontWeight: 700 }}>
              {stockLevel === 'OUT_OF_STOCK' ? '1 à 2 semaines' : '3 à 5 jours'}
            </span>
          </span>
        </li>
        <li>
          {stockLevel === 'OUT_OF_STOCK' ? (
            <span style={{ color: 'black', fontWeight: 400 }}>
              Débit <span style={{ fontWeight: 700 }}>à l&acute;expédition</span>
            </span>
          ) : (
            <span style={{ color: 'black', fontWeight: 400 }}>
              À partir de{' '}
              <span style={{ fontWeight: 700 }}>{priceFormat(shippingFee, currency)}</span>
            </span>
          )}
        </li>
      </ul>
    </Flex>
  )
}

export default memo(ProductShipping)
