import '../layout/simplePage.css'
import './pledg.css'

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  CloseButton,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Picto,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'

import { ApiContext, WebsiteContext } from '../context'
import Conditions from './Conditions'

const PledgPaymentForm = (props) => {
  const { idPledgButton, paymentMethod, setPledgError = {}, cgv = [] } = props
  const websiteContext = useContext(WebsiteContext)
  const [isLoading, setIsLoading] = useState(false)
  const [isValid, setIsValid] = useState(false)
  const [pledgErrorMessage, setPledgErrorMessage] = useState()
  const [pledgInstance, setPledgInstance] = useState()
  const [isCGVChecked, setIsCGVChecked] = useState(false)
  const pledgButton = document.querySelector(`#${idPledgButton}`)

  const { isOpen, onClose, onOpen } = useDisclosure()
  const apiContext = useContext(ApiContext)
  const cgvRef = useRef()

  const getMetadata = (result) => {
    const metadata = {
      paymentId: result?.transaction?.id,
      transactionDate: result?.transaction?.created_at,
      paymentStatus: result?.transaction?.status,
      token: result?.purchase?.uid
    }

    return metadata
  }
  const getPledgToken = async (paymentMethod) => {
    const result = await apiContext.OrderApi.generatePledgToken(paymentMethod)
    const signature = {
      signature: result
    }
    return signature
  }

  const createPledgPayment = async (paymentMethod) => {
    try {
      setPledgInstance(
        new Pledg(pledgButton, {
          ...(await getPledgToken(paymentMethod)),
          ...{
            containerElement: document.querySelector('#pledg-funnel-container'),
            showCloseButton: false,
            externalCheckoutValidation: true,
            onSuccess: async function (result) {
              const metadata = getMetadata(result)
              try {
                const res = await apiContext.OrderApi.addPaymentToOrder(
                  paymentMethod,
                  metadata,
                  false
                )
                navigate('/checkout/order-confirmation', {
                  state: { order: res },
                  replace: true
                })
              } catch (error) {
                console.log('error', error)
              }
            },
            onCheckValidity: function ({ isValid, error }) {
              setIsLoading(false)
              if (!isValid) {
                setPledgErrorMessage(error?.message)
              }
            },
            onCheckoutFormStatusChange: function (readiness) {
              if (readiness) {
                setIsLoading(false)
                setIsValid(true)
              }
            },
            onError: function (error) {
              setIsLoading(false)
              setPledgErrorMessage(error?.message)
              document.querySelector('.pledg-iframe-wrapper').classList.toggle('activeError')
            },
            onCancel: function () {
              setIsLoading(false)
              setPledgError(true)
            }
          }
        })
      )
    } catch (err) {
      console.log('err', err)
    }
  }
  const handlePayment = (checked) => {
    if (!checked) {
      onOpen()
      return
    }
    setIsLoading(true)
    pledgInstance.validateCheckout()
  }
  useEffect(async () => {
    await createPledgPayment(paymentMethod)
    pledgButton.click()
  }, [paymentMethod])

  return (
    <Box py="20px">
      <div id="pledg-funnel-container"></div>

      <Flex direction="column" width="100%" justify="center" align="center" my="10px">
        <Conditions
          ref={cgvRef}
          conditions={cgv}
          isCGVChecked={isCGVChecked}
          setIsCGVChecked={setIsCGVChecked}
        />
        {!pledgErrorMessage ? (
          <Button
            borderRadius="0"
            bg={websiteContext.mainColor}
            _hover={{ bg: websiteContext.darkColor }}
            _focus={{ border: 'none' }}
            color="#FFF"
            onClick={() => handlePayment(cgvRef?.current?.checked)}
            id={`hipay-submit-button_${paymentMethod}`}
            disabled={!isValid || isLoading}
            isLoading={isLoading}
            fontFamily={websiteContext.fontFamily}>
            VALIDER MON PAIEMENT
          </Button>
        ) : (
          <Button
            borderRadius="0"
            bg={websiteContext.mainColor}
            _hover={{ bg: websiteContext.darkColor }}
            _focus={{ border: 'none' }}
            color="#FFF"
            onClick={() => {
              setPledgErrorMessage(null)
              setPledgError(true)
            }}
            isLoading={isLoading}
            fontFamily={websiteContext.fontFamily}>
            CHOISIR UNE AUTRE METHODE DE PAIEMENT
          </Button>
        )}
        <Flex mt="10px">
          <Picto icon="locker" width="12px" color="#8b8b8b" />
          <Text
            textAlign="center"
            fontSize="14px"
            color="#8b8b8b"
            fontFamily={websiteContext.fontFamily}
            ml="7px"
            position="relative"
            bottom="-1px">
            100% sécurisé
          </Text>
        </Flex>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          p="40px"
          w={{ base: '90%', md: '100%' }}
          h={{ base: '90%', md: 'auto' }}>
          <ModalCloseButton
            style={{ boxShadow: 'none' }}
            right={{ base: '0', md: '-4px' }}
            _hover={{ background: 'none' }}
          />
          <ModalBody>
            <Flex justifyContent="space-around" pb="20px">
              <Picto
                icon="informationCercle"
                width="80px"
                height="80px"
                color={websiteContext.mainColor}
              />
            </Flex>
            <Text
              align="center"
              fontFamily={websiteContext.fontFamily}
              fontSize="14px"
              color="#999999">
              Veuillez accepter les Conditions Générales de Vente et le cas échéant*, les Conditions
              Générales d&apos;Adhésion de la carte fidélité.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default memo(PledgPaymentForm)
