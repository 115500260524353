import { Flex, GatsbyImage, Text, useDisclosure } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import WatchTVPopin from './WatchTVPopin'

const WatchTVShowButton = (props) => {
  const { tvShowSettings } = props
  const { isOpen, onOpen, onClose } = useDisclosure()
  const websiteContext = useContext(WebsiteContext)

  const handleClick = () => {
    DataLayerUtils.addEvent({
      event: 'qwampEvent',
      eventCat: 'Header',
      eventAct: 'Départ popin émissions tv'
    })
    onOpen()
  }

  return (
    <Flex
      direction="row"
      align="center"
      width={{ base: 'auto', md: '115px' }}
      style={{
        cursor: 'pointer'
      }}
      onClick={handleClick}>
      {!websiteContext?.isMobile && (
        <Flex w="auto">
          <GatsbyImage
            image={tvShowSettings?.player?.image?.childImageSharp?.gatsbyImageData}
            alt="voir les émissions"
            loading="eager"
            style={{ width: '25px', height: '25px' }}
          /> 
        </Flex>
      )}
      <Text ml={{ md: '10px' }} style={{ lineHeight: 1, fontSize: '14px' }}>
        Voir {websiteContext?.isMobile ? "l'émission du jour" : 'les émissions TV'}
      </Text>
      <WatchTVPopin isOpen={isOpen} onClose={onClose} legalMention={tvShowSettings?.legalMentionReplay} embedUrl={tvShowSettings?.playerEmbedUrl} replayUrl={tvShowSettings?.lastVideosReplayUrl} bannerImage={tvShowSettings?.playerBannerImage?.image?.childImageSharp?.gatsbyImageData}/>
    </Flex>
  )
}

export default memo(WatchTVShowButton)
