import { Checkbox as _Checkbox, CheckboxGroup } from '@chakra-ui/react'
import React, { forwardRef } from 'react'

import { Box } from '../layout'
import { Picto } from '../media'

const Checkbox = forwardRef((props, ref) => {
  const {
    isChecked,
    onChange,
    isDisabled = false,
    style = {},
    backgroundColorChecked = '#000000',
    size = '20px',
    ...rest
  } = props

  return (
    <Box
      style={{
        position: 'relative',
        minHeight: size,
        minWidth: size,
        display: 'flex',
        border: `solid 1px ${isDisabled ? '#dfdfdf' : '#999'}`,
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        backgroundColor: isDisabled ? '#dfdfdf' : isChecked ? backgroundColorChecked : '#fff'
      }}>
      <Picto icon="check" width="12px" height="12px" color={isDisabled ? '#dfdfdf' : '#fff'} />
      <_Checkbox
        size="lg"
        ref={ref}
        isChecked={isChecked}
        isDisabled={isDisabled}
        position="absolute"
        {...rest}
        onChange={onChange}
        style={{ ...style, opacity: 0 }}
      />
    </Box>
  )
})

export { Checkbox, CheckboxGroup }
