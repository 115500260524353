const replaceSpecialChar = (str) => {
  return str
    .replaceAll('&quot;', '"')
    .replaceAll('&amp;', '&')
    .replaceAll('&gt;', '>')
    .replaceAll('&lt;', '<')
    .replaceAll('&#39;', "'")
}

export default replaceSpecialChar
