import './productSlide.css'

import { Carousel, Flex, Picto, useBreakpointValue } from '@stars-ecom/shared-atoms-ui'
import compact from 'lodash/compact'
import React, { useContext, useEffect } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'
import ProductThumbnail from './ProductThumbnail'

const ProductSlide = (props) => {
  const {
    listName = 'Caroussel produits',
    products,
    offerType = 'STANDARD',
    additionalSlides = [],
    arrowColor,
    maxSlidesToShow = 4,
    isUpsell = false,
    tvShow = {},
    productSlideId,
    dataLayerFunction
  } = props

  const slidesToShow = useBreakpointValue({
    base: 4,
    sm: 2,
    md: maxSlidesToShow >= 3 ? 3 : maxSlidesToShow - 1,
    lg: maxSlidesToShow
  })

  const displayDots = useBreakpointValue({
    base: false,
    md: true
  })

  const websiteContext = useContext(WebsiteContext)

  const Dot = (props) => {
    const { onClick, active } = props
    return (
      <div
        style={{
          width: '14px',
          height: '14px',
          borderRadius: '7px',
          backgroundColor: active ? websiteContext?.mainColor : 'white',
          border: '1px solid #9c9c9c',
          cursor: active ? 'default' : 'pointer'
        }}
        onClick={active ? null : onClick}
      />
    )
  }

  const PrevArrow = (props) => {
    const { onClick } = props
    return (
      <Picto
        icon="chevronLeft"
        width="15px"
        height="30px"
        onClick={onClick}
        color={arrowColor}
        style={{ marginRight: '7px', cursor: 'pointer' }}
      />
    )
  }

  const NextArrow = (props) => {
    const { onClick } = props
    return (
      <Picto
        icon="chevronRight"
        width="15px"
        height="30px"
        onClick={onClick}
        color={arrowColor}
        style={{ marginLeft: '7px', cursor: 'pointer' }}
      />
    )
  }

  useEffect(() => {
    DataLayerUtils.addProductImpression({
      command: 'productImpression',
      productList: products,
      listName: listName
    })
  }, [products])

  const cleanProductsList = (products) => {
    let product = []
    products.map((p) => {
      if (
        p.variants?.[0]?.customFields.productType !== 'FID' &&
        p.variants?.[0]?.customFields.productType !== 'EXT'
      ) {
        product.push(p)
      }
    })
    return product
  }

  const cleanProducts = cleanProductsList(products)

  return (
    <Flex direction="row" justify="center" align="center">
      <Carousel
        isMobile={websiteContext?.isMobile}
        carouselId={productSlideId}
        hasScrollX={websiteContext?.isMobile}
        direction="row"
        slideWidth={isUpsell ? 185 : 242}
        slideHeight={isUpsell ? 300 : 340}
        slidesToShow={slidesToShow}
        slidesToScroll={slidesToShow}
        nextArrow={NextArrow}
        prevArrow={PrevArrow}
        displayDots={displayDots}
        isUpsell={isUpsell}
        dot={Dot}
        gap={0}
        tvShow={tvShow}
        dataLayerFunction={dataLayerFunction}>
        {compact([
          ...(cleanProducts || [])?.map((product, i) => (
            <ProductThumbnail
              listName={listName}
              key={`product_${product.id}`}
              product={product}
              offerType={offerType}
              isUpsell={isUpsell}
              hasBorder={false}
              position={i}
            />
          )),
          ...additionalSlides
        ])}
      </Carousel>
    </Flex>
  )
}

export default ProductSlide
