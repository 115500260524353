import React from 'react'
import Helmet from 'react-helmet'

const JsonLd = (props) => {
  const { jsonLdData } = props

  return (
    <Helmet encodeSpecialCharacters={false}>
      <script type="application/ld+json">{JSON.stringify(jsonLdData)}</script>
    </Helmet>
  )
}

export default JsonLd
