import { axios } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'

const search = async (term = '', sort = null, filter = null, facets = null) => {
  try {
    const result = await axios.post(
      `search/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}`,
      { term, sort, filter, facets }
    )
    return get(result, 'data', {})
  } catch (e) {
    console.error(e)
    return {}
  }
}

export { search }
