import {
  Box,
  Button,
  Fade,
  Flex,
  Image,
  Input,
  List,
  ListItem,
  Text,
  useDisclosure,
  useState
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { useContext, useEffect } from 'react'

import { WebsiteContext } from '../../context'
import flag from './assets/FR.png'
const MondialRelayFormSearch = (props) => {
  const {
    fetch = () => {},
    setPostion = () => {},
    setPostCode = () => {},
    defaultPostCode = '75001',
    defaultCountry = process.env.GATSBY_API_COUNTRY_CODE,
    search = () => {}
  } = props
  const [cp, setCp] = useState(defaultPostCode)
  const [country] = useState(defaultCountry)
  const websiteContext = useContext(WebsiteContext)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [searchText, setSearchText] = useState('')
  const [citySelected, setCitySelected] = useState({})
  const [cities, setCities] = useState([])
  const [debounce, setDebounce] = useState(null)

  const doSearch = async () => {
    await search({
      cp,
      country
    })
  }
  const aroundMe = () => {
    try {
      navigator.geolocation.getCurrentPosition((position) => {
        setPostion(position.coords)
        setPostCode('')
      })
    } catch (e) {
      console.error(e)
    }
  }

  useEffect(() => {
    setCp(defaultPostCode)
  }, [defaultPostCode])

  const resetField = () => {
    if (get(citySelected, 'Name') !== searchText) {
      setSearchText('')
    }
  }

  const searchCity = () => {
    if (searchText.length > 3) {
      clearTimeout(debounce)
      setDebounce(
        setTimeout(() => {
          getCitiesByInput(searchText)
        }, 600)
      )
    }
  }

  const getCitiesByInput = async (input) => {
    if (input) {
      try {
        const data = await fetch(
          'https://widget.mondialrelay.com/parcelshop-picker/v4_0/services/parcelshop-picker.svc/AutoCPLCity',
          {
            callbackQuery: 'method',
            callbackName: 'receive',
            Country: defaultCountry,
            City: input
          }
        )
        if (data) {
          setCities(data.Value)
          onOpen()
        }
      } catch (error) {
        console.error(error)
      }
    }
  }

  const setCity = async (city) => {
    setSearchText(get(city, 'Name'))
    setCitySelected(city)
    await search({ cp: get(city, 'PostCode'), country: defaultCountry })
    onClose()
  }

  useEffect(() => {
    if (searchText !== get(citySelected, 'Name')) {
      searchCity()
    }
  }, [searchText])

  useEffect(() => {
    getCitiesByInput(searchText)
  }, [])

  // const changeCP = async (cp) => {
  //   setPostCode(cp)
  //   if (!isEmpty(cp)) {
  //     setPostion({})
  //   }
  //   await search({
  //     cp,
  //     country: defaultCountry
  //   })
  // }

  return (
    <Flex flexWrap="wrap" justify={{ base: 'space-around', md: 'start' }}>
      <Flex maxW={{ base: '300px', md: 'unset' }}>
        <Flex justify="space-between" position="relative">
          <Text fontSize="12px" p="5px">
            Ville:
          </Text>
          <Input
            placeholder="Ville"
            value={searchText}
            onBlur={resetField}
            backgroundColor="white"
            borderRadius="0px"
            fontSize="14px"
            py="5px"
            px="10px"
            h="25px"
            onChange={(e) => setSearchText(e.target.value)}
          />
          <Fade in={isOpen}>
            <Box
              minW={['191px', '233px']}
              maxH="300px"
              overflowY="scroll"
              position="absolute"
              top="25px"
              left="28px"
              bg="#FFFFFF"
              textAlign="left"
              zIndex="1001"
              border="1px solid #ced4da">
              <List styleType="none" m="0" p="0">
                {cities.length > 0 ? (
                  cities.map((city, key) => (
                    <ListItem
                      _hover={{ bg: '#f0f0f0', borderLeft: 'solid 2px #ca0047' }}
                      _focus={{ boxShadow: 'outline' }}
                      _selected={{ bg: '#f0f0f0', borderLeft: 'solid 2px #ca0047' }}
                      p="6px"
                      cursor="pointer"
                      key={key}
                      onClick={() => setCity(city)}>
                      {`${get(city, 'Name')} (${get(city, 'PostCode')})`}
                    </ListItem>
                  ))
                ) : (
                  <ListItem p="6px" cursor="pointer" onClick={onClose}>
                    {'Aucun résultat'}
                  </ListItem>
                )}
              </List>
            </Box>
          </Fade>
        </Flex>
        <Flex justify="center">
          <Text fontSize="12px" p="5px" maxW="100px">
            CP:
          </Text>
          <Input
            value={cp}
            placeholder="Code Postal"
            backgroundColor="white"
            borderRadius="0px"
            fontSize="14px"
            py="5px"
            px="10px"
            h="25px"
            w="60px"
            onChange={(e) => setCp(e.target.value)}
          />
          <Box p="10px">
            <Image src={flag} alt="mondial relay" w="16px" />
          </Box>
        </Flex>
      </Flex>
      <Flex justify="center" w={{ base: '100%', md: 'unset' }} mt={{ base: '8px', md: 'unset' }}>
        <Button
          backgroundColor={websiteContext.mainColor}
          fontFamily="'PT Sans', Arial, Helvetica, sans-serif"
          color="white"
          fontSize="12px"
          borderRadius="0px"
          fontWeight="100"
          h="24px"
          p="3px 12px"
          onClick={() => {
            if (cp.length > 2) {
              doSearch()
              setPostCode(cp)
            }
          }}>
          Chercher
        </Button>

        <Button
          backgroundColor={websiteContext.mainColor}
          color="white"
          fontFamily="'PT Sans', Arial, Helvetica, sans-serif"
          fontSize="12px"
          fontWeight="100"
          borderRadius="0px"
          maxW="150px"
          h="24px"
          p="3px 12px"
          ml="5px"
          onClick={() => {
            aroundMe()
          }}>
          Autour de ma position
        </Button>
      </Flex>
    </Flex>
  )
}

export default MondialRelayFormSearch
