import PropTypes from 'prop-types'
import React from 'react'

import { ListItem } from '../../dataDisplay/List'
import { Link } from '../Link'

const PageView = (props) => {
  let { pageClassName, pageIndexClassName, pageLinkClassName, color = '#000000' } = props
  const {
    page,
    selected,
    activeClassName,
    activeLinkClassName,
    getEventListener,
    pageSelectedHandler,
    href,
    extraAriaContext,
    pageLabelBuilder
  } = props

  let ariaLabel =
    props.ariaLabel || 'Page ' + page + (extraAriaContext ? ' ' + extraAriaContext : '')
  let ariaCurrent = null

  if (selected) {
    ariaCurrent = 'page'

    ariaLabel = props.ariaLabel || 'Page ' + page + ' is your current page'

    if (typeof pageClassName !== 'undefined') {
      pageClassName = pageClassName + ' ' + activeClassName
    } else {
      pageClassName = activeClassName
    }

    if (typeof pageLinkClassName !== 'undefined') {
      if (typeof activeLinkClassName !== 'undefined') {
        pageLinkClassName = pageLinkClassName + ' ' + activeLinkClassName
      }
    } else {
      pageLinkClassName = activeLinkClassName
    }
  }

  return (
    <ListItem
      role="button"
      className={[pageIndexClassName, pageClassName].join(' ')}
      onKeyPress={pageSelectedHandler}
      color={color}
      cursor="pointer"
      display={{ base: 'none', md: 'inline-block' }}
      bg={pageClassName === 'active' ? color : '#FFFFFF'}
      border={pageClassName === 'active' ? `1px solid ${color}` : '1px solid rgb(231,233,238)'}
      {...getEventListener(pageSelectedHandler)}>
      <Link
        className={pageLinkClassName}
        to={href}
        tabIndex="0"
        aria-label={ariaLabel}
        aria-current={ariaCurrent}>
        {pageLabelBuilder(page)}
      </Link>
    </ListItem>
  )
}

PageView.propTypes = {
  pageSelectedHandler: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  pageClassName: PropTypes.string,
  pageLinkClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  activeLinkClassName: PropTypes.string,
  extraAriaContext: PropTypes.string,
  href: PropTypes.string,
  ariaLabel: PropTypes.string,
  page: PropTypes.number.isRequired,
  getEventListener: PropTypes.func.isRequired,
  pageLabelBuilder: PropTypes.func.isRequired
}

export default PageView
