import { Box, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

const AccountReturnSuccess = (props, websiteContext) => {
  const {
    returnDetails,
    urlCommerciale,
    productName,
    isPaidByUser,
    wishToExchange,
    storeName,
    ReturnNumbers
  } = props
  const getItem = (key) => {
    return returnDetails?.find((param) => param?.key === key)?.value
  }
  return (
    <Box backgroundColor="white">
      <Box mb={{ base: '0px', md: '20px' }} padding={{ base: '20px', md: '40px' }}>
        <Text
          borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
          as="h2"
          style={{
            fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
            fontSize: '26px',
            fontWeight: '700',
            marginBottom: '20px'
          }}>
          Confirmation de retour
        </Text>
        <Box fontFamily="PT Sans ,Arial,sans-serif" fontSize="16px" lineHeight="24px" color="#333">
          <Text as="p" p="10px 0">
            Votre demande de retour pour <strong>{productName}</strong> a bien été enregistrée.
          </Text>
          {!isPaidByUser && (
            <Box>
              <Text as="p" p="10px 0">
                Vous allez recevoir sous {getItem('stickerSendMail')} une étiquette de réexpédition
                par mail.
              </Text>
              <Text as="p" p="10px 0">
                {storeName} prend en charge les frais de retour. Il vous suffit de renvoyer votre
                produit, sous {getItem('returnProductSociety')}, en suivant les étapes indiquées
                ci-dessous :
              </Text>
              <ol style={{ padding: '20px', listStyle: 'decimal inside none' }}>
                <li>Retournez le produit complet dans son emballage d’origine.</li>
                <li>
                  Indiquez sur le carton le numéro d’autorisation de retour suivant :{' '}
                  <strong>{ReturnNumbers}</strong>.
                </li>
                <li>Apposez l’étiquette de réexpédition, reçue par mail, sur votre colis.</li>
                <li>Déposez votre colis dans le bureau de Poste de votre choix.</li>
              </ol>
            </Box>
          )}
          {isPaidByUser && (
            <Box>
              <Box p="10px 0">
                Il vous suffit de renvoyer votre produit, à vos frais, sous{' '}
                {getItem('returnProductClient')}, selon la marche à suivre expliquée ci-dessous :
              </Box>
              <ol style={{ padding: '20px', listStyle: 'decimal inside none' }}>
                <li>Retournez le produit complet dans son emballage d’origine.</li>
                <li>
                  Indiquez sur le carton le numéro d’autorisation de retour suivant :{' '}
                  <strong>{ReturnNumbers}</strong>.
                </li>
                <li>
                  Envoyez votre colis à l’adresse suivante : <br />
                  <Box
                    textAlign="center"
                    className="adresseEntrepot"
                    dangerouslySetInnerHTML={{ __html: getItem('adresseStore') }}></Box>
                </li>
                <li>Déposez votre colis dans le bureau de Poste de votre choix.</li>
              </ol>
            </Box>
          )}
          <Text as="p" p="10px 0">
            Pour suivre l’avancement de votre retour, vous pouvez dès à présent le consulter sur la
            page{' '}
            <Link htoref="/productreturn/list" style={{ color: websiteContext?.mainColor }}>
              MES RETOURS PRODUITS
            </Link>
            .
          </Text>
          <Box>
            {wishToExchange && (
              <Text p="10px 0">
                Dès réception de votre colis dans notre entrepôt, nous procéderons au traitement
                demandé (sous réserve de disponibilité). Vous recevrez votre échange dans un délai{' '}
                {getItem('exchange')}.
              </Text>
            )}
            {!wishToExchange && (
              <Text p="10px 0">
                Dès réception de votre colis dans notre entrepôt, nous procèderons au remboursement
                de votre produit sous {getItem('refund')}
              </Text>
            )}
          </Box>
          <Text p="10px 0">Nous vous remercions de votre confiance.</Text>
          <Text p="10px 0">
            A très bientôt sur{' '}
            <Link to={urlCommerciale} style={{ color: websiteContext?.mainColor }}>
              {storeName} !
            </Link>
          </Text>
        </Box>
      </Box>
    </Box>
  )
}

export default AccountReturnSuccess
