import {
  AccordionDescendantsProvider,
  AccordionProvider,
  useAccordion,
  useAccordionContext,
  useAccordionItem
} from '@chakra-ui/accordion'
import { Icon } from '@chakra-ui/react'
import { createContext } from '@chakra-ui/react-utils'
import {
  chakra,
  forwardRef,
  omitThemingProps,
  StylesProvider,
  useMultiStyleConfig,
  useStyles
} from '@chakra-ui/system'
import { Collapse } from '@chakra-ui/transition'
import { __DEV__, cx, runIfFn } from '@chakra-ui/utils'
import React from 'react'

/* -------------------------------------------------------------------------------------------------
 * Accordion - The wrapper that provides context for all accordion items
 * -----------------------------------------------------------------------------------------------*/

/**
 * The wrapper that provides context and focus management
 * for all accordion items.
 *
 * It wraps all accordion items in a `div` for better grouping.
 * @see Docs https://chakra-ui.com/accordion
 */
export const Accordion = forwardRef(({ children, reduceMotion, ...props }, ref) => {
  const styles = useMultiStyleConfig('Accordion', props)
  const ownProps = omitThemingProps(props)

  const { htmlProps, descendants, ...context } = useAccordion(ownProps)

  const ctx = React.useMemo(
    () => ({ ...context, reduceMotion: !!reduceMotion }),
    [context, reduceMotion]
  )

  return (
    <AccordionDescendantsProvider value={descendants}>
      <AccordionProvider value={ctx}>
        <StylesProvider value={styles}>
          <chakra.div ref={ref} {...htmlProps} className={cx('chakra-accordion', props.className)}>
            {children}
          </chakra.div>
        </StylesProvider>
      </AccordionProvider>
    </AccordionDescendantsProvider>
  )
})

if (__DEV__) {
  Accordion.displayName = 'Accordion'
}

/* -------------------------------------------------------------------------------------------------
 * Accordion Item
 * -----------------------------------------------------------------------------------------------*/

const [AccordionItemProvider, useAccordionItemContext] = createContext({
  name: 'AccordionItemContext',
  errorMessage:
    'useAccordionItemContext: `context` is undefined. Seems you forgot to wrap the accordion item parts in `<AccordionItem />` '
})

/**
 * AccordionItem is a single accordion that provides the open-close
 * behavior when the accordion button is clicked.
 *
 * It also provides context for the accordion button and panel.
 */
export const AccordionItem = forwardRef((props, ref) => {
  const { children, className } = props
  const { htmlProps, ...context } = useAccordionItem(props)

  const styles = useStyles()
  const containerStyles = {
    ...styles.container,
    overflowAnchor: 'none'
  }

  const ctx = React.useMemo(() => context, [context])

  return (
    <AccordionItemProvider value={ctx}>
      <chakra.div
        ref={ref}
        {...htmlProps}
        className={cx('chakra-accordion__item', className)}
        __css={containerStyles}>
        {runIfFn(children, {
          isExpanded: !!context.isOpen,
          isDisabled: !!context.isDisabled
        })}
      </chakra.div>
    </AccordionItemProvider>
  )
})

if (__DEV__) {
  AccordionItem.displayName = 'AccordionItem'
}

/**
 * React hook to get the state and actions of an accordion item
 */
export function useAccordionItemState() {
  const { isOpen, isDisabled, onClose, onOpen } = useAccordionItemContext()
  return { isOpen, onClose, isDisabled, onOpen }
}

/* -------------------------------------------------------------------------------------------------
 * Accordion Item => Button
 * -----------------------------------------------------------------------------------------------*/

/**
 * AccordionButton is used expands and collapses an accordion item.
 * It must be a child of `AccordionItem`.
 *
 * Note 🚨: Each accordion button must be wrapped in an heading tag,
 * that is appropriate for the information architecture of the page.
 */
export const AccordionButton = forwardRef((props, ref) => {
  const { getButtonProps } = useAccordionItemContext()
  const buttonProps = getButtonProps(props, ref)

  const styles = useStyles()
  const buttonStyles = {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    outline: 0,
    ...styles.button
  }

  return (
    <chakra.button
      {...buttonProps}
      className={cx('chakra-accordion__button', props.className)}
      id={props.id}
      __css={buttonStyles}
    />
  )
})

if (__DEV__) {
  AccordionButton.displayName = 'AccordionButton'
}

/* -------------------------------------------------------------------------------------------------
 * Accordion Item => Panel
 * -----------------------------------------------------------------------------------------------*/

/**
 * Accordion panel that holds the content for each accordion.
 * It shows and hides based on the state login from the `AccordionItem`.
 *
 * It uses the `Collapse` component to animate its height.
 */
export const AccordionPanel = forwardRef((props, ref) => {
  const { reduceMotion } = useAccordionContext()
  const { getPanelProps, isOpen } = useAccordionItemContext()

  // remove `hidden` prop, 'coz we're using height animation
  const panelProps = getPanelProps(props, ref)

  const _className = cx('chakra-accordion__panel', props.className)
  const styles = useStyles()

  if (!reduceMotion) {
    delete panelProps.hidden
  }

  const child = <chakra.div {...panelProps} __css={styles.panel} className={_className} />

  if (!reduceMotion) {
    return <Collapse in={isOpen}>{child}</Collapse>
  }

  return child
})

if (__DEV__) {
  AccordionPanel.displayName = 'AccordionPanel'
}

/* -------------------------------------------------------------------------------------------------
 * Accordion Item => Icon
 * -----------------------------------------------------------------------------------------------*/

/**
 * AccordionIcon that gives a visual cue of the open/close state of the accordion item.
 * It rotates `180deg` based on the open/close state.
 */
export const AccordionIcon = (props) => {
  const { size, openPos = '0', closePos = '-90', ...rest } = props
  const { isOpen, isDisabled } = useAccordionItemContext()
  const { reduceMotion } = useAccordionContext()

  const _className = cx('chakra-accordion__icon', props.className)
  const styles = useStyles()

  const iconStyles = {
    opacity: isDisabled ? 0.4 : 1,
    transform: isOpen ? `rotate(${openPos}deg)` : `rotate(${closePos}deg)`,
    transition: reduceMotion ? undefined : 'transform 0.2s',
    transformOrigin: 'center',
    ...styles.icon
  }

  return (
    <Icon
      viewBox="0 0 24 24"
      aria-hidden
      className={_className}
      __css={iconStyles}
      {...rest}
      h={size}
      w={size}>
      <path fill="currentColor" d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
    </Icon>
  )
}

if (__DEV__) {
  AccordionIcon.displayName = 'AccordionIcon'
}
