import get from 'lodash/get'
import includes from 'lodash/includes'
import isNaN from 'lodash/isNaN'
import isNil from 'lodash/isNil'
import isNumber from 'lodash/isNumber'
import isString from 'lodash/isString'
import keys from 'lodash/keys'
import parseInt from 'lodash/parseInt'

import { Breakpoint } from '../constants'

const getSize = (t) => {
  if (isNil(t)) {
    return 0
  }
  if (isNumber(t)) {
    return t
  }
  if (isString(t)) {
    if (includes(keys(Breakpoint), t)) {
      return get(Breakpoint, t)
    }
    if (!isNaN(parseInt(t))) {
      return parseInt(t)
    }
  }
  throw new Error(`Undefined size ${t}`)
}

export { getSize }
