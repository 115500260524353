import { Box, Flex, Link } from '@stars-ecom/shared-atoms-ui'
import capitalize from 'lodash/capitalize'
import get from 'lodash/get'
import React, { memo, useContext } from 'react'

import { WebsiteContext } from '../context'
import { StarBox } from '../rating'

const ProductTitle = (props) => {
  const { product, openDescription, openReviews, order } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex direction="column" order={order} w="100%" mb={{ base: '20px', md: 0 }}>
      <h1
        style={{
          marginTop: '10px',
          fontSize: '22px',
          color: 'rgb(36,42,48)',
          fontWeight: 700,
          fontFamily: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
        }}>
        {product?.name}{' '}
        <span
          style={{
            fontSize: '17px',
            color: '#8b8b8b',
            fontWeight: 400,
            fontFamily: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
          }}>{`- ${capitalize(get(product, 'customFields.searchKeywords.0', '').trim())}`}</span>
      </h1>
      <h2
        style={{
          fontFamily: 'PT Sans, Arial, sans-serif',
          fontSize: '18px',
          color: 'rgb(36,42,48)',
          fontWeight: 700,
          fontStyle: 'italic'
        }}>
        {product?.customFields?.hook}
      </h2>
      <Flex direction={{ base: 'column', md: 'row' }}>
        {websiteContext.showReviews && product?.reviewAvg?.nbReviews > 0 && (
          <Flex direction="row" align="center" justify={{ base: 'flex-start', md: 'center' }}>
            <StarBox rating={product?.reviewAvg?.rate} size={12} gap={2} />
            <Link
              to={`${product?.customFields.urlKey}#reviews`}
              style={{
                textDecoration: 'underline',
                color: '#999999',
                fontSize: '13px',
                fontWeight: 400,
                marginLeft: '5px'
              }}
              target="_self"
              onClick={openReviews}>
              {`(${product?.reviewAvg?.nbReviews} avis)`}
            </Link>
            <Box
              display={{ base: 'none', md: 'block' }}
              style={{
                width: 1,
                height: '24px',
                borderLeft: '1px solid rgb(36,42,48)',
                marginLeft: '10px',
                marginRight: '10px'
              }}
            />
          </Flex>
        )}
        <Link
          to={`${product?.customFields.urlKey}#description`}
          onClick={openDescription}
          target="_self">
          <span
            style={{
              textDecoration: 'underline',
              fontFamily: 'PT Sans, Arial, sans-serif',
              fontSize: '13px',
              color: '#333333'
            }}>
            Descriptif détaillé
          </span>
        </Link>
      </Flex>
    </Flex>
  )
}

export default memo(ProductTitle)
