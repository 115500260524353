import { Box, Flex, Link, Text } from '@stars-ecom/shared-atoms-ui'
import PropTypes from 'prop-types'
import React from 'react'

const Loyalty = (props) => {
  const { reduction, type, price, link, currency } = props
  return (
    reduction > 0 && (
      <Box>
        <Text fontSize="1rem" color="#333" fontWeight="bold">
          -{reduction}
          {type} supplémentaires{' '}
        </Text>
        <Flex>
          <Text fontSize="1rem" color="#333">
            avec la <Link to={link}>carte de fidelité</Link> soit{' '}
          </Text>
          <Text fontWeight="bold">{`${price} ${currency}`}</Text>
        </Flex>
      </Box>
    )
  )
}

Loyalty.propTypes = {
  reduction: PropTypes.number,
  type: PropTypes.string,
  price: PropTypes.number,
  link: PropTypes.link,
  currency: PropTypes.string
}

Loyalty.defaultProps = {
  reduction: 10,
  type: '%',
  price: 999,
  link: 'https://www.teleshopping.fr/fidelite',
  currency: '€'
}

export default Loyalty
