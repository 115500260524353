import { forwardRef } from '@chakra-ui/system'
import React, { memo, useContext } from 'react'

import { Accordion } from '../../disclosure'
import { Flex } from '../../layout'
import { FormContext } from './FormContext'

const FormWrapper = forwardRef((props, ref) => {
  const { children, step, width = '100%', maxWidth = '100%' } = props
  const { formOptions } = useContext(FormContext)

  switch (formOptions?.sectionType) {
    case 'wizardAccordion':
      return (
        <Accordion
          ref={ref}
          index={[step]}
          width={width}
          maxWidth={maxWidth}
          my="20px"
          mr={{ base: 0, md: '20px' }}>
          {children}
        </Accordion>
      )
    default:
      return (
        <Flex direction="column" position="relative" w="100%">
          {children}
        </Flex>
      )
  }
})

export default memo(FormWrapper)
