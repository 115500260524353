import {
  Alert,
  AlertIcon,
  Button,
  CloseButton,
  Flex,
  GatsbyImage,
  Text
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import React, { useContext, useState } from 'react'

import { DataLayerUtils, UserContext, WebsiteContext } from '../context'

const AccountLoyaltyCard = (props) => {
  const { loyaltyCard } = props
  const currentUser = useContext(UserContext)
  const [alert, setAlert] = useState()
  const websiteContext = useContext(WebsiteContext)
  const getLoyaltyCardStatus = (status) => {
    switch (status) {
      case 'VALID':
        return 'Valide'
      case 'EXPIRED':
        return 'Expirée'
      case 'CANCELLED':
        return 'Annulée'
      case 'REFUNDED':
        return 'Remboursée'
      case 'DUPLICATE':
        return 'Doublon'
    }
    return null
  }
  const getColorStatus = (status) => {
    switch (status) {
      case 'VALID':
        return '#14C514'
      case 'EXPIRED':
        return '#F2241D'
    }
    return null
  }
  const LoyaltyItem = ({ label, value, color }) => {
    return (
      <Flex
        direction={{ base: 'column', md: 'row' }}
        width="100%"
        align={{ base: 'left', md: 'center' }}>
        <Text
          width={{ base: '100%', md: '50%' }}
          mb={{ base: '0', md: '40px' }}
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="16px"
          fontWeight={700}
          color="#333333">
          {`${label} :`}
        </Text>
        <Text
          width={{ base: '100%', md: '50%' }}
          mb="40px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="14px"
          color={color ? color : '#333333'}>
          {value}
        </Text>
      </Flex>
    )
  }

  return (
    <Flex width="100%" h={{ md: '100%' }} position="relative" backgroundColor="#ffffff" mb="20px">
      <Flex direction="column" padding={{ base: '15px', md: '40px' }} width="100%">
        <Flex
          direction="row"
          align={{ base: 'center', md: 'flex-start' }}
          mb={{ base: '25px', md: '40px' }}
          gridGap="15px">
          <Flex width={{ base: '134px', md: '119px' }} height="75px">
            <GatsbyImage
              image={loyaltyCard?.featuredAsset?.smallImage?.childImageSharp?.gatsbyImageData}
              loading="eager"
              alt={loyaltyCard?.name}
              title={loyaltyCard?.name}
              width="100%"
              height="100%"
            />
          </Flex>
          <Flex direction="column">
            <Text
              as="h2"
              fontFamily={websiteContext?.fontFamilyNarrow}
              fontSize="26px"
              color="#333333"
              fontWeight={700}
              mb="10px">
              Ma carte de fidélité
            </Text>
            {currentUser?.customFields?.loyaltyStatus ? (
              <Flex direction="column" width="100%">
                <Text fontFamily="PT Sans, Arial, sans-serif" fontSize="14px" color="#333333">
                  Vous avez souscrit à la carte de fidélité.
                </Text>
                <Text
                  mb={{ md: '40px' }}
                  fontFamily="PT Sans, Arial, sans-serif"
                  fontSize="14px"
                  color="#333333">
                  Vous bénéficiez de tous ses avantages.
                </Text>
              </Flex>
            ) : currentUser?.customFields?.loyaltyIsRenew ? (
              <Text
                mb={{ md: '40px' }}
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="14px"
                color="#333333">
                Votre carte est arrivée à échéance, vous ne bénéficiez donc plus des avantages
                fidélité. Profitez vite de l&apos;offre de renouvellement.
              </Text>
            ) : (
              <Text
                mb={{ md: '40px' }}
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="14px"
                color="#333333">
                Vous n&apos;avez pas souscrit à la carte de fidélité.
              </Text>
            )}
          </Flex>
        </Flex>
        {currentUser?.customFields?.loyaltyStatus && (
          <Flex direction="row" width="100%" gridGap="30px">
            <Flex direction="column" width="50%">
              <LoyaltyItem
                label="N° Carte fidélité"
                value={currentUser?.customFields?.loyaltyNumber}
              />
              <LoyaltyItem
                label="Date de début"
                value={dayjs(currentUser?.customFields?.loyaltyStartDate).format('DD MMM YYYY')}
              />
              <LoyaltyItem
                label="Date d'achat"
                value={dayjs(currentUser?.customFields?.loyaltyPurchaseDate).format('DD MMM YYYY')}
              />
            </Flex>
            <Flex direction="column" width="50%">
              <LoyaltyItem
                label="Statut carte"
                color={getColorStatus(currentUser?.customFields?.loyaltyCardStatus)}
                value={getLoyaltyCardStatus(currentUser?.customFields?.loyaltyCardStatus)}
              />
              <LoyaltyItem
                label="Date d'expiration"
                value={dayjs(currentUser?.customFields?.loyaltyEndDate).format('DD MMM YYYY')}
              />
            </Flex>
          </Flex>
        )}
        {alert && (
          <Alert status={alert.status} mb="20px">
            <AlertIcon />
            {alert.message}
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => {
                setAlert(null)
              }}
            />
          </Alert>
        )}
        <Flex direction="row" justify="flex-end" width="100%">
          <Button
            onClick={() => {
              DataLayerUtils.addEvent({
                event: 'qwampEvent',
                eventCat: 'Compte client',
                eventAct: 'Départ avantages carte fid'
              })
              navigate('/fidelite')
            }}
            variant="solid"
            style={{
              borderRadius: 0,
              color: '#ffffff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              fontSize: '14px',
              paddingLeft: '20px',
              paddingRight: '20px',
              fontWeight: 400,
              fontFamily: 'PT Sans,Arial,sans-serif',
              boxShadow: 'none'
            }}
            backgroundColor={websiteContext.mainColor}
            _hover={{ backgroundColor: websiteContext.darkColor }}>
            {currentUser?.customFields?.loyaltyIsRenew
              ? 'Renouveler la carte'
              : 'Découvrez les avantages de la carte'}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default AccountLoyaltyCard
