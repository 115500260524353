import { Box, Flex, GatsbyImage, Picto, SkeletonText, Text } from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isNil from 'lodash/isNil'
import React, { memo, useContext, useEffect, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { priceFormat } from '../utils/price'
import OrderLine from './OrderLine'

dayjs.extend(customParseFormat)

const Order = (props) => {
  const { order, trackings, hash } = props
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)
  const [expanded, setExpanded] = useState(order?.code === hash.substring(1) ? true : false)
  const [details, setDetails] = useState()
  const [isLoading, setIsLoading] = useState(false)
  const getData = async () => {
    setIsLoading(true)
    const result = await apiContext?.AccountApi?.getOrder(order?.customFields?.externalCode)
    if (result) {
      setDetails(result || [])
    }
    setIsLoading(false)
  }

  const getWarrantyExtension = (numPos) => {
    return order?.lines?.filter(
      (ol) => ol?.customFields?.indExt === 1 && ol?.customFields?.assNum == numPos
    )
  }
  useEffect(() => {
    if (expanded && !details) {
      getData()
    }
  }, [expanded])

  return (
    <Flex
      id={order.code}
      width="100%"
      direction="column"
      gridGap={{ base: '2px', md: '5px' }}
      fontFamily={websiteContext?.fontFamily}>
      <Flex
        width="100%"
        direction={{ base: 'column', md: 'row' }}
        align={{ base: 'left', md: 'center' }}
        justify="space-between"
        backgroundColor="#f6f3f1"
        p="20px">
        <Flex direction="column" w="100%" maxW="500px">
          <Box
            w="100%"
            height="100px"
            position="relative"
            h="116px"
            overflowX="auto"
            overflowY="hidden">
            <Flex position="absolute" direction="row" gridGap={{ base: '13px', md: '20px' }}>
              {order?.lines?.map(
                (ol, i) =>
                  ol?.customFields?.indExt !== 1 && (
                    <Flex
                      key={`ol_${order.code}_${i}`}
                      width="100px"
                      height="100px"
                      justify="center"
                      align="center"
                      backgroundColor="#ffffff">
                      {ol?.productVariant?.product?.featuredAsset?.smallImage?.childImageSharp
                        ?.gatsbyImageData ? (
                        <GatsbyImage
                          alt={ol?.productVariant?.name}
                          image={
                            ol?.productVariant?.product?.featuredAsset?.smallImage?.childImageSharp
                              ?.gatsbyImageData
                          }
                        />
                      ) : (
                        ol?.productVariant?.product?.customFields?.externalCode
                      )}
                    </Flex>
                  )
              )}
            </Flex>
          </Box>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            mt="10px"
            fontSize="14px"
            lineHeight="21px"
            color="#333">
            <Flex>
              <Text>{`Commande `}</Text>
              <Text ml="5px" fontWeight={700}>
                {order?.code}
              </Text>{' '}
              <Text ml="5px">{`du ${dayjs(order?.orderPlacedAt).format('DD MMMM YYYY')}`}</Text>
            </Flex>
            <Box>
              <Text ml={{ base: '0', md: '30px' }} mb={{ base: '12px', md: '0' }}>
                Total : {priceFormat(order?.totalWithTax)}
              </Text>
            </Box>
          </Flex>
        </Flex>
        <Flex
          ml="10px"
          direction="row"
          align="center"
          style={{ cursor: 'pointer' }}
          onClick={() => setExpanded(!expanded)}>
          <Text
            fontSize="14px"
            lineHeight="21px"
            fontWeight={700}
            color="#333"
            textTransform={{ base: 'uppercase', md: 'capitalize' }}
            _hover={{ color: websiteContext?.mainColor }}>
            {expanded ? 'Replier' : 'Voir Détails'}
          </Text>
          <Box borderRadius="10px" ml="10px">
            <Picto
              icon={expanded ? 'minusCercle' : 'plusCercle'}
              width="16px"
              height="16px"
              color="#000"
            />
          </Box>
        </Flex>
      </Flex>
      {expanded && (
        <Flex direction="column" width="100%" gridGap="2px">
          <Flex direction="column" width="100%" backgroundColor="#f6f3f1" p="20px">
            <SkeletonText isLoaded={!isLoading && details} noOfLines={6} spacing="18px">
              <Flex direction="row" fontSize="14px">
                <Text fontWeight={700}>Numéro de la commande :</Text>
                <Text ml="5px">{order?.code}</Text>
              </Flex>

              <Flex direction="row" fontSize="14px">
                <Text fontWeight={700}>Numéro du client :</Text>
                <Text ml="5px">{currentUser?.customFields?.externalCode}</Text>
              </Flex>

              <Flex direction="row" fontSize="14px">
                <Text fontWeight={700}>Date de la commande :</Text>
                <Text ml="5px">{dayjs(order?.orderPlacedAt).format('DD MMMM YYYY')}</Text>
              </Flex>

              <Flex direction="row" fontSize="14px">
                <Text fontWeight={700}>Montant total de la commande :</Text>
                <Text ml="5px">{priceFormat(order?.totalWithTax)}</Text>
              </Flex>

              <Flex direction="row" fontSize="14px">
                <Text fontWeight={700}>Dont total frais et services :</Text>
                <Text ml="5px">
                  {priceFormat(
                    details?.taxes ? details?.taxes : 0 + details?.services ? details?.services : 0
                  )}
                </Text>
              </Flex>

              <Flex direction="row" fontSize="14px">
                <Text fontWeight={700}>Mode de règlement :</Text>
                <Text ml="5px">
                  {order?.paymentMode?.trim()
                    ? order?.paymentMode?.trim()
                    : details?.paymentMode?.trim()}
                </Text>
              </Flex>
            </SkeletonText>
          </Flex>
          {order?.lines?.map(
            (ol, index) =>
              ol?.customFields?.indExt !== 1 && (
                <OrderLine
                  key={`ol_${index}`}
                  order={order}
                  orderLine={ol}
                  trackings={trackings}
                  details={details?.lines?.find(
                    (o) => o?.customFields?.numPos === ol?.customFields?.numPos
                  )}
                  isLoading={isLoading || isNil(details)}
                  warrantyExtension={getWarrantyExtension(ol?.customFields?.numPos)}
                />
              )
          )}
        </Flex>
      )}
    </Flex>
  )
}

export default memo(Order)
