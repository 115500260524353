import './mondialRelayMap.css'
import 'leaflet/dist/leaflet.css'

import { Box } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MapContainer, TileLayer } from 'react-leaflet'

const isBrowser = typeof window !== 'undefined'
//import { Icon, LatLng, marker } from 'leaflet'
const Leaflet = isBrowser ? require('leaflet') : null

const MondialRelayMap = (props) => {
  if (isBrowser) {
    const { DivIcon, LatLng, marker } = Leaflet
    const mapRef = useRef()

    const { parcelShopList, selected, selectParcel, position = [48.859, 2.35] } = props

    const [lmap, setLmap] = useState(null)
    const [lmarks, setLmarks] = useState([])
    //const [, setResizeObserver] = useState(null)
    const [parcelSelected, setParcelSelected] = useState(selected)

    useEffect(() => {
      // setResizeObserver(new ResizeObserver( () => {
      //   if (lmap) {
      //     lmap.invalidateSize()
      //   }
      // }).observe(mapRef.current))
    }, [])

    useEffect(() => {
      if (lmap && !isEmpty(parcelShopList)) {
        addMarker(parcelShopList)
      }
    }, [parcelShopList, lmap])

    useEffect(() => {
      onChange(parcelSelected)
    }, [parcelSelected, lmap])

    useEffect(() => {
      setParcelSelected(selected)
    }, [selected])

    const onChange = (newParcelSelected) => {
      if (newParcelSelected) {
        selectParcel(newParcelSelected)
        if (lmap) {
          lmap.setView(
            new LatLng(parseCoords(newParcelSelected.Lat), parseCoords(newParcelSelected.Long)),
            18
          )
        }
      }
    }

    const addMarker = (newMarkers) => {
      lmap.setView(new LatLng(parseCoords(newMarkers[0].Lat), parseCoords(newMarkers[0].Long)), 14)
      lmarks.forEach((mark) => {
        lmap.removeLayer(mark)
      })
      let mrks = []
      newMarkers.forEach((mark, index) => {
        const popup = `<div class="PR-Name">${get(mark, 'Nom')}</div>
        ${get(mark, 'Warning', '')}  <br/>  ${get(mark, 'HoursHtmlTable')}`
        const mIcom = new DivIcon({
          iconSize: [45, 45],
          iconAnchor: [22, 38],
          popupAnchor: [0, -38],
          className: mark.Warning ? 'leaflet-div-icon1' : 'leaflet-div-icon2',
          html: `<div class="leaflet-div-icon-text" >${index + 1}</div>`
        })

        const item = marker([parseCoords(mark.Lat), parseCoords(mark.Long)])
          .setIcon(mIcom)
          .addTo(lmap)
          .bindPopup(popup, {
            minWidth: 292
          })
          .on('click', () => {
            if (!mark.Warning) {
              selectParcel(mark)
              setParcelSelected(mark)
            }
          })
        mrks.push(item)
      })

      setLmarks(mrks)
    }

    const parseCoords = (cord) => cord.replace(',', '.')

    const displayMap = useMemo(
      () => (
        <>
          {typeof window !== 'undefined' && (
            <MapContainer
              style={{
                height: '370px',
                width: '100%'
              }}
              center={position}
              scrollWheelZoom={false}
              whenCreated={setLmap}>
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            </MapContainer>
          )}
        </>
      ),
      []
    )

    return (
      <Box w="100%" height={'450px'} ref={mapRef}>
        {displayMap}
      </Box>
    )
  } else {
    return null
  }
}

export default MondialRelayMap
