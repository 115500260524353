import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import isFunction from 'lodash/isFunction'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'

import { UnorderedList } from '../../dataDisplay'
import { ListItem } from '../../dataDisplay/List'
import { Link } from '../Link'
import BreakView from './BreakView'
import PageView from './PageView'

const PaginationBoxView = (props) => {
  const {
    initialPage = 0,
    forcePage = 0,
    hrefBuilder: _hrefBuilder,
    disableInitialCallback,
    onNextPage,
    onPrevPage,
    onPage
  } = props
  const [selected, setSelected] = useState(initialPage || forcePage || 0)

  useEffect(() => {
    if (typeof initialPage !== 'undefined' && !disableInitialCallback) {
      callCallback(initialPage)
    }
  }, [])

  useEffect(() => {
    if (typeof forcePage !== 'undefined') {
      setSelected(forcePage)
    }
  }, [forcePage])

  const handlePreviousPage = (evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)
    if (selected > 0) {
      if (isFunction(onPrevPage)) {
        onPrevPage()
      }
      handlePageSelected(selected - 1, evt)
    }
  }

  const handleNextPage = (evt) => {
    const { pageCount } = props

    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)
    if (selected < pageCount - 1) {
      if (isFunction(onNextPage)) {
        onNextPage()
      }
      handlePageSelected(selected + 1, evt)
    }
  }

  const handlePageSelected = (newSelected, evt) => {
    if (_hrefBuilder) {
      if (isFunction(onPage)) {
        onPage()
      }
      return true
    } else {
      evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)
    }

    if (selected === newSelected) {
      callActiveCallback(newSelected)
      return
    }
    setSelected(newSelected)
    callCallback(newSelected)
  }

  const getEventListener = (handlerFunction) => {
    const { eventListener } = props
    return {
      [eventListener]: handlerFunction
    }
  }

  const getForwardJump = () => {
    const { pageCount, pageRangeDisplayed } = props

    const forwardJump = selected + pageRangeDisplayed
    return forwardJump >= pageCount ? pageCount - 1 : forwardJump
  }

  const getBackwardJump = () => {
    const { pageRangeDisplayed } = props

    const backwardJump = selected - pageRangeDisplayed
    return backwardJump < 0 ? 0 : backwardJump
  }

  const handleBreakClick = (index, evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false)

    handlePageSelected(selected < index ? getForwardJump() : getBackwardJump(), evt)
  }

  const hrefBuilder = (pageIndex) => {
    if (_hrefBuilder && pageIndex !== selected && pageIndex >= 0 && pageIndex < props.pageCount) {
      return _hrefBuilder(pageIndex + 1)
    }
  }

  const ariaLabelBuilder = (pageIndex) => {
    const isSelected = pageIndex === selected
    if (props.ariaLabelBuilder && pageIndex >= 0 && pageIndex < props.pageCount) {
      let label = props.ariaLabelBuilder(pageIndex + 1, isSelected)
      // DEPRECATED: The extraAriaContext prop was used to add additional context
      // to the aria-label. Users should now use the ariaLabelBuilder instead.
      if (props.extraAriaContext && !isSelected) {
        label = label + ' ' + props.extraAriaContext
      }
      return label
    }
  }

  const callCallback = (selectedItem) => {
    if (typeof props.onPageChange !== 'undefined' && typeof props.onPageChange === 'function') {
      props.onPageChange({ selected: selectedItem })
    }
  }

  const callActiveCallback = (selectedItem) => {
    if (typeof props.onPageActive !== 'undefined' && typeof props.onPageActive === 'function') {
      props.onPageActive({ selected: selectedItem })
    }
  }

  const getPageElement = (index) => {
    const {
      pageClassName,
      pageIndexClassName,
      pageLinkClassName,
      activeClassName,
      activeLinkClassName,
      extraAriaContext,
      pageLabelBuilder,
      color = '#000000'
    } = props

    return (
      <PageView
        key={index}
        color={color}
        pageSelectedHandler={handlePageSelected.bind(null, index)}
        selected={selected === index}
        pageClassName={pageClassName}
        pageIndexClassName={pageIndexClassName}
        pageLinkClassName={pageLinkClassName}
        activeClassName={activeClassName}
        activeLinkClassName={activeLinkClassName}
        extraAriaContext={extraAriaContext}
        href={hrefBuilder(index)}
        ariaLabel={ariaLabelBuilder(index)}
        page={index + 1}
        pageLabelBuilder={pageLabelBuilder}
        getEventListener={getEventListener}
      />
    )
  }

  const pagination = () => {
    const items = []
    const {
      pageRangeDisplayed,
      pageCount,
      marginPagesDisplayed,
      breakLabel,
      breakClassName,
      breakLinkClassName
    } = props

    if (pageCount <= pageRangeDisplayed) {
      for (let index = 0; index < pageCount; index++) {
        items.push(getPageElement(index))
      }
    } else {
      let leftSide = pageRangeDisplayed / 2
      let rightSide = pageRangeDisplayed - leftSide

      // If the selected page index is on the default right side of the pagination,
      // we consider that the new right side is made up of it (= only one break element).
      // If the selected page index is on the default left side of the pagination,
      // we consider that the new left side is made up of it (= only one break element).
      if (selected > pageCount - pageRangeDisplayed / 2) {
        rightSide = pageCount - selected
        leftSide = pageRangeDisplayed - rightSide
      } else if (selected < pageRangeDisplayed / 2) {
        leftSide = selected
        rightSide = pageRangeDisplayed - leftSide
      }

      let index
      let page
      let breakView
      let createPageView = (index) => getPageElement(index)

      for (index = 0; index < pageCount; index++) {
        page = index + 1

        // If the page index is lower than the margin defined,
        // the page has to be displayed on the left side of
        // the pagination.
        if (page <= marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        // If the page index is greater than the page count
        // minus the margin defined, the page has to be
        // displayed on the right side of the pagination.
        if (page > pageCount - marginPagesDisplayed) {
          items.push(createPageView(index))
          continue
        }

        // If the page index is near the selected page index
        // and inside the defined range (pageRangeDisplayed)
        // we have to display it (it will create the center
        // part of the pagination).
        if (index >= selected - leftSide && index <= selected + rightSide) {
          items.push(createPageView(index))
          continue
        }

        // If the page index doesn't meet any of the conditions above,
        // we check if the last item of the current "items" array
        // is a break element. If not, we add a break element, else,
        // we do nothing (because we don't want to display the page).
        if (breakLabel && items[items.length - 1] !== breakView) {
          breakView = (
            <BreakView
              key={index}
              breakLabel={breakLabel}
              breakClassName={breakClassName}
              breakLinkClassName={breakLinkClassName}
              breakHandler={handleBreakClick.bind(null, index)}
              getEventListener={getEventListener}
            />
          )
          items.push(breakView)
        }
      }
    }
    return items
  }

  const {
    disabledClassName,
    pageCount,
    containerClassName,
    previousClassName,
    previousLinkClassName,
    previousAriaLabel,
    prevRel,
    nextClassName,
    nextAriaLabel,
    nextRel
  } = props

  const previousClasses = previousClassName + (selected === 0 ? ` ${disabledClassName}` : '')
  const nextClasses = nextClassName + (selected === pageCount - 1 ? ` ${disabledClassName}` : '')

  const previousAriaDisabled = selected === 0 ? 'true' : 'false'
  const nextAriaDisabled = selected === pageCount - 1 ? 'true' : 'false'

  return (
    <UnorderedList display="inline-block" pl="15px" pr="15px" ml="0" className={containerClassName}>
      <ListItem
        display="inline-block"
        bg="#ffffff"
        border={{ base: 'none', md: '1px solid rgb(231, 233, 238)' }}
        cursor="pointer"
        className={previousClasses}
        onKeyPress={handlePreviousPage}
        {...getEventListener(handlePreviousPage)}>
        <Link
          to={hrefBuilder(selected - 1)}
          tabIndex="0"
          role="button"
          aria-disabled={previousAriaDisabled}
          aria-label={previousAriaLabel}
          rel={prevRel}>
          <ChevronLeftIcon
            h={{ base: 6, md: 8 }}
            w={{ base: 6, md: 8 }}
            marginTop={{ base: '-5px', md: '0' }}
          />
        </Link>
      </ListItem>

      {/* isMobile  */}
      <ListItem
        bg="#ffffff"
        cursor="pointer"
        border={'1px solid rgb(231, 233, 238)'}
        display={{ base: 'inline-block', md: 'none' }}
        className={previousLinkClassName}
        justify="center"
        align="center"
        style={{
          width: 'auto',
          cursor: 'default',
          height: 'auto',
          padding: '13px 30px',
          lineHeight: '20px',
          fontSize: '17px'
        }}>
        <span style={{ color: '#333333' }}>{`${selected + 1}`} </span>
        <span style={{ color: '#999999' }}>{`/ ${pageCount}`}</span>
      </ListItem>
      {
        // Desktop
        pagination()
      }
      <ListItem
        bg="#ffffff"
        cursor="pointer"
        border={{ base: 'none', md: '1px solid rgb(231, 233, 238)' }}
        display="inline-block"
        className={nextClasses}
        onKeyPress={handleNextPage}
        {...getEventListener(handleNextPage)}>
        <Link
          to={hrefBuilder(selected + 1)}
          tabIndex="0"
          role="button"
          aria-disabled={nextAriaDisabled}
          aria-label={nextAriaLabel}
          rel={nextRel}>
          <ChevronRightIcon
            h={{ base: 6, md: 8 }}
            w={{ base: 6, md: 8 }}
            marginTop={{ base: '-5px', md: '0' }}
          />
        </Link>
      </ListItem>
    </UnorderedList>
  )
}

PaginationBoxView.propTypes = {
  pageCount: PropTypes.number.isRequired,
  pageRangeDisplayed: PropTypes.number.isRequired,
  marginPagesDisplayed: PropTypes.number.isRequired,
  previousLabel: PropTypes.node,
  previousAriaLabel: PropTypes.string,
  prevRel: PropTypes.string,
  nextLabel: PropTypes.node,
  nextAriaLabel: PropTypes.string,
  nextRel: PropTypes.string,
  breakLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  hrefBuilder: PropTypes.func,
  onPageChange: PropTypes.func,
  onPageActive: PropTypes.func,
  initialPage: PropTypes.number,
  forcePage: PropTypes.number,
  disableInitialCallback: PropTypes.bool,
  containerClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  pageIndexClassName: PropTypes.string,
  pageLinkClassName: PropTypes.string,
  pageLabelBuilder: PropTypes.func,
  activeClassName: PropTypes.string,
  activeLinkClassName: PropTypes.string,
  previousClassName: PropTypes.string,
  nextClassName: PropTypes.string,
  previousLinkClassName: PropTypes.string,
  nextLinkClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
  breakClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  extraAriaContext: PropTypes.string,
  ariaLabelBuilder: PropTypes.func,
  eventListener: PropTypes.string
}

PaginationBoxView.defaultProps = {
  pageCount: 10,
  pageRangeDisplayed: 2,
  marginPagesDisplayed: 3,
  pageIndexClassName: 'pageIndex',
  activeClassName: 'selected',
  previousLabel: 'Previous',
  previousClassName: 'previous',
  previousAriaLabel: 'Previous page',
  prevRel: 'prev',
  nextLabel: 'Next',
  nextClassName: 'next',
  nextAriaLabel: 'Next page',
  nextRel: 'next',
  breakLabel: '...',
  disabledClassName: 'disabled',
  disableInitialCallback: false,
  pageLabelBuilder: (page) => page,
  eventListener: 'onClick'
}
export default PaginationBoxView
