import {
  BackgroundImage,
  Box,
  Flex,
  Link,
  Picto,
  Text,
  useHasMounted
} from '@stars-ecom/shared-atoms-ui'
import drop from 'lodash/drop'
import head from 'lodash/head'
import take from 'lodash/take'
import React, { memo, useContext } from 'react'

import { WebsiteContext } from '../context'
import ProductThumbnail from './ProductThumbnail'

const ProductMasonryPush = (props) => {
  const { push } = props
  const websiteContext = useContext(WebsiteContext)
  const hasMounted = useHasMounted()

  /*useEffect(() => {
    DataLayerUtils.addPromotionImpression({
      promotionName: push?.title?.replace(/(<([^>]+)>)/ig, ''),
      promotionCreative: push?.backgroundImage?.url,
      promotionPosition: position,
      promotionId: push.id
    })
  }, [])*/

  if (!hasMounted) {
    return <div style={{ display: 'none' }} />
  }

  return (
    <BackgroundImage
      backgroundImage={push?.backgroundImage?.image || null}
      backgroundColor={push?.backgroundColor}
      anchor={`push_${push?.id}`}
      style={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        textAlign: 'center',
        paddingTop: '0px',
        paddingBottom: '0px',
        transition: 'all .4s',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1
      }}>
      <Flex direction="column">
        <Text
          as="h2"
          order={{ md: '0' }}
          fontSize={{ base: '21px', md: '30px' }}
          fontFamily={websiteContext.fontFamily}
          style={{
            position: 'relative',
            paddingTop: '20px',
            width: '100%',
            fontWeight: 400,
            maxWidth: '1000px',
            color: push?.textColor,
            textAlign: 'center',
            lineHeight: 1.5,
            textTransform: 'uppercase',
            margin: 'auto'
          }}
          dangerouslySetInnerHTML={{ __html: push?.title }}></Text>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          order={{ md: '3' }}
          gridGap={{ md: '20px' }}
          width={{ base: '100%', md: '1000px' }}
          p={{ base: '24px 10px 10px', md: 0 }}
          justify="space-between"
          align="flex-start"
          margin="auto"
          marginBottom={{ md: '40px' }}>
          <Flex direction="column" width={{ base: '100%', md: '271px' }}>
            <Text
              as="h3"
              fontSize={{
                base: `${push?.subtitleSize ? push?.subtitleSize : '65px'}`,
                md: '42px'
              }}
              lineHeight={{ base: '65px', md: '42px' }}
              textAlign={{ base: 'center', md: 'left' }}
              mb="10px"
              style={{
                fontFamily: 'Agency FB, sans-serif',
                fontWeight: 700,
                letterSpacing: '5px',
                color: push?.subtitleColor
              }}>
              {push?.subtitle}
            </Text>
            <div
              style={{
                fontFamily: websiteContext.fontFamily,
                fontSize: '13px',
                color: push?.textColor,
                lineHeight: '19px',
                marginBottom: '13px',
                textAlign: 'left'
              }}
              dangerouslySetInnerHTML={{ __html: push?.description }}
            />
          </Flex>
          <Flex
            display={{ base: 'none', md: 'flex' }}
            flexDirection="row"
            wrap="wrap"
            width={{ base: '100%', md: '490px' }}
            justify="space-between"
            gridGap="20px">
            {push?.products
              ?.filter((p, i) => i === 0 || i === 2 || i === 3)
              .map((product, i) => (
                <ProductThumbnail
                  listName={push?.title?.replace(/(<([^>]+)>)/gi, '')}
                  isLarge={i === 0}
                  key={`product_${product?.id}`}
                  product={product}
                  hasBorder={false}
                  position={i + 1}
                />
              ))}
          </Flex>
          <Flex display={{ base: 'flex', md: 'none' }} width="100%" mt="15px">
            <ProductThumbnail
              isLarge={true}
              key={`product_${head(push?.products)?.id}`}
              product={head(push?.products)}
              hasBorder={false}
              listName={push?.title?.replace(/(<([^>]+)>)/gi, '')}
              position={0}
            />
          </Flex>
          <Flex
            display={{ base: 'none', md: 'flex' }}
            flexDirection="column"
            width="235px"
            gridGap="20px">
            {push?.products
              ?.filter((p, i) => i === 1 || i === 4)
              ?.map((product, i) => (
                <ProductThumbnail
                  listName={push?.title?.replace(/(<([^>]+)>)/gi, '')}
                  key={`product_${product?.id}`}
                  product={product}
                  hasBorder={false}
                  position={i + 3}
                />
              ))}
          </Flex>
          <Flex
            direction="row"
            display={{ base: 'flex', md: 'none' }}
            width="100%"
            overflow="hidden"
            overflowX="scroll"
            flexWrap="nowrap"
            gridGap="10px"
            mt="15px">
            {take(drop(push?.products, 1), 4)?.map((product, i) => (
              <Box key={`product_${product?.id}`}>
                <ProductThumbnail
                  listName={push?.title?.replace(/(<([^>]+)>)/gi, '')}
                  product={product}
                  width="235px"
                  hasBorder={false}
                  position={i}
                />
              </Box>
            ))}
          </Flex>
        </Flex>
        {push?.link && push?.moreText && (
          <Flex
            order={{ md: '1' }}
            pb={{ base: '10px', md: '6px' }}
            width="100%"
            m="auto"
            maxWidth="1000px"
            justify="center"
            align="center">
            <Flex
              width="100%"
              maxWidth="1000px"
              justify={{ base: 'space-around', md: 'flex-end' }}
              margin="auto">
              <Link
                to={push?.link}
                target="_self"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  right: '0px'
                }}
                display={{ base: 'none', md: 'flex' }}>
                <Text
                  style={{
                    color: push?.moreTextColor,
                    fontSize: '16px',
                    textTransform: 'none',
                    fontFamily: websiteContext.fontFamily
                  }}
                  _hover={{ textDecoration: 'underline' }}>
                  {push?.moreText}
                </Text>
                <Picto
                  icon="arrowRight"
                  width="6px"
                  height="6px"
                  color={push?.moreTextColor}
                  style={{ marginLeft: '12px', marginTop: '2px' }}
                />
              </Link>
            </Flex>
          </Flex>
        )}
      </Flex>
    </BackgroundImage>
  )
}

export default memo(ProductMasonryPush)