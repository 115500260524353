import 'react-datepicker/dist/react-datepicker.css'
import './datepicker.css'

import fr from 'date-fns/locale/fr'
import React from 'react'
import _DatePicker, { registerLocale, setDefaultLocale } from 'react-datepicker'

import { Input } from './Input'

registerLocale('fr', fr)
setDefaultLocale('fr')

const DatePicker = (props) => {
  const { inputProps = {}, ...rest } = props
  let inputRef
  return (
    <_DatePicker
      locale="fr"
      customInput={<Input {...inputProps} ref={(r) => (inputRef = r)} />}
      customInputRef={inputRef}
      {...rest}
    />
  )
}
export { DatePicker }
