import axios from 'axios'
import get from 'lodash/get'

import { getSession, refreshToken } from '../context/SessionContext'

const initAxios = async () => {
  axios.defaults.headers.common['Content-Type'] = 'application/json'
  axios.defaults.headers.common.Accept = 'application/json'
  axios.defaults.baseURL = `${process.env.GATSBY_API_ENDPOINT}/${process.env.GATSBY_API_STAGE}`
  axios.defaults.withCredentials = true
  axios.interceptors.response.use(
    (response) => {
      const token = get(response, 'headers.auth-token')
      if (token && token.trim().length > 0) {
        axios.defaults.headers.common.Authorization = `Bearer ${token}`
        refreshToken(token)
      }
      return response
    },
    (error) => {
      return Promise.reject(error)
    }
  )
  axios.interceptors.request.use((request) => {
    const token = getSession('token')
    request.headers['X-Cookie'] = document.cookie
    if (token) {
      request.headers.common.Authorization = `Bearer ${token}`
    }
    return request
  })
  return true
}

export { initAxios, axios }
