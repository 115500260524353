import { axios } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'

const getNbViews = async (productId) => {
  try {
    const result = await axios.get(
      `analytics/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/${productId}`
    )
    return get(result, 'data.count', 0)
  } catch (e) {
    console.error(e)
    return {}
  }
}

export { getNbViews }
