import { Picto, Select } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import React, { memo } from 'react'

const ProductVariantSelector = (props) => {
  const { product, productVariant, onChange = () => {}, isClicked, isUpsell = false } = props

  if (isEmpty(get(product, 'variants.0.options', []))) {
    return null
  }

  const variants = product?.variants.filter((variant) => variant.customFields.offers.length > 0)

  return (
    <Select
      placeholder={get(product, 'variants.0.options.0.group.name')}
      className="selectVariant"
      fontFamily="PT Sans, Arial, sans-serif"
      marginTop={isUpsell ? '6px' : '12px'}
      marginBottom={isUpsell ? '6px' : '0'}
      bg="#fff"
      borderRadius="0"
      h={isUpsell ? '30px' : '38px'}
      fontSize={isUpsell ? '11.5px' : '16px'}
      borderColor={!isClicked || (isClicked && productVariant) ? '#858585' : 'red'}
      _hover="#858585"
      borderWidth={isClicked && !productVariant ? '2px' : '1px'}
      _focus={{ borderWidth: isClicked && !productVariant ? '2px' : '1px' }}
      icon={
        productVariant && !isUpsell ? (
          <Picto icon="checkCercleOutline" width="16px" height="16px" color="#39a132" />
        ) : (
          <Picto
            icon="chevronDown"
            width={isUpsell ? '12px' : '16px'}
            height={isUpsell ? '12px' : '16px'}
            color={!isClicked || (isClicked && productVariant) ? '#858585' : 'red'}
          />
        )
      }
      value={productVariant?.id}
      onChange={onChange}>
      {orderBy(variants, [(pv) => pv?.options?.map((o) => o.name).join(' - ')], ['asc'])?.map(
        (pv) => (
          <option key={`product_variant_${pv.id}`} value={pv.id}>
            {pv?.options?.map((o) => o.name).join(' - ')}
          </option>
        )
      )}
    </Select>
  )
}

export default memo(ProductVariantSelector)
