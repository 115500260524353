import { Box, Flex, Image, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import logo from './assets/mr-64.png'
const MondialRelayHeader = ({ headerTitle, children }) => (
  <>
    <Box pt="10px" pb="10px">
      <Text
        fontFamily="PT Sans, Arial, sans-serif"
        textAlign="center"
        color="#333"
        fontSize="19px"
        fontWeight="800"
        width={{ base: '100%', md: 'unset' }}
        maxWidth={{ base: '330px', md: 'unset' }}>
        {headerTitle}
      </Text>
    </Box>
    <Flex flexDirection={{ base: 'column', md: 'row' }}>
      <Box
        py="8px"
        px="10px"
        alignSelf={{ base: 'center', md: 'left' }}
        display={{ base: 'none', md: 'block' }}>
        <Image src={logo} alt="mondial relay" />
      </Box>
      <Box>{children}</Box>
    </Flex>
  </>
)

export default MondialRelayHeader
