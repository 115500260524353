import {
  Alert,
  AlertIcon,
  Box,
  CircularProgress,
  CloseButton,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'

dayjs.extend(customParseFormat)

const OrderReturn = (props) => {
  const { orderReturn } = props
  const [expanded, setExpanded] = useState(false)
  const websiteContext = useContext(WebsiteContext)
  const Decision = () => {
    switch (orderReturn.decision) {
      case 'REFUND':
        return (
          <>
            <Picto icon="checkLight" width="20px" height="20px" color="green" />
            <Text color="green" fontWeight={600} fontSize="14px" lineHeight="15px" ml="10px">
              Remboursé
            </Text>
          </>
        )
      case 'EXCHANGE':
        return (
          <>
            <Picto icon="checkLight" width="20px" height="20px" color="green" />
            <Text color="green" fontWeight={600} fontSize="14px" lineHeight="15px" ml="10px">
              Echangé
            </Text>
          </>
        )
      case 'PROCESSED':
        return (
          <>
            <Picto icon="clock" width="20px" height="20px" color="rgb(255, 162, 0)" />
            <Text
              color="rgb(255, 162, 0)"
              fontWeight={600}
              fontSize="14px"
              lineHeight="15px"
              ml="10px">
              En cours de traitement
            </Text>
          </>
        )
    }
  }
  const getMotive = () => {
    switch (orderReturn.motive) {
      case 'GUARANTEE':
        return 'Je souhaite bénéficier de la garantie satisfait ou remboursé.'
      case 'NOTMATCHING':
        return 'Le produit que j’ai reçu ne correspond pas à l’article que j’ai commandé.'
      case 'INCOMPLETE':
        return 'Le produit que j’ai reçu est incomplet.'
      case 'DEFECTIVE':
        return 'Le produit est en panne ou défectueux.'
      default:
        return 'Non précisé'
    }
  }
  const getCustomerWish = () => {
    switch (orderReturn.wish) {
      case 'REFUND':
        return 'Remboursement'
      case 'SAMEPRODUCT':
        return 'Echange contre le même produit de couleur ou taille différente'
      case 'IDENTICALPRODUCT':
        return 'Echange contre un article identique'
      case 'UNSPECIFIED':
        return 'Non précisé'
    }
  }
  const getStatus = () => {
    switch (orderReturn.status) {
      case 'WAITING':
        return (
          <Flex direction="row" align="center">
            <Picto icon="clock" width="20px" height="20px" color="rgb(255, 162, 0)" />
            <Text
              color="rgb(255, 162, 0)"
              fontWeight={600}
              ml="10px"
              fontSize="12px"
              lineHeight="15px">
              En attente de retour
            </Text>
          </Flex>
        )
      case 'PROGRESS':
        return (
          <Flex direction="row" align="center">
            <Picto
              icon="clock"
              width="20px"
              height="20px"
              fontWeight="100"
              color="rgb(255, 162, 0)"
            />
            <Text
              color="rgb(255, 162, 0)"
              fontWeight={600}
              ml="10px"
              fontSize="11px"
              lineHeight="15px">
              En cours de traitement
            </Text>
          </Flex>
        )
      case 'PAY':
        return (
          <Flex direction="row" align="center">
            <Decision />
          </Flex>
        )

      default:
        return null
    }
  }

  return (
    <Flex
      direction="column"
      w="100%"
      backgroundColor="#f6f3f1"
      p="15px"
      fontFamily={websiteContext?.fontFamily}>
      <Flex direction={{ base: 'column', md: 'row' }} w="100%" fontSize="14px">
        <Text w={{ base: '100%', md: '12%' }} fontWeight={{ base: '300', md: '700' }}>
          {websiteContext?.isMobile && 'Retour N°'} <b>{orderReturn.returnCode}</b>
        </Text>
        <Text w={{ base: '100%', md: '22%' }} fontWeight={{ base: '300', md: '700' }}>
          {websiteContext?.isMobile && 'du'} {dayjs(orderReturn?.date).format('DD MMMM YYYY')}
        </Text>
        <Text w={{ base: '100%', md: '22%' }} fontWeight={{ base: '300', md: '700' }}>
          {websiteContext?.isMobile && 'Commande N°'} <b>{orderReturn.orderCode}</b>
        </Text>
        <Text w={{ base: '100%', md: '22%' }} fontWeight={{ base: '300', md: '700' }}>
          {getStatus()}
        </Text>
        <Flex
          w={{ base: '100%', md: '22%' }}
          direction="row"
          justify={{ base: 'flex-start', md: 'flex-end' }}
          flex="auto"
          mt={{ base: '15px', md: '0' }}
          style={{ cursor: 'pointer' }}
          onClick={() => setExpanded(!expanded)}>
          <Text
            fontSize="14px"
            lineHeight="21px"
            textTransform={{ base: 'uppercase', md: 'initial' }}
            fontWeight={700}
            color="#333"
            _hover={{ color: websiteContext?.mainColor }}>
            {expanded ? 'Voir moins' : 'Voir plus'}
          </Text>
          <Box borderRadius="10px" ml="10px">
            <Picto
              icon={expanded ? 'minusCercle' : 'plusCercle'}
              width="16px"
              height="16px"
              color="#000"
            />
          </Box>
        </Flex>
      </Flex>
      {expanded && (
        <Flex
          direction={{ base: 'column', md: 'row' }}
          w="100%"
          pt="15px"
          mt="10px"
          borderTop="2px solid white">
          <Flex direction={{ base: 'row' }} width={{ base: '100%', md: '35%' }} height="auto">
            <Flex
              key={`ol_${orderReturn?.code}`}
              mr={{ base: '3%', md: '6%' }}
              justify="left"
              align="left"
              pt="5px"
              height="68px"
              backgroundColor="#ffffff">
              {orderReturn?.product?.featuredAsset?.smallImage?.childImageSharp
                ?.gatsbyImageData && (
                <GatsbyImage
                  alt={orderReturn?.product.name}
                  height="auto"
                  style={{ height: '55px', width: '70px' }}
                  image={
                    orderReturn?.product?.featuredAsset?.smallImage?.childImageSharp
                      ?.gatsbyImageData
                  }
                />
              )}
            </Flex>
            <Flex direction="column" maxWidth="90%">
              <Text as="h3" fontWeight="700" fontSize="14px" color="#333" lineHeight="20px">
                {orderReturn?.product?.name}
              </Text>
              <Text as="h3" fontSize="12px" color="#333" lineHeight="20px">
                ref : {orderReturn?.product?.customFields?.externalCode}
              </Text>
              <Text
                fontSize="12px"
                lineHeight="18px"
                color="gray">{`Quantité retournée : ${orderReturn?.quantity}`}</Text>
            </Flex>
          </Flex>
          <Flex direction={{ base: 'row', md: 'column' }} width={{ base: '100%', md: '22%' }}>
            <Text as="h3" fontSize="14px" color="#333" fontWeight={800} lineHeight="20px">
              {` Motif du retour : `}
            </Text>
            <Text
              maxWidth="90%"
              as="h3"
              fontSize="12px"
              color="#333"
              lineHeight="20px"
              ml={{ base: '4px', md: '0px' }}>
              {getMotive()}
            </Text>
          </Flex>
          <Flex direction={{ base: 'row', md: 'column' }} width={{ base: '100%', md: '22%' }}>
            <Text as="h3" fontSize="14px" color="#333" fontWeight={800} lineHeight="20px">
              {` Souhait client : `}
            </Text>
            <Text
              as="h3"
              fontSize="12px"
              color="#333"
              lineHeight="20px"
              maxWidth="90%"
              ml={{ base: '4px', md: '0px' }}>
              {getCustomerWish()}
            </Text>
          </Flex>
          <Flex
            flex="auto"
            direction={{ base: 'row', md: 'column' }}
            mt={{ base: '10px', md: '0px' }}
            cursor="pointer"
            borderRadius="3px">
            <Flex
              justifyContent={{ base: 'center', md: 'flex-end' }}
              width={{ base: '100%', md: 'auto' }}>
              <Flex
                border="2px solid #dedede"
                p="7px"
                pl="10px"
                justifyContent="center"
                pr="20px"
                width={{ base: '100%', md: 'auto' }}
                _hover={{ backgroundColor: 'white' }}>
                <Link
                  to={`/customer/account#${orderReturn.orderCode}`}
                  style={{ display: 'flex', alignItems: 'center' }}>
                  <Picto icon="eye" width="20px" height="20px" />
                  <Text ml="10px" fontSize="12px" fontWeight="700" lineHeight="1.1">
                    Consulter la
                    {!websiteContext?.isMobile && <br />} commande
                  </Text>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      )}
    </Flex>
  )
}

const AccountReturn = () => {
  const [alert, setAlert] = useState()
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const currentUser = useContext(UserContext)
  const [loading, setLoading] = useState(false)

  const [data, setData] = useState([])

  const getData = async () => {
    setLoading(true)
    const result = await apiContext.AccountApi.getReturns()
    if (result) {
      setData(result)
    }
    setLoading(false)
  }

  useEffect(() => {
    getData()
  }, [currentUser])

  return (
    <Flex
      direction="column"
      width="100%"
      h={{ md: '100%' }}
      position="relative"
      backgroundColor="#ffffff">
      <Flex direction="column" w="100%" padding={{ base: '15px', md: '40px' }}>
        <Text
          as="h2"
          fontFamily={websiteContext?.fontFamilyNarrow}
          fontSize="26px"
          borderBottom="1px solid #d3d3d3"
          color="#333333"
          borderBottom={{ base: '1px solid #c1c1c1', md: 'none' }}
          fontWeight={700}
          mb="20px">
          Mes retours produits
        </Text>
        {!loading && isEmpty(data) && (
          <Text mb="40px" fontFamily="PT Sans, Arial, sans-serif" fontSize="14px" color="#333333">
            Vous n&acute;avez effectué aucun retour de produits.
          </Text>
        )}
        {!loading && !isEmpty(data) && (
          <Flex direction="column" w="100%" fontFamily={websiteContext?.fontFamily}>
            {!websiteContext?.isMobile && (
              <Flex direction="row" w="100%" p="15px">
                <Text fontWeight={800} w="12%" fontSize="14px">
                  Numéro
                </Text>

                <Text fontWeight={800} w="22%" fontSize="14px">
                  Date du retour
                </Text>
                <Text fontWeight={800} w="22%" fontSize="14px">
                  N° de commande
                </Text>
                <Text fontWeight={800} w="22%" fontSize="14px">
                  État du retour
                </Text>
              </Flex>
            )}
            {data.map((orderReturn, i) => (
              <OrderReturn key={`order_return${i}`} orderReturn={orderReturn} />
            ))}
          </Flex>
        )}
        {alert && (
          <Alert status={alert.status} mb="20px">
            <AlertIcon />
            {alert.message}
            <CloseButton
              position="absolute"
              right="8px"
              top="8px"
              onClick={() => {
                setAlert(null)
              }}
            />
          </Alert>
        )}
      </Flex>

      {loading && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          justify="center"
          paddingTop="90px"
          style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )}
    </Flex>
  )
}

export default AccountReturn
