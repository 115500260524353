import { useEffect, useLayoutEffect, useState } from 'react'
const useWindowWidth = () => {
  const windowGlobal = typeof window !== 'undefined' && window
  const useSafeLayoutEffect = windowGlobal ? useLayoutEffect : useEffect

  if (windowGlobal) {
    const [width, setWidth] = useState(windowGlobal ? windowGlobal.innerWidth : 0)

    useSafeLayoutEffect(() => {
      const handleResize = () => setWidth(windowGlobal.innerWidth)

      windowGlobal.addEventListener('resize', handleResize)
      return () => windowGlobal.removeEventListener('resize', handleResize)
    }, [windowGlobal])

    return width
  }
}

export default useWindowWidth
