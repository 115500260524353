import { Box, Button, Flex, Input, RadioGroup, Text, Textarea } from '@stars-ecom/shared-atoms-ui'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import set from 'lodash/set'
import React, { memo, useCallback, useContext, useEffect, useRef, useState } from 'react'

import { ApiContext, DataLayerUtils, WebsiteContext } from '../context'
import { FormContext, FormContextProvider } from '../context/FormContext'
import fields from './schema'

const Field = (props) => {
  const { field = {}, onChange = () => {}, origin = {}, object = {}, showAllErrors } = props
  const {
    label,
    type,
    values = [],
    attrKey = '',
    readOnly: _readOnly = false,
    schema,
    required
  } = field
  const fileInput = useRef(null)

  const readOnly = isFunction(_readOnly) ? _readOnly(origin)(object) : _readOnly
  const [errors, setErrors] = useState([])
  const [showError, setShowError] = useState(false)
  const value = get(object, attrKey, null)
  const websiteContext = useContext(WebsiteContext)
  const formContext = useContext(FormContext)

  const validate = async () => {
    try {
      if (schema) {
        await schema(origin)(object).validate(value, { abortEarly: false })
      }
      setErrors([])
    } catch (err) {
      setErrors([...err.errors])
    }
  }

  useEffect(() => {
    if (formContext?.initForm) {
      setShowError(false)
    }
  }, [formContext?.initForm])

  useEffect(() => {
    validate()
  }, [object])

  useEffect(() => {
    if (isArray(formContext?.errors) && isFunction(formContext?.setErrors)) {
      if (isEmpty(errors) && formContext?.errors?.includes(attrKey)) {
        formContext?.setErrors(formContext.errors.filter((e) => e !== attrKey))
      } else if (!isEmpty(errors) && !formContext?.errors?.includes(attrKey)) {
        formContext?.setErrors([...formContext?.errors, attrKey])
      }
    }
  }, [errors, object])

  useEffect(() => {
    if (showAllErrors == true) {
      validate()
      setShowError(true)
    }
  }, [showAllErrors])

  const renderComponent = () => {
    switch (type) {
      case 'Input':
        return (
          <Input
            name={attrKey}
            type="text"
            onChange={(e) => {
              onChange(e.target?.value)
            }}
            onBlur={() => {
              validate()
              setShowError(true)
            }}
            value={value || ''}
            readOnly={readOnly}
            isInvalid={!isEmpty(errors) && showError}
            style={{ fontFamily: websiteContext?.fontFamily }}
            backgroundColor="#FFF"
            border="1px solid"
            width="auto"
            borderColor="#c1c1c1"
          />
        )
      case 'Textarea':
        return (
          <Textarea
            onChange={(e) => {
              onChange(e?.target?.value)
            }}
            onBlur={() => {
              validate()
              setShowError(true)
            }}
            value={value || ''}
            isDisabled={readOnly}
            isInvalid={!isEmpty(errors) && showError}
            rows={10}
            width="auto"
            style={{ fontFamily: websiteContext?.fontFamily }}
            backgroundColor="#FFF"
            border="1px solid"
            borderColor="#c1c1c1"
          />
        )
      case 'File':
        return (
          <Input
            mt="10px"
            type="file"
            fontSize="14px"
            pl="4px"
            ref={fileInput}
            onChange={(e) => {
              onChange(e.target?.files[0])
            }}
            onBlur={() => {
              validate()
              setShowError(true)
            }}
            isDisabled={readOnly}
            isInvalid={!isEmpty(errors) && showError}
            style={{ fontFamily: websiteContext?.fontFamily }}
            border="none"
            _focus={{ boxShadow: 'none' }}
          />
        )
      case 'Radio':
        return (
          <RadioGroup
            defaultValue={value}
            onChange={(v) => {
              onChange(v)
            }}
            readOnly={readOnly}
            options={values}
            color={websiteContext?.mainColor}
          />
        )

      /*<RadioGroup
            mt="5px"
            value={value}
            color={{ base: '#999999', md: '#333' }}
            fontWeight={{ base: 'bold', md: 'normal' }}
            onBlur={() => {
              validate()
              setShowError(true)
            }}
            onChange={(e) => {
              onChange(e)
            }}
            isDisabled={readOnly}
            fontFamily={websiteContext?.fontFamily}>
            <Stack direction={{ base: 'column', md: 'row' }}>
              {values?.map((v, i) => (
                <Radio
                  key={`radio_${i}`}
                  value={v}
                  mr="60px"
                  color="#999"
                  backgroundColor="#FFF"
                  size="md"
                  fontSize="14px !important"
                  isInvalid={!isEmpty(errors) && showError}>
                  {v}
                </Radio>
              ))}
            </Stack>
              </RadioGroup>*/
    }
  }
  return (
    <Flex
      direction="row"
      flexDirection={{ base: 'column', md: 'row' }}
      align="flex-start"
      mb={{ base: '10px', md: '20px' }}
      justifyContent="space-around">
      <Text
        as="label"
        fontFamily={websiteContext?.fontFamily}
        color="#999999"
        fontSize="14px"
        fontWeight={700}
        width="215px"
        mt="10px"
        mb={{ base: '5px', md: '0px' }}
        style={{ textTransform: 'uppercase' }}>
        {`${label || ''}${required ? ' *' : ''}`}
      </Text>
      <Flex direction="column" width={{ base: '100%', md: '510px' }}>
        {renderComponent()}
        {showError &&
          errors?.map((error, i) => (
            <Text
              key={`error_${i}`}
              color="#E53E3E"
              fontSize="11px"
              pl="15px"
              dangerouslySetInnerHTML={{ __html: error }}
            />
          ))}
      </Flex>
    </Flex>
  )
}

const _Section = (props) => {
  const { fields = [], origin, object, onChange = () => {}, showAllErrors } = props

  return (
    <Flex direction="column" width="100%">
      {fields?.map((f, i) => (
        <Field
          key={`field_${i}`}
          field={f}
          origin={origin}
          object={object}
          onChange={onChange(f.attrKey)}
          showAllErrors={showAllErrors}
        />
      ))}
    </Flex>
  )
}

const Section = memo(_Section)

const ContactForm = (props) => {
  const {
    endPoint,
    contactEmail,
    categ,
    nature,
    subCateg,
    setAlert,
    categoryLabel,
    subCategoryLabel,
    categSereneoId
  } = props
  const [data, setData] = useState({ country: process.env.GATSBY_API_COUNTRY })
  const [errors, setErrors] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [initForm, setInitForm] = useState(false)
  const [showAllErrors, setShowAllErrors] = useState(false)
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const windowGlobal = typeof window !== 'undefined' && window

  const handleChange = useCallback(
    (getter, setter) => (attrKey) => async (value) => {
      //debugger
      const copy = cloneDeep(getter)
      set(copy, attrKey, value)
      setter(copy)
    },
    []
  )

  const validate = () => {
    setIsValid(isEmpty(errors) && categ != 0 && subCateg != 0)
  }

  useEffect(() => {
    validate()
  }, [errors, categ, subCateg])

  const reset = () => {
    setData({ country: process.env.GATSBY_API_COUNTRY })
    setInitForm(true)
  }
  const goToTop = () => {
    windowGlobal?.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })

  const getFormData = async () => {
    let base64

    if (data?.attachment) {
      base64 = await toBase64(data?.attachment)
    }
    return {
      canal: 'FormulaireWeb',
      civilite: data?.title ? data?.title : '',
      nom: data?.lastName ? data?.lastName : '',
      prenom: data?.firstName ? data?.firstName : '',
      idClient: data?.client ? data?.client : '',
      email: data?.emailAddress ? data?.emailAddress : '',
      enseigne: process.env.GATSBY_API_CODSOC ? process.env.GATSBY_API_CODSOC : '1001',
      rubrique: categSereneoId ? categSereneoId : '',
      sousrubrique: subCateg ? subCateg : '',
      idCommande: data?.commande ? data?.commande : '',
      batiment: data?.addressLine1 ? data?.addressLine1 : '',
      voie: data?.addressLine2 ? data?.addressLine2 : '',
      lieudit: data?.addressLine3 ? data?.addressLine3 : '',
      codepostal: data?.postalCode ? data?.postalCode : '',
      ville: data?.city ? data?.city : '',
      pays: data?.country ? data?.country : '',
      message: data?.message ? data?.message : '',
      filename: data?.attachment?.name ? data?.attachment?.name : '',
      type: data?.attachment?.type ? data?.attachment?.type : '',
      endPoint: endPoint,
      contactEmail,
      nature: nature,
      fileBase64: base64?.substr(base64?.indexOf(',') + 1),
      webSite: process.env.GATSBY_API_WEBSITE
    }
  }
  const save = async () => {
    validate()
    if (isValid) {
      setLoading(true)
      const formData = await getFormData()
      try {
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Contact',
          eventAct: "Envoi d'un formulaire",
          eventLab: `${categoryLabel} - ${subCategoryLabel}`
        })
        const result = await apiContext.SereneoApi.sendForm(formData)
        if (result.success) {
          setAlert({
            status: 'success',
            message:
              "Votre message a été envoyé avec succès, et nous nous empresserons d'y répondre. Merci."
          })
          reset()
        } else {
          setAlert({
            state: 'error',
            message: "Une erreur s'est produite. Veuillez réessayer plus tard"
          })
        }
      } catch (e) {
        console.error(e)
        setAlert({
          status: 'error',
          message: "Une erreur d'enregistrement s'est produite, veuillez réessayer plus tard "
        })
      } finally {
        setLoading(false)
        goToTop()
      }
    } else {
      setAlert({
        status: 'error',
        message: 'veuillez remplir tous les champs obligatoires'
      })
      setShowAllErrors(true)
      setLoading(false)
      goToTop()
    }
  }
  return (
    <FormContextProvider value={{ errors, setErrors, initForm }}>
      <Box
        mt={{ md: '10px' }}
        mb={{ md: '10px' }}
        padding={{ md: '30px 100px 20px' }}
        backgroundColor={{ base: 'transparent', md: '#FFF' }}>
        <Flex direction="column" gridGap="20px" width="100%" position="relative">
          <Section
            fields={[
              fields.emailAddress,
              fields.client,
              fields.commande,
              fields.civility,
              fields.lastName,
              fields.firstName,
              fields.addressLine1,
              fields.addressLine2,
              fields.addressLine3,
              fields.postalCode,
              fields.city,
              fields.country,
              fields.message,
              fields.attachment
            ]}
            origin={data}
            object={data}
            onChange={handleChange(data, setData)}
            showAllErrors={showAllErrors}
          />

          <Flex direction="row" width="100%" justify="right">
            <Button
              onClick={save}
              width={{ base: '100%', md: 'auto' }}
              variant="solid"
              isLoading={loading}
              style={{
                borderRadius: 0,
                color: '#ffffff',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '38.5px',
                fontSize: '19px',
                paddingLeft: '30px',
                paddingRight: '30px',
                fontWeight: 400,
                fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif',
                boxShadow: 'none'
              }}
              mr={{ md: '22px' }}
              backgroundColor={websiteContext?.mainColor}
              _hover={{ backgroundColor: websiteContext?.lightColor }}>
              Envoyer
            </Button>
          </Flex>
        </Flex>
      </Box>
    </FormContextProvider>
  )
}

export default ContactForm
