import { Flex, Link, Media } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const ProductBlogThumbnail = (props) => {
  const { product, colorBtn } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      style={{
        width: '330px',
        height: '426px',
        padding: '10px',
        backgroundColor: '#ffffff',
        overflow: 'hidden'
      }}>
      <Flex
        direction="column"
        align="center"
        justify="space-between"
        mb="18px"
        style={{ border: '1px solid #e7e7e7', width: '310px', height: '345px', padding: '10px' }}>
        <h3
          style={{
            fontSize: '24px',
            fontWeight: 700,
            fontFamily: 'PT Sans Narrow, Arial, sans-serif',
            textAlign: 'center',
            maxWidth: '100%'
          }}>
          {product?.name}
        </h3>
        <div style={{ width: '288px', height: '230px' }}>
          {product?.featuredAsset && (
            <Link to={`/${product?.customFields?.urlKey}`}>
              <Media
                alt={product?.name}
                asset={product?.featuredAsset}
                style={{ width: '288px', height: '230px' }}
              />
            </Link>
          )}
        </div>
      </Flex>
      <Link
        to={`/${product?.customFields?.urlKey}`}
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        onClick={() =>
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Blog',
            eventAct: 'Départ lien maillage',
            eventLab: `/${product?.customFields?.urlKey}`
          })
        }>
        <Flex
          backgroundColor={colorBtn ? colorBtn : websiteContext?.mainColor}
          align="center"
          justify="center"
          color="#FFFFFF"
          width={{ base: '100%', md: '195px' }}
          height="50px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="16px"
          _hover={{
            textDecoration: 'underline',
            backgroundColor: colorBtn ? colorBtn : websiteContext?.lightColor
          }}>
          Voir le produit
        </Flex>
      </Link>
    </Flex>
  )
}

export default memo(ProductBlogThumbnail)
