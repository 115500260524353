import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import set from 'lodash/set'
import React, { useEffect, useReducer } from 'react'

import { initAxios } from '../network/axios'
import { getCurrentUser } from './ApiContext'
import { updateOrder } from './CartContext'
import { setUser } from './UserContext'

const windowGlobal = typeof window !== 'undefined' && window

const nexus = {}

const resetContext = {
  isConnected: false,
  token: null,
  lastTokenRefreshDate: null
}

const initialState = windowGlobal?.localStorage?.getItem('session')
  ? JSON.parse(windowGlobal?.localStorage?.getItem('session'))
  : resetContext

const sessionReducer = (state, action) => {
  switch (action.type) {
    case 'logout':
      return resetContext
    case 'login':
      return {
        ...state,
        isConnected: true
      }
    case 'refreshToken':
      if (state?.token !== action.token) {
        getCurrentUser()
      }
      return {
        ...state,
        token: action.token,
        lastTokenRefreshDate: new Date()
      }
    default:
      throw new Error('undefined action for sessionContext')
  }
}

const SessionContext = React.createContext(resetContext)

const SessionContextProvider = (props) => {
  const { children } = props
  const [session, dispatch] = useReducer(sessionReducer, initialState)
  const storage = windowGlobal?.localStorage

  useEffect(() => {
    storage?.setItem('session', JSON.stringify(session))
  }, [session])

  useEffect(() => {
    initAxios()
    if (session.token) {
      getCurrentUser()
    }
  }, [session?.token])

  set(nexus, 'getSession', (key) => {
    return get(session, key)
  })

  set(nexus, 'logout', () => dispatch({ type: 'logout' }))

  set(nexus, 'login', () => dispatch({ type: 'login' }))

  set(nexus, 'refreshToken', (token) => dispatch({ type: 'refreshToken', token }))

  return <SessionContext.Provider value={{ session, dispatch }}>{children}</SessionContext.Provider>
}

const getSession = (key) => {
  if (isFunction(nexus.getSession)) {
    return nexus.getSession(key)
  }
}

const sessionLogout = () => {
  if (isFunction(nexus.logout)) {
    nexus.logout()
  }
  if (isFunction(setUser)) {
    setUser(null)
  }
  if (isFunction(updateOrder)) {
    updateOrder({})
  }
  getCurrentUser()
}

const sessionLogin = (user, order) => {
  if (isFunction(nexus.login)) {
    nexus.login()
  }
  if (isFunction(setUser)) {
    setUser(user)
  }
  if (isFunction(updateOrder)) {
    updateOrder(order)
  }
}

const refreshToken = (token) => {
  if (isFunction(nexus.refreshToken)) {
    nexus.refreshToken(token)
  }
}

SessionContextProvider.displayName = 'SessionContextProvider'

export {
  SessionContextProvider,
  SessionContext,
  getSession,
  refreshToken,
  sessionLogout,
  sessionLogin
}
