import { Box, Flex, Link, Picto, Select, Text } from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import React, { memo, useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const windowGlobal = typeof window !== 'undefined' && window

const menuItems = [
  {
    label: 'Mes commandes',
    link: '/customer/account'
  },
  {
    label: 'Mes infos personnelles',
    link: '/customer/account/edit'
  },
  {
    label: 'Mes adresses de livraison',
    link: '/customer/address'
  },
  {
    label: 'Mes cartes bancaires',
    link: '/customer/cards'
  },
  {
    label: 'Mes préférences',
    link: '/customer/newsletter/edit'
  },
  {
    label: 'Gérér ma carte de fidélité',
    link: '/customer/account/fidelity'
  },
  {
    label: 'Mes abonnements',
    link: '/abonnement'
  },
  {
    label: 'Mes retours produits',
    link: '/productreturn/list'
  }
]
const _AccountLink = ({ label, link }) => {
  const websiteContext = useContext(WebsiteContext)
  return (
    <Link
      to={link}
      style={{ color: '#999999' }}
      activeStyle={{ color: websiteContext.mainColor }}
      onClick={() =>
        DataLayerUtils.addEvent({
          event: 'qwampEventLab',
          eventCat: 'Compte client',
          eventAct: 'Navigation rubrique',
          eventLab: link
        })
      }>
      <Flex
        direction="row"
        height="49px"
        width="100%"
        style={{ borderBottom: '1px solid #e3e1df' }}
        align="center"
        justify="space-between"
        pr="10px"
        pl="5px"
        _hover={{ color: websiteContext.mainColor }}>
        <Text
          as="span"
          style={{
            textTransform: 'uppercase',
            fontFamily: websiteContext?.fontFamily,
            fontSize: '14px',
            verticalAlign: 'middle',
            display: 'inline-block',
            fontWeight: 700
          }}>
          {label}
        </Text>
        <Picto icon="chevronRight" color="#999999" width="10px" height="10px" />
      </Flex>
    </Link>
  )
}

const AccountLinkMobile = () => {
  const handleChangeMenu = (e) => {
    navigate(e.target.value)
  }
  return (
    <Box pr="10px" pl="10px" display={{ base: 'block', md: 'none' }}>
      <Select
        backgroundColor="white"
        onChange={handleChangeMenu}
        mb="20px"
        value={windowGlobal?.location?.pathname}>
        {menuItems.map((item, i) => {
          return (
            <option key={`menu_item${i}`} value={item?.link}>
              {item?.label}
            </option>
          )
        })}
      </Select>
    </Box>
  )
}

const AccountLink = memo(_AccountLink)
const AccountMenu = () => {
  return (
    <Flex
      direction="column"
      width="235px"
      style={{ borderTop: '1px solid #e3e1df' }}
      display={{ base: 'none', md: 'flex' }}>
      {menuItems.map((item, i) => (
        <AccountLink key={`menu_item${i}`} label={item?.label} link={item?.link} />
      ))}
    </Flex>
  )
}
const AccountMenuMobile = () => {
  return <AccountLinkMobile />
}

AccountMenu.MENU_ITEMS = menuItems

export { AccountMenu, AccountMenuMobile }
