import { Accordion, Box, Flex } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'

import { UserContext, WebsiteContext } from '../../context'
import OrderPhone from '../../phone/OrderPhone'
import Catalog from './Catalog'
import CountryLinks from './CountryLinks'
import LegalDisclaimer from './LegalDisclaimer'
import Loyalty from './Loyalty'
import Newsletter from './Newsletter'
import Partner from './Partner'
import { Quicklinks, QuicklinksAccordion } from './Quicklinks'
import Reinsurrance from './Reinsurrance'
import ReviewLink from './ReviewLink'
import SocialLinks from './SocialLinks'

const Footer = (props) => {
  const { data } = props
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box mb="30px">
      <OrderPhone desktopHide={true} />
      <Reinsurrance data={data?.reinsurance} />
      <Newsletter
        picto={data?.newsletterPicto}
        title={data?.newsletterSubscribeTitle}
        subTitle={data?.newsletterSubscribeSubTitle}
        buttonText={data?.newsletterButtonText}
        link="/customer/newsletter/subscribe"
      />
      <Flex bg="#F3F4F9" flexDirection="column" align="center">
        <Flex borderBottom={{ md: '1px solid #cfd5e3' }}>
          <Flex
            direction={{ base: 'column', md: 'row' }}
            width={{ base: '100%', md: '1000px' }}
            justifyContent="space-between">
            <Loyalty
              color="#ff8b2b"
              title={data?.loyaltyTitle}
              subTitle={data?.loyaltySubTitle}
              buttonText={data?.loyaltyButtonText}
              link={data?.loyaltyLink}
              loyaltyItems={data?.loyaltyLinks}
              image={data?.loyaltyImage}
            />
            <Flex flexDirection="column" mt="20px">
              {data?.showCatalog && (
                <Catalog
                  title={data?.interactiveCatalogTitle}
                  subTitle={data?.interactiveCatalogSubTitle}
                  link={data?.interactiveCatalogLink}
                  buttonText={data?.interactiveCatalogButtonText}
                  image={data?.interactiveCatalogImage}
                />
              )}
              {data?.showPartner && (
                <Partner
                  title={data?.partnerBrandTitle}
                  description={data?.partnerBrandText}
                  link={data?.partnerBrandLink}
                  picto={data?.partnerBrandPicto}
                />
              )}
            </Flex>
          </Flex>
        </Flex>
        <Flex
          direction={{ base: 'column', md: 'row' }}
          mt="20px"
          mb="20px"
          width="100%"
          maxWidth="1000px"
          justify="space-between">
          <Quicklinks categoryLabel={data?.productTitle} itemsList={data?.productLinks} />
          <Quicklinks categoryLabel={data?.orderTitle} itemsList={data?.orderLinks} />
          <Quicklinks categoryLabel={data?.partnerTitle} itemsList={data?.partnerLinks} />
          <Quicklinks categoryLabel={data?.aboutTitle} itemsList={data?.aboutLinks} />
          <Accordion
            display={{ base: 'block', md: 'none' }}
            allowMultiple={false}
            allowToggle
            width="100%">
            <QuicklinksAccordion
              categoryLabel={data?.productTitle}
              itemsList={data?.productLinks}
            />
            <QuicklinksAccordion categoryLabel={data?.orderTitle} itemsList={data?.orderLinks} />
            <QuicklinksAccordion
              categoryLabel={data?.partnerTitle}
              itemsList={data?.partnerLinks}
            />
            <QuicklinksAccordion categoryLabel={data?.aboutTitle} itemsList={data?.aboutLinks} />
          </Accordion>
          <Flex direction="column" justify="flex-start">
            {data?.showSocialNetworks && ( 
              <SocialLinks socialNetworkstitle={data?.socialNetworkstitle} socialLinks={data?.socialLinks} />
            )}
            {websiteContext?.showReviews && data?.globalRate && (
              <ReviewLink globalRate={data?.globalRate} reviewLink={data.reviewLink} />
            )}
            {!currentUser && <CountryLinks countries={data?.countries} />}
          </Flex>
        </Flex>
      </Flex>
      <LegalDisclaimer
        legalLinks={data?.legalLinks}
        legalMention={data?.legalMention}
        legalMentionLink={data?.legalMentionLink}
        legalMentionImage={data?.legalMentionImage}
      />
    </Box>
  )
}

export default memo(Footer)
