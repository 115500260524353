import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  GatsbyImage,
  Grid,
  GridItem,
  Input,
  Link,
  Select,
  Text,
  Textarea
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import { navigate } from 'gatsby'
import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import includes from 'lodash/includes'
import isArray from 'lodash/isArray'
import isEmpty from 'lodash/isEmpty'
import isFunction from 'lodash/isFunction'
import isNil from 'lodash/isNil'
import set from 'lodash/set'
import unset from 'lodash/unset'
import React, { memo, useCallback, useContext, useEffect, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { FormContext, FormContextProvider } from '../context/FormContext'
import AccountReturnSuccess from './AccountReturnSuccess'
import fields from './schema'

const RetourButton = ({ websiteContext }) => {
  return (
    <Box>
      <Button
        onClick={() => {
          navigate('/customer/account')
        }}
        variant="solid"
        width={{ base: '100%', md: 'auto' }}
        style={{
          borderRadius: 0,
          color: '#ffffff',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height: '40px',
          fontSize: '19px',
          paddingLeft: '30px',
          paddingRight: '30px',
          fontWeight: 400,
          fontFamily: 'PT Sans Narrow, PT Sans, Arial, sans-serif',
          boxShadow: 'none'
        }}
        mr={{ base: '10px', md: '0px' }}
        backgroundColor={websiteContext?.mainColor}
        _hover={{ backgroundColor: websiteContext?.lightColor }}>
        Retour
      </Button>
    </Box>
  )
}

const ProductAlreadyReturned = ({ websiteContext }) => {
  return (
    <Box>
      <Text
        borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
        as="h2"
        style={{
          fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
          fontSize: '26px',
          fontWeight: '700',
          marginBottom: '20px'
        }}>
        Retourner un produit
      </Text>
      <Box
        fontSize="18px"
        fontFamily="PT Sans Narrow, PT Sans,Arial,sans-serif"
        border="1px solid #000"
        mr="10px"
        mb="30px"
        p="15px">
        Ce produit a d&eacute;j&agrave; &eacute;t&eacute; int&eacute;gralement retourn&eacute;.
        <br />
        Vous pouvez suivre l&rsquo;avancement de vos retours sur la page&nbsp;
        <Link to="/productreturn/list" style={{ color: websiteContext?.mainColor }}>
          MES RETOURS PRODUITS
        </Link>
        &nbsp;de votre Espace Client.
      </Box>
      <RetourButton websiteContext={websiteContext} />
    </Box>
  )
}

const ReasonNotEligible = ({ srcPhoneNumber, websiteContext }) => {
  return (
    <Box>
      <Box
        fontSize="18px"
        fontFamily="PT Sans Narrow, PT Sans,Arial,sans-serif"
        border="1px solid #000"
        mr="10px"
        mb="30px"
        p="15px">
        Vous ne trouvez pas votre choix dans notre liste ? <br />
        <br />
        Pour retourner ce produit, nous vous invitons &agrave; contacter notre Service Relation
        Client :<br />- au {srcPhoneNumber} (tarif local) <br />- ou via ce{' '}
        <Link to="/contacts" style={{ color: websiteContext?.mainColor }}>
          formulaire de contact.
        </Link>
        <br />
        Nos charg&eacute;s de client&egrave;le vous expliqueront les modalit&eacute;s de retour et
        vous communiqueront un num&eacute;ro d&rsquo;autorisation de retour.
      </Box>
    </Box>
  )
}

const ReturnCommandObsolete = ({ srcPhoneNumber, websiteContext }) => {
  return (
    <Box>
      <Text
        borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
        as="h2"
        style={{
          fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
          fontSize: '26px',
          fontWeight: '700',
          marginBottom: '20px'
        }}>
        Retourner un produit
      </Text>
      <Box
        fontSize="18px"
        fontFamily="PT Sans Narrow, PT Sans,Arial,sans-serif"
        border="1px solid #000"
        mr="10px"
        mb="30px"
        p="15px">
        Votre commande a plus de deux ans et ne peut faire l’objet d’un retour sur notre site
        internet. <br />
        <br />
        Si l’un de vos produits possède une extension de garantie, nous vous invitons &agrave;
        contacter notre Service Relation Client :<br />- au {srcPhoneNumber} (tarif local) <br />-
        ou via ce{' '}
        <Link to="/contacts" style={{ color: websiteContext?.mainColor }}>
          formulaire de contact.
        </Link>
        <br />
        Nos charg&eacute;s de client&egrave;le vous expliqueront les modalit&eacute;s de retour et
        vous communiqueront un num&eacute;ro d&rsquo;autorisation de retour.
      </Box>
      <RetourButton websiteContext={websiteContext} />
    </Box>
  )
}

const ReturnSuisse = ({ srcPhoneNumber, websiteContext }) => {
  return (
    <Box>
      <Text
        borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
        as="h2"
        style={{
          fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
          fontSize: '26px',
          fontWeight: '700',
          marginBottom: '20px'
        }}>
        Retourner un produit
      </Text>
      <Box
        fontSize="18px"
        fontFamily="PT Sans Narrow, PT Sans,Arial,sans-serif"
        border="1px solid #000"
        mr="10px"
        mb="30px"
        p="15px">
        Pour retourner un produit, nous vous invitons &agrave; contacter notre Service Relation
        Client :<br />- au {srcPhoneNumber} (tarif local) <br />- ou via ce{' '}
        <Link to="/contacts" style={{ color: websiteContext?.mainColor }}>
          formulaire de contact.
        </Link>
        <br />
        Nos charg&eacute;s de client&egrave;le vous expliqueront les modalit&eacute;s de retour et
        vous communiqueront un num&eacute;ro d&rsquo;autorisation de retour.
      </Box>
      <RetourButton websiteContext={websiteContext} />
    </Box>
  )
}
const ReturnProductsExcluded = ({ srcPhoneNumber, websiteContext }) => {
  return (
    <Box>
      <Text
        borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
        as="h2"
        style={{
          fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
          fontSize: '26px',
          fontWeight: '700',
          marginBottom: '20px'
        }}>
        Retourner un produit
      </Text>
      <Box
        fontSize="18px"
        fontFamily="PT Sans Narrow, PT Sans,Arial,sans-serif"
        border="1px solid #000"
        mr="10px"
        mb="30px"
        p="15px">
        Ce produit ne peut faire l&apos;objet d&apos;un retour via notre site internet.
        <br />
        <br />
        Pour le retourner, nous vous invitons &agrave; contacter notre Service Relation Client :
        <br />- au {srcPhoneNumber} (tarif local) <br />- ou via ce{' '}
        <Link to="/contacts" style={{ color: websiteContext?.mainColor }}>
          formulaire de contact.
        </Link>
        <br />
        Nos charg&eacute;s de client&egrave;le vous expliqueront les modalit&eacute;s de retour et
        vous communiqueront un num&eacute;ro d&rsquo;autorisation de retour.
      </Box>
      <RetourButton websiteContext={websiteContext} />
    </Box>
  )
}

const Field = (props) => {
  const {
    field = {},
    onChange = () => {},
    origin = {},
    object = {},
    product,
    udpateQuatity = () => {},
    onUpdateReason = () => {},
    pRWish,
    isUnderWarranty = () => {}
  } = props
  const {
    label,
    placeholder,
    type,
    values = [],
    attrKey = '',
    readOnly: _readOnly = false,
    schema,
    required
  } = field

  const readOnly = isFunction(_readOnly) ? _readOnly(origin)(object) : _readOnly
  const [errors, setErrors] = useState([])
  const [showError, setShowError] = useState(false)
  const MAX_CHARACTER = 500
  const [nbCharacter, setNbCharacter] = useState(MAX_CHARACTER)
  const value = get(object, attrKey, null)
  const websiteContext = useContext(WebsiteContext)
  const formContext = useContext(FormContext)
  const PR_WISH = 'productReturnWish'
  const validate = async () => {
    try {
      if (schema) {
        await schema(origin)(object).validate(value, { abortEarly: false })
      }
      setErrors([])
    } catch (err) {
      setErrors([...err.errors])
    }
  }
  useEffect(() => {
    if (formContext?.initForm) {
      setShowError(false)
    }
  }, [formContext?.initForm])

  useEffect(() => {
    validate()
  }, [object])

  useEffect(() => {
    if (isArray(formContext?.errors) && isFunction(formContext?.setErrors)) {
      if (isEmpty(errors) && formContext?.errors?.includes(attrKey)) {
        formContext?.setErrors(formContext.errors.filter((e) => e !== attrKey))
      } else if (!isEmpty(errors) && !formContext?.errors?.includes(attrKey)) {
        formContext?.setErrors([...formContext?.errors, attrKey])
      }
    }
  }, [errors, object])

  const inputProps = { fontFamily: 'PT Sans, Arial, sans-serif' }

  const renderComponent = () => {
    switch (type) {
      case 'Input':
        return (
          <Input
            name={attrKey}
            type="text"
            onChange={(e) => {
              onChange(e?.target?.value)
            }}
            onBlur={() => {
              validate()
              setShowError(true)
            }}
            value={value || ''}
            readOnly={readOnly}
            isInvalid={!isEmpty(errors) && showError}
            style={inputProps}
            backgroundColor="white"
            border="1px solid"
            width="auto"
            borderColor="#c1c1c1"
          />
        )
      case 'Textarea':
        return (
          <Box>
            <Textarea
              onChange={(e) => {
                onChange(e?.target?.value)
                setNbCharacter(MAX_CHARACTER - e.target.value.length)
              }}
              onBlur={() => {
                validate()
                setShowError(true)
              }}
              value={value || ''}
              isDisabled={readOnly}
              mt="7px"
              isInvalid={!isEmpty(errors) && showError}
              rows={formContext?.mobileDevice ? 5 : 10}
              width="100%"
              style={inputProps}
              backgroundColor="white"
              border="1px solid"
              borderColor="#c1c1c1"
              placeholder={placeholder}
            />
            <Text
              style={{
                border: '1px solid #c1c1c1',
                borderRadius: '0 0 5px 5px',
                background: '#f6f3f1',
                padding: '5px 15px 3px 15px',
                marginBottom: '10px',
                marginTop: '-4px',
                fontSize: '11px',
                textAlign: 'right'
              }}>
              {`${nbCharacter} caractères restants `}
            </Text>
            <Text as="span" fontSize="12px" lineHeight="18px">
              En l&apos;absence de commentaires significatifs, votre retour de produits ne pourra
              pas être validé par notre Service Clientèle.
            </Text>
          </Box>
        )
      case 'Checkbox':
        return (
          <Flex direction="row" width="100%" align="center" pt="20px" mb="20px">
            <Checkbox
              isDisabled={false}
              value={product?.productVariant?.product?.customFields?.externalCode}
              //faut penser un mettre un checker car le ischecked doit avoir un state qui pourra se mettre a jour on check
              isChecked={true}
              onChange={(e) => {
                onChange(e?.target?.value)
              }}
              backgroundColorChecked={websiteContext?.mainColor}
            />
            <Text
              ml="10px"
              mt="-5px"
              fontFamily="PT Sans, Arial, sans-serif"
              fontSize="16px"
              fontWeight={500}>
              {product?.productVariant?.product?.name}
            </Text>
            {product?.quantity > 1 && (
              <Select
                width="auto"
                onChange={(e) => {
                  udpateQuatity(e?.target?.value)
                }}
                ml="15px"
                style={inputProps}>
                {[...Array(product?.quantity).keys()].map((x) => {
                  return (
                    <option value={x + 1} key={x}>
                      {x + 1}
                    </option>
                  )
                })}
              </Select>
            )}
          </Flex>
        )
      case 'Select':
        return (
          <Select
            placeholder={placeholder}
            colorScheme="blue"
            width="100%"
            onChange={(e) => {
              onChange(e?.target?.value)
              if (attrKey === 'reason') {
                onUpdateReason(e?.target?.value)
              }
            }}
            onBlur={() => {
              validate()
              setShowError(true)
            }}
            isDisabled={readOnly}
            isInvalid={!isEmpty(errors) && showError}
            style={inputProps}>
            {attrKey === 'reason' &&
              Object.keys(isUnderWarranty(values, 'R01')).map((key) => {
                return (
                  <option key={`option_${key}`} value={key}>
                    {values[key]}
                  </option>
                )
              })}

            {attrKey === PR_WISH &&
              !isNil(values[pRWish]) &&
              Object.keys(values[pRWish]).map((key) => {
                const displayWish =
                  (product?.productVariant?.product?.variants?.length > 0 && key !== 'ECM') ||
                  (!product?.productVariant?.product?.variants && key !== 'ECA') ||
                  pRWish === 'R08'
                return (
                  <>
                    {displayWish && (
                      <option key={`optionV_${key}`} value={key}>
                        {values[pRWish][key]}
                      </option>
                    )}
                  </>
                )
              })}
          </Select>
        )
    }
  }
  return (
    <Flex
      direction="column"
      display={
        (attrKey === 'productReturnWish' && !!formContext?.rsNotEligible) ||
        (attrKey === 'comment' && (!!formContext?.rsNotEligible || !!formContext?.wshNotEligible))
          ? 'none'
          : 'block'
      }
      align="flex-start"
      mb={{ base: '10px', md: '5px' }}
      justifyContent="space-around">
      <Text
        as="label"
        fontFamily={websiteContext?.fontFamily}
        color="#333"
        fontSize="14px"
        fontWeight={700}
        lineHeight="21px"
        mt="10px"
        mb={{ base: '5px', md: '10px' }}>
        {`${label || ''}${required ? ' *' : ''}`}
      </Text>
      <Flex direction="column" width="100%">
        {renderComponent()}
        {showError &&
          errors?.map((error, i) => (
            <Text
              key={`error_${i}`}
              color="red"
              fontSize="11px"
              pl="15px"
              dangerouslySetInnerHTML={{ __html: error }}
            />
          ))}
      </Flex>
    </Flex>
  )
}
const _Section = (props) => {
  const {
    fields = [],
    origin,
    object,
    product,
    onChange = () => {},
    udpateQuatity = () => {},
    onUpdateReason = () => {},
    showError,
    pRWish,
    isUnderWarranty = () => {}
  } = props

  return (
    <Flex direction="column">
      {fields?.map((f, i) => (
        <Field
          key={`field_${i}`}
          field={f}
          origin={origin}
          object={object}
          onChange={onChange(f.attrKey)}
          showError={showError}
          onUpdateReason={onUpdateReason}
          pRWish={pRWish}
          product={product}
          udpateQuatity={udpateQuatity}
          isUnderWarranty={isUnderWarranty}
        />
      ))}
    </Flex>
  )
}

const Section = memo(_Section)
const AccountReturnForm = (props) => {
  const { order, returnDetails, store, urlWebsite } = props
  const initialData = {
    quantity: '1',
    sku: order?.orderLine?.productVariant?.product?.customFields?.externalCode
  }

  const [data, setData] = useState(initialData)
  const [screenToDisplay, setScreenToDisplay] = useState('form')

  const [rsNotEligible, setRsNotEligible] = useState(false)
  const [wshNotEligible, setWshNotEligible] = useState(false)

  const [errors, setErrors] = useState([])
  const [isValid, setIsValid] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingProducts, setLoadingProducts] = useState(false)
  const [initForm, setInitForm] = useState(false)
  const websiteContext = useContext(WebsiteContext)
  const [success, setSuccess] = useState(false)
  const apiContext = useContext(ApiContext)
  const [pRWish, setPRWISH] = useState()
  const [previewMode, setPreviewMode] = useState(false)
  const windowGlobal = typeof window !== 'undefined' && window
  const srcPhoneNumber = returnDetails?.find((detail) => detail?.key === 'srcPhoneNumber')
  const currentUser = useContext(UserContext)
  useEffect(() => {
    const minPrice = getItem('minPrice') ? getItem('minPrice') * 100 : 6000
    if (
      order?.details?.customFields?.isProductAlreadyReturned ||
      order?.details?.customFields?.returnableQuantity < 1
    ) {
      setScreenToDisplay('returned')
    } else if (
      order?.details?.customFields?.isOrderReturnObsolete ||
      dayjs().diff(dayjs(order?.order?.orderPlacedAt), 'year') >= 2
    ) {
      setScreenToDisplay('obsolete')
    } else if (
      order?.details?.customFields?.isReturnExclude ||
      order?.orderLine?.linePriceWithTax +
        order?.details?.customFields?.ecopart / order?.details?.quantity <=
        minPrice
    ) {
      setScreenToDisplay('exclude')
    } else if (
      order?.details?.customFields?.isClientSupport ||
      process.env.GATSBY_API_CHANNEL === 'suisse'
    ) {
      setScreenToDisplay('suisse')
    }
  }, [])
  const handleChange = useCallback(
    (getter, setter) => (attrKey) => async (value) => {
      //debugger
      if (attrKey === 'reason') {
        if (value === 'other') {
          setRsNotEligible(true)
        } else {
          setRsNotEligible(false)
        }
      }
      if (attrKey === 'productReturnWish') {
        if (value === 'other') {
          setWshNotEligible(true)
        } else {
          setWshNotEligible(false)
        }
      }
      const copy = cloneDeep(getter)
      set(copy, attrKey, value)
      setter(copy)
    },
    []
  )
  const isPaidByUser = () => {
    return includes(['R02', 'R07', 'R08'], data?.reason)
  }
  const wishToExchange = () => {
    return includes(['SAV', 'ECA', 'ECM', 'REE'], data?.productReturnWish)
  }
  //conditions satisfait ou remboursé
  const isUnderWarranty = (values, key) => {
    const hoursDiff = dayjs().diff(dayjs(order?.details?.customFields?.validateDate), 'hour')
    const daysDiff = Math.floor(hoursDiff / 24)
    if (currentUser?.customFields?.loyaltyStatus) {
      if (daysDiff <= 95) {
        return values
      }
    } else if (daysDiff <= 45) {
      return values
    }
    unset(values, key)
    return values
  }
  const updateQuantity = (value) => {
    setData({ ...data, quantity: value })
  }
  const updatePRWish = (reason) => {
    setPRWISH(reason)
  }
  const validate = () => {
    setIsValid(isEmpty(errors))
  }

  useEffect(() => {
    validate()
  }, [errors])

  const goToTop = () => {
    windowGlobal?.scrollTo({ top: 0, behavior: 'smooth' })
  }
  const preview = () => {
    validate()
    if (isValid) {
      setPreviewMode(true)
      goToTop()
    }
  }
  const getItem = (key) => {
    return returnDetails?.find((param) => param?.key === key)?.value
  }
  const save = async () => {
    setLoadingProducts(true)
    const request = {
      orderExternalCode: order?.customFields?.externalCode,
      reason: data?.reason,
      wish: data?.productReturnWish,
      freetext: data?.comment,
      productReturns: [
        {
          quantity: data?.quantity,
          productExternalCode: data?.sku
        }
      ]
    }

    const formEmail = {
      civility: currentUser?.title,
      CustomerName: currentUser?.lastName,
      CustomerFirstName: currentUser?.firstName,
      CustomerEmail: currentUser?.emailAddress,
      productName: order?.orderLine?.productVariant?.product?.name,
      isPaidByTSH: !isPaidByUser(),
      stickerSendMail: getItem('stickerSendMail'),
      storeName: store,
      returnProductSociety: getItem('returnProductSociety'),
      returnNumbers: order?.order?.code,
      returnProductClient: getItem('returnProductClient'),
      adrEntrepot: getItem('adresseStore'),
      wishToExchange: wishToExchange(),
      exchange: getItem('exchange'),
      refund: getItem('refund')
    }
    try {
      await apiContext.AccountApi.createReturn(request, formEmail)
      setSuccess(true)
      goToTop()
    } catch (err) {
      console.log(err)
    }
  }
  const displayForm = () => {
    setData(initialData)
    validate()
    setPreviewMode(false)
  }
  const PreviewTemplate = ({ websiteContext }) => {
    return (
      <>
        <Text
          borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
          as="h2"
          style={{
            fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
            fontSize: '26px',
            fontWeight: '700',
            marginBottom: '20px'
          }}>
          Récaptitulatif de la demande de retour
        </Text>
        <Box>
          {!websiteContext?.isMobile && (
            <Grid
              fontSize="14px"
              gap={15}
              templateColumns="repeat(3, 1fr)"
              color="#333"
              textTransform="none"
              padding="10px 10px"
              fontWeight={700}>
              <GridItem w="100%">Produit concerné par le retour</GridItem>
              <GridItem w="100%">Motif du retour</GridItem>
              <GridItem w="100%">Souhait du client</GridItem>
            </Grid>
          )}
          <Grid
            background="#f6f3f1"
            gap={15}
            templateColumns={websiteContext?.isMobile ? '' : 'repeat(3, 1fr)'}
            fontSize="13px"
            padding="20px 10px">
            <GridItem>
              <Flex>
                {order?.orderLine?.productVariant?.product?.featuredAsset?.smallImage
                  ?.childImageSharp?.gatsbyImageData && (
                  <Box width="80px" height="70px">
                    <GatsbyImage
                      alt={order?.orderLine?.productVariant?.name}
                      image={
                        order?.orderLine?.productVariant?.product?.featuredAsset?.smallImage
                          ?.childImageSharp?.gatsbyImageData
                      }
                      style={{
                        marginRight: '10px'
                      }}
                    />
                  </Box>
                )}
                <Box>
                  <Text fontSize="13px" fontWeight="700">
                    {order?.orderLine?.productVariant?.product?.name}
                  </Text>
                  <Text>Quantité retournée : {data?.quantity}</Text>
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              {websiteContext?.isMobile && <b>Motif : </b>}
              {fields.reason?.values[data?.reason]}
            </GridItem>
            <GridItem>
              {websiteContext?.isMobile && <b>Souhait : </b>}
              {fields.productReturnWish?.values[data?.reason][data?.productReturnWish]}
            </GridItem>
          </Grid>
        </Box>
        <Flex width="100%" justify="space-between" mt="40px">
          <Button
            onClick={() => displayForm()}
            variant="solid"
            width={{ base: '100%', md: 'auto' }}
            style={{
              borderRadius: 0,
              color: '#ffffff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              fontSize: '19px',
              paddingLeft: '30px',
              paddingRight: '30px',
              fontWeight: 400,
              fontFamily: 'PT Sans Narrow, PT Sans, Arial, sans-serif',
              boxShadow: 'none'
            }}
            mr={{ base: '10px', md: '0px' }}
            backgroundColor="#c1c1c1"
            _hover={{ backgroundColor: '#c1c1c1' }}>
            Retour
          </Button>
          <Button
            onClick={save}
            width={{ base: '100%', md: 'auto' }}
            variant="solid"
            isLoading={loadingProducts}
            ml={{ base: '10px', md: '0px' }}
            style={{
              borderRadius: 0,
              color: '#ffffff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '40px',
              fontSize: '19px',
              paddingLeft: '30px',
              paddingRight: '30px',
              fontWeight: 400,
              fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif',
              boxShadow: 'none'
            }}
            disabled={!isValid}
            backgroundColor={websiteContext.mainColor}
            _hover={{ backgroundColor: websiteContext.darkColor }}>
            Confirmer ma demande
          </Button>
        </Flex>
      </>
    )
  }

  return (
    <Box
      width={{ base: '100%', md: '745px' }}
      padding={{ base: '20px', md: '0px' }}
      backgroundColor={{ base: '#fff', md: 'transparent' }}>
      <Box
        mb={{ base: '0px', md: '20px' }}
        padding={{ base: '0px', md: '40px' }}
        backgroundColor={{ base: 'transparent', md: 'white' }}>
        {screenToDisplay === 'obsolete' && (
          <ReturnCommandObsolete
            srcPhoneNumber={srcPhoneNumber?.value}
            websiteContext={websiteContext}
          />
        )}
        {screenToDisplay == 'exclude' && (
          <ReturnProductsExcluded
            srcPhoneNumber={srcPhoneNumber?.value}
            websiteContext={websiteContext}
          />
        )}
        {screenToDisplay == 'suisse' && (
          <ReturnSuisse srcPhoneNumber={srcPhoneNumber?.value} websiteContext={websiteContext} />
        )}
        {screenToDisplay == 'returned' && (
          <ProductAlreadyReturned
            srcPhoneNumber={srcPhoneNumber?.value}
            websiteContext={websiteContext}
          />
        )}
        {!previewMode && !success && screenToDisplay == 'form' && (
          <FormContextProvider
            value={{
              errors,
              setErrors,
              initForm,
              mobileDevice: websiteContext?.isMobile,
              rsNotEligible,
              wshNotEligible
            }}>
            <Text
              borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
              as="h2"
              style={{
                fontFamily: 'PT Sans Narrow ,PT Sans ,Arial,sans-serif',
                fontSize: '26px',
                fontWeight: '700',
                marginBottom: '20px'
              }}>
              Retourner un produit
            </Text>

            <Flex direction="column" gridGap="20px" width="100%" position="relative">
              <Section
                fields={[fields.product, fields.reason, fields.productReturnWish, fields.comment]}
                origin={data}
                object={data}
                onChange={handleChange(data, setData)}
                onUpdateReason={updatePRWish}
                showError={false}
                product={order?.orderLine}
                udpateQuatity={updateQuantity}
                pRWish={pRWish}
                isUnderWarranty={isUnderWarranty}
              />
              {(rsNotEligible || wshNotEligible) && (
                <ReasonNotEligible srcPhoneNumber={srcPhoneNumber?.value} />
              )}

              <Flex width="100%" justify="space-between" flex>
                <Button
                  onClick={() => {
                    navigate('/customer/account')
                  }}
                  variant="solid"
                  width={{ base: '100%', md: 'auto' }}
                  style={{
                    borderRadius: 0,
                    color: '#ffffff',
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '40px',
                    fontSize: '19px',
                    paddingLeft: '30px',
                    paddingRight: '30px',
                    fontWeight: 400,
                    fontFamily: 'PT Sans Narrow, PT Sans, Arial, sans-serif',
                    boxShadow: 'none'
                  }}
                  mr={{ base: '10px', md: '0px' }}
                  backgroundColor="#c1c1c1"
                  _hover={{ backgroundColor: '#c1c1c1' }}>
                  Retour
                </Button>
                {!rsNotEligible && !wshNotEligible && (
                  <Button
                    onClick={preview}
                    width={{ base: '100%', md: 'auto' }}
                    variant="solid"
                    ml={{ base: '10px', md: '0px' }}
                    style={{
                      borderRadius: 0,
                      color: '#ffffff',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'center',
                      height: '40px',
                      fontSize: '19px',
                      paddingLeft: '30px',
                      paddingRight: '30px',
                      fontWeight: 400,
                      fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif',
                      boxShadow: 'none'
                    }}
                    disabled={!isValid}
                    backgroundColor={websiteContext.mainColor}
                    _hover={{ backgroundColor: websiteContext.darkColor }}>
                    Poursuivre
                  </Button>
                )}
              </Flex>
            </Flex>
          </FormContextProvider>
        )}
        {previewMode && !success && <PreviewTemplate websiteContext={websiteContext} />}
        {loading && (
          <Flex
            position="absolute"
            width="100%"
            height="100%"
            justify="center"
            paddingTop="90px"
            style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
            <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
          </Flex>
        )}
        {success && (
          <AccountReturnSuccess
            websiteContext={websiteContext}
            returnDetails={returnDetails}
            urlCommerciale={urlWebsite}
            productName={order?.orderLine?.productVariant?.product?.name}
            isPaidByUser={() => isPaidByUser()}
            wishToExchange={() => wishToExchange()}
            storeName={store}
            ReturnNumbers={order?.order?.code}
          />
        )}
      </Box>
    </Box>
  )
}

export default AccountReturnForm
