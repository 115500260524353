import {
  BackgroundImage,
  Flex,
  Link,
  Picto,
  Text,
  useHasMounted
} from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { DataLayerUtils } from '../context'
import ProductSlide from './ProductSlide'

const ProductCarouselPush = (props) => {
  const { push } = props

  const hasMounted = useHasMounted()

  /* useEffect(() => {
    DataLayerUtils.addPromotionImpression({
      promotionName: push?.title?.replace(/(<([^>]+)>)/gi, ''),
      promotionCreative: push?.backgroundImage?.url,
      promotionPosition: position,
      promotionId: push.id
    })
  }, []) */

  const dataLayerFunction = (event = {}) => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Homepage',
      eventAct: `Défiler slider ${push?.title?.replace(/(<([^>]+)>)/gi, '')}`,
      ...event
    })
  }

  if (!hasMounted) {
    return <div style={{ display: 'none' }} />
  }

  if (push?.products?.length > 0) {
    return (
      <BackgroundImage
        backgroundImage={push?.backgroundImage?.image || null}
        backgroundColor={push?.backgroundColor || '#EEF4FF'}
        anchor={`push_${push?.id}`}
        style={{
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          textAlign: 'center',
          paddingTop: '0',
          paddingBottom: '0',
          transition: 'all .4s',
          display: 'flex',
          flexDirection: 'column',
          position: 'relative',
          zIndex: 0
        }}>
        <Flex direction="column">
          <Text
            as="h2"
            fontSize={{ base: '21px', md: '30px' }}
            fontFamily="PT Sans, Arial, sans-serif"
            pl={{ base: '20px', md: 0 }}
            pr={{ base: '20px', md: 0 }}
            style={{
              position: 'relative',
              paddingTop: '30px',
              width: '100%',
              fontWeight: 400,
              maxWidth: push?.nbProductsVisible * 245 + 40 || 980,
              color: push?.textColor,
              textAlign: 'center',
              lineHeight: 1.5,
              textTransform: 'uppercase',
              margin: 'auto',
              marginBottom: '20px'
            }}
            dangerouslySetInnerHTML={{ __html: push?.title }}
          />
          {push?.link && push?.moreText && (
            <Flex
              position={{ base: 'absolute', md: 'initial' }}
              bottom="0"
              pb={{ base: '10px', md: '6px' }}
              display="block"
              width="100%"
              justify="center"
              align="center">
              <Flex
                w={{ base: '100%', md: push?.nbProductsVisible * 245 + 40 || 980 }}
                position="relative"
                top={{ md: '-6px' }}
                h={{ md: '0' }}
                justify={{ base: 'center', md: 'flex-end' }}
                margin="auto">
                <Link
                  to={push?.link}
                  target="_self"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    right: '0px'
                  }}
                  display={{ base: 'none', md: 'flex' }}>
                  <Text
                    fontFamily="PT Sans, Arial, sans-serif"
                    style={{ color: push?.moreTextColor, fontSize: '16px', textTransform: 'none' }}
                    _hover={{ textDecoration: 'underline' }}>
                    {push?.moreText}
                  </Text>
                  <Picto
                    icon="arrowRight"
                    width="6px"
                    height="6px"
                    color={push?.moreTextColor}
                    style={{ marginLeft: '8px', marginTop: '2px' }}
                  />
                </Link>
              </Flex>
            </Flex>
          )}
        </Flex>
        <ProductSlide
          listName={push?.title?.replace(/(<([^>]+)>)/gi, '')}
          products={push?.products}
          arrowColor={push?.moreTextColor ? push?.moreTextColor : '#fffff'}
          maxSlidesToShow={push?.nbProductsVisible || 4}
          dataLayerFunction={dataLayerFunction}
        />
        <Flex height={{ base: '40px', md: '20px' }} />
      </BackgroundImage>
    )
  }
  return null
}

export default memo(ProductCarouselPush)
