import { Flex, GatsbyImage, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { WebsiteContext } from '../context'

const type = {
  DEFAULT: 'DEFAULT',
  IMG_ONLY: 'IMG_ONLY'
}

const Logo = (props) => {
  const { isCheckout = false, isHome = false } = props
  const websiteContext = useContext(WebsiteContext)

  const Hook = () => {
    return (
      <Flex
        flexDirection="column"
        display={{ base: isCheckout ? 'flex' : 'none', md: isCheckout ? 'none' : 'flex' }}
        align={{ base: isCheckout ? 'center' : 'flex-start', md: 'flex-start' }}
        ml={{ base: isCheckout ? 0 : '10px', md: '10px' }}
        mt={{ base: isCheckout ? '10px' : 0, md: 0 }}
        lineHeight="1">
        {websiteContext?.subtitle1 && !isCheckout && (
          <Text color={websiteContext.mainColor} fontWeight={700} fontSize="14px" as="span">
            {websiteContext?.subtitle1}
          </Text>
        )}
        {websiteContext?.subtitle2 && !isCheckout && (
          <Text color="rgb(51,51,51)" fontWeight={400} fontSize="14px" as="span">
            {websiteContext?.subtitle2}
          </Text>
        )}
      </Flex>
    )
  }

  const HookWrapper = () => {
    if (isHome) {
      return (
        <h1>
          <Hook />
        </h1>
      )
    }
    return <Hook />
  }
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      alignItems="center"
      justify="flex-start"
      w={{ base: 'auto', md: '245px' }}
      h="auto">
        <Flex maxWidth="85px" w={{ base: 'auto', md: '94px' }} height={{ base: '39px', md: 'auto' }}>
          <GatsbyImage
            alt={websiteContext?.title}
            image={websiteContext?.logo?.image?.childImageSharp?.gatsbyImageData}
            layout="fixed"
            placeholder="blurred"
            objectFit="contain"
          />
        </Flex>
      <HookWrapper />
    </Flex>
  )
}

Logo.type = type

export default Logo
