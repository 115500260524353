import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import reverse from 'lodash/reverse'
import set from 'lodash/set'

const getExpiredCards = (cards, delay = 1) => {
  let expired = []
  if (!isEmpty(cards)) {
    expired = cards.filter((c) => {
      const today = new Date()
      const exMonth = get(c, 'card_expiry_month')
      const exYear = get(c, 'card_expiry_year')
      if (today.getFullYear() > parseInt(exYear)) {
        return true
      } else if (today.getFullYear() === parseInt(exYear)) {
        if (today.getMonth() + 1 >= parseInt(exMonth) - delay) {
          return true
        }
      }
      return false
    })
  }
  return expired
}

const getHipayConfig = (options) => {
  const { defaultFirstname, defaultLastname, suffix } = options
  return {
    fields: {
      cardHolder: {
        selector: `hipay-card-holder_${suffix}`, // card holder div id
        defaultFirstname: defaultFirstname?.toUpperCase(),
        defaultLastname: defaultLastname?.toUpperCase()
      },
      cardNumber: {
        selector: `hipay-card-number_${suffix}` // card number div id
      },
      expiryDate: {
        selector: `hipay-expiry-date_${suffix}` // expiry date div id
      },
      cvc: {
        selector: `hipay-cvc_${suffix}`, // cvc div id
        helpButton: true // activate the help button
      }
    },
    styles: {
      base: {
        // default field styling
        color: '#000000',
        fontSize: '15px',
        fontWeight: 400,
        placeholderColor: '#999999',
        iconColor: '#00ADE9',
        caretColor: '#00ADE9'
      },
      invalid: {
        // invalid field styling
        color: '#D50000',
        caretColor: '#D50000'
      }
    }
  }
}

const getMultipaymentData = (queryResult) => {
  const result = []
  const orderedResult = orderBy(
    queryResult?.filter(
      (item) => item?.customFields?.nbDebit > 1 && !item?.code?.toLowerCase().includes('pledg')
    ),
    ['customFields.nbDebit'],
    ['desc']
  )
  let min
  for (const item of orderedResult) {
    const temp = {
      count: item?.customFields?.nbDebit || 1,
      applicationFee: item?.customFields?.applicationFee || 0
    }
    if (min) {
      set(temp, 'max', min)
    }
    min = parseInt(item?.checker?.args?.find((a) => a.name === 'orderMinimum')?.value)
    set(temp, 'min', min)
    result.push(temp)
  }
  return reverse(result)
}

export { getExpiredCards, getHipayConfig, getMultipaymentData }
