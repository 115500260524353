import { BackgroundImage, Flex, Text, useHasMounted } from '@stars-ecom/shared-atoms-ui'
import compact from 'lodash/compact'
import isEmpty from 'lodash/isEmpty'
import sortBy from 'lodash/sortBy'
import take from 'lodash/take'
import React, { memo, useEffect } from 'react'

import { DataLayerUtils } from '../context'
import * as TimedContent from '../utils/timedContent'
import EventCard from './EventCard'

const validatePushEntries = (push) => {
  const larges = TimedContent.filter(push?.sub_pushes, {
    additionalFilter: (p) => p.size === 'LARGE',
    preProcessor: (c, i) => (c._position = i)
  })
  const mediums = TimedContent.filter(push?.sub_pushes, {
    additionalFilter: (p) => p.size === 'MEDIUM',
    preProcessor: (c, i) => (c._position = i)
  })
  const smalls = TimedContent.filter(push?.sub_pushes, {
    additionalFilter: (p) => p.size === 'SMALL',
    preProcessor: (c, i) => (c._position = i)
  })

  if (larges.length > 0) {
    return [larges[0]]
  }
  if (mediums.length > 0) {
    return sortBy(compact([mediums[0], ...take(smalls, 1)]), ['_position'], ['asc'])
  }
  return sortBy(take(smalls, 3))
}

const EventPush = (props) => {
  const { push, position } = props

  const hasMounted = useHasMounted()

  useEffect(() => {
    if (hasMounted) {
      DataLayerUtils.addPromotionImpression({
        promotions: pushEntries.map((p, i) => ({
          promotionName: p.title,
          promotionCreative: p?.backgroundImage?.url,
          promotionPosition: `${position}_${i}`,
          promotionId: p.id
        }))
      })
    }
  }, [hasMounted])
  const pushEntries = validatePushEntries(push)

  if (!hasMounted) {
    return <div style={{ display: 'none' }} />
  }

  if (!pushEntries || isEmpty(pushEntries)) {
    return <div style={{ display: 'none' }} />
  }

  return (
    <BackgroundImage
      backgroundImage={push?.backgroundImage?.image || null}
      backgroundColor={push?.backgroundColor}
      anchor={`push_${push?.id}`}
      style={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        textAlign: 'center',
        paddingTop: '0px',
        paddingBottom: '0px',
        transition: 'all .4s',
        display: 'flex',
        flexDirection: 'column'
      }}>
      <Text
        as="h2"
        fontSize={{ base: '21px', md: '30px' }}
        fontFamily="PT Sans, Arial, sans-serif"
        pt="20px"
        mb={{ base: '10px', md: '25px' }}
        style={{
          width: '100%',
          fontWeight: 400,
          color: push?.textColor,
          textAlign: 'center',
          lineHeight: 1.5,
          textTransform: 'uppercase'
        }}
        dangerouslySetInnerHTML={{ __html: push?.title }}
      />
      <Flex
        direction={{ base: 'column', md: 'row' }}
        pl={{ base: '5px', md: 0 }}
        pr={{ base: '5px', md: 0 }}
        width={{ base: '100%', md: '1000px' }}
        justifyContent="space-around"
        align="center"
        margin="auto"
        marginBottom="20px">
        {pushEntries?.map((subPush, i) => (
          <EventCard key={`event_${subPush.id}`} event={subPush} position={`${position}_${i}`} />
        ))}
      </Flex>
    </BackgroundImage>
  )
}

export default memo(EventPush)
