import PropTypes from 'prop-types'
import React from 'react'

import { ListItem } from '../../dataDisplay/List'
import { Link } from '../Link'

const BreakView = (props) => {
  const { breakLabel, breakClassName, breakLinkClassName, breakHandler, getEventListener } = props
  const className = breakClassName || 'break'

  return (
    <ListItem
      cursor="pointer"
      border={{ base: 'none', md: '1px solid rgb(231, 233, 238)' }}
      display={{ base: 'none', md: 'inline-block' }}
      bg={'#FFFFFF'}
      className={className}>
      <Link
        className={breakLinkClassName}
        role="button"
        tabIndex="0"
        onKeyPress={breakHandler}
        {...getEventListener(breakHandler)}>
        {breakLabel}
      </Link>
    </ListItem>
  )
}

BreakView.propTypes = {
  breakLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  breakClassName: PropTypes.string,
  breakLinkClassName: PropTypes.string,
  breakHandler: PropTypes.func.isRequired,
  getEventListener: PropTypes.func.isRequired
}

export default BreakView
