import { Box, CircularProgress, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { useContext, useEffect, useState } from 'react'

import { WebsiteContext } from '../../context'
import MondialRelayErrorMessage from './MondialRelayErrorMessage'
import MondialRelayFormSearch from './MondialRelayFormSearch'
import MondialRelayHeader from './MondialRelayHeader'
import MondialRelayMap from './MondialRelayMap'

const MondialRelay = (props) => {
  const {
    brand,
    defaultPostCode = 75001,
    defaultCountry = process.env.GATSBY_API_COUNTRY_CODE,
    maxResults = '10',
    deliveryMode = '24R',
    setSelectParcel = () => {},
    fetch = () => {}
  } = props

  const websiteContext = useContext(WebsiteContext)
  const [messageError, setMessageError] = useState(null)
  const [hasError, setHasError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [mobileShowMap, setMobileShowMap] = useState(false)
  const [postion, setPostion] = useState({})
  const [parcelShopList, setParcelShopList] = useState([])
  const [parcelSelected, setParcelSelected] = useState(null)
  const [postCode, setPostCode] = useState(defaultPostCode)

  const windowGlobal = typeof window !== 'undefined' && window

  const scheduleFormatter = (htmlContent) => {
    const result = []
    const parser = new windowGlobal.DOMParser()
    const xmlDoc = parser.parseFromString(`<root>${htmlContent}</root>`, 'text/xml')
    for (let i = 0; i < xmlDoc?.getElementsByTagName('tr')?.length; i++) {
      const temp = xmlDoc?.getElementsByTagName('tr')?.item(i)?.childNodes
      const schedule = {
        day: get(temp, '0.innerHTML', '-'),
        morning: get(temp, '1.innerHTML', '-'),
        afternoon: get(temp, '2.innerHTML', '-')
      }
      result.push(schedule)
    }
    return result
  }
  const selectParcel = (parcel) => {
    setSelectParcel({
      type: 'mondial-relay',
      id: parcel.ID,
      name: parcel.Nom,
      address1: parcel.Adresse1,
      postalCode: parcel.CP,
      city: parcel.Ville,
      schedule: scheduleFormatter(parcel.HoursHtmlTable)
    })
    setParcelSelected(parcel)
  }
  const search = async (data) => {
    if (data.cp !== postCode) {
      setPostCode(get(data, 'cp'))
    }
  }

  const getParcelShopList = async () => {
    try {
      const data = await fetch(
        'https://widget.mondialrelay.com/parcelshop-picker/v4_0/services/parcelshop-picker.svc/SearchPR',
        {
          callbackQuery: 'method',
          callbackName: 'receive',
          Brand: brand,
          ClientContainerId: 'Zone_Widget',
          ColLivMod: deliveryMode,
          Country: defaultCountry,
          NbResults: maxResults,
          PostCode: postCode,
          SearchDelay: '',
          SearchFar: '75',
          Service: '',
          VacationAfter: '',
          VacationBefore: '',
          Weight: '',
          Latitude: postCode ? '' : postion?.latitude,
          Longitude: postCode ? '' : postion?.longitude
        }
      )
      if (data) {
        if (data.Error) {
          setParcelShopList([])
          setMessageError(data.Error)
          setHasError(true)
        } else {
          setParcelShopList(data.PRList)
          setMessageError(null)
          setHasError(false)
        }
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getParcelShopList()
  }, [postCode])

  useEffect(() => {
    setMobileShowMap(false)
    if (!isEmpty(postCode)) {
      search({
        cp: postCode,
        country: defaultCountry
      })
    }
  }, [])
  return (
    <>
      {isLoading ? (
        <Flex align="center">
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      ) : (
        <Box bg="#FFFFFF" maxW={{ base: '680px', md: '80vw' }} m="auto">
          <MondialRelayHeader
            headerTitle={
              <>
                Sélectionnez votre Point Relais<sup>®</sup> Mondial Relay.
              </>
            }>
            <Flex py="20px">
              <MondialRelayFormSearch
                fetch={fetch}
                search={search}
                setPostCode={setPostCode}
                setPostion={setPostion}
                defaultPostCode={postCode}
                defaultCountry={defaultCountry}
              />
            </Flex>
          </MondialRelayHeader>
          {hasError ? (
            <MondialRelayErrorMessage message={messageError} />
          ) : (
            <>
              {/* <Box display={['flex', 'none']} px="20px" justifyContent="flex-end">
                {!mobileShowMap ? (
                  <Button onClick={() => setMobileShowMap(true)}>Afficher la carte</Button>
                ) : (
                  <Button onClick={() => setMobileShowMap(false)}>Afficher la liste</Button>
                )}
              </Box> */}
              <Flex maxH="380px">
                {!websiteContext?.isMobile || (websiteContext?.isMobile && !mobileShowMap) ? (
                  <Flex w={['100%', '35%']} p="10px" overflowY={'scroll'}>
                    <Box h="450px" w="100%">
                      {parcelShopList &&
                        parcelShopList.map((item, key) => (
                          <Box
                            cursor={item.Warning ? '' : 'pointer'}
                            textAlign="left"
                            my="5px"
                            borderLeft={
                              item.Warning
                                ? 'solid 2px #f0f0f0'
                                : parcelSelected && parcelSelected.ID == item.ID
                                ? 'solid 2px #0671b1'
                                : 'solid 2px #ddd'
                            }
                            pl="8px"
                            py="2px"
                            key={key}
                            onClick={() => (item.Warning ? {} : selectParcel(item))}
                            bg={
                              parcelSelected && parcelSelected.ID == item.ID ? '#f0f0f0' : '#FFFFFF'
                            }>
                            <Text
                              fontWeight="700"
                              fontSize="12px"
                              color={item.Warning ? '#c4c4c4' : websiteContext?.mainColor}>{`${
                              key + 1
                            } - ${get(item, 'Nom')}`}</Text>

                            <Text fontSize="12px" color={item.Warning ? '#c4c4c4' : '#333333'}>
                              {get(item, 'Adresse1')}
                            </Text>
                            <Text
                              fontSize="12px"
                              color={item.Warning ? '#c4c4c4' : '#333333'}>{`${get(
                              item,
                              'CP'
                            )} ${get(item, 'Ville')}`}</Text>
                          </Box>
                        ))}
                    </Box>
                  </Flex>
                ) : null}
                {!websiteContext?.isMobile || (websiteContext?.isMobile && mobileShowMap) ? (
                  <Flex w={['100%', '65%']} p="10px">
                    <MondialRelayMap
                      parcelShopList={parcelShopList}
                      selected={parcelSelected}
                      selectParcel={selectParcel}
                    />
                  </Flex>
                ) : null}
              </Flex>
            </>
          )}
        </Box>
      )}
    </>
  )
}

export default MondialRelay
