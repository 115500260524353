import './hipay.css'
import '../layout/simplePage.css'

import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  CloseButton,
  Flex,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Picto,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import { get, isEmpty, set } from 'lodash'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'

import { ApiContext, CartContext, UserContext, WebsiteContext } from '../context'
import { getHipayConfig } from '../utils'
import Conditions from './Conditions'
import OneClickForm from './OneClickForm'

const windowGlobal = typeof window !== 'undefined' && window

const PaymentForm = (props) => {
  const { paymentMethod, cgv = [], hasOutOfStock = false, isMultiple = false } = props
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [hipayInstance, setHipayInstance] = useState()
  const [hipayErrorMessage, setHipayErrorMessage] = useState('')
  const [isDisabled, setIsDisabled] = useState(true)
  const [isValid, setIsValid] = useState(false)
  const [isMultiUse, setIsMultiUse] = useState(false)
  const [cards, setCards] = useState([])
  const [selectedCard, setSelectedCard] = useState({})
  const [displayCards, setDisplayCards] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [CVCHelpMessage, setCVCHelpMessage] = useState('')
  const [isShowForm, setIsShowForm] = useState(true)
  const [isCGVChecked, setIsCGVChecked] = useState(false)
  const [securityLoading, setSecurityLoading] = useState(true)

  const { isOpen, onOpen, onClose } = useDisclosure()
  const formRef = useRef()
  const checkRef = useRef()
  const cvcRef = useRef()
  const cgvRef = useRef()
  const cardInstance = useRef()

  const getData = async () => {
    const cardsMetadata = get(currentUser, 'customFields.cardsMetadata', [])
    if (!isEmpty(cardsMetadata)) {
      const _tmpCards = cardsMetadata.map((c) => JSON.parse(c))
      setCards(_tmpCards)
    }
  }

  useEffect(() => {
    if (isShowForm) {
      setSelectedCard({ card_id: '-1' })
    }
  }, [isShowForm])

  const handlePayment = async (metadata, checked, retry = false) => {
    if (!checked) {
      onOpen()
      return
    }
    setIsLoading(true)

    try {
      let browser_info = {}
      let device_fingerprint

      if (hipayInstance) {
        browser_info = hipayInstance.getBrowserInfo()
        device_fingerprint = hipayInstance.getDeviceFingerprint() || 'undefined'
        const payload = { ...metadata }
        if (browser_info) {
          set(payload, 'browser_info', browser_info)
        }
        if (device_fingerprint) {
          set(payload, 'device_fingerprint', device_fingerprint)
        }
        const saveCardCheckbox = checkRef?.current

        const result = await apiContext.OrderApi.addPaymentToOrder(
          paymentMethod,
          payload,
          saveCardCheckbox?.checked
        )
        if (isEmpty(result) || result?.__typename !== 'Order') {
          if (result?.__typename == 'PaymentDeclinedError') {
            setHipayErrorMessage(
              'Votre paiement a été refusé. Veuillez vérifier les informations de votre carte de paiement, puis réessayer. '
            )
          } else {
            setHipayErrorMessage('Une erreur est survenue lors du paiement!')
          }

          setIsLoading(false)
          return
        }

        const hipayForward3DS = get(result, 'customFields.hipayForward3DS')

        if (get(result, 'state') === 'PaymentIn3dsProcess' && hipayForward3DS) {
          navigate(hipayForward3DS)
        } else {
          navigate('/checkout/order-confirmation', {
            state: { order: result },
            replace: true
          })
        }
        setIsLoading(false)
      } else {
        if (!retry) {
          setTimeout(() => {
            handlePayment(metadata, cgvRef?.current?.checked, true)
          }, 1000)
        } else {
          console.log(
            'Une erreur est survenue lors du paiement! Veuillez réessayer dans quelques instants'
          )
          setHipayErrorMessage(
            'Une erreur est survenue lors du paiement! Veuillez réessayer dans quelques instants'
          )
          setIsLoading(false)
        }
      }
    } catch (err) {
      console.error('Payment failed', err)
      setHipayErrorMessage('Une erreur est survenue lors du paiement!')
      setIsLoading(false)
    }
  }

  useEffect(() => {
    getData()
  }, [currentUser])

  useEffect(() => {
    if (windowGlobal?.HiPay) {
      const hipay = windowGlobal?.HiPay({
        username:
          paymentMethod === 'bcontact'
            ? process.env.GATSBY_API_HIPAY_BCONTACT_USERNAME
            : process.env.GATSBY_API_HIPAY_USERNAME,
        password:
          paymentMethod === 'bcontact'
            ? process.env.GATSBY_API_HIPAY_BCONTACT_PASSWORD
            : process.env.GATSBY_API_HIPAY_PASSWORD,
        environment: process.env.GATSBY_API_HIPAY_ENV,
        lang: 'fr'
      })

      setHipayInstance(hipay)
    }
  }, [windowGlobal?.HiPay, paymentMethod])

  useEffect(() => {
    if (hipayInstance && isShowForm) {
      const config = getHipayConfig({
        suffix: paymentMethod,
        defaultFirstname: `${currentUser?.firstName}`,
        defaultLastname: `${currentUser?.lastName}`
      })

      cardInstance.current = hipayInstance.create('card', config)

      const saveCardCheckbox = checkRef?.current
      cardInstance.current.on('change', (event) => {
        cardInstance.current.setMultiUse(saveCardCheckbox?.checked || hasOutOfStock || isMultiple)
        setHipayErrorMessage('')
        if (event.error) {
          /* Display error(s), if any */
          setHipayErrorMessage(event.error)
        }
        /* Enable / disable submit button */
        setIsDisabled(!event.valid)
      })

      cardInstance.current.on('helpButtonToggled', (event) => {
        if (event?.element === 'cvc' && event?.message) {
          helpButtonToggle(event?.message)
        } else {
          helpButtonToggle('')
        }
      })
    }
  }, [hipayInstance, isShowForm, paymentMethod])

  useEffect(() => {
    if (!securityLoading && cardInstance.current) {
      if (formRef && formRef.current) {
        let cardForm = formRef.current
        const cardFormSubmitListener = (event) => {
          event.preventDefault()
          /* Tokenize your card information when the submit button is clicked */
          cardInstance.current.getPaymentData().then(
            (response) => {
              handlePayment(response, cgvRef?.current?.checked)
            },
            (errors) => {
              setHipayErrorMessage(errors[0].error)
            }
          )
        }
        cardForm.addEventListener('submit', cardFormSubmitListener)
        const saveCardCheckbox = checkRef?.current
        saveCardCheckbox.addEventListener('change', () => {
          cardInstance.current.setMultiUse(saveCardCheckbox?.checked || hasOutOfStock || isMultiple)
        })
      }
    }
  }, [securityLoading])

  useEffect(() => {
    const cardsToDisplay = cards.filter((c) => c.status === 1)
    setDisplayCards(cardsToDisplay)
    setIsShowForm(isEmpty(cardsToDisplay))
  }, [cards])

  const helpButtonToggle = (msg) =>
    isEmpty(cvcRef.current) ? setCVCHelpMessage(msg) : setCVCHelpMessage('')

  useEffect(() => {
    //document.write(unescape("%3Cscript src='https://mpsnare.iesnare.com/snare.js' type='text/javascript'%3E%3C/script%3E"));
    if (isShowForm) {
      const timeout = setTimeout(() => {
        setSecurityLoading(false)
      }, 1000)
      return () => {
        if (timeout) {
          clearTimeout(timeout)
        }
      }
    }
  }, [isShowForm])

  return (
    <Box py="20px">
      {hipayErrorMessage && (
        <Alert status="error" mb="20px">
          <AlertIcon />
          {hipayErrorMessage}
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => {
              setHipayErrorMessage(null)
            }}
          />
        </Alert>
      )}

      <form id={`hipay-form_${paymentMethod}`} className="hipay-form" ref={formRef}>
        <OneClickForm
          cards={displayCards}
          hipayInstance={hipayInstance}
          setHipayErrorMessage={setHipayErrorMessage}
          setIsValid={setIsValid}
          isShowForm={isShowForm}
          showForm={setIsShowForm}
          selectedCard={selectedCard}
          setSelectedCard={setSelectedCard}
        />

        {isShowForm && (
          <HipayForm
            securityLoading={securityLoading}
            paymentMethod={paymentMethod}
            CVCHelpMessage={CVCHelpMessage}
            cvcRef={cvcRef}
            checkRef={checkRef}
            isMultiUse={isMultiUse}
            setIsMultiUse={setIsMultiUse}
          />
        )}

        <Conditions
          ref={cgvRef}
          conditions={cgv}
          isCGVChecked={isCGVChecked}
          setIsCGVChecked={setIsCGVChecked}
        />

        <Flex direction="column" width="100%" justify="center" align="center" my="10px">
          {isShowForm ? (
            <Button
              borderRadius="0"
              bg={websiteContext.mainColor}
              _hover={{ bg: websiteContext.darkColor }}
              _focus={{ border: 'none' }}
              color="#FFF"
              type="submit"
              id={`hipay-submit-button_${paymentMethod}`}
              disabled={isDisabled || isLoading}
              isLoading={isLoading}
              fontFamily={websiteContext.fontFamily}>
              VALIDER MON PAIEMENT
            </Button>
          ) : (
            <Button
              borderRadius="0"
              bg={websiteContext.mainColor}
              _hover={{ bg: websiteContext.darkColor }}
              _focus={{ border: 'none' }}
              color="#FFF"
              onClick={() => handlePayment(selectedCard, cgvRef?.current?.checked)}
              disabled={!isValid || isLoading}
              isLoading={isLoading}
              fontFamily={websiteContext.fontFamily}>
              VALIDER MON PAIEMENT
            </Button>
          )}
          <Flex mt="10px">
            <Picto icon="locker" width="12px" color="#8b8b8b" />
            <Text
              textAlign="center"
              fontSize="14px"
              color="#8b8b8b"
              fontFamily={websiteContext.fontFamily}
              ml="7px"
              position="relative"
              bottom="-1px">
              100% sécurisé
            </Text>
          </Flex>
        </Flex>
      </form>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          p="40px"
          w={{ base: '90%', md: '100%' }}
          h={{ base: '90%', md: 'auto' }}>
          <ModalCloseButton
            style={{ boxShadow: 'none' }}
            right={{ base: '0', md: '-4px' }}
            _hover={{ background: 'none' }}
          />
          <ModalBody>
            <Flex justifyContent="space-around" pb="20px">
              <Picto
                icon="informationCercle"
                width="80px"
                height="80px"
                color={websiteContext.mainColor}
              />
            </Flex>
            <Text
              align="center"
              fontFamily={websiteContext.fontFamily}
              fontSize="14px"
              color="#999999">
              Veuillez accepter les Conditions Générales de Vente et le cas échéant*, les Conditions
              Générales d&apos;Adhésion de la carte fidélité.
            </Text>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

const HipayForm = (props) => {
  const {
    securityLoading,
    paymentMethod,
    CVCHelpMessage,
    cvcRef,
    checkRef,
    isMultiUse,
    setIsMultiUse
  } = props
  const websiteContext = useContext(WebsiteContext)

  useEffect(() => {
    const script = document.createElement('script')

    script.src = '/scripts/hipay-devicefingerprint.js'

    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])
  return (
    <Flex justify="center" position="relative">
      <input type="hidden" id="ioBB" />
      <Flex w="85%" direction="column">
        <FormControl
          display="flex"
          justifyContent="space-between"
          my={{ base: '10px', md: '20px' }}
          flexDirection={{ base: 'column', md: 'row' }}>
          <Flex
            w={{ base: '100%', md: '60%' }}
            className="hostedfield"
            id={`hipay-card-holder_${paymentMethod}`}>
            {/* Cardholder field will be inserted here */}
          </Flex>
          <FormLabel
            fontFamily={websiteContext.fontFamily}
            fontSize="14px"
            fontWeight={700}
            color="#999999"
            mb={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: websiteContext.fontFamily
            }}>
            Titulaire de la carte
          </FormLabel>
        </FormControl>
        <FormControl
          display="flex"
          justifyContent="space-between"
          my={{ base: '10px', md: '20px' }}
          flexDirection={{ base: 'column', md: 'row' }}>
          <Flex
            w={{ base: '100%', md: '60%' }}
            className="hostedfield"
            id={`hipay-card-number_${paymentMethod}`}>
            {/*  Card number field will be inserted here */}
          </Flex>
          <FormLabel
            fontFamily={websiteContext.fontFamily}
            fontSize="14px"
            fontWeight={700}
            mb={0}
            color="#999999"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: websiteContext.fontFamily
            }}>
            N° de la carte
          </FormLabel>
        </FormControl>
        <FormControl
          display="flex"
          my={{ base: '10px', md: '20px' }}
          justifyContent="space-between"
          flexDirection={{ base: 'column', md: 'row' }}>
          <Flex
            w={{ base: '100%', md: '60%' }}
            className="hostedfield"
            id={`hipay-expiry-date_${paymentMethod}`}>
            {/* Expiry date field will be inserted here */}
          </Flex>
          <FormLabel
            fontFamily={websiteContext.fontFamily}
            fontSize="14px"
            fontWeight={700}
            mb={0}
            color="#999999"
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: websiteContext.fontFamily
            }}>{`Date d'expiration`}</FormLabel>
        </FormControl>
        <FormControl
          display="flex"
          justifyContent="space-between"
          my={{ base: '10px', md: '20px' }}
          flexDirection={{ base: 'column', md: 'row' }}>
          <Flex
            w={{ base: '100%', md: '60%' }}
            className="hostedfield"
            id={`hipay-cvc_${paymentMethod}`}>
            {/* CVC field will be inserted here */}
          </Flex>
          <FormLabel
            fontFamily={websiteContext.fontFamily}
            fontSize="14px"
            fontWeight={700}
            color="#999999"
            mb={0}
            style={{
              display: 'flex',
              alignItems: 'center',
              fontFamily: websiteContext.fontFamily
            }}>
            Cryptogramme
          </FormLabel>
        </FormControl>
        {!isEmpty(CVCHelpMessage) && (
          <Alert status="warning" p="0px 12px" mb="10px">
            <Text
              ref={cvcRef}
              style={{
                fontSize: '14px',
                marginTop: '4px',
                marginBottom: '5px',
                color: '#856404'
              }}>
              {CVCHelpMessage}
            </Text>
          </Alert>
        )}

        <Flex direction="row" flexWrap="wrap" align="start" justify="start" mt="10px" mb="20px">
          <Checkbox
            ref={checkRef}
            size="10px"
            isChecked={isMultiUse}
            onChange={(e) => setIsMultiUse(e.target.checked)}
            backgroundColorChecked={websiteContext?.mainColor}
          />

          <Text
            ml="10px"
            textAlign="center"
            fontSize="12px"
            color="#8b8b8b"
            maxW={{ base: '226px', md: 'unset' }}
            fontFamily={websiteContext.fontFamily}>
            Enregistrer ces informations pour faciliter mes prochains achats
          </Text>
        </Flex>
      </Flex>
      {securityLoading && (
        <Flex
          position="absolute"
          w="100%"
          h="100%"
          align="center"
          justify="center"
          backgroundColor="rgba(0, 0, 0, .4)">
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )}
    </Flex>
  )
}
export default memo(PaymentForm)
