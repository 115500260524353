import { get } from 'lodash'

export const getProductStockLevel = (product) => {
  const result = product?.variants?.reduce(
    (a, c) => (a === 'OUT_OF_STOCK' ? 'OUT_OF_STOCK' : c.stockLevel),
    ''
  )
  return result || 'OUT_OF_STOCK'
}

export const getStockLevel = async (product, productVariant, InventoryApi) => {
  let directStockLevel = 'IN_STOCK'
  if (productVariant) {
    directStockLevel = productVariant?.stockLevel
  } else {
    directStockLevel = getProductStockLevel(product)
  }
  const promise = InventoryApi?.getStockLevel(productVariant?.id || get(product, 'variants.0.id'))
  return [directStockLevel, promise]
}
