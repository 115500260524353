import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'

import { WebsiteContext } from '../context'

const HelpHeader = (props) => {
  const { help } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex
      direction="column"
      width="100%"
      height={{ base: '120px', md: '270px' }}
      mt={{ base: '55px', md: '0' }}
      backgroundColor={websiteContext.mainColor}
      align="center">
      <Flex
        direction="column"
        maxWidth="1000px"
        width="100%"
        pl={{ base: '20px', md: '10px' }}
        pr={{ base: '20px', md: '10px' }}>
        <Text
          as="h1"
          pt={{ base: '28px', md: '60px' }}
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize={{ base: '28px', md: '44px' }}
          lineHeight={{ base: '28px', md: '44px' }}
          fontWeight={400}
          color="#ffffff">
          {help?.title}
        </Text>
        <Text
          as="p"
          pt={{ base: '14px', md: '18px' }}
          maxWidth={{ base: '100%', md: '405px' }}
          fontFamily={{
            base: 'PT Sans, Arial, sans-serif',
            md: 'PT Sans Narrow, PT Sans, Arial, sans-serif'
          }}
          fontSize={{ base: '14px', md: '18px' }}
          lineHeight={{ base: '21px', md: '27px' }}
          fontWeight={400}
          color="#ffffff">
          {help?.subtitle}
        </Text>
      </Flex>
    </Flex>
  )
}

export default memo(HelpHeader)
