import React, { memo, useContext } from 'react'

import {
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel
} from '../../disclosure/Accordion'
import Flex from '../../layout/Flex'
import Text from '../../typography/Text'
import { Button } from '../Button'
import { FormContext } from './FormContext'

const SectionWrapper = (props) => {
  const {
    title,
    children,
    hasNext,
    nextFunction = () => {},
    backFunction = () => {},
    isCurrentStep = false
  } = props
  const { formOptions } = useContext(FormContext)

  switch (formOptions?.sectionType) {
    case 'wizardAccordion':
      return (
        <AccordionItem
          w="100%"
          isFocusable={false}
          //isDisabled={!isCurrentStep}
          border={isCurrentStep ? '1px solid #017dc7' : '1px solid #ced4e1'}
          mb="10px">
          <AccordionButton
            onClick={backFunction}
            style={{ boxShadow: 'none' }}
            _hover={{ background: 'none' }}>
            <Flex flex="1" textAlign="left">
              <Text
                as="h3"
                fontFamily="PT Sans, Arial, sans-serif"
                fontSize="26px"
                fontWeight={700}
                borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
                color={isCurrentStep ? '#333333' : '#d3d3d3'}>
                {title}
              </Text>
            </Flex>
            <AccordionIcon openPos="-180" closePos="0" fontSize="40px" color="#333333" />
          </AccordionButton>

          <AccordionPanel>
            <Flex direction="column" pl="25px">
              {children}
            </Flex>
            {hasNext && (
              <Flex justifyContent="center" width="100%" py="10px">
                <Button
                  onClick={nextFunction}
                  textTransform="uppercase"
                  fontFamily={formOptions?.fontFamily}
                  fontWeight="400"
                  fontSize="16px"
                  color="#FFF"
                  borderRadius="0"
                  bg={formOptions?.mainColor}
                  _hover={{ bg: formOptions?.darkColor }}
                  disabled={false}>
                  {formOptions?.nextButtonLabel || 'Continuer'}
                </Button>
              </Flex>
            )}
          </AccordionPanel>
        </AccordionItem>
      )
    default:
      return (
        <Flex direction="column" w="100%" mb="20px">
          <Text
            as="h3"
            mb="20px"
            fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
            fontSize="26px"
            fontWeight={700}
            borderBottom={{ base: '1px solid #d3d3d3', md: 'none' }}
            color="#333333">
            {title}
          </Text>
          {children}
        </Flex>
      )
  }
}

export default memo(SectionWrapper)
