import './header.css'

import { Box, Flex, Link, Picto, useDisclosure } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import replace from 'lodash/replace'
import React, { useContext } from 'react'

import { AccountButton } from '../account'
import { HeaderBanner } from '../banners'
import { CartButton } from '../cart'
import { DataLayerUtils, WebsiteContext } from '../context'
import { Logo } from '../logo'
import Phone from '../phone/Phone'
import { SearchBar } from '../search'
import { BroadcastButton, WatchTVShowButton } from '../tv'
// import Channels from './channels/Channels'
import Menu from './Menu'

const HeaderTop = (props) => {
  const { color = '#fffff', header = {} } = props
  const websiteContext = useContext(WebsiteContext)
  const data = [
    {
      leftIcon: 'certificate',
      text: get(header, 'topHeader.0.label'),
      link: get(header, 'topHeader.0.link'),
      widthIcon: '26px',
      heightIcon: '26px',
      fontSize: '13px',
      analyticEvent: 'Départ réassurance'
    },
    {
      leftIcon: 'thumbUp',
      text: get(header, 'topHeader.1.label'),
      link: get(header, 'topHeader.1.link'),
      widthIcon: '26px',
      heightIcon: '26px',
      fontSize: '13px',
      analyticEvent: 'Départ réassurance'
    },
    {
      leftIcon: 'telephone',
      text: (
        <p>
          Commander par <br /> téléphone
        </p>
      ),
      rightComponent: (
        <Phone
          phoneNumber={get(header, 'topHeader.2.phoneNumber')}
          price={get(header, 'topHeader.2.phoneNumberPrice')}
          color="#A50F78"
        />
      ),
      link: `tel:${replace(get(header, 'topHeader.2.phoneNumber'), ' ', '')}`,
      widthIcon: '20px',
      heightIcon: '20px',
      fontSize: '12px',
      analyticEvent: 'Départ téléphone'
    }
  ]
  if (!isEmpty(data)) {
    return (
      <Flex
        direction="row"
        align="center"
        justify="space-between"
        style={{ backgroundColor: websiteContext?.mainColor, color, width: '100%', height: '40px' }}
        pl={[0, 0, 0, '87px']}>
        {data.map((d, i) => (
          <Link
            key={i}
            to={`${d.link}`}
            onClick={() =>
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Header',
                eventAct: d.analyticEvent,
                eventLab: d.link
              })
            }>
            <Flex
              direction="row"
              align="center"
              style={{
                fontSize: d.fontSize,
                color: '#ffffff',
                fontFamily: 'PT Sans, Arial, Helvetica'
              }}>
              {d.leftIcon && (
                <Picto
                  icon={d.leftIcon}
                  color="#ffffff"
                  width={d.widthIcon}
                  height={d.heightIcon}
                />
              )}
              <Box _hover={{ textDecoration: 'underline' }} align="right" lineHeight="1" m="0 4px">
                {d.text}
              </Box>
              {d.rightComponent}
            </Flex>
          </Link>
        ))}
      </Flex>
    )
  } else {
    return null
  }
}

const Header = (props) => {
  const {
    forgottenPasswordLink,
    forgottenPasswordLabel,
    collections,
    landingPages,
    maxContentWidth = 1000,
    headerBanners = [],
    headerTopColor = '#ffffff',
    menuPush,
    blogLabel,
    storeLabel,
    header,
    isHome = false,
    showCatalog = false,
    showPartner= false,
    catalogLink,
    tvShowSettings
  } = props
  const windowGlobal = typeof window !== 'undefined' && window
  const websiteContext = useContext(WebsiteContext)
  const { isOpen: isOpenCart, onOpen: onOpenCart, onClose: onCloseCart } = useDisclosure()

  const homeLink = windowGlobal?.location?.origin || '/'

  const goToTop = () => {
    windowGlobal && windowGlobal?.scrollTo({ top: 0 })
  }

  const onLogoClick = () => {
    DataLayerUtils.addEvent({ event: 'qwampEvent', eventCat: 'Header', eventAct: 'Clic logo' })
    goToTop()
  }

  return (
    <Flex direction="column" align="center" m="0" p="0">
      <Flex
        backgroundColor={websiteContext?.mainColor}
        className="header-top-wrapper"
        display={{ base: 'none', md: 'flex' }}
        direction="row"
        w="100%"
        justify="center">
        <div
          style={{
            maxWidth: `${maxContentWidth}px`,
            flexGrow: 1
          }}>
          <HeaderTop
            header={header}
            backgroundColor={websiteContext?.mainColor}
            color={headerTopColor}
          />
        </div>
      </Flex>
      <Flex
        maxWidth={{ base: '100vw', md: maxContentWidth }}
        className="header-container"
        w={{ base: '100vw', md: '100%' }}
        direction="row"
        wrap="wrap"
        justify="space-between"
        p={{ base: '10px 0', md: '15px 15px 0' }}
        gap={{ base: '0', md: '11px' }}
        gridGap={{ base: '0', md: '11px' }}
        borderBottomColor="rgb(176, 186, 211)"
        borderBottomStyle="solid"
        borderBottomWidth={{ base: '1px', md: '0' }}
        style={{ fontFamily: 'PT Sans, Arial, Helvetica' }}>
        <Flex className="logo-wrapper">
          <Link resetState={true} to={homeLink} onClick={onLogoClick} target="_self">
            <Logo isHome={isHome} />
          </Link>
        </Flex>
        <Flex className="search-wrapper" alignItems="center">
          <SearchBar />
        </Flex>
        {header?.displayTVShows && (
          <Flex
            className="tv-wrapper"
            display={{ base: 'none', md: 'flex' }}
            direction="row"
            alignItems="center">
            <WatchTVShowButton tvShowSettings={tvShowSettings}/>
          </Flex>
        )}
        {header?.displayTVChannel && (
          <Flex
            className="broadcast-wrapper"
            display={{ base: 'none', md: 'flex' }}
            direction="row"
            alignItems="center">
            <BroadcastButton label={header?.tvChannelLabel} link={header?.tvChannelLink} />
          </Flex>
        )}
        <Flex
          className="account-wrapper"
          direction="row"
          alignItems="center"
          w={{ base: '40px', md: 'auto' }}
          h={{ base: '40px', md: 'auto' }}>
          <AccountButton
            forgottenPasswordLink={forgottenPasswordLink}
            forgottenPasswordLabel={forgottenPasswordLabel}
            isOpenCart={isOpenCart}
          />
        </Flex>
        <Flex
          className="cart-wrapper"
          direction="row"
          alignItems="center"
          w={{ base: '40px', md: 'auto' }}
          h={{ base: '40px', md: 'auto' }}
          mr={{ base: '10px', md: '0' }}>
          <CartButton onCloseCart={onCloseCart} isOpenCart={isOpenCart} onOpenCart={onOpenCart} />
        </Flex>
        <Flex
          className="menu-wrapper"
          direction="row"
          ml={{ base: '10px', md: '-15px' }}
          mr="-15px"
          w={{ base: '40px', md: 'calc(100% + 30px)' }}
          h={{ base: '40px', md: 'auto' }}>
          {
            <div style={{ width: '100%' }}>
              <Menu
                collections={collections}
                push={menuPush}
                landingPages={landingPages}
                blogLabel={blogLabel}
                storeLabel={storeLabel}
                header={header}
                showCatalog={showCatalog}
                showPartner={showPartner}
                catalogLink={catalogLink}
              />
            </div>
          }
        </Flex>
      </Flex>
      <div className="headerBanner-wrapper">
        <HeaderBanner headerBanners={headerBanners} />
      </div>
    </Flex>
  )
}
export default Header
