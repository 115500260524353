import React from 'react'

const FormContext = React.createContext({
  setErrors: () => {},
  errors: []
})

const FormContextProvider = (props) => {
  const { children, value } = props

  return (
    <FormContext.Provider value={value}>
      <form
        style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        {children}
      </form>
    </FormContext.Provider>
  )
}

export { FormContextProvider, FormContext }
