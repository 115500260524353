import React from 'react'

const FormContext = React.createContext({
  setErrors: () => {},
  errors: []
})

const FormContextProvider = (props) => {
  const { children, value } = props

  return (
    <FormContext.Provider value={value}>
      <form>{children}</form>
    </FormContext.Provider>
  )
}

export { FormContextProvider, FormContext }
