import { Box } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import PaymentLegalNotice from './PaymentLegalNotice'
import Reinsurrance from './Reinsurrance'

const FooterCheckout = (props) => {
  const { footer, data, isPayment = false } = props

  return (
    <Box mb="30px" mt="40px">
      <Reinsurrance data={footer?.reinsurance} isCheckout={true} />
      {isPayment && <PaymentLegalNotice data={data}/>}
    </Box>
  )
}

export default memo(FooterCheckout)
