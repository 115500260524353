import { Flex, Link, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { WebsiteContext } from '../../context'
import { StarBox } from '../../rating'
import imageAvisVerifie from './logo-avis-verifies.png'

const ReviewLink = (props) => {
  const { globalRate, reviewLink } = props
  const websiteContext = useContext(WebsiteContext)

  if (websiteContext.showReviews) {
    return (
      <Flex
        direction="column"
        alignSelf="center"
        bg="#fff"
        borderRadius="14px"
        w="200px"
        h="75px"
        mt={{ base: 0, md: '28px' }}>
        <Link to={reviewLink}>
          <Flex justify="center" h="70%" align="center">
            <img
              src={imageAvisVerifie}
              alt="Avis vérifiés"
              style={{ width: '85px', height: '22px' }}
            />
          </Flex>
          <Flex
            borderTop="1.5px solid #F28E23"
            direction="column"
            position="relative"
            sx={{
              '&:before': {
                content: '""',
                position: 'absolute',
                display: 'block',
                border: '7.5px solid transparent',
                borderTopColor: '#F28E23',
                top: '-1px',
                right: '12px'
              }
            }}>
            <Flex>
              <Text
                fontFamily="sans-serif"
                textTransform="uppercase"
                ml="8px"
                mt="2px"
                fontSize="11.5px"
                lineHeight="1.2"
                letterSpacing=".2px">
                Avis de nos clients
              </Text>
            </Flex>
            <Flex justify="flex-end" align="center">
              <StarBox
                rating={globalRate}
                size={12}
                gap={4}
                activeColor="#F28E23"
                unactiveColor="#fff"
                mode={4}
              />
              <Text fontFamily="sans-serif" fontSize="24px" lineHeight="0" mr="8px" ml="5px">
                {globalRate}/5
              </Text>
            </Flex>
          </Flex>
        </Link>
      </Flex>
    )
  } else {
    return null
  }
}

export default ReviewLink
