import { Box, Flex, GatsbyImage, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { DataLayerUtils } from '../../context'

const Loyalty = (props) => {
  const {
    color = '#000',
    title,
    subTitle,
    buttonText,
    image,
    link = '/fidelite',
    loyaltyItems = []
  } = props

  const LoyaltyItem = (props) => {
    const { label, picto, isFirst } = props
    return (
      <>
        {!isFirst && (
          <Box
            display={{ base: 'none', md: 'block' }}
            style={{ borderLeft: '1px solid #dde4f4', height: '50px' }}
          />
        )}
        <Flex
          flexDirection="column"
          align="center"
          justify="center"
          mt="4px"
          width={{ base: '50%', md: 'auto' }}>
          {picto && <Svg data={picto} height="50px" color={color} />}
          <Text
            style={{ maxWidth: '115px', textAlign: 'center' }}
            fontWeight="600"
            fontSize="12px"
            lineHeight="14px"
            mt="2px"
            mb="10px">
            {label}
          </Text>
        </Flex>
      </>
    )
  }

  return (
    <Flex
      direction="column"
      w={{ base: '100%', md: '75%' }}
      pl={{ base: '16px', md: 0 }}
      mt="20px"
      pb="20px"
      align={{ base: 'center', md: 'flex-start' }}>
      <Text
        color="#333333"
        fontWeight={600}
        fontSize="20px"
        fontFamily="PT Sans, Arial, sans-serif"
        ml={{ base: 0, md: '188px' }}
        textAlign={{ base: 'center', md: 'left' }}
        mb="10px">
        {title}
      </Text>
      <Flex direction="row" justify={{ base: 'space-between', md: 'space-evenly' }} width="100%">
        <Flex align="center" justify="center" w={{ base: '85px', md: '119px' }}>
          <Link
            to={link}
            onClick={() =>
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Footer',
                eventAct: 'Départ carte de fidélité',
                eventLab: 'Image'
              })
            }>
            <GatsbyImage
              alt={title}
              image={image?.image?.childImageSharp?.gatsbyImageData}
              loading="eager"
            />
          </Link>
        </Flex>
        <Flex
          direction="column"
          align="flex-start"
          fontFamily="PT Sans, Arial, sans-serif"
          color="#333333"
          fontSize="14px"
          w="70%">
          <Text display={{ base: 'none', md: 'block' }}>{subTitle}</Text>
          <Flex
            align="center"
            justify="space-between"
            wrap={{ base: 'wrap', md: 'no-wrap' }}
            width={{ base: 'auto', md: '100%' }}
            maxWidth={{ base: '344px', md: '100%' }}>
            {loyaltyItems?.map((l, i) => (
              <LoyaltyItem
                key={`loyalty_item_${i}`}
                label={l?.label}
                picto={l?.picto}
                isFirst={i === 0}
              />
            ))}
          </Flex>
          <Box display={{ base: 'none', md: 'block' }}>
            <Link
              to={link}
              style={{ textDecoration: 'underline' }}
              onClick={() =>
                DataLayerUtils.addEvent({
                  event: 'qwampEventLab',
                  eventCat: 'Footer',
                  eventAct: 'Départ carte de fidélité',
                  eventLab: 'En profiter'
                })
              }>
              {buttonText}
            </Link>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Loyalty
