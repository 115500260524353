import { Flex, Picto } from '@stars-ecom/shared-atoms-ui'
import { compact, groupBy, last, values } from 'lodash'
import React, { memo } from 'react'

import rewriteMediaUrl from '../../../shared-atoms-ui/src/utils/rewriteMediaUrl'

const getExtension = (filename) => {
  return last(filename?.split('.')).replace('?x-id=GetObject', '')
}

const ProductAttachmentsIcon = ({ attachmentType }) => {
  const getAttachmentIcon = (attachementType) => {
    switch (attachementType) {
      case 'pdf':
      case 'PDF':
        return <Picto icon="filePdf" height="22px" />

      case 'txt':
      case 'TXT':
        return <Picto icon="fileTxt" height="22px" />

      case 'doc':
      case 'DOC':
      case 'docx':
      case 'DOCX':
        return <Picto icon="fileDoc" height="22px" />

      case 'ppt':
      case 'PPT':
      case 'pptx':
      case 'PPTX':
        return <Picto icon="filePpt" height="22px" />

      case 'xls':
      case 'XLS':
      case 'xlsx':
      case 'XLSX':
        return <Picto icon="fileXls" height="22px" />

      default:
        return <Picto icon="fileTxt" height="22px" />
    }
  }
  return getAttachmentIcon(attachmentType)
}

const ProductAttachments = (props) => {
  const {
    attachments = [],
    labelStyle = {
      fontFamily: 'Arial, helvetica, sans-serif',
      fontSize: '13px',
      fontWeight: 600
    },
    categories = [
      'FDS',
      'Fiche QCE',
      'Guide des tailles',
      'Indice de réparabilité',
      'Informations complémentaires',
      'Informations réglementaires',
      'Liste des ingrédients',
      "Mode d'emploi"
    ]
  } = props
  return (
    <Flex direction="column" w="100%">
      {values(
        groupBy(
          attachments?.filter((a) => categories.includes(a?.customFields?.category)),
          (a) => a?.customFields?.category
        )
      )?.map((category, i) => {
        return compact(category).map((a, j) => (
          <a
            key={`attachment_${i}_${j}`}
            href={rewriteMediaUrl(a?.customFields?.sourceOrigin)}
            target="_blank"
            style={{ color: '#333333', marginTop: '12px', marginBottom: '12px', ...labelStyle }}
            rel="noreferrer">
            <Flex direction="row" align="center" justify="flex-start">
              <ProductAttachmentsIcon
                attachmentType={getExtension(a?.customFields?.sourceOrigin)}
              />
              <span style={{ marginLeft: '10px' }}>{`${a?.customFields?.category} ${
                category.length > 1 ? j + 1 : ''
              }`}</span>
            </Flex>
          </a>
        ))
      })}
    </Flex>
  )
}

export default memo(ProductAttachments)
