import { isEmpty, isObject } from 'lodash'
import isArray from 'lodash/isArray'
import React, { memo, useContext, useState } from 'react'

import { ListItem, UnorderedList } from '../../dataDisplay'
import { Flex } from '../../layout'
import { Picto } from '../../media'
import { Text } from '../../typography'
import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from '../FormControl'
import { FormContext } from './FormContext'
const FieldWrapper = (props) => {
  const { field, fieldErrors, isFieldValid, children } = props
  const { value, originValue, formOptions } = useContext(FormContext)
  const [helperVisible, setHelperVisible] = useState(false)
  const { label, helper, hideLabel, type, isVisible = () => () => true } = field
  if (formOptions?.isFloating && type?.toLowerCase() === 'yesnoradio') {
    return (
      <FormControl
        isRequired={false}
        isInvalid={!isEmpty(fieldErrors)}
        mb="20px"
        display={isVisible(originValue)(value) ? 'flex' : 'none'}
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        color="#1A202C"
        onFocus={() => setHelperVisible(true)}
        onBlur={() => setHelperVisible(false)}>
        <Flex direction="row" align="center">
          <FormLabel
            m={0}
            fontSize="14px"
            fontFamily="PT Sans, Arial, sans-serif"
            width={{ base: '100%', md: '500px' }}
            mr="10px">
            {label}
          </FormLabel>
          {children}
          {!isEmpty(fieldErrors) && (
            <Picto
              color="#E53E3E"
              width="14px"
              height="14px"
              icon="cross"
              style={{ marginLeft: '10px' }}
            />
          )}
          {isFieldValid === true && (
            <Picto
              color="green"
              width="16px"
              height="16px"
              icon="check"
              style={{ marginLeft: '10px' }}
            />
          )}
        </Flex>

        <FormHelperText
          display={{ base: 'none', md: helperVisible ? 'block' : 'none' }}
          m={0}
          ml="5px"
          fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
          fontSize="12px"
          maxW="90px">
          {helper}
        </FormHelperText>
        <FormErrorMessage
          w="100%"
          mt={0}
          ml={{ base: '15px', md: '515px' }}
          fontSize="12px"
          lineHeight="18px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontWeight={700}>
          {isArray(fieldErrors) &&
            fieldErrors.map((err, i) => (
              <Flex key={`error_${i}`} dangerouslySetInnerHTML={{ __html: err }} />
            ))}
          {!isArray(fieldErrors) && isObject(fieldErrors) && (
            <Flex direction="column">
              <Text>{fieldErrors.label}</Text>
              <UnorderedList>
                {fieldErrors?.rules?.map((rule, i) => (
                  <ListItem key={`rule_${i}`} ml="5px">
                    <Flex direction="row" alignItems="center">
                      {rule.label}
                      <Picto
                        color={rule.isValid ? 'green' : '#E53E3E'}
                        width={rule.isValid ? '10px' : '8px'}
                        height={rule.isValid ? '10px' : '8px'}
                        icon={rule.isValid ? 'check' : 'cross'}
                        style={{ marginLeft: '5px' }}
                      />
                    </Flex>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          )}
        </FormErrorMessage>
      </FormControl>
    )
  } else if (formOptions?.isFloating && !['radio'].includes(type?.toLowerCase())) {
    return (
      <FormControl
        isRequired={false}
        isInvalid={!isEmpty(fieldErrors)}
        mb="20px"
        display={isVisible(originValue)(value) ? 'flex' : 'none'}
        direction="row"
        alignItems="center"
        flexWrap="wrap"
        color="#1A202C"
        onFocus={() => setHelperVisible(true)}
        onBlur={() => setHelperVisible(false)}
        variant="floating">
        {children}
        <FormLabel>{label}</FormLabel>

        {!isEmpty(fieldErrors) && (
          <Picto
            color="#E53E3E"
            width="14px"
            height="14px"
            icon="cross"
            style={{ marginLeft: '10px' }}
          />
        )}
        {isFieldValid === true && (
          <Picto
            color="green"
            width="16px"
            height="16px"
            icon="check"
            style={{ marginLeft: '10px' }}
          />
        )}
        <FormHelperText
          display={{ base: 'none', md: helperVisible ? 'block' : 'none' }}
          m={0}
          ml="5px"
          fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
          fontSize="12px"
          maxW="90px">
          {helper}
        </FormHelperText>
        <FormErrorMessage
          w="100%"
          mt={0}
          ml="15px"
          fontSize="12px"
          lineHeight="18px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontWeight={700}>
          {isArray(fieldErrors) &&
            fieldErrors.map((err, i) => (
              <Flex key={`error_${i}`} dangerouslySetInnerHTML={{ __html: err }} />
            ))}
          {!isArray(fieldErrors) && isObject(fieldErrors) && (
            <Flex direction="column">
              <Text>{fieldErrors.label}</Text>
              <UnorderedList>
                {fieldErrors?.rules?.map((rule, i) => (
                  <ListItem key={`rule_${i}`} ml="5px">
                    <Flex direction="row" alignItems="center">
                      {rule.label}
                      <Picto
                        color={rule.isValid ? 'green' : '#E53E3E'}
                        width={rule.isValid ? '10px' : '8px'}
                        height={rule.isValid ? '10px' : '8px'}
                        icon={rule.isValid ? 'check' : 'cross'}
                        style={{ marginLeft: '5px' }}
                      />
                    </Flex>
                  </ListItem>
                ))}
              </UnorderedList>
            </Flex>
          )}
        </FormErrorMessage>
      </FormControl>
    )
  }
  return (
    <FormControl
      isRequired={false}
      isInvalid={!isEmpty(fieldErrors)}
      mb="20px"
      display={isVisible(originValue)(value) ? 'flex' : 'none'}
      direction="row"
      alignItems="center"
      flexWrap="wrap"
      color="#1A202C"
      onFocus={() => setHelperVisible(true)}
      onBlur={() => setHelperVisible(false)}>
      {!hideLabel && (
        <FormLabel
          m={0}
          fontSize="14px"
          fontFamily="PT Sans, Arial, sans-serif"
          width={{ base: '100%', md: '215px' }}
          color="#999999"
          fontWeight={700}>
          {label}
        </FormLabel>
      )}
      {children}
      {!isEmpty(fieldErrors) && (
        <Picto
          color="#E53E3E"
          width="14px"
          height="14px"
          icon="cross"
          style={{ marginLeft: '10px' }}
        />
      )}
      {isFieldValid === true && (
        <Picto
          color="green"
          width="16px"
          height="16px"
          icon="check"
          style={{ marginLeft: '10px' }}
        />
      )}
      <FormHelperText
        display={{ base: 'none', md: helperVisible ? 'block' : 'none' }}
        m={0}
        ml="5px"
        fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
        fontSize="12px"
        maxW="90px">
        {helper}
      </FormHelperText>
      <FormErrorMessage
        w="100%"
        mt={0}
        ml={{ base: 0, md: '230px' }}
        fontSize="12px"
        lineHeight="18px"
        fontFamily="PT Sans, Arial, sans-serif"
        fontWeight={700}>
        {isArray(fieldErrors) &&
          fieldErrors.map((err, i) => (
            <Flex key={`error_${i}`} dangerouslySetInnerHTML={{ __html: err }} />
          ))}
        {!isArray(fieldErrors) && isObject(fieldErrors) && (
          <Flex direction="column">
            <Text>{fieldErrors.label}</Text>
            <UnorderedList>
              {fieldErrors?.rules?.map((rule, i) => (
                <ListItem key={`rule_${i}`} ml="5px">
                  <Flex direction="row" alignItems="center">
                    {rule.label}
                    <Picto
                      color={rule.isValid ? 'green' : '#E53E3E'}
                      width={rule.isValid ? '10px' : '8px'}
                      height={rule.isValid ? '10px' : '8px'}
                      icon={rule.isValid ? 'check' : 'cross'}
                      style={{ marginLeft: '5px' }}
                    />
                  </Flex>
                </ListItem>
              ))}
            </UnorderedList>
          </Flex>
        )}
      </FormErrorMessage>
    </FormControl>
  )
}

export default memo(FieldWrapper)
