import { Box, Text } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { NewAddress } from '../../account'
import { ApiContext } from '../../context'

const NewAddressForm = (props) => {
  const { onClose } = props
  const apiContext = useContext(ApiContext)

  const createCustomerAddress = async (address) => {
    try {
      const result = await apiContext?.AccountApi?.createCustomerAddress(address)
      onClose(result)
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <>
      <Text as="h3" py="20px" fontWeight="600">
        Nouvelle adresse de livraison
      </Text>
      <Box p="20px" bg="#F6F6F6" className="checkout">
        <NewAddress
          displayLabel={false}
          createCustomerAddress={createCustomerAddress}
          handleRead={onClose}
        />
      </Box>
    </>
  )
}
export default NewAddressForm
