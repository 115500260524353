import './link.css'

import { Link as GatsbyLink } from 'gatsby'
import includes from 'lodash/includes'
import startsWith from 'lodash/startsWith'
import React from 'react'

const Link = ({
  children,
  to,
  activeClassName,
  partiallyActive,
  target,
  resetState,
  underlineOnHover,
  ...other
}) => {
  const internal =
    (/^\/(?!\/)/.test(to) || /^#\w+/.test(to)) && !/^tel:/.test(to) && !/^mailto:/.test(to)

  if (internal) {
    if (to && !startsWith(to, '/')) {
      to = `/${to}`
    }
    to = to?.length > 1 ? to?.replace(/\/+$/, '') : to
    if (includes(to, '/#')) {
      to = to.replace('/#', '#')
    }
    if (includes(to, '/?')) {
      to = to.replace('/?', '?')
    }
  }

  if (internal && target !== '_blank') {
    return (
      <GatsbyLink
        className={underlineOnHover ? 'link_underline' : ''}
        to={to}
        target={target}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        state={resetState ? {} : undefined}
        {...other}>
        {children}
      </GatsbyLink>
    )
  }
  return (
    <a
      href={to}
      target={target ?? '_blank'}
      {...other}
      className={underlineOnHover ? 'link_underline' : ''}>
      {children}
    </a>
  )
}
export default Link
export { Link }
