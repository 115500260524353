import { Flex, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import { DataLayerUtils } from '../../context'

const SocialLinks = (props) => {
  const { socialNetworkstitle, socialLinks } = props

  return (
    <Flex
      direction={{ base: 'row', md: 'column' }}
      justify={{ base: 'center', md: 'flex-start' }}
      align={{ base: 'center', md: 'flex-start' }}
      mb={{ base: '20px', md: 0 }}
      mt={{ base: '20px', md: 0 }}
      maxWidth={{ md:'200px' }}>
      <Text
        style={{
          fontFamily: 'PT Sans, Arial, sans-serif',
          fontWeight: 600,
          fontSize: '14px',
          color: '#333333',
          textTransform: 'uppercase'
        }}
        mb={{ md: '14px' }}
        mr={{ base: '10px', md: 0 }}>
        {socialNetworkstitle}
      </Text>
      <Flex flexDirection="row" flexWrap="wrap">
        {socialLinks?.map((sl, i) => (
          <Flex
            key={`social_link_${i}`}
            fontFamily="PT Sans, Arial, sans-serif"
            _hover={{ textDecoration: 'underline' }}
            align="center"
            justify="center"
            pr="10px"
            mb={{ md: '5px' }}>
              <Link
                to={sl?.link}
                onClick={() => {
                  DataLayerUtils.addEvent({
                    event: 'socialInt',
                    socialNet: 'RS',
                    socialAct: 'Suivre',
                    socialTarget: 'Footer'
                  })
                }}
              >
                <Svg data={sl?.picto} style="border-radius: 8px" width="32px" height="32px" />
              </Link>
            </Flex>
        ))}  
      </Flex>
    </Flex>
  )
}

export default SocialLinks
