import { Button, Flex, Image, Text } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isFunction from 'lodash/isFunction'
import React, { useEffect, useState } from 'react'

import { americanExpress, cb, mastercard, visa } from '../../checkout/icones'

const PaymentCard = (props) => {
  const { paymentCard = {}, onEdit = () => {}, onDelete = () => {}, isExpired = false } = props
  const windowGlobal = typeof window !== 'undefined' && window
  const [icon, setIcon] = useState(undefined)

  const editHandler = () => {
    isFunction(onEdit) && onEdit(paymentCard)
  }

  const deleteHandler = () => {
    isFunction(onDelete) && onDelete(get(paymentCard, 'token'))
  }

  useEffect(() => {
    if (paymentCard) {
      const brand = get(paymentCard, 'brand')

      switch (brand) {
        case 'VISA':
          setIcon(visa)
          break
        case 'MASTERCARD':
          setIcon(mastercard)
          break
        case 'cb':
          setIcon(cb)
          break
        case 'AMERICAN EXPRESS':
          setIcon(americanExpress)
          break
      }
    }
  }, [paymentCard])

  return (
    <Flex
      direction="column"
      justify="space-between"
      width={{ base: '100%', md: '325px' }}
      backgroundColor="#f6f3f1"
      pt="20px"
      pb="15px"
      pl="15px"
      pr="15px">
      <Text
        fontFamily="PT Sans, Arial, sans-serif"
        fontSize="14px"
        fontWeight={'900'}
        color="#333333"
        mb="5px">
        {get(paymentCard, 'label')}
      </Text>
      <Flex>
        {icon && <Image src={icon} w="40px" mr="10px" />}
        <Text
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="14px"
          fontWeight={'900'}
          color="#333333"
          mb="5px"
          pt="5px">
          {`${get(paymentCard, 'brand')}`}
        </Text>
      </Flex>
      <Text
        fontFamily="PT Sans, Arial, sans-serif"
        fontSize="14px"
        fontWeight={'400'}
        color="#333333"
        mb="5px">
        {`${get(paymentCard, 'pan')}`}
      </Text>
      <Text
        fontFamily="PT Sans, Arial, sans-serif"
        fontSize="14px"
        fontWeight={'400'}
        color={isExpired ? '#C53030' : '#333333'}
        mb="5px">
        {`Expire fin ${get(paymentCard, 'card_expiry_month')}/${get(
          paymentCard,
          'card_expiry_year'
        )}`}
      </Text>
      <Flex direction="row">
        {!isExpired && (
          <Button
            onClick={editHandler}
            variant="solid"
            style={{
              borderRadius: 0,
              color: '#ffffff',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
              height: '28px',
              fontSize: '14px',
              paddingLeft: '20px',
              paddingRight: '20px',
              fontWeight: 400,
              fontFamily: 'PT Sans,Arial,sans-serif',
              boxShadow: 'none',
              marginRight: '15px'
            }}
            colorScheme="blue">
            Editer
          </Button>
        )}
        <Button
          onClick={() => {
            if (windowGlobal?.confirm('Êtes-vous sûr de vouloir supprimer cette carte ?'))
              deleteHandler()
          }}
          colorScheme="red"
          variant="solid"
          style={{
            borderRadius: 0,
            color: '#ffffff',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '28px',
            fontSize: '14px',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontWeight: 400,
            fontFamily: 'PT Sans,Arial,sans-serif',
            boxShadow: 'none'
          }}>
          Supprimer
        </Button>
      </Flex>
    </Flex>
  )
}
export default PaymentCard
