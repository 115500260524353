import './layout.css'
import 'dayjs/locale/fr'

import { useDisclosure, useIsMobile } from '@stars-ecom/shared-atoms-ui'
import {
  AppContextProvider,
  DefaultLayout,
  Footer,
  FooterCheckout,
  getSession,
  Header,
  HeaderCheckout,
  initAxios,
  LoginPopin,
  ScrollToTop
} from '@stars-ecom/shared-organisms-ui'
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import startsWith from 'lodash/startsWith'
import React, { useEffect } from 'react'

import staticPages from '../../staticPages'
import * as Api from '../services'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)
dayjs.locale('fr')
dayjs.tz.setDefault('Europe/Paris')

initAxios()

const windowGlobal = typeof window !== 'undefined' && window

const Layout = (props) => {
  const { children, pageContext, location, data } = props

  const {
    backgroundColor = '#ffffff',
    breadcrumb = [],
    website,
    header,
    headerBanners = [],
    footer,
    collections,
    loyaltyCard,
    loyaltyCardImage,
    landingPages = [],
    headerType = 'DEFAULT',
    footerType = 'DEFAULT',
    maintenance = false,
    headerStep,
    isCheckout = false,
    isPayment = false,
    isProduct = false,
    noBreadcrumb = false,
    mustBeConnected = false,
    websiteSettings,
    tvShowSettings,
    blogLabel,
    storeLabel
  } = pageContext

  const isHome = location?.pathname === '/' || !location?.pathname
  const isConnected = getSession('isConnected')
  const isMobile = useIsMobile()
  const {
    isOpen: isOpenLoginPopin,
    onOpen: onOpenLoginPopin,
    onClose: onCloseLoginPopin
  } = useDisclosure()

  useEffect(() => {
    if (mustBeConnected && !isConnected) {
      onOpenLoginPopin()
    }
  }, [mustBeConnected, isConnected, children])

  const getParam = (key) => {
    const search = location?.search?.replace('?', '').split('&')
    for (const s of search) {
      if (startsWith(s, key)) {
        return s
      }
    }
    return null
  }

  // TODO handle get parameter and transform it to cookie (ticket #3570)
  useEffect(() => {
    if (windowGlobal) {
      const codeMed = getParam('codmed_')
      if (codeMed) {
        windowGlobal.document.cookie = `${codeMed.replace('codmed_', '')}; expires=${dayjs()
          .add(1, 'day')
          .format('ddd, D MMM YYYY hh:mm:ss z')}"`
      }
    }

    // On dtrait le param get en question location?.search
    // axios.request.headers.common['X-Media'] = mon param
  }, [location])

  const CustomHeader = () => {
    if (headerType === 'NONE') {
      return null
    }
    if (headerType === 'CHECKOUT') {
      return <HeaderCheckout headerStep={headerStep} />
    }
    return (
      <Header
        collections={collections}
        landingPages={landingPages}
        forgottenPasswordLabel="Mot de passe oublié ?"
        forgottenPasswordLink={`/${staticPages?.forgottenPasswordPage?.path}`}
        header={header}
        headerBanners={headerBanners}
        blogLabel={blogLabel}
        storeLabel={storeLabel}
        isHome={isHome}
        showCatalog={footer?.showCatalog}
        showPartner={footer?.showPartner}
        catalogLink={footer?.interactiveCatalogLink}
        tvShowSettings={tvShowSettings}
      />
    )
  }

  const CustomFooter = () => {
    if (footerType === 'NONE') {
      return null
    }
    if (footerType === 'CHECKOUT') {
      return <FooterCheckout footer={footer} isPayment={isPayment} data={data} />
    }
    return <Footer data={footer} />
  }

  return (
    <AppContextProvider
      websiteContext={{ ...website, isMobile, loyaltyCard, loyaltyCardImage, websiteSettings }}
      dataLayerContext={{
        location,
        pageType: children?.type?.pageType,
        breadcrumb: pageContext?.breadcrumb
      }}
      apiContext={Api}>
      <DefaultLayout
        headerComponent={CustomHeader}
        noBreadcrumb={noBreadcrumb}
        website={website}
        isCheckout={isCheckout}
        isBlurred={!isConnected && mustBeConnected}
        isProduct={isProduct}
        breadCrumbData={breadcrumb}
        footerComponent={CustomFooter}
        backgroundColor="#ffffff"
        headerBackgroundColor="#ffffff"
        headerFixedThreshold="md"
        maintenance={maintenance}
        contentBackgroundColor={backgroundColor}
        contentMaxWidth="100vw"
        scrollToTop={<ScrollToTop />}>
        {children}
        {!isConnected && mustBeConnected && (
          <LoginPopin isOpen={isOpenLoginPopin} onClose={onCloseLoginPopin} />
        )}
      </DefaultLayout>
    </AppContextProvider>
  )
}

export default Layout
