import { axios } from '@stars-ecom/shared-organisms-ui'

/**
 * Get product reviews from lambda getReviewDetails in service review
 * @return {Object}
 */
const getReviewDetails = async (
  productGroupCode,
  page = 0,
  limit = 10,
  orderBy = 'date|DESC',
  filter
) => {
  let channel = `${process.env.GATSBY_API_WEBSITE}`

  channel = channel
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/\s/g, '')
    .toLowerCase()

  try {
    const result = await axios.get(
      `review/${process.env.GATSBY_API_VERSION}/${channel}/${productGroupCode}/details`,
      { params: { page, limit, orderBy, filter } }
    )

    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

/**
 * Get product average rate from lambda getReviewAvg in service review
 * @return {Object}
 */
const getReviewAvg = async (productGroupCode) => {
  let channel = `${process.env.GATSBY_API_WEBSITE}`
  channel = channel
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .replace(/\s/g, '')
    .toLowerCase()

  try {
    const result = await axios.get(
      `review/${process.env.GATSBY_API_VERSION}/${channel}/${productGroupCode}`
    )

    return result.data
  } catch (e) {
    console.error(e)
    return {}
  }
}

export { getReviewAvg, getReviewDetails }
