import './webloyalty.css'

import { Box, Flex } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import OrderHistory from '../order/OrderHistory'
import AccountWelcome from './AccountWelcome'

const AccountOrderHistory = (props) => {
  const { trackings, orderCode, loyaltyCard } = props

  return (
    <Flex direction="column" gridGap="20px" width="100%" marginBottom={{ base: '0px', md: '40px' }}>
      <Box display={{ base: 'none', md: 'block' }}>
        <AccountWelcome loyaltyCard={loyaltyCard} />
      </Box>
      <OrderHistory trackings={trackings} orderCode={orderCode} />
    </Flex>
  )
}

export default AccountOrderHistory
