import cloneDeep from 'lodash/cloneDeep'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import isFunction from 'lodash/isFunction'
import isObject from 'lodash/isObject'
import set from 'lodash/set'
import trim from 'lodash/trim'
import React, { memo, useEffect, useState } from 'react'

import useDidMountEffect from '../../hooks/useDidMountEffect'
import { Flex } from '../../layout'
import { Button } from '../Button'
import { FormContextProvider } from './FormContext'
import FormWrapper from './FormWrapper'
import Section from './Section'

const Form = (props) => {
  const {
    formSettings,
    formOptions,
    originValue,
    validateFunction: _validateFunction = () => {},
    cancelFunction: _cancelFunction,
    validateText = 'Enregistrer',
    cancelText = 'Annuler',
    setLoading = () => {},
    width = '100%',
    maxWidth = '100%',
    parentRef
  } = props
  const [value, setValue] = useState(originValue)
  const [isFormValid, setIsFormValid] = useState(false)
  const [isFormChanged, setIsFormChanged] = useState(false)
  const [globalErrors, setGlobalErrors] = useState([])
  const [step, setStep] = useState(0)
  const windowGlobal = typeof window !== 'undefined' && window
  const scrollToTop = () => windowGlobal.scrollTo({ top: 0, behavior: 'smooth' })

  const onFormChange = (key) => (val) => {
    const newValue = cloneDeep(value)
    set(newValue, key, val)
    setValue(newValue)
  }

  const validateForm = async () => {
    let errors = []
    for (const section of formSettings) {
      for (const field of section?.fields) {
        try {
          if (field?.validator && isFunction(field?.validator)) {
            await field?.validator(originValue)(value).validate(get(value, field?.attrKey), {
              abortEarly: false
            })
          } else if (field?.validator && isObject(field?.validator)) {
            const { label: validatorLabel = '', rules = [] } = field?.validator
            const result = { label: validatorLabel }
            let score = 0
            let i = 0
            for (const rule of rules) {
              const { label: ruleLabel, validator: ruleValidator, weight } = rule
              set(result, `rules.${i}`, { label: ruleLabel, isValid: true })
              try {
                await ruleValidator(originValue)(value).validate(get(value, field?.attrKey), {
                  abortEarly: false
                })
                score = score + weight
              } catch (ruleErr) {
                set(result, `rules.${i}`, { label: ruleLabel, isValid: false })
              }
              i++
            }
            if (score < 1 && trim(get(value, field?.attrKey))) {
              errors.push('passwordError')
            }
          }
        } catch (err) {
          errors.push(...err?.errors)
        }
      }
    }
    setGlobalErrors(errors)
  }

  const triggerValidation = async () => {
    for (const section of formSettings) {
      for (const field of section?.fields) {
        if (isFunction(field?.triggerValidation)) {
          await field?.triggerValidation(originValue)(value)()
        }
      }
    }
  }

  const validateFunction = async () => {
    if (isFormValid && isFormChanged) {
      setLoading(true)
      const result = await _validateFunction(value)
      if (result?.error) {
        setStep(0)
        parentRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'start' })
      }
      setLoading(false)
    } else {
      triggerValidation()
    }
    scrollToTop()
  }

  const cancelFunction = async () => {
    setLoading(true)
    setValue(originValue)
    await _cancelFunction()
    setLoading(false)
  }

  useEffect(() => {
    setIsFormValid(isEmpty(globalErrors))
  }, [globalErrors])

  useDidMountEffect(
    () => {
      validateForm()
    },
    [value],
    formOptions?.validateOnMount
  )

  useEffect(() => {
    setIsFormChanged(!isEqual(originValue, value))
  }, [value])

  const nextFunction = () => {
    setStep(step + 1)
  }

  const backFunction = (index) => () => {
    const idx = index - 1
    if (idx >= 0 && idx < step) {
      setStep(idx)
    } else if (idx >= step) {
      setStep(step + 1)
    } else if (idx <= 0) {
      setStep(0)
    }
  }

  return (
    <FormContextProvider
      value={{
        formSettings,
        formOptions,
        onFormChange,
        originValue,
        value,
        setValue,
        globalErrors,
        setGlobalErrors,
        validateFunction,
        cancelFunction
      }}>
      <FormWrapper width={width} maxWidth={maxWidth} step={step}>
        {formSettings?.map((section, i) => (
          <Section
            key={`section_${i}`}
            section={section}
            hasNext={i < formSettings?.length - 1}
            step={step}
            isCurrentStep={step === i}
            nextFunction={nextFunction}
            backFunction={backFunction(i)}
          />
        ))}
        {((formOptions?.sectionType === 'wizardAccordion' && step === formSettings?.length - 1) ||
          formOptions?.sectionType !== 'wizardAccordion') && (
          <Flex
            direction={{ base: 'column', md: 'row' }}
            w="100%"
            justify={isFunction(_cancelFunction) ? 'space-between' : 'center'}>
            {isFunction(_cancelFunction) && (
              <Button
                order={{ base: 2, md: 1 }}
                w={{ base: '100%', md: 'auto' }}
                onClick={cancelFunction}
                variant="solid"
                style={{
                  borderRadius: 0,
                  color: '#ffffff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  boxShadow: 'none'
                }}
                textTransform="uppercase"
                fontSize="16px"
                fontWeight="400"
                fontFamily={formOptions?.fontFamily}
                mb={{ base: '20px', md: '0px' }}
                backgroundColor="#c1c1c1">
                {cancelText}
              </Button>
            )}
            <Button
              order={{ base: 1, md: 2 }}
              w={{ base: '100%', md: 'auto' }}
              onClick={validateFunction}
              variant="solid"
              style={{
                borderRadius: 0,
                color: '#ffffff',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                height: '40px',
                paddingLeft: '30px',
                paddingRight: '30px',
                boxShadow: 'none'
              }}
              textTransform="uppercase"
              fontSize="16px"
              fontWeight="400"
              fontFamily={formOptions?.fontFamily}
              cursor={isFormValid && isFormChanged ? 'pointer' : 'not-allowed'}
              disabled={!isFormValid}
              mb={{ base: '20px', md: '0px' }}
              backgroundColor={formOptions?.mainColor}
              _hover={{ bg: formOptions?.darkColor }}>
              {validateText}
            </Button>
          </Flex>
        )}
      </FormWrapper>
    </FormContextProvider>
  )
}

export default memo(Form)
