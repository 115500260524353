import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { DataLayerUtils } from '../context'
import ProductSlide from './ProductSlide'

const ProductCrossSell = (props) => {
  const { products = [], title1 = 'VOUS AIMEREZ', title2 = 'AUSSI...', container } = props

  const dataLayerFunction = (event = {}) => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: container,
      eventAct: `Défiler slider ${title1} ${title2}`,
      ...event
    })
  }

  if (products.length > 0) {
    return (
      <Flex
        direction="column"
        style={{ backgroundColor: 'rgb(238, 244, 255)', paddingBottom: '20px', paddingTop: '15px' }}
        alignItems="center">
        <Text
          as="h2"
          m={{ base: '24px 0', md: '10px 0' }}
          style={{
            fontSize: '24px',
            fontFamily: 'PT Sans, Arial, sans-serif',
            fontWeight: '700',
            textTransform: 'uppercase',
            textAlign: 'center'
          }}>
          {title1} <span style={{ fontWeight: '400' }}>{title2}</span>
        </Text>
        <ProductSlide
          listName={`${title1} ${title2}`}
          products={products}
          arrowColor="#333333"
          dataLayerFunction={dataLayerFunction}
        />
      </Flex>
    )
  }
  return null
}

export default memo(ProductCrossSell)
