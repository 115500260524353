import { Box, Link } from '@stars-ecom/shared-atoms-ui'
import { WebsiteContext } from '@stars-ecom/shared-organisms-ui'
import React, { useContext } from 'react'

import Phone from './Phone'

const OrderPhone = (props) => {
  const { desktopHide, labelHide } = props
  const websiteContext = useContext(WebsiteContext)

  return (
    <Box
      className="savPhone"
      display={!desktopHide ? 'block' : ['block', 'block', 'none', 'none']}>
      <Link
        to={`tel:${websiteContext?.phoneNumber?.replaceAll(' ', '')}`}
        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box
          textAlign="center"
          fontWeight="600"
          fontSize="18px"
          m="16px 0 12px"
          display={websiteContext?.phoneNumberLabel?.length == 0 || labelHide ? 'none' : 'block'}>
            {websiteContext?.phoneNumberLabel}
        </Box>
        <Phone price={websiteContext?.phoneNumberPrice} phoneNumber={websiteContext?.phoneNumber} color="#A50F78" />
      </Link>
    </Box>
  )
}

export default OrderPhone
