import { axios } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'

const getVideos = async (urlReplay) => {
  try {
    const result = await axios.post(
      `external-services/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/getExternalData`,
      { url: urlReplay, params: {} }
    )
    return get(result, 'data')
  } catch (e) {
    console.error(e)
    return {}
  }
}
export { getVideos }
