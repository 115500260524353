import { isArray, isObject, isString, keys, trim } from 'lodash'

import chroma from './chroma'
import * as Crypto from './crypto'
import formatter from './formatNumber'
import replaceSpecialChar from './formatString'
import rewriteMediaUrl from './rewriteMediaUrl'

const attributesToUpperCase = (object, toExclude = []) => {
  let output = object
  if (isArray(object)) {
    output = object.map((o) => attributesToUpperCase(o))
  } else if (isObject(object)) {
    keys(object).forEach((key) => {
      if (toExclude.includes(key)) {
        output[key] = object[key]
      } else {
        output[key] = isString(object[key])
          ? trim(object[key]).toUpperCase()
          : attributesToUpperCase(object[key])
      }
    })
  }
  return output
}

export { formatter, replaceSpecialChar, chroma, Crypto, attributesToUpperCase, rewriteMediaUrl }
