import {
  Alert,
  AlertDescription,
  Box,
  Button,
  CircularProgress,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Picto,
  Text
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import isFunction from 'lodash/isFunction'
import trim from 'lodash/trim'
import React, { useContext, useEffect, useState } from 'react'

import { ApiContext, WebsiteContext } from '../context'
import { Regex } from '../utils'

const windowGlobal = typeof window !== 'undefined' && window

const LoginPopin = (props) => {
  const { isOpen, onClose: _onClose, goBackOnClose = true, analyticEvent } = props
  const apiContext = useContext(ApiContext)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [show, setShow] = useState(false)
  const [errorCode, setErrorCode] = useState('')
  const [emailError, setEmailError] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [loading, setLoading] = useState(false)
  const togglePassword = () => setShow(!show)
  const websiteContext = useContext(WebsiteContext)

  const login = async (e) => {
    e.preventDefault()
    if ((!email || trim(email) === '') && (!password || trim(password) === '')) {
      setEmailError(true)
      setPasswordError(true)
      return
    }
    if (!email || trim(email) === '') {
      setEmailError(true)
      return
    }
    if (!Regex.validateEmail(email)) {
      setErrorCode('Veuillez vérifier le format de votre adresse e-mail.')
      return
    }
    if (!password || trim(password) === '') {
      setPasswordError(true)
      return
    }
    setLoading(true)
    const result = await apiContext?.AccountApi?.login(email, password)
    setLoading(false)
    if (result && result.errorCode === 'WRONG_MAIN_WEBSITE') {
      windowGlobal.location.href = `https://${result.dns}`
    } else if (result && result.errorCode) {
      setErrorCode('Compte non identifié ou mot de passe incorrect')
    } else {
      if (isFunction(analyticEvent)) {
        analyticEvent()
      }
      if (isFunction(_onClose)) {
        _onClose()
      }
    }
  }

  const onClose = () => {
    if (goBackOnClose) {
      navigate(-1)
    }
    if (isFunction(_onClose)) {
      _onClose()
    }
  }

  useEffect(() => {
    if (email && trim(email) !== '') {
      setEmailError(false)
    }
    if (password && trim(password) !== '') {
      setPasswordError(false)
    }
  }, [email, password])

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      scrollBehavior="inside"
      className="chakra-modal__login-popin"
      isCentered={!websiteContext.isMobile}>
      <ModalOverlay />
      <ModalContent maxW="50rem" borderRadius="0" minH={{ base: '570px', md: 'unset' }}>
        <ModalCloseButton
          style={{ boxShadow: 'none', zIndex: 10000, right: '0', top: '0' }}
          _hover={{ boxShadow: 'none' }}
          _active={{ boxShadow: 'none' }}
        />
        <ModalBody p="0">
          <Flex
            w="100%"
            position="relative"
            direction={{ base: 'column', md: 'row' }}
            id="loginPopin">
            <Flex
              w={{ base: '100%', md: '50%' }}
              flexDirection="column"
              p={{ base: '20px', md: '30px' }}>
              <Text fontWeight="900" fontSize="20px" mb="16px" fontFamily="PT Sans">
                Déjà client {websiteContext?.title} ?
              </Text>
              {errorCode && (
                <Alert
                  status="error"
                  backgroundColor="#f2dede"
                  border="1px solid #ebccd1"
                  mb="12px"
                  p="7px 10px">
                  <AlertDescription color="#a94442" fontSize="14px">
                    {errorCode}
                  </AlertDescription>
                </Alert>
              )}
              <form
                onSubmit={login}
                style={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                <Input
                  value={email}
                  pr="4.5rem"
                  placeholder="Votre e-mail"
                  backgroundColor="white"
                  borderRadius="0px"
                  borderColor={emailError || errorCode ? 'red' : 'unset'}
                  _focus={{ borderColor: '#c1c1c1' }}
                  border={errorCode ? '2px solid red' : '1px solid #c1c1c1'}
                  fontSize="14px"
                  onChange={(e) => {
                    setEmail(e.target.value)
                    setErrorCode('')
                  }}></Input>
                {emailError && (
                  <Text color="red" fontSize="12px" lineHeight="12px" mb="-12px">
                    Ce champ est obligatoire
                  </Text>
                )}
                <InputGroup size="md" mt="20px">
                  <Input
                    value={password}
                    pr="4.5rem"
                    placeholder="Votre mot de passe"
                    backgroundColor="white"
                    borderRadius="0px"
                    borderColor={passwordError || errorCode ? 'red' : 'unset'}
                    _focus={{ borderColor: '#c1c1c1' }}
                    border={errorCode ? '2px solid red' : '1px solid #c1c1c1'}
                    fontSize="14px"
                    onChange={(e) => {
                      setPassword(e.target.value)
                      setErrorCode('')
                    }}
                    type={show ? 'text' : 'password'}></Input>
                  <InputRightElement width="4.5rem" cursor="pointer">
                    <Picto
                      color="grey"
                      width="16px"
                      height="16px"
                      icon="eye"
                      onClick={togglePassword}
                    />
                  </InputRightElement>
                </InputGroup>
                {passwordError && (
                  <Text color="red" fontSize="12px" lineHeight="12px" mb="-12px">
                    Ce champs est obligatoire
                  </Text>
                )}

                <Box textAlign="right" mb="30px">
                  <Link to={'/customer/account/forgotpassword'}>
                    <Text
                      as="span"
                      fontSize="14px"
                      className="forgotten-password"
                      color="#777777"
                      _hover={{
                        textDecoration: 'underline'
                      }}>
                      Mot de passe oublié ?
                    </Text>
                  </Link>
                </Box>
                <Flex direction="column" align="center" flex="1" justify="flex-end">
                  <Button
                    type="submit"
                    backgroundColor={websiteContext.mainColor}
                    fontFamily="PT Sans"
                    color="white"
                    fontSize="16px"
                    fontWeight="400"
                    letterSpacing="0.5px"
                    textTransform="uppercase"
                    borderRadius="0px"
                    _hover={{ bg: websiteContext.darkColor }}
                    isFullWidth={websiteContext.isMobile}>
                    Se connecter
                  </Button>
                </Flex>
              </form>
            </Flex>
            <Flex
              backgroundColor="#eef4ff"
              w={{ base: '100%', md: '50%' }}
              flexDirection="column"
              p={{ base: '20px', md: '30px' }}
              justifyContent="space-between">
              <Text fontWeight="900" fontSize="20px" mb="8px" fontFamily="PT Sans">
                Pas de compte {websiteContext?.title} ?
              </Text>
              <Text fontSize="14px" mb="8px" fontFamily="PT Sans">
                Créez votre compte et gagnez du temps sur votre prochaine commande.
              </Text>
              <Flex fontSize="14px" w={{ base: '100%', md: '80%' }}>
                <List spacing={1} mb="30px" fontFamily="PT Sans">
                  <ListItem>
                    <Flex alignItems="center">
                      <Picto color="green" width="16px" height="16px" icon="check" />
                      <Text as="span" ml="10px">
                        Profitez de vos avantages fidélité
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex alignItems="center">
                      <Picto color="green" width="16px" height="16px" icon="check" />
                      <Box as="span" ml="10px">
                        Sauvegardez votre panier
                      </Box>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex alignItems="center">
                      <Picto color="green" width="16px" height="16px" icon="check" />
                      <Text as="span" ml="10px">
                        Suivez vos commandes
                      </Text>
                    </Flex>
                  </ListItem>
                  <ListItem>
                    <Flex alignItems="center">
                      <Picto color="green" width="16px" height="16px" icon="check" />
                      <Text as="span" ml="10px">
                        Enregistrez vos moyens de paiement
                      </Text>
                    </Flex>
                  </ListItem>
                </List>
              </Flex>
              <Link to="/checkout/account" style={{ textAlign: 'center' }}>
                <Button
                  backgroundColor={websiteContext.mainColor}
                  fontFamily="PT Sans"
                  color="white"
                  fontSize="16px"
                  fontWeight="400"
                  letterSpacing="0.5px"
                  textTransform="uppercase"
                  borderRadius="0px"
                  _hover={{ bg: websiteContext.darkColor }}
                  isFullWidth={websiteContext.isMobile}>
                  Créer un compte
                </Button>
              </Link>
            </Flex>
            {loading && (
              <Flex
                w="100%"
                h="100%"
                position="absolute"
                justify="center"
                align="center"
                background="rgba(0,0,0,0.1)">
                <CircularProgress isIndeterminate={true} color={websiteContext?.mainColor} />
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default LoginPopin
