import { ErrorModal } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { WebsiteContext } from '../context'

const MaxProductModal = (props) => {
  const { isOpen, onOpen, onClose } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <ErrorModal
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      icon="informationCercle"
      message="Le nombre maximal de produits mis dans le panier est atteint. Si vous souhaitez
              ajouter d’autres produits, merci de valider votre commande et de passer une nouvelle
              commande."
      color={websiteContext?.mainColor}
    />
  )
}

export default MaxProductModal
