import {
  Alert,
  AlertIcon,
  Box,
  CircularProgress,
  CloseButton,
  Flex,
  Form,
  Text
} from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import React, { useContext, useState } from 'react'

import { ApiContext, UserContext, WebsiteContext } from '../context'
import { CustomerHelper } from '../utils'
import accountEdit from './forms/accountEdit.schema'
import checkoutEditAccount from './forms/checkoutEditAccount.schema.js'

const AccountEdit = (props) => {
  const { isCheckout = false } = props
  const currentUser = useContext(UserContext)
  let billingAddress = CustomerHelper.getDefaultBillingAddress(currentUser)
  if (!billingAddress) {
    billingAddress = {
      country: {
        name: process.env.GATSBY_API_COUNTRY
      }
    }
  }
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const windowGlobal = typeof window !== 'undefined' && window
  const scrollToTop = () => windowGlobal.scrollTo({ top: 0, behavior: 'smooth' })

  const validateFunction = async (val) => {
    const result = await apiContext?.AccountApi?.updateUser(
      {
        ...val?.currentUser,
        currentPassword: val?.currentPassword,
        newPassword: val?.newPassword,
        confirmNewPassword: val?.confirmNewPassword
      },
      val?.billingAddress,
      isCheckout
    )
    scrollToTop()
    if (result && !result?.error) {
      setAlert({
        status: 'success',
        message: 'Les informations du compte ont été sauvegardées'
      })
      if (isCheckout) {
        navigate('/checkout/shipping', { replace: true })
      }
    } else if (result?.error == 'EmailAddressConflictError') {
      setAlert({
        status: 'error',
        message: 'E-mail déjà associé à un compte client : merci de saisir un autre e-mail.'
      })
    } else {
      setAlert({
        state: 'error',
        message: 'Veuillez vérifier votre mot de passe'
      })
    }
  }

  const cancelFunction = async () => {
    scrollToTop()
  }

  return (
    <Flex direction="column" width="100%" position="relative">
      {alert && (
        <Alert status={alert.status}>
          <AlertIcon />
          {alert.message}
          <CloseButton
            position="absolute"
            right="8px"
            top="8px"
            onClick={() => {
              setAlert(null)
            }}
          />
        </Alert>
      )}
      <Flex
        direction="column"
        padding={{ base: '0px 15px 30px', md: '40px' }}
        backgroundColor="#ffffff">
        {!billingAddress && !loading && (
          <Text
            fontFamily={websiteContext?.fontFamily}
            fontSize="14px"
            color="#333333"
            mt="15px"
            mb="15px">
            Veuillez compléter vos informations personnelles en saisissant votre adresse de
            facturation.
          </Text>
        )}
        {
          <Form
            formSettings={isCheckout ? checkoutEditAccount : accountEdit}
            originValue={{
              currentUser,
              billingAddress,
              currentPassword: '',
              newPassword: '',
              confirmNewPassword: ''
            }}
            validateFunction={validateFunction}
            cancelFunction={cancelFunction}
            formOptions={{ mainColor: websiteContext?.mainColor }}
            setLoading={setLoading}
            isCheckout={isCheckout}
          />
        }
      </Flex>
      <Box
        mt="20px"
        mb="10px"
        fontSize="11px"
        ml={{ md: '20px' }}
        fontFamily="PT Sans Narrow"
        color="#5e5e5e"
        align="justify"
        sx={{
          '& a': {
            color: websiteContext?.mainColor,
            '&:hover': {
              textDecoration: 'underline',
              color: websiteContext?.darkColor
            }
          }
        }}
        dangerouslySetInnerHTML={{ __html: websiteContext?.legalMention }}
      />
      {loading && (
        <Flex
          position="absolute"
          width="100%"
          height="100%"
          justify="center"
          paddingTop="90px"
          style={{ backgroundColor: 'rgb(0, 0, 0, 0.2)' }}>
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      )}
    </Flex>
  )
}

export default AccountEdit
