import {
  Box,
  Carousel,
  CircularProgress,
  Flex,
  GatsbyImage,
  ImageZoomOverlay,
  Media,
  Picto,
  useDisclosure,
  useWindowWidth
} from '@stars-ecom/shared-atoms-ui'
import head from 'lodash/head'
import uniqBy from 'lodash/uniqBy'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'

import { DataLayerUtils, WebsiteContext } from '../context'

const windowGlobal = typeof window !== 'undefined' && window
const slidesToShow = 4

const ProductImage = (props) => {
  const { product, order, collection } = props
  const elementRef = useRef()

  const assets = [
    ...(product?.assets || [])?.filter((a) => a.mimeType === 'video/mp4'),
    ...(product?.assets || [])?.filter((a) => a.mimeType !== 'video/mp4')
  ]

  const nbVideo = uniqBy(assets, (a) => a.id)?.filter(
    (asset) => asset?.mimeType === 'video/mp4'
  ).length

  const websiteContext = useContext(WebsiteContext)

  const [currentImage, setCurrentImage] = useState(head(assets))

  const [screenWidth, setWidthState] = useState(windowGlobal ? windowGlobal?.innerWidth : 0)

  const windowWidth = useWindowWidth()

  useEffect(() => {
    if (windowWidth) {
      setWidthState(windowWidth)
    }
  }, [windowWidth])

  const { isOpen, onOpen, onClose } = useDisclosure()

  const handleImageChange = (image) => {
    setCurrentImage(image)
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Pages produit',
      eventAct: 'Défiler photo',
      eventLab: product?.customFields?.externalCode
    })
  }

  const zoom = () => {
    onOpen()
  }

  const PrevArrow = (props) => {
    const { onClick, activeSlide } = props
    return (
      <div
        onClick={activeSlide <= 0 ? null : onClick}
        style={{ marginBottom: '12px', cursor: activeSlide <= 0 ? 'default' : 'pointer' }}>
        <Picto
          icon="chevronUp"
          width="20px"
          color={activeSlide <= 0 ? 'silver' : websiteContext?.mainColor}
        />
      </div>
    )
  }

  const NextArrow = (props) => {
    const { onClick, activeSlide } = props
    return (
      <div
        onClick={activeSlide >= product?.assets?.length - slidesToShow ? null : onClick}
        style={{
          marginTop: '12px',
          cursor: activeSlide >= product?.assets?.length - slidesToShow ? 'default' : 'pointer'
        }}>
        <Picto
          icon="chevronDown"
          width="20px"
          color={
            activeSlide >= product?.assets?.length - slidesToShow
              ? 'silver'
              : websiteContext?.mainColor
          }
        />
      </div>
    )
  }

  const Dot = (props) => {
    const { onClick: _onClick, active } = props
    const onClick = () => {
      DataLayerUtils.addEvent({
        event: 'qwampEventLab',
        eventCat: 'Pages produit',
        eventAct: 'Défiler photo',
        eventLab: collection?.name
      })
      if (_onClick) {
        _onClick()
      }
    }
    return (
      <div
        style={{
          width: '14px',
          height: '14px',
          borderRadius: '7px',
          backgroundColor: active ? websiteContext?.mainColor : 'white',
          border: `1px solid ${websiteContext?.mainColor}`,
          cursor: active ? 'default' : 'pointer',
          marginRight: '10px'
        }}
        onClick={active ? null : onClick}
      />
    )
  }

  return (
    <Flex ref={elementRef} direction="row" maxHeight="400" p={{ md: '20px 10px' }} order={order}>
      <Flex
        direction="column"
        style={{ width: 100, marginRight: 20 }}
        display={{ base: 'none', md: 'flex' }}
        alignItems="center">
        <Carousel
          isMobile={websiteContext?.isMobile}
          direction="column"
          slideWidth={104}
          slideHeight={75}
          slidesToShow={slidesToShow}
          slidesToScroll={1}
          nextArrow={NextArrow}
          prevArrow={PrevArrow}
          displayDots={false}
          isProductPage={true}
          gap={10}
          justify="flex-start">
          {uniqBy(assets, (a) => a.id)?.map((a, i) => (
            <div key={`product_image_${i}`}>
              {a.smallImage?.childImageSharp?.gatsbyImageData && (
                <div
                  style={{
                    border: '1px solid',
                    borderColor: currentImage?.id === a?.id ? websiteContext?.mainColor : '#c3d5e3',
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100px',
                    height: '71px',
                    paddingLeft: '7px',
                    paddingRight: '7px'
                  }}
                  onClick={() => handleImageChange(a)}>
                  <GatsbyImage
                    image={a.smallImage?.childImageSharp?.gatsbyImageData}
                    loading="eager"
                    alt={product?.name}
                    title={product?.name}
                  />
                </div>
              )}
              {a.mimeType === 'video/mp4' && (
                <div
                  style={{
                    position: 'relative',
                    border: '1px solid',
                    borderColor: currentImage?.id === a?.id ? websiteContext?.mainColor : '#c3d5e3',
                    marginBottom: '5px',
                    cursor: 'pointer',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100px',
                    height: '71px',
                    paddingLeft: '7px',
                    paddingRight: '7px'
                  }}
                  onClick={() => handleImageChange(a)}>
                  {nbVideo > 1 && (
                    <span
                      style={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        display: 'block',
                        background: websiteContext?.mainColor,
                        color: '#fff',
                        zIndex: 3,
                        fontSize: '9px',
                        borderRadius: '5px 0 0 5px',
                        padding: '0 5px',
                        fontFamily: 'PT Sans'
                      }}>
                      Vidéo {i + 1}
                    </span>
                  )}
                  <GatsbyImage
                    image={product?.featuredAsset?.smallImage?.childImageSharp?.gatsbyImageData}
                    loading="eager"
                    alt={product?.name}
                    title={product?.name}
                  />
                  <svg
                    style={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-14px',
                      marginLeft: '-14px'
                    }}
                    width="28px"
                    height="28px"
                    viewBox="0 0 28 28"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xmlns="http://www.w3.org/2000/svg">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      fill={websiteContext.mainColor}
                      d="M1.71429 14C1.71429 7.21479 7.21479 1.71429 14 1.71429C20.7852 1.71429 26.2857 7.21479 26.2857 14C26.2857 20.7852 20.7852 26.2857 14 26.2857C7.21479 26.2857 1.71429 20.7852 1.71429 14ZM14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0ZM11.1429 20.03L18.8571 14.5864L11.1429 9.14285L11.1429 20.03Z"
                    />
                  </svg>
                </div>
              )}
            </div>
          ))}
        </Carousel>
      </Flex>
      <Flex flexDirection="column">
        <Box
          display={{ base: 'none', md: 'block' }}
          w={{ base: '100%', md: '450px' }}
          h={{ base: 'auto', md: '360px' }}>
          <Media
            asset={currentImage}
            defaultImage={product?.featuredAsset}
            imageAttribute="largeImage"
            loading="lazy"
            alt={product?.name}
            overlayColor={websiteContext.mainColor}
            zoomable={currentImage?.mimeType !== 'video/mp4' ? true : false}
            zoom={zoom}
            style={{
              cursor:
                currentImage?.mimeType !== 'video/mp4'
                  ? `url(
                "data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='32px' height='32px' viewBox='0 0 28 28' style='enable-background:new 0 0 28 28;' xml:space='preserve'%3E %3Cpath d='M13.3573 7.73317V10.5452H16.1694C16.9459 10.5452 17.5754 11.1747 17.5754 11.9513C17.5754 12.7278 16.9459 13.3573 16.1694 13.3573H13.3573V16.1694C13.3573 16.9459 12.7278 17.5754 11.9513 17.5754C11.1747 17.5754 10.5452 16.9459 10.5452 16.1694V13.3573H7.73317C6.95664 13.3573 6.32714 12.7278 6.32714 11.9513C6.32714 11.1747 6.95664 10.5452 7.73317 10.5452H10.5452V7.73317C10.5452 6.95664 11.1747 6.32714 11.9513 6.32714C12.7278 6.32714 13.3573 6.95664 13.3573 7.73317Z M20.4021 3.50044C24.733 7.83135 25.0451 14.6592 21.3384 19.35L28 26.0116L26.5087 27.5029L19.8906 20.8848C15.1974 25.0634 8.00083 24.9025 3.50044 20.4021C-1.16681 15.7348 -1.16681 8.1677 3.50044 3.50044C8.1677 -1.16681 15.7348 -1.16681 20.4021 3.50044ZM18.9108 18.9108C22.7544 15.0671 22.7544 8.83539 18.9108 4.99177C15.0671 1.14814 8.83539 1.14814 4.99177 4.99177C1.14814 8.83539 1.14814 15.0671 4.99177 18.9108C8.83539 22.7544 15.0671 22.7544 18.9108 18.9108Z'/%3E %3C/svg%3E"
              ),
              pointer`
                  : 'default'
            }}
            className="product-image-zoomable"
            analyticData={product?.customFields?.externalCode}
          />
          <ImageZoomOverlay
            isOpen={isOpen}
            onClose={onClose}
            imgSize="hugeImage"
            loading="eager"
            asset={currentImage}
            className="zoom-close-button"
            alt={product?.name}
          />
        </Box>
        <Box
          display={{ base: 'block', md: 'none' }}
          w={{ base: '100%', md: '450px' }}
          h={{ base: 'auto', md: '360px' }}>
          {screenWidth > 0 ? (
            <Carousel
              isMobile={websiteContext?.isMobile}
              direction="row"
              slideWidth={screenWidth}
              slideHeight={340}
              slidesToShow={1}
              slidesToScroll={1}
              slideBelowMd={false}
              displayArrow={false}
              displayDots={true}
              isProductPage={true}
              unmountInvisible={true}
              gap={0}
              dot={Dot}
              justify="flex-start">
              {assets?.map((a, i) => (
                <div key={`product_image_${i}`}>
                  {a.largeImage?.childImageSharp?.gatsbyImageData && (
                    <div
                      style={{
                        marginBottom: '5px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px'
                      }}
                      onClick={() => handleImageChange(a)}>
                      <GatsbyImage
                        image={a.largeImage?.childImageSharp?.gatsbyImageData}
                        loading="eager"
                        alt={product?.name}
                        title={product?.name}
                      />
                    </div>
                  )}
                  {a.mimeType === 'video/mp4' && (
                    <div
                      style={{
                        position: 'relative',
                        marginBottom: '5px',
                        cursor: 'pointer',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: '20px'
                      }}
                      onClick={() => handleImageChange(a)}>
                      <Media
                        asset={currentImage}
                        defaultImage={product?.featuredAsset}
                        imageAttribute="largeImage"
                        loading="lazy"
                        alt={product?.name}
                        overlayColor={websiteContext.mainColor}
                      />
                    </div>
                  )}
                </div>
              ))}
            </Carousel>
          ) : (
            <>
              <CircularProgress h="384px" isIndeterminate color={websiteContext?.mainColor} />
            </>
          )}
        </Box>
        <Flex
          width="100%"
          style={{ marginTop: '10px', width: '100%', borderBottom: '1px solid silver' }}
        />
      </Flex>
    </Flex>
  )
}

export default memo(ProductImage)
