import { Flex, Text } from '@stars-ecom/shared-atoms-ui'
import React, { memo } from 'react'

import { priceFormat } from '../../utils'
import PopoverInfo from '../PopoverInfo'

const _PopoverEcopart = (props) => {
  const { deee = 0, ecomob = 0, price, currency = 'EUR', quantity = 1, showPopover = true } = props
  const countryCode = process.env.GATSBY_API_COUNTRY_CODE

  if (deee !== 0 || ecomob !== 0) {
    return (
      <PopoverInfo
        labelButton={`dont ${priceFormat((deee + ecomob) * quantity, currency)} d'éco-part`}
        showPopover={showPopover}
        fontSize="11px"
        w="100%"
        color="#999">
        <span
          style={{
            fontSize: '15px',
            fontWeight: 700,
            color: '#242a30',
            fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif',
            marginBottom: '10px',
            marginLeft: '5px'
          }}>
          Éco-participation
        </span>
        <Text
          style={{
            fontSize: '13px',
            color: '#242a30',
            fontFamily: 'PT Sans Narrow,PT Sans,Arial,sans-serif',
            lineHeight: '1.3',
            marginRight: '20px',
            marginLeft: '5px'
          }}>
          {`L'éco-participation est une contribution qui s'applique obligatoirement sur le prix
            de vente des appareils électriques ou des éléments d'ameublement vendus en France.
            Elle permet de financer la collecte, le tri, le recyclage et / ou la valorisation
            énergétique de ces produits usagés par les éco-organismes.`}
        </Text>
        <Flex
          direction="column"
          fontSize="11px"
          fontFamily="PT Sans,Arial,sans-serif"
          style={{
            margin: '10px 20px 0 5px',
            color: '#242a30',
            backgroundColor: '#eef4ff',
            padding: '10px'
          }}>
          <Flex direction="row" justify="space-between">
            Hors éco-participation
            <span style={{ textAlign: 'right' }}>
              {priceFormat(price - deee - ecomob, currency)}
            </span>
          </Flex>
          {ecomob !== 0 && (
            <Flex direction="row" justify="space-between">
              éco-participation mobilier
              <span style={{ textAlign: 'right' }}>{priceFormat(ecomob, currency)}</span>
            </Flex>
          )}
          {deee !== 0 && (
            <Flex direction="row" justify="space-between">
              éco-participation {countryCode == "BE" ? "RECUPEL" : "DEEE"} 
              <span style={{ textAlign: 'right' }}>{priceFormat(deee, currency)}</span>
            </Flex>
          )}
        </Flex>
        <Flex
          direction="row"
          justify="space-between"
          style={{
            marginBottom: '15px',
            padding: '10px',
            fontSize: '18px',
            fontWeight: 700,
            color: '#242a30',
            fontFamily: 'PT Sans,Arial,sans-serif',
            marginRight: '20px',
            marginLeft: '5px'
          }}>
          Prix total <span>{priceFormat(price, currency)}</span>
        </Flex>
      </PopoverInfo>
    )
  }
  return null
}

const PopoverEcopart = memo(_PopoverEcopart)

export { PopoverEcopart }
