import get from 'lodash/get'
import React from 'react'

import { Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from '../overlay/Modal'
import GatsbyImage from './GatsbyImage'

const ImageZoomOverlay = (props) => {
  const { isOpen, onClose, asset, imgSize, className, alt } = props
  if (
    get(
      asset,
      `${imgSize}.childImageSharp.gatsbyImageData`,
      get(imgSize, `${imgSize}.childImageSharp.gatsbyImageData`)
    )
  ) {
    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent maxW="50rem">
          <ModalCloseButton
            _hover={{ backgroundColor: 'none' }}
            _focus={{ borderStyle: 'none' }}
            _active={{ backgroundColor: 'none' }}
            style={{ top: '2px', right: '2px', borderStyle: 'none' }}
          />
          <ModalBody>
            <GatsbyImage
              image={get(
                asset,
                `${imgSize}.childImageSharp.gatsbyImageData`,
                get(imgSize, `${imgSize}.childImageSharp.gatsbyImageData`)
              )}
              className={className}
              loading="eager"
              alt={alt}
              title={alt}
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    )
  } else {
    return null
  }
}
export default ImageZoomOverlay
