import { Flex, Link, Svg, Text, useBreakpointValue } from '@stars-ecom/shared-atoms-ui'
import React, { useContext } from 'react'

import { DataLayerUtils, WebsiteContext } from '../../context'

const ReinsurranceItem = (props) => {
  const { link, label, picto, mobileDisplay = true, isEven, isCheckout } = props
  const pictoSize = useBreakpointValue({ base: '35px', md: '46px' })
  const websiteContext = useContext(WebsiteContext)

  return (
    <Flex
      display={{ base: mobileDisplay ? 'flex' : 'none', md: 'flex' }}
      flexDirection="column"
      justify="center"
      align="center"
      width={{ base: isCheckout ? '100%' : '50%', md: '150px' }}
      height="115px"
      pb={isCheckout ? '30px' : '20px'}
      pt={isCheckout ? '30px' : '20px'}
      borderTop={{ base: '1px solid #cfd5e3', md: 'none' }}
      borderRight={{ base: isCheckout || isEven ? 'none' : '1px solid #cfd5e3', md: 'none' }}>
      <Link
        to={link}
        //target="_self"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        onClick={() =>
          DataLayerUtils.addEvent({
            event: 'qwampEventLab',
            eventCat: 'Footer',
            eventAct: 'Départ réassurance',
            eventLab: link
          })
        }>
        <Svg data={picto} color={websiteContext?.mainColor} width={pictoSize} height={pictoSize} />
        <Text
          fontFamily="PT Sans, Arial, sans-serif"
          fontWeight={600}
          fontSize="18px"
          lineHeight="20px"
          textAlign="center"
          mt="13px"
          style={{ maxWidth: '150px', color: '#333333' }}>
          {label}
        </Text>
      </Link>
    </Flex>
  )
}
const Reinsurance = (props) => {
  const { data = [], isCheckout = false } = props
  return (
    <Flex
      flexDirection={{ base: isCheckout ? 'column' : 'row', md: 'row' }}
      width="100%"
      maxWidth="1000px"
      margin="auto"
      justify="space-evenly"
      flexWrap="wrap"
      borderTop={{ base: '0', md: isCheckout ? '1px solid #cfd5e3' : 'none' }}
      borderBottom={isCheckout ? 'none' : '1px solid #cfd5e3'}
      mt={{ base: '20px', md: '0' }}
      pt={{ base: '0', md: isCheckout ? '40px' : '0' }}>
      {data?.map((r, i) =>
        // TODO
        !isCheckout || i > 1 ? (
          <ReinsurranceItem
            key={`reinsurrance_${i}`}
            label={r?.label}
            link={r?.link}
            picto={r?.picto}
            mobileDisplay={i !== 0}
            isEven={i % 2 === 0}
            isCheckout={isCheckout}
          />
        ) : null
      )}
    </Flex>
  )
}

export default Reinsurance
