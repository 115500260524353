import { axios } from '@stars-ecom/shared-organisms-ui'
import get from 'lodash/get'

const getStockLevel = async (productVariantId) => {
  try {
    const result = await axios.get(
      `inventory/${process.env.GATSBY_API_VERSION}/${process.env.GATSBY_API_CHANNEL_ID}/${productVariantId}`
    )
    return get(result, 'data', 'IN_STOCK')
  } catch (e) {
    console.error(e)
    return {}
  }
}
export { getStockLevel }
