import './hipay.css'
import '../layout/simplePage.css'

import {
  Box,
  Button,
  Checkbox,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  rewriteMediaUrl,
  Text,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import React, { forwardRef, memo, useContext, useState } from 'react'

import { WebsiteContext } from '../context'

const Conditions = forwardRef((props, ref) => {
  const { conditions = [], isCGVChecked = false, setIsCGVChecked = () => {} } = props
  const websiteContext = useContext(WebsiteContext)
  const [modalData, setModalData] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const openModal = (slug) => {
    setModalData(conditions.find((e) => e.slug === slug))
    onOpen()
  }
  const contentWithMediaUrls = modalData ? rewriteMediaUrl(modalData.content) : ''
  return (
    <Flex justify="center">
      <Flex
        direction="row"
        flexWrap="wrap"
        align="center"
        justify="center"
        mb="20px"
        w={{ base: '90%', md: '60%' }}>
        <Checkbox
          ref={ref}
          size="10px"
          isChecked={isCGVChecked}
          onChange={(e) => setIsCGVChecked(e.target.checked)}
          backgroundColorChecked={websiteContext?.mainColor}
        />
        <Text
          ml="10px"
          textAlign="center"
          fontSize="12px"
          color="#8b8b8b"
          fontFamily={websiteContext.fontFamily}>
          J&apos;accepte les
        </Text>
        <Button
          borderRadius="0"
          textAlign="center"
          color="#8b8b8b"
          h="20px"
          p={0}
          mr="4px"
          ml="4px"
          _hover={{ border: '0', color: websiteContext?.mainColor }}
          _focus={{ border: '0', color: websiteContext?.mainColor }}
          fontSize="12px"
          fontFamily={websiteContext.fontFamily}
          fontWeight="400"
          textDecoration="underline"
          variant="unstyled"
          onClick={() => openModal('cgv')}>
          Conditions générales de Vente
        </Button>
        <Text
          textAlign="center"
          fontSize="12px"
          color="#8b8b8b"
          fontFamily={websiteContext.fontFamily}>
          et le cas échéant*, les
        </Text>
        <Button
          borderRadius="0"
          textAlign="center"
          _hover={{ border: '0', color: websiteContext?.mainColor }}
          _focus={{ border: '0', color: websiteContext?.mainColor }}
          fontSize="12px"
          h="20px"
          p={0}
          color="#8b8b8b"
          fontFamily={websiteContext.fontFamily}
          fontWeight="400"
          textDecoration="underline"
          variant="unstyled"
          ml="4px"
          mr="4px"
          onClick={() => openModal('cgv-fidelite')}>
          Conditions Générales d’Adhésion
        </Button>{' '}
        <Text
          textAlign="center"
          fontSize="12px"
          color="#8b8b8b"
          fontFamily={websiteContext.fontFamily}>
          de la carte fidélité
        </Text>
      </Flex>

      <Modal size="4xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderRadius={0}
          w={{ base: '90%', md: '100%' }}
          h={{ base: '90%', md: 'auto' }}>
          <ModalHeader>{modalData?.title}</ModalHeader>
          <ModalCloseButton
            style={{ boxShadow: 'none' }}
            right={{ base: '0', md: '-4px' }}
            _hover={{ background: 'none' }}
          />
          <ModalBody background="white">
          {contentWithMediaUrls&& 
            <Box
              maxH={{ base: 'unset', md: '360px' }}
              overflow={{ base: 'auto', md: 'scroll' }}
              className="simple-page-content"
              dangerouslySetInnerHTML={{ __html: contentWithMediaUrls }}></Box>}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  )
})
Conditions.displayName = 'Conditions'
export default memo(Conditions)
