import _BackgroundImage from 'gatsby-background-image'
import { getImage } from 'gatsby-plugin-image'
import { convertToBgImage } from 'gbimage-bridge'
import React, { forwardRef, memo } from 'react'

import Box from './Box'

const BackgroundImage = forwardRef((props, ref) => {
  const {
    backgroundImage,
    backgroundColor,
    tag = 'section',
    anchor,
    children,
    style = {},
    width,
    height,
    className
  } = props

  const image = convertToBgImage(getImage(backgroundImage))

  return (
    <Box ref={ref} width={width} height={height} style={{backgroundColor, ...style}} className={className}>
      {image ? (
        <_BackgroundImage
          tag={tag}
          preserveStackingContext
          {...image}
          style={style}
          id={anchor}
          className={className}>
          {children}
        </_BackgroundImage>
      ) : (
        <Box style={{ backgroundColor, ...style }} id={anchor} className={className}>
          {children}
        </Box>
      )}
    </Box>
  )
})

export default memo(BackgroundImage)
