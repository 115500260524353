import {
  Alert,
  AlertIcon,
  Box,
  CircularProgress,
  CloseButton,
  Flex,
  Image,
  Text,
  useState
} from '@stars-ecom/shared-atoms-ui'
import debounce from 'lodash/debounce'
import isArray from 'lodash/isArray'
import React, { memo, useCallback, useContext, useEffect } from 'react'

import {
  bancontact,
  //americanExpress, cb,
  mastercard,
  visa
} from '../checkout/icones'
import { ApiContext, CartContext, PaymentContext, WebsiteContext } from '../context'
import { priceFormat } from '../utils'
import PaymentForm from './PaymentForm'
import PaymentModeItem from './PaymentModeItem'
import PledgPaymentForm from './PledgPaymentForm'
const PaymentModeList = (props) => {
  const { cgv, error = '', closeErrorMsg = () => {} } = props
  const [paymentMethods, setPaymentMethods] = useState([])
  const [pledgError, setPledgError] = useState(false)
  const { paymentMethod, setPaymentMethod } = useContext(PaymentContext)
  const [loading, setLoading] = useState(false)
  const apiContext = useContext(ApiContext)
  const currentCart = useContext(CartContext)
  const websiteContext = useContext(WebsiteContext)

  const hasOutOfStock = currentCart?.lines?.reduce(
    (a, c) => c.customFields.stockLevel === 'OUT_OF_STOCK' || a,
    false
  )
  const getEligiblePaymentMethods = async () => {
    setLoading(true)
    const result = await apiContext.OrderApi.eligiblePaymentMethods()
    if (result && isArray(result)) {
      setPaymentMethods(result?.filter((p) => p?.isEligible) || [])
    }
    setLoading(false)
  }
  const appendScript = (scriptToAppend) => {
    const script = document.createElement('script')
    script.src = scriptToAppend
    script.async = true
    document.body.appendChild(script)
  }
  const isEligiblePledgPayment = () => {
    if (pledgError) {
      return false
    } else {
      if (currentCart?.lines?.length !== 1) {
        return false
      }
      if (currentCart?.lines[0]?.productVariant?.stockLevel !== 'IN_STOCK') {
        return false
      }
    }

    return true
  }
  const isEligibleBcontactPayment = () => {
    let inStock = true
    currentCart?.lines?.map((item) => {
      if (item?.productVariant?.stockLevel !== 'IN_STOCK') {
        inStock = false
        return
      }
    })
    return inStock
  }
  useEffect(() => {
    debounce(getEligiblePaymentMethods, 200)()
    if (paymentMethod && paymentMethods?.length >= 1) {
      setPaymentMethod(paymentMethods.find((pm) => pm.code === 'standard'))
    }
  }, [currentCart, pledgError])

  useEffect(() => {
    if (!paymentMethod && paymentMethods?.length >= 1) {
      setPaymentMethod(paymentMethods.find((pm) => pm.code === 'standard'))
    }

    if (paymentMethods?.some((pm) => (pm?.code).search(/pledg/i))) {
      if (process.env.GATSBY_API_STAGE === 'prod') {
        appendScript(
          'https://s3-eu-west-1.amazonaws.com/pledg-assets/ecard-plugin/master/plugin.min.js'
        )
      } else {
        appendScript(
          'https://s3-eu-west-1.amazonaws.com/pledg-assets/ecard-plugin/staging/plugin.min.js'
        )
      }
    }
  }, [paymentMethods])

  const displayPaymentMethods = useCallback(() => {
    const result = []
    const standard = paymentMethods?.find((pm) => pm.code === 'standard')
    const multiple2 = paymentMethods.find((pm) => pm.code === 'multiple2')
    const multiple3 = paymentMethods.find((pm) => pm.code === 'multiple3')
    const multiple4 = paymentMethods.find((pm) => pm.code === 'multiple4')
    const bcontact = isEligibleBcontactPayment()
      ? paymentMethods.find((pm) => pm.code === 'bcontact')
      : false
    const pledg10x = isEligiblePledgPayment()
      ? paymentMethods.find((pm) => pm.code === 'pledg10x')
      : false
    const pledg4x = isEligiblePledgPayment()
      ? paymentMethods.find((pm) => pm.code === 'pledg4x')
      : false
    const pledg3x = isEligiblePledgPayment()
      ? paymentMethods.find((pm) => pm.code === 'pledg3x')
      : false

    //const deferred30 = paymentMethods.find((pm) => pm.code === 'deffered30')
    //const deffered60 = paymentMethods.find((pm) => pm.code === 'deffered60')
    if (standard) {
      result.push(
        <Flex direction="column" key="standard">
          <Text
            mt="20px"
            mb="10px"
            as="h3"
            style={{
              fontSize: '18px',
              color: 'rgb(36,42,48)',
              fontWeight: 700,
              fontFamily: websiteContext.fontFamily
            }}>
            Paiement comptant :
          </Text>
          <PaymentModeItem
            selected={paymentMethod?.code === 'standard'}
            paymentMethod={standard}
            head={
              <>
                <Text>{'Par carte bancaire'}</Text>
                <Flex pl="20px" w="90px" justifyContent="space-between">
                  <Image src={visa} w="32px" />
                  <Image src={mastercard} w="32px" />
                </Flex>
              </>
            }
            message={`Afin de sécuriser votre paiement, votre banque est susceptible de vous demander une
            authentification. Munissez-vous dès à présent de votre portable ou de votre smartphone
            au cas où.`}
            value="carte">
            <PaymentForm
              cgv={cgv}
              paymentMethod="standard"
              isMultiple={false}
              hasOutOfStock={hasOutOfStock}
            />
          </PaymentModeItem>
        </Flex>
      )
    }
    if (bcontact) {
      result.push(
        <Flex direction="column" key="bcontact">
          <Text
            mt="20px"
            mb="10px"
            as="h3"
            style={{
              fontSize: '18px',
              color: 'rgb(36,42,48)',
              fontWeight: 700,
              fontFamily: websiteContext.fontFamily
            }}>
            Paiement Bancontact :
          </Text>
          <PaymentModeItem
            selected={paymentMethod?.code === 'bcontact'}
            paymentMethod={bcontact}
            head={
              <>
                <Text>{'Par carte bancaire'}</Text>
                <Flex pl="20px" w="90px" justifyContent="space-between">
                  <Image src={bancontact} w="32px" />
                </Flex>
              </>
            }
            message={`Afin de sécuriser votre paiement, votre banque est susceptible de vous demander une
            authentification. Munissez-vous dès à présent de votre portable ou de votre smartphone
            au cas où.`}
            value="carte">
            <PaymentForm cgv={cgv} paymentMethod="bcontact" isMultiple={false} />
          </PaymentModeItem>
        </Flex>
      )
    }
    if (multiple2 || multiple3 || multiple4 || pledg4x || pledg3x) {
      result.push(
        <Text
          key="multiple"
          mt="20px"
          mb="10px"
          as="h3"
          style={{
            fontSize: '18px',
            color: 'rgb(36,42,48)',
            fontWeight: 700,
            fontFamily: websiteContext.fontFamily
          }}>
          Paiement en plusieurs fois par carte bancaire :
        </Text>
      )
    }
    if (!pledg4x && !pledg3x && multiple2) {
      result.push(
        <PaymentModeItem
          key="multiple2"
          selected={paymentMethod?.code === 'multiple2'}
          paymentMethod={multiple2}
          head={
            <Text>
              {'Paiement en 2 fois, soit '}
              <strong>{`${priceFormat(currentCart?.totalWithTax / 2, 'EUR')}/mois¹ + ${priceFormat(
                multiple2?.customFields?.applicationFee || 0,
                'EUR'
              )}`}</strong>
              {' de frais de dossier²'}
            </Text>
          }
          value="carte">
          <PaymentForm cgv={cgv} paymentMethod="multiple2" isMultiple={true} />
        </PaymentModeItem>
      )
    }
    if (!pledg4x && !pledg3x && multiple3) {
      result.push(
        <PaymentModeItem
          key="multiple3"
          selected={paymentMethod?.code === 'multiple3'}
          paymentMethod={multiple3}
          head={
            <Text>
              {'Paiement en 3 fois, soit '}
              <strong>{`${priceFormat(currentCart?.totalWithTax / 3, 'EUR')}/mois¹ + ${priceFormat(
                multiple3?.customFields?.applicationFee || 0,
                'EUR'
              )}`}</strong>
              {' de frais de dossier²'}
            </Text>
          }
          value="carte">
          <PaymentForm cgv={cgv} paymentMethod="multiple3" isMultiple={true} />
        </PaymentModeItem>
      )
    }
    if (!pledg4x && !pledg3x && multiple4) {
      result.push(
        <PaymentModeItem
          selected={paymentMethod?.code === 'multiple4'}
          paymentMethod={multiple4}
          key="multiple4"
          head={
            <Text>
              {'Paiement en 4 fois '}
              <strong>{`${priceFormat(currentCart?.totalWithTax / 4, 'EUR')}/mois¹ + ${priceFormat(
                multiple4?.customFields?.applicationFee || 0,
                'EUR'
              )}`}</strong>
              {' de frais de dossier²'}
            </Text>
          }
          value="carte">
          <PaymentForm cgv={cgv} paymentMethod="multiple4" isMultiple={true} />
        </PaymentModeItem>
      )
    }
    if (pledg4x) {
      result.push(
        <PaymentModeItem
          selected={paymentMethod?.code === 'pledg4x'}
          paymentMethod={pledg4x}
          key="pledg4x"
          idPledgButton="pledg-button-4x"
          head={
            <>
              <Text dangerouslySetInnerHTML={{ __html: pledg4x?.description }}></Text>
              <Flex pl="20px" w="90px" justifyContent="space-between">
                <Image src={visa} w="32px" />
                <Image src={mastercard} w="32px" />
              </Flex>
            </>
          }
          value="carte">
          <PledgPaymentForm
            idPledgButton="pledg-button-4x"
            paymentMethod="pledg4x"
            setPledgError={setPledgError}
            cgv={cgv}
          />
        </PaymentModeItem>
      )
    }
    if (pledg3x) {
      result.push(
        <PaymentModeItem
          selected={paymentMethod?.code === 'pledg3x'}
          paymentMethod={pledg3x}
          key="pledg3x"
          idPledgButton="pledg-button-3x"
          head={
            <>
              <Text dangerouslySetInnerHTML={{ __html: pledg3x?.description }}></Text>
              <Flex pl="20px" w="90px" justifyContent="space-between">
                <Image src={visa} w="32px" />
                <Image src={mastercard} w="32px" />
              </Flex>
            </>
          }
          value="carte">
          <PledgPaymentForm
            idPledgButton="pledg-button-3x"
            paymentMethod="pledg3x"
            setPledgError={setPledgError}
            cgv={cgv}
          />
        </PaymentModeItem>
      )
    }

    if (pledg10x) {
      result.push(
        <PaymentModeItem
          selected={paymentMethod?.code === 'pledg10x'}
          paymentMethod={pledg10x}
          key="pledg10x"
          idPledgButton="pledg-button-10x"
          head={
            <>
              <Text dangerouslySetInnerHTML={{ __html: pledg10x?.description }}></Text>
              <Flex pl="20px" w="90px" justifyContent="space-between">
                <Image src={visa} w="32px" />
                <Image src={mastercard} w="32px" />
              </Flex>
            </>
          }
          value="carte">
          <PledgPaymentForm
            idPledgButton="pledg-button-10x"
            paymentMethod="pledg10x"
            setPledgError={setPledgError}
            cgv={cgv}
          />
        </PaymentModeItem>
      )
    }

    return result
  }, [paymentMethods, paymentMethod])

  return (
    <Box w="100%">
      <Text
        p={{ md: '13px 0' }}
        as="h1"
        fontSize={{ base: '17px', md: '22px' }}
        style={{
          color: 'rgb(36,42,48)',
          fontWeight: 700,
          fontFamily: websiteContext.fontFamily
        }}>
        Choisissez votre mode de paiement :
      </Text>
      {error && (
        <Alert status="error" mb="20px">
          <AlertIcon />
          {error}
          <CloseButton position="absolute" right="8px" top="8px" onClick={closeErrorMsg} />
        </Alert>
      )}
      {loading && <CircularProgress isIndeterminate color={websiteContext?.mainColor} />}
      {!loading && displayPaymentMethods()}
    </Box>
  )
}

export default memo(PaymentModeList)
