import { Box, Link, Select, Text } from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import React, { memo, useContext } from 'react'

import { CartContext, ShippingContext, UserContext } from '../../context'
import { CustomerHelper, ShippingUtils } from '../../utils'

const AddressSelection = (props) => {
  const { showAddressForm, isHeavy, isDisabled = false } = props
  const currentUser = useContext(UserContext)
  const currentCart = useContext(CartContext)
  const { shipping, setShipping } = useContext(ShippingContext)
  const addresses = CustomerHelper.getShippingAddresses(currentUser)

  const updateShipping = ({
    shippingMethod,
    shippingCustomerAddress,
    shippingRelayAddress,
    phone,
    cellPhone,
    isHeavy = false
  }) => {
    const newShipping = ShippingUtils.updateShipping(
      {
        shippingMethod,
        shippingCustomerAddress,
        shippingRelayAddress,
        phone,
        cellPhone,
        isHeavy
      },
      currentCart,
      currentUser,
      shipping
    )
    setShipping(newShipping)
  }

  const handleAddressChange = (idAddress) => {
    const address = addresses?.find((a) => a.id === idAddress)
    updateShipping({ shippingCustomerAddress: address, isHeavy })
  }

  return (
    <Box w={['auto', '370px']} bg="#F6F6F6" p="20px" m={{ base: '0 10px 0 40px', md: '0' }}>
      <Select
        isDisabled={isDisabled}
        value={get(shipping, `shippingAddress.${isHeavy ? 'heavy' : 'light'}.id`, '')}
        backgroundColor="white"
        borderRadius="0"
        fontFamily="PT Sans, Arial, sans-serif"
        onChange={(e) => {
          handleAddressChange(e.target.value)
        }}>
        {addresses &&
          addresses.map((address) => (
            <option value={address.id} key={address.id}>
              {address.fullName || address.city}
            </option>
          ))}
      </Select>
      <DisplayAddress address={get(shipping, `shippingAddress.${isHeavy ? 'heavy' : 'light'}`)} />
      {isDisabled ? (
        <Text
          style={{
            textDecoration: 'underline',
            fontFamily: 'PT Sans, Arial, sans-serif',
            color: '#333333',
            fontSize: '16px'
          }}>
          {`L'adresse de livraison à domicile choisie en étape 1 a été appliquée.`}
        </Text>
      ) : (
        <Link onClick={showAddressForm}>
          <Text
            as="span"
            fontSize={{ base: '17px', md: '16px' }}
            style={{
              textDecoration: 'underline',
              fontFamily: 'PT Sans, Arial, sans-serif',
              color: '#333333',
              cursor: 'pointer'
            }}>
            Livrer à une nouvelle adresse
          </Text>
        </Link>
      )}
    </Box>
  )
}

const _DisplayAddress = (props) => {
  const { address = {} } = props
  return (
    <Box py="20px">
      <Text fontSize="16px" fontFamily="PT Sans, Arial, sans-serif">{`${
        get(address, 'customFields.firstName') || ''
      } ${get(address, 'customFields.lastName', '')}`}</Text>
      <Text fontSize="16px" fontFamily="PT Sans, Arial, sans-serif">{`${
        get(address, 'streetLine1') || ''
      } ${get(address, 'streetLine2') || ''} ${
        get(address, 'customFields.addressLine3') || ''
      } `}</Text>
      <Text fontSize="16px" fontFamily="PT Sans, Arial, sans-serif">{`${
        get(address, 'postalCode') || ''
      } ${get(address, 'city') || ''}`}</Text>
    </Box>
  )
}

const DisplayAddress = memo(_DisplayAddress)

export default AddressSelection
