import { Box, Button, Flex, Text } from '@stars-ecom/shared-atoms-ui'
import isFunction from 'lodash/isFunction'
import trim from 'lodash/trim'
import React from 'react'
const Address = (props) => {
  const { address = {}, onEdit = () => {}, onDelete = () => {} } = props

  const editHandler = () => {
    isFunction(onEdit) && onEdit(address)
  }
  const windowGlobal = typeof window !== 'undefined' && window
  const scrollToTop = () => windowGlobal.scrollTo({ top: 0, behavior: 'smooth' })

  const deleteHandler = () => {
    isFunction(onDelete) && onDelete(address)
    scrollToTop()
  }
  return (
    <Flex
      direction="column"
      justify="space-between"
      width={{ base: '100%', md: '325px' }}
      backgroundColor="#f6f3f1"
      minHeight={{ base: 'auto', md: '190px' }}
      pt="20px"
      pb="15px"
      pl="15px"
      pr="15px">
      <Box>
        <Text
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="14px"
          color="#333333"
          fontWeight={700}>
          {address?.fullName || `Adresse ${address?.customFields?.externalCode ?? ''}`}
        </Text>
        <Text
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize="14px"
          color="#333333"
          mb="5px"
          fontStyle={{ base: 'italic', md: 'normal' }}>
          {`${address?.customFields?.civility ?? ''} ${address?.customFields?.firstName ?? ''} ${
            address?.customFields?.lastName ?? ''
          }`}
          <br />
          {trim(address?.streetLine1) && <>{address?.streetLine1}</>}
          {trim(address?.streetLine2) && (
            <>
              <br />
              {address?.streetLine2}
            </>
          )}
          {trim(address?.customFields?.addressLine3) && (
            <>
              <br />
              {address?.customFields?.addressLine3}
            </>
          )}
          {trim(address?.customFields?.addressLine4) && (
            <>
              <br />
              {address?.customFields?.addressLine4}
            </>
          )}
          {trim(`${address?.postalCode} ${address?.city}`) && (
            <>
              <br />
              {`${address?.postalCode} ${address?.city}`}
            </>
          )}
          {trim(address?.phoneNumber) && (
            <>
              <br />
              {`T : ${address?.phoneNumber}`}
            </>
          )}
          {trim(address?.customFields?.cellPhoneNumber) && (
            <>
              <br />
              {`P : ${address?.customFields?.cellPhoneNumber}`}
            </>
          )}
        </Text>
      </Box>

      <Flex
        direction="row"
        justifyContent={{ base: 'center', md: 'initial' }}
        mt={{ base: '5px', md: '0px' }}>
        <Button
          onClick={editHandler}
          variant="solid"
          style={{
            borderRadius: 0,
            color: '#ffffff',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '28px',
            fontSize: '14px',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontWeight: 400,
            fontFamily: 'PT Sans,Arial,sans-serif',
            boxShadow: 'none',
            marginRight: '15px'
          }}
          backgroundColor="#c1c1c1"
          _hover={{ backgroundColor: '#8d8e90' }}>
          Editer
        </Button>
        <Button
          onClick={deleteHandler}
          variant="solid"
          style={{
            borderRadius: 0,
            color: '#ffffff',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            height: '28px',
            fontSize: '14px',
            paddingLeft: '20px',
            paddingRight: '20px',
            fontWeight: 400,
            fontFamily: 'PT Sans,Arial,sans-serif',
            boxShadow: 'none'
          }}
          backgroundColor="#c1c1c1"
          _hover={{ backgroundColor: '#8d8e90' }}>
          Supprimer
        </Button>
      </Flex>
    </Flex>
  )
}
export default Address
