import {
  Alert,
  AlertDescription,
  Box,
  Button,
  ChakraIcons,
  Flex,
  FormControl,
  FormLabel,
  GatsbyImage,
  Image,
  Input,
  Link,
  ListItem,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Picto,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Spinner,
  Text,
  UnorderedList,
  useBreakpointValue,
  useDisclosure
} from '@stars-ecom/shared-atoms-ui'
import dayjs from 'dayjs'
import { get, isEmpty, trim } from 'lodash'
import React, { memo, useContext, useEffect, useRef, useState } from 'react'

import { cb, mastercard, visa } from '../checkout/icones'
import { ApiContext, DataLayerUtils, UserContext, WebsiteContext } from '../context'
import { getExpiredCards, Regex } from '../utils'
import { AccountMenu } from './AccountMenu'
import LogoutButton from './LogoutButton'

const windowGlobal = typeof window !== 'undefined' && window

const PopoverContentChevronDown = ({
  heightMobile = '100%',
  heightDesktop = '100%',
  rightGapChevron = '0px',
  ...props
}) => {
  const contentHeightBreakpoint = useBreakpointValue({ base: heightMobile, md: heightDesktop })
  const transition = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3
      }
    },
    enter: {
      height: contentHeightBreakpoint,
      opacity: 1,
      transition: {
        duration: 0.3
      }
    }
  }

  const websiteContext = useContext(WebsiteContext)

  return (
    <PopoverContent
      width={{ base: '100vw', md: '482px' }}
      overflow="hidden"
      border="none"
      borderRadius="0"
      variants={transition}
      style={{
        boxShadow: 'none'
      }}>
      <Flex
        direction="column"
        p="20px"
        bg="#fff"
        w="full"
        border="1px"
        borderColor="#cfd5e3"
        borderTop="4px"
        borderTopColor={websiteContext?.mainColor}
        _before={{
          position: 'absolute',
          display: 'block',
          content: `""`,
          border: '25px solid transparent',
          right: rightGapChevron,
          top: 0,
          borderTopColor: websiteContext?.mainColor
        }}
        _after={{
          position: 'absolute',
          display: 'block',
          content: `""`,
          border: '25px solid transparent',
          right: rightGapChevron,
          top: '-6px',
          borderTopColor: '#fff'
        }}
        {...props}
      />
    </PopoverContent>
  )
}

const InfoPopup = () => {
  const {
    onOpen: onOpenInfo,
    onClose: onCloseInfo,
    isOpen: isOpenInfo,
    onToggle: onToggleInfo
  } = useDisclosure()
  const howShowInfo = useBreakpointValue({ base: 'click', md: 'hover' })

  const websiteContext = useContext(WebsiteContext)

  return (
    <Popover
      placement="bottom-end"
      trigger={howShowInfo}
      isOpen={isOpenInfo}
      onToggle={onToggleInfo}
      onClose={onCloseInfo}
      onOpen={onOpenInfo}>
      <PopoverTrigger>
        <ChakraIcons.QuestionIcon boxSize="20px" color="#c1c1c1" style={{ cursor: 'pointer' }} />
      </PopoverTrigger>
      <PopoverContent
        style={{ boxShadow: 'none' }}
        minW={{ base: '100%', md: '382px' }}
        borderRadius="0"
        p="10px"
        bg="#f6f3f1"
        color="#333"
        border="1px"
        borderColor="#333"
        fontSize="14px"
        fontWeight="normal"
        lineHeight="17px">
        <Text>
          Pour les clients sans compte web souhaitant créer un compte, retrouvez votre numéro de
          client :
        </Text>
        <UnorderedList ml="40px">
          <ListItem>en haut de votre facture</ListItem>
          <ListItem>sur le SMS de création de compte reçu</ListItem>
          <ListItem>ou par le Service Client au {websiteContext?.savPhoneNumber}</ListItem>
        </UnorderedList>
      </PopoverContent>
    </Popover>
  )
}

const AccountButton = (props) => {
  const {
    forgottenPasswordLabel = 'Mot de passe oublié ?',
    forgottenPasswordLink,
    isOpenCart
  } = props
  const websiteContext = useContext(WebsiteContext)
  const currentUser = useContext(UserContext)
  const [iconColor, setIconColor] = useState('#333333')
  const { isOpen, onClose, onOpen } = useDisclosure()

  // const handleClick = (state) => {
  //   if (!state) {
  //     state = isOpenAccount
  //   }
  //   if (state) {
  //     onCloseAccount()
  //   } else {
  //     onOpenAccount()
  //     DataLayerUtils.addEvent({
  //       event: 'qwampEvent',
  //       eventCat: 'Header',
  //       eventAct: 'Clic compte client'
  //     })
  //   }
  // }

  // useEffect(() => {
  //   if (!isOpen) {
  //     // Hack for ios
  //     debounce(handleClick, 200)(true)
  //   }
  // }, [isOpen])

  //close popoverAccount if hover CartButton
  useEffect(() => {
    if (isOpenCart) {
      onClose()
    }
  }, [isOpenCart])

  useEffect(() => {
    setIconColor(currentUser?.id ? websiteContext?.mainColor : '#333333')
  }, [currentUser])

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      strategy="fixed"
      trigger="click"
      autoFocus
      closeOnBlur
      offset={[-200, 10]}>
      <PopoverTrigger placement="bottom">
        <Button
          id="accountButton"
          variant="unstyled"
          style={{
            border: 'none',
            boxShadow: 'none',
            display: 'flex ',
            flexDirection: 'column',
            alignItems: 'center',
            width: '40px',
            height: 'auto'
          }}
          onClick={() =>
            DataLayerUtils.addEvent({
              event: 'qwampEvent',
              eventCat: 'Header',
              eventAct: 'Clic compte client'
            })
          }>
          <Picto icon="user" width="28px" height="28px" color={iconColor} />
          <Text
            display={{ base: 'none', md: 'block' }}
            fontSize="12px"
            fontFamily={websiteContext?.fontFamily}
            fontWeight="normal"
            mt="5px">
            Compte
          </Text>
        </Button>
      </PopoverTrigger>
      <Portal>
        {currentUser ? (
          <ConnectedContent onClose={onClose} />
        ) : (
          <LoginContent {...{ forgottenPasswordLabel, forgottenPasswordLink }} />
        )}
      </Portal>
    </Popover>
  )
}

const LoginContent = (props) => {
  const { forgottenPasswordLabel, forgottenPasswordLink } = props
  const [login, setLogin] = useState('')
  const [password, setPassword] = useState('')
  const [passwordLabel, setPasswordLabel] = useState('Votre mot de passe')
  const [passwordPlaceholder, setPasswordPlaceholder] = useState('Mot de passe')
  const [loginType, setLoginType] = useState('email')
  const [passwordType, setPasswordType] = useState('password')
  const [loading, setLoading] = useState('')
  const [error, setError] = useState()
  const [success, setSuccess] = useState()
  const websiteContext = useContext(WebsiteContext)
  const apiContext = useContext(ApiContext)
  const loginInputRef = useRef()
  const [redirectionData, setRedirectionData] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure()
  const doLogin = async (e) => {
    setError(false)
    e.preventDefault()
    setLoading(true)
    if (
      !(login && parseInt(trim(login)) == trim(login) && login.length > 5) &&
      !Regex.validateEmail(login)
    ) {
      setError('Veuillez vérifier le format de votre adresse e-mail.')
      setLoading(false)
      return
    }
    const result = await apiContext?.AccountApi?.login(login, password)
    console.log('resultLogin', isEmpty(result), result)
    if (result && result.errorCode === 'WRONG_MAIN_WEBSITE') {
      // windowGlobal.location.href = `https://${result.dns}`
      setRedirectionData({ dns: result.dns, country: result.country })
      onOpen()
    } else if (result?.errorCode === 'INVALID_CREDENTIALS_ERROR' || isEmpty(result)) {
      setError('Compte non identifié ou mot de passe incorrect.')
    } else if (result?.errorCode === 'CUSTOMER_ALREADY_EXISTS') {
      setError(
        'Ce numéro de client est associé à un compte web actif. Veuillez vous identifier avec votre adresse e-mail et votre mot de passe.'
      )
    } else if (result?.errorCode === 'ACCOUNT_CREATED') {
      setSuccess(
        'Votre compte a été créé. Vous aller recevoir un email vous permettant de réinitialiser votre mot de passe.'
      )
    }
    DataLayerUtils.addEvent({
      event: 'loginEvent',
      customerId: get(result, '0.id') 
    })
    setLoading(false)
  }
  useEffect(() => {
    if (login && parseInt(trim(login)) == trim(login) && login.length > 5) {
      setPasswordLabel('Les 4 premières lettres de votre nom')
      setPasswordPlaceholder('4 premières lettres de votre nom')
      setLoginType('text')
      setPasswordType('text')
    } else {
      setPasswordLabel('Votre mot de passe')
      setPasswordPlaceholder('Mot de passe')
      setLoginType('email')
      setPasswordType('password')
    }
  }, [login])

  useEffect(() => {
    // Hack to handle cursor position change on changing loginType from email to text
    if (loginType !== 'email') {
      if (loginInputRef) {
        const end = login.length
        loginInputRef?.current?.setSelectionRange(end, end)
        loginInputRef?.current?.focus()
      }
    }
  }, [loginType])
  return (
    <>
      <Modal isOpen={isOpen} isCentered size="lg">
        <ModalOverlay />
        <ModalContent
          style={{ borderRadius: '0px' }}
          fontFamily={websiteContext?.fontFamily}
          fontWeight="normal">
          <ModalBody padding="30px 34px">
            <Box textAlign="center">
              <Picto
                color={websiteContext?.mainColor}
                width="60px"
                height="60px"
                icon="information"
                style={{ margin: 'auto' }}
              />
            </Box>
            <Box textAlign="center" fontSize="14px" mt="15px">
              Si vous souhaitez être livré en {redirectionData?.country} nous vous invitons à vous
              rendre sur notre site {redirectionData?.dns}
            </Box>

            <Flex justifyContent="space-between" mt={{ base: '15px', md: 'auto' }}>
              <Button
                w="auto"
                mt={{ base: '0px', md: '15px' }}
                padding={{ base: '7px 35px', md: '5px 50px' }}
                placeContent={{ base: 'start', md: 'initial' }}
                bg="#f0f0f0"
                color="#333"
                fontWeight="normal"
                _focus={{ outline: 'none' }}
                border="1px solid #ddd"
                _hover={{
                  backgroundColor: 'white',
                  color: websiteContext?.darkColor,
                  border: `1px solid ${websiteContext?.darkColor}`
                }}
                borderRadius="0"
                onClick={onClose}>
                NON
              </Button>
              <Button
                w="auto"
                mt={{ base: '0px', md: '15px' }}
                padding={{ base: '7px 35px', md: '5px 50px' }}
                bg={websiteContext?.darkColor}
                fontWeight="normal"
                _focus={{ outline: 'none' }}
                color="#fff"
                border={`1px solid ${websiteContext?.darkColor}`}
                _hover={{ backgroundColor: 'white', color: websiteContext?.darkColor }}
                onClick={() => (windowGlobal.location.href = `https://${redirectionData.dns}`)}
                borderRadius="0">
                OUI
              </Button>
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <PopoverContentChevronDown
        heightMobile="auto"
        heightDesktop="auto"
        rightGapChevron="12px"
        id="accountButtonPopover">
        {error && (
          <Alert
            status="error"
            backgroundColor="#f2dede"
            border="1px solid #ebccd1"
            mt="12px"
            p="7px 10px">
            <AlertDescription color="#a94442" fontSize="14px">
              {error}
            </AlertDescription>
          </Alert>
        )}
        {success && (
          <Alert status="success" mt="12px" p="7px 10px">
            <AlertDescription fontSize="14px">{success}</AlertDescription>
          </Alert>
        )}
        <Text
          fontWeight="700"
          fontSize="26px"
          color="#333333"
          fontFamily={websiteContext?.fontFamilyNarrow}>
          Déjà client ? Identifiez-vous
        </Text>
        <form onSubmit={doLogin}>
          <FormControl id="email" fontFamily={websiteContext?.fontFamily} isRequired>
            <Flex alignItems="center">
              <FormLabel
                textTransform="uppercase"
                fontSize="0.875rem"
                color={error ? 'red' : '#999'}
                fontWeight={error ? '900' : '700'}
                marginBottom="4px"
                marginTop="10px">
                Votre adresse e-mail
                <Box as="span" color="#c3c3c3" fontSize="11px" ml="6px">
                  ou numéro de client (sans compte web actif)
                </Box>
              </FormLabel>
              <InfoPopup />
            </Flex>
            <Input
              id="accountButtonPopoverLogin"
              ref={loginInputRef}
              type={loginType}
              value={login}
              onChange={(e) => {
                setLogin(e?.target?.value)
                setError(false)
              }}
              placeholder="Adresse e-mail ou numéro de client"
              color="#333"
              fontSize="14px"
              fontFamily={websiteContext?.fontFamily}
              _focus={{ borderColor: '#c1c1c1' }}
              border={error ? '2px solid red' : '1px solid #c1c1c1'}
              borderColor={error ? 'red' : '#c1c1c1'}
              _placeholder={{ color: '#333', opacity: '0.54' }}
            />
          </FormControl>
          <FormControl id="password" isRequired fontFamily={websiteContext?.fontFamily}>
            <FormLabel
              textTransform="uppercase"
              fontSize="0.875rem"
              color={error ? 'red' : '#999'}
              fontWeight={error ? '900' : '700'}
              marginBottom="4px"
              marginTop="10px">
              {passwordLabel}
            </FormLabel>
            <Input
              id="accountButtonPopoverPassword"
              placeholder={passwordPlaceholder}
              type={passwordType}
              value={password}
              onChange={(e) => {
                setPassword(e?.target?.value)
                setError(false)
              }}
              color="#333"
              fontSize="14px"
              fontFamily={websiteContext?.fontFamily}
              _focus={{ borderColor: '#c1c1c1' }}
              border={error ? '2px solid red' : '1px solid #c1c1c1'}
              borderColor={error ? 'red' : '#c1c1c1'}
              _placeholder={{ color: '#333', opacity: '0.54' }}
            />
          </FormControl>
          <Box textAlign="right">
            <Link
              to={`${forgottenPasswordLink}?login=${login}`}
              color="#333"
              style={{ textAlign: 'right', fontSize: '12px' }}
              underlineOnHover>
              {forgottenPasswordLabel}
            </Link>
          </Box>

          <Flex justifyContent="flex-end" mt="20px">
            <Input
              id="accountButtonPopoverDoLogin"
              type="submit"
              w={{ base: 'full', md: 'initial' }}
              borderRadius="0"
              bg={websiteContext?.mainColor}
              color="white"
              fontSize="19px"
              fontWeight="normal"
              fontFamily={websiteContext?.fontFamilyNarrow}
              value="Se connecter"
              cursor="pointer"
              _hover={{ bg: websiteContext.darkColor }}
            />
          </Flex>
        </form>
        <Flex alignItems="center">
          <Text
            fontFamily={websiteContext?.fontFamilyNarrow}
            fontSize="20px"
            fontWeight="700"
            mr="6px">
            Nouveau client ?
          </Text>
          <Link
            to="/customer/account/create"
            style={{ color: websiteContext?.mainColor, fontSize: '14px' }}>
            Je crée mon compte
          </Link>
        </Flex>
        {loading && (
          <Flex
            align="center"
            justify="center"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, .5)'
            }}>
            <Spinner color={websiteContext?.mainColor} />
          </Flex>
        )}
      </PopoverContentChevronDown>
    </>
  )
}

const ConnectedContent = ({ onClose = () => {} }) => {
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)

  // eslint-disable-next-line react/display-name
  const AccountLink = memo(({ label, link, onClick = () => {} }) => (
    <Link
      onClick={onClick}
      to={link}
      style={{ color: websiteContext.mainColor, fontSize: '14px', fontWeight: 400 }}>
      {label}
    </Link>
  ))

  const handleClick = (link) => {
    DataLayerUtils.addEvent({
      event: 'qwampEvent',
      eventCat: 'Header',
      eventAct: 'Départ lien compte client',
      eventLab: link
    })
    onClose()
  }
  return (
    <PopoverContentChevronDown heightMobile="auto" heightDesktop="auto" rightGapChevron="12px">
      <Flex direction="row" width="100%" justifyContent="space-between">
        <Text
          fontWeight="700"
          fontSize="26px"
          color="#333333"
          fontFamily={websiteContext?.fontFamilyNarrow}>
          {`Bonjour ${currentUser?.firstName?.toUpperCase()}`}
        </Text>
        <LogoutButton />
      </Flex>
      <Flex direction="column">
        <CardsExpiryAlert />
        {AccountMenu.MENU_ITEMS?.map((item, i) => (
          <AccountLink
            key={`menu_item${i}`}
            label={item?.label}
            link={item?.link}
            onClick={() => handleClick(item?.link)}
          />
        ))}
        {currentUser?.customFields?.loyaltyStatus && (
          <LoyaltyStatus loyaltyEndDate={currentUser?.customFields?.loyaltyEndDate} />
        )}
      </Flex>
    </PopoverContentChevronDown>
  )
}

const LoyaltyStatus = ({ loyaltyEndDate }) => {
  const websiteContext = useContext(WebsiteContext)
  return (
    <Flex direction="row" mt="16px" pt="16px" w="full" borderTop="1px solid #cfd5e3">
      <Flex w="100px" pr="16px" alignItems="center">
        <GatsbyImage
          image={websiteContext?.loyaltyCardImage?.image?.childImageSharp?.gatsbyImageData}
          alt="Carte de fidélité"
          loading="eager"
        />
      </Flex>
      <Flex direction="column" color="#999999" justifyContent="center">
        <Box fontSize="14px">Vous disposez de la carte {websiteContext?.title}</Box>
        <Box fontSize="14px">Expire le {dayjs(loyaltyEndDate).format('DD/MM/YYYY')}</Box>
      </Flex>
    </Flex>
  )
}

const _CardsExpiryAlert = () => {
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)
  const [alert, setAlert] = useState()

  const getData = async () => {
    const cardsMetadata = get(currentUser, 'customFields.cardsMetadata', [])

    if (cardsMetadata) {
      const cards = cardsMetadata.map((c) => JSON.parse(c))

      const expired = getExpiredCards(cards, 1)
      if (!isEmpty(expired)) {
        setAlert({ status: 'error', message: `L'une des cartes bancaires arrive à expiration` })
      }
    }
  }

  useEffect(() => {
    getData()
  }, [])

  return (
    <>
      {alert && (
        <Flex
          py="5px"
          my="10px"
          direction="row"
          width="100%"
          align="center"
          justifyContent="space-between"
          borderBottom="1px solid #C53030"
          borderTop="1px solid #C53030">
          <Flex justifyContent="center" mr="3px">
            <Picto color="#C53030" width="24px" height="24px" icon="warning" />
            <Text ml="5px" fontSize="14px" fontFamily={websiteContext?.fontFamily} color="red.600">
              {alert.message}
            </Text>
          </Flex>
          <Box justifyContent="center" mr="3px" lineHeight="1">
            <Flex justifyContent="space-between" w="100%">
              <Image src={cb} w="32px" />
              <Image src={mastercard} w="32px" />
              <Image src={visa} w="32px" />
            </Flex>
            <Link
              to="/customer/cards"
              style={{
                color: websiteContext.mainColor,
                fontSize: '14px',
                fontWeight: 400
              }}>
              Modifier mes cartes
            </Link>
          </Box>
        </Flex>
      )}
    </>
  )
}

const CardsExpiryAlert = memo(_CardsExpiryAlert)

export default AccountButton
