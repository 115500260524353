import './cart.css'

import { Button, Flex, GatsbyImage, Link, Picto, Text } from '@stars-ecom/shared-atoms-ui'
import { navigate } from 'gatsby'
import React, { useContext } from 'react'

import { CartContext, DataLayerUtils, UserContext, WebsiteContext } from '../context'
import AddToCartPopinPrice from '../product/price/AddToCartPopinPrice'
import ProductSlide from '../product/ProductSlide'
import { OfferUtils } from '../utils'

const AddToCartPopin = (props) => {
  const { product, productVariant, onClose, loyaltyCardImage } = props
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const websiteContext = useContext(WebsiteContext)

  const goToCheckout = () => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'Pages produit',
      eventAct: 'Popin ajout panier',
      eventLab: 'Départ panier'
    })
    navigate(`/checkout/onepage?previousUrl=/${product.slug}`)
  }

  const upSells =
    product?.customFields?.upSell?.filter(
      (p) =>
        OfferUtils.getCurrentProductOffer(
          p,
          'UPSELL',
          process.env.GATSBY_API_CHANNEL_ID,
          currentUser
        )?.price > 0
    ) || []

  const dataLayerFunction = (event = {}) => {
    DataLayerUtils.addEvent({
      event: 'qwampEventLab',
      eventCat: 'ProductPage',
      eventAct: `Défiler slider upsell`,
      ...event
    })
  }

  return (
    <Flex direction="column" p={{ base: '15px', md: '35px 35px 15px' }}>
      <Flex
        direction="row"
        align="center"
        m={{ base: '15px', md: currentCart?.totalQuantity === 10 ? '0' : '0 0 15px' }}
        justify={{ base: 'center', md: 'flex-start' }}>
        <Picto icon="checkCercle" width="22px" height="22px" color={websiteContext?.mainColor} />
        <Text
          w={{ base: '55%', md: '100%' }}
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize={{ base: '15px', md: '18px' }}
          textAlign={{ base: 'center', md: 'left' }}
          ml="10px">
          Votre article a <strong>bien été ajouté au panier.</strong>
        </Text>
      </Flex>
      {currentCart?.totalQuantity === 10 && (
        <Flex w={{ base: '70%', md: '100%' }} textAlign="center" m="0 auto 15px">
          <Text
            className="maxProductMention"
            fontFamily="PT Sans, Arial, sans-serif"
            fontSize={{ base: '15px', md: '18px' }}>
            Vous avez atteint le nombre maximal de produits dans votre panier
          </Text>
        </Flex>
      )}
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        width="100%"
        borderTop="1px"
        borderTopStyle="solid"
        borderTopColor="#000"
        align={{ base: 'center', md: 'flex-start' }}
        justify="space-between"
        pt="16px">
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          <Flex width="180px" height="auto">
            {product?.featuredAsset?.image && (
              <GatsbyImage
                image={product?.featuredAsset?.image?.childImageSharp?.gatsbyImageData}
                loading="eager"
                alt={product?.name}
                title={product?.name}
              />
            )}
          </Flex>
          <Text
            as="h2"
            m={{ base: '16px 0', md: '0 0 0 20px' }}
            maxWidth="350px"
            fontSize="15px"
            fontWeight={700}
            color="#242A30"
            textAlign="center"
            fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif">
            {productVariant?.name}
          </Text>
        </Flex>
        <AddToCartPopinPrice width="100px" product={product} productVariant={productVariant} />
      </Flex>
      <Flex
        ml="auto"
        mr="auto"
        mt="20px"
        w={{ base: '100%', md: 'auto' }}
        flexDirection={{ base: 'column-reverse', md: 'row' }}>
        {currentCart?.totalQuantity !== 10 && (
          <Button
            variant="unstyled"
            w={{ base: '100%', md: '217px' }}
            m={{ base: '8px 0', md: '0 6px' }}
            h="auto"
            p="7px"
            fontSize="14px"
            fontWeight="normal"
            fontFamily="PT Sans, Arial, sans-serif"
            lineHeight="21px"
            borderRadius="none"
            border="#c0c0c0"
            borderStyle="solid"
            borderWidth="1px"
            textTransform="uppercase"
            _hover={{ color: '#fff', bg: websiteContext?.mainColor }}
            onClick={() => {
              DataLayerUtils.addEvent({
                event: 'qwampEventLab',
                eventCat: 'Pages produit',
                eventAct: 'Popin ajout panier',
                eventLab: 'Continuer mon shopping'
              })
              onClose()
            }}>
            Continuer mon shopping
          </Button>
        )}
        <Button
          w={{ base: '100%', md: '217px' }}
          m={{ base: '0', md: '0 6px' }}
          h="auto"
          color="#fff"
          bg={websiteContext?.mainColor}
          p="7px"
          fontSize="14px"
          fontFamily="PT Sans, Arial, sans-serif"
          fontWeight="normal"
          lineHeight="21px"
          borderRadius="none"
          _hover={{
            border: '#c0c0c0',
            borderStyle: 'solid',
            borderWidth: '1px',
            color: '#000',
            bg: '#fff'
          }}
          textTransform="uppercase"
          onClick={goToCheckout}
          id="popinGoToCart">
          Voir mon panier
        </Button>
      </Flex>
      {currentCart?.totalQuantity !== 10 &&
        (upSells?.length > 0 ? (
          <Flex
            direction="column"
            backgroundColor="#eef4ff"
            align="center"
            mt="15px"
            pb={product?.customFields?.upSell?.length < 4 ? '0' : { md: '15px' }}>
            <Text as="h2" fontSize="18px" fontWeight="700" color="#444" p="10px">
              Pour aller avec ...
            </Text>
            <ProductSlide
              listName="Popin ajout panier"
              products={upSells}
              isUpsell={true}
              maxSlidesToShow={3}
              arrowColor="#444"
              dataLayerFunction={dataLayerFunction}
            />
          </Flex>
        ) : (
          <Flex
            direction="column"
            backgroundColor="#eef5ff"
            w="100%"
            mt="15px"
            align="center"
            p={{ base: '8px', md: '20px' }}>
            <Flex direction="row" align="center" justify="center">
              <Flex ml="5px">
                <GatsbyImage
                  style={{ width: '70px', height: 'auto' }}
                  image={loyaltyCardImage?.image?.childImageSharp?.gatsbyImageData}
                  alt="Fidélité"
                />
              </Flex>
              <Text
                ml="20px"
                fontSize="18px"
                fontFamily="PT Sans, arial, sans-serif"
                color="#444"
                fontWeight={700}>
                Comment bénéficier des{' '}
                <span style={{ color: '#fe8c2c' }}>«&nbsp;Prix fidélité&nbsp;» ?</span>
              </Text>
            </Flex>
            <Flex
              direction={{ base: 'column', md: 'row' }}
              align="center"
              justify="center"
              color="#444"
              mt="20px">
              <Flex
                direction="column"
                w={{ md: '50%' }}
                lineHeight="18px"
                borderRight={{ md: '1px solid #444' }}
                borderBottom={{ base: '1px solid #444', md: 'none' }}
                p={{ base: '10px 0', md: '0 20px' }}>
                <Text
                  fontFamily="PT Sans, Arial, sans-serif"
                  fontSize="16px"
                  fontWeight={700}
                  textAlign="center">
                  Vous avez déjà la carte de fidélité ?
                </Text>
                <Text fontFamily="PT Sans, Arial, sans-serif" fontSize="14px" textAlign="center">
                  Le prix fidélité sera automatiquement affiché dans votre panier, après
                  identification.
                </Text>
              </Flex>
              <Flex
                direction="column"
                lineHeight="18px"
                w={{ md: '50%' }}
                p={{ base: '10px 0', md: '0' }}>
                <Text
                  fontFamily="PT Sans, Arial, sans-serif"
                  fontSize="16px"
                  fontWeight={700}
                  textAlign="center"
                  pl="12px"
                  pr="12px">
                  Vous souhaitez bénéficier du « Prix fidélité » ?
                </Text>
                <Text fontFamily="PT Sans, Arial, sans-serif" fontSize="14px" textAlign="center">
                  Il suffit d&acute;adhérer à la carte de fidélité {websiteContext.title}.
                  <br />
                  <Link
                    to="/fidelite"
                    target="_blank"
                    style={{
                      textDecoration: 'underline',
                      fontFamily: 'PT Sans, Arial, sans-serif'
                    }}
                    onClick={() =>
                      DataLayerUtils.addEvent({
                        event: 'qwampEventLab',
                        eventCat: 'Pages produit',
                        eventAct: 'Popin ajout panier',
                        eventLab: 'Départ infos carte fidélité'
                      })
                    }>
                    En savoir +
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Flex>
        ))}
    </Flex>
  )
}

export default AddToCartPopin
