import './popoverinfo.css'

import {
  Flex,
  Picto,
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Text
} from '@stars-ecom/shared-atoms-ui'
import React, { memo, useContext } from 'react'

import { WebsiteContext } from '../context'

const _PopoverInfo = (props) => {
  const { labelButton, color, fontSize, children, icon = 'what', showPopover = true } = props
  const websiteContext = useContext(WebsiteContext)
  return (
    <Popover placement="bottom-end" disabled preventOverflow={false} flip={false} arrowSize={12}>
      <PopoverTrigger>
        <Flex
          flexDirection="row"
          w="100%"
          cursor="pointer"
          justify="flex-end"
          pointerEvents={showPopover ? 'auto' : 'none'}>
          <Text
            color={color}
            fontSize={fontSize}
            fontFamily="PT Sans, Arial, Helvetica"
            _hover={{ textDecoration: 'underline' }}>
            {labelButton}
          </Text>
          {showPopover && (
            <Picto icon={icon} width={fontSize} color={color} style={{ marginLeft: '6px' }} />
          )}
        </Flex>
      </PopoverTrigger>
      <PopoverContent
        p="10px"
        borderRadius="0"
        borderColor={websiteContext?.mainColor}
        bg="#fff"
        style={{ width: '330px', marginRight: '-30px', boxShadow: 'none' }}>
        <PopoverArrow
          border="1px"
          borderColor={`${websiteContext?.mainColor} #fff #fff ${websiteContext?.mainColor}`}
          marginLeft="60px"
          marginTop="-.5px"
        />
        <PopoverCloseButton style={{ marginTop: '4px' }} _hover={{ bg: 'transparent' }} />
        {children}
      </PopoverContent>
    </Popover>
  )
}

const PopoverInfo = memo(_PopoverInfo)

export default PopoverInfo
