import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Text,
  useState
} from '@stars-ecom/shared-atoms-ui'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import React, { memo, useContext, useEffect } from 'react'

import { WebsiteContext } from '../../context'

const CardEditForm = (props) => {
  const { paymentCard, onClose = () => {}, onSave = () => {} } = props
  const [isDisabled, setIsDisabled] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isDefault, setIsDefault] = useState(Boolean(get(paymentCard, 'isDefault', false)))
  const [label, setLabel] = useState(`${get(paymentCard, 'label', '')}`)
  const websiteContext = useContext(WebsiteContext)

  useEffect(() => {
    setIsDisabled(isEmpty(label))
  }, [label, isDefault])

  const update = async () => {
    setIsLoading(true)
    try {
      await onSave({ ...paymentCard, isDefault, label })
    } catch (e) {
      console.error(e)
    }
    setIsLoading(false)
  }

  const formatCardNumber = (value) => {
    const regex = /^([0-9Xx]{0,4})([0-9Xx]{0,4})([0-9Xx]{0,4})(\d{0,4})$/g

    return value.replace(regex, (regex, $1, $2, $3, $4) =>
      [$1, $2, $3, $4].filter((group) => !!group).join(' ')
    )
  }

  return (
    <>
      {isLoading ? (
        <Flex width="100%" height="200" align="center" justify="center">
          <CircularProgress isIndeterminate color={websiteContext?.mainColor} />
        </Flex>
      ) : (
        <>
          <Box w="100%" py="20px" px="40px" alignItems="center">
            <Box w={['100%', '550px']}>
              <Text
                as="h3"
                fontFamily={websiteContext?.fontFamilyNarrow}
                fontSize="26px"
                color="#333333"
                fontWeight={700}
                mb="20px">
                Editer la carte
              </Text>

              <FormControl display="flex" justifyContent="space-between" pt="20px">
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999999"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  NOM DE LA CARTE
                </FormLabel>
                <Box w="55%" h="40px">
                  <Input
                    value={label}
                    backgroundColor="white"
                    borderRadius="0px"
                    fontFamily={websiteContext.fontFamily}
                    fontSize="14px"
                    fontWeight={700}
                    color="#999999"
                    py="5px"
                    px="10px"
                    onChange={(e) => setLabel(e.target.value)}
                  />
                </Box>
              </FormControl>

              <FormControl display="flex" justifyContent="space-between" pt="20px">
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999999"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                  NUMÉRO DE LA CARTE
                </FormLabel>
                <Box w="55%" h="40px">
                  <Text
                    textTransform="uppercase"
                    fontFamily={websiteContext.fontFamily}
                    fontSize="14px"
                    fontWeight={700}
                    color="#999999"
                    mb="5px">
                    {formatCardNumber(`${get(paymentCard, 'pan')}`)}
                  </Text>
                </Box>
              </FormControl>

              <FormControl display="flex" justifyContent="space-between" pt="20px">
                <FormLabel
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999999"
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>{`DATE D'EXPIRATION`}</FormLabel>
                <Box w="55%" h="40px">
                  <Text
                    mb="5px"
                    fontFamily={websiteContext.fontFamily}
                    fontSize="14px"
                    fontWeight={700}
                    color="#999999">
                    {`${get(paymentCard, 'card_expiry_month')}/${get(
                      paymentCard,
                      'card_expiry_year'
                    )}`}
                  </Text>
                </Box>
              </FormControl>

              <Flex direction="row" width="100%" align="center" pt="20px" mb="20px">
                <Text
                  mr="65px"
                  fontFamily={websiteContext.fontFamily}
                  fontSize="14px"
                  fontWeight={700}
                  color="#999999">
                  CARTE BANCAIRE PAR DÉFAUT
                </Text>
                <Checkbox
                  value={isDefault}
                  isChecked={isDefault}
                  color="#ffffff"
                  backgroundColorChecked={websiteContext?.mainColor}
                  onChange={(e) => setIsDefault(e.target.checked)}
                />
              </Flex>
            </Box>
            <Flex
              direction="row"
              width="100%"
              justifyContent="space-between"
              align="center"
              mt="50px"
              mb="10px">
              <Button
                onClick={onClose}
                variant="solid"
                style={{
                  borderRadius: 0,
                  color: '#ffffff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  fontSize: '19px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  fontWeight: 400,
                  fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif',
                  boxShadow: 'none'
                }}
                backgroundColor="#c1c1c1"
                _hover={{ backgroundColor: '#c1c1c1' }}>
                Retour
              </Button>
              <Button
                onClick={update}
                variant="solid"
                style={{
                  borderRadius: 0,
                  color: '#ffffff',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '40px',
                  fontSize: '19px',
                  paddingLeft: '30px',
                  paddingRight: '30px',
                  fontWeight: 400,
                  fontFamily: 'PT Sans Narrow, PT Sans,Arial,sans-serif',
                  boxShadow: 'none'
                }}
                backgroundColor={websiteContext.mainColor}
                _hover={{ backgroundColor: websiteContext.darkColor }}
                disabled={isDisabled}>
                Sauvegarder
              </Button>
            </Flex>
          </Box>
        </>
      )}
    </>
  )
}
export default memo(CardEditForm)
