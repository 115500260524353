import {
  Box,
  Button,
  ChakraIcons,
  CircularProgress,
  ErrorModal,
  Flex,
  GatsbyImage,
  Link,
  Picto,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Portal,
  QantityInput,
  Select,
  Text,
  useBreakpointValue,
  useDisclosure,
  useState
} from '@stars-ecom/shared-atoms-ui'
// import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import React, { useContext, useEffect, useRef } from 'react'

import { MaxProductModal } from '../cart'
import { ApiContext, CartContext, DataLayerUtils, UserContext, WebsiteContext } from '../context'
import { priceFormat } from '../utils'

const windowGlobal = typeof window !== 'undefined' && window
// -- !! Start to delete -> use same component PopoverContentChevronDown as AccountButton !! --
const PopoverContentChevronDown = ({
  heightMobile = '100%',
  heightDesktop = '100%',
  rightGapChevron = '0px',
  ...props
}) => {
  const contentHeightBreakpoint = useBreakpointValue({ base: heightMobile, md: heightDesktop })
  const transition = {
    exit: {
      opacity: 0,
      height: 0,
      transition: {
        duration: 0.3
      }
    },
    enter: {
      height: contentHeightBreakpoint,
      opacity: 1,
      transition: {
        duration: 0.3
      }
    }
  }
  const websiteContext = useContext(WebsiteContext)

  return (
    <PopoverContent
      id="cartButtonPopover"
      w={{ base: '100vw', md: '482px' }}
      overflow="hidden"
      border="none"
      borderRadius="0"
      variants={transition}
      style={{
        boxShadow: 'none'
      }}>
      <Flex
        direction="column"
        p="20px"
        bg="#fff"
        w="full"
        border="1px"
        borderColor="#cfd5e3"
        borderTop="4px"
        borderTopColor={websiteContext?.mainColor}
        _before={{
          position: 'absolute',
          display: 'block',
          content: `""`,
          border: '25px solid transparent',
          right: rightGapChevron,
          top: 0,
          borderTopColor: websiteContext?.mainColor
        }}
        _after={{
          position: 'absolute',
          display: 'block',
          content: `""`,
          border: '25px solid transparent',
          right: rightGapChevron,
          top: '-6px',
          borderTopColor: '#fff'
        }}
        {...props}
      />
    </PopoverContent>
  )
}

const ProductCardInPopoverCart = ({ line, setIsLoading = () => {} }) => {
  const [qntProduct, setQntProduct] = useState(line?.quantity)
  const [loading, setLoading] = useState(false)
  const [isRemoved, setIsRemoved] = useState(false)
  const apiContext = useContext(ApiContext)
  const websiteContext = useContext(WebsiteContext)
  const currentCart = useContext(CartContext)

  const {
    isOpen: isOpenOrderLimitErrorModal,
    onOpen: onOpenOrderLimitErrorModal,
    onClose: onCloseOrderLimitErrorModal
  } = useDisclosure()

  const {
    isOpen: isOpenErrorModal,
    onOpen: onOpenErrorModal,
    onClose: onCloseErrorModal
  } = useDisclosure()

  const handleQuantityChange = async (newQuantity) => {
    newQuantity = parseInt(newQuantity)

    if (newQuantity <= 0) {
      setIsLoading(true)
      /*DataLayerUtils.addEvent({
        event: 'santianoEcommerce',
        eventCommand: 'cartProductRemoved',
        currencyCode: 'EUR', // Code de la devise
        productList: [
          {
            productName: line?.productVariant?.name, // string - Nom du produit
            productId: line?.productVariant?.customFields?.externalCode, // string - SKU
            productCodeOffre: line?.customFields?.offerCode, // string - Code offre du produit
            productUnitPrice: line?.priceWithTax / 100, // floating - Prix du produit TTC
            promoDiscount: line?.customFields?.discount, // string - Pourcentage de remise du prix, si il y a une remise, sinon laisser vide
            productQuantity: line.quantity // quantité retirée du panier
          }
        ]
      })*/
      apiContext?.OrderApi?.removeOrderLine(line?.id)
      DataLayerUtils.removeFromCart({
        orderCode: currentCart?.code,
        product: line?.productVariant?.product,
        productVariant: line?.productVariant,
        stockLevel: line?.customFields?.stockLevel,
        quantity: line?.quantity,
        offerType: line?.customFields?.type
      })
      setIsRemoved(true)
    } else {
      setIsLoading(true)
      setLoading(true)
      const result = await apiContext?.OrderApi?.adjustOrderLine(line?.id, newQuantity)
      if (result && result?.__typename === 'OrderLimitError') {
        onOpenOrderLimitErrorModal()
      } else if (!result || result?.__typename !== 'Order') {
        onOpenErrorModal()
      } else {
        if (newQuantity > line.quantity) {
          DataLayerUtils.addToCart({
            orderCode: currentCart?.code,
            product: line?.productVariant?.product,
            productVariant: line?.productVariant,
            stockLevel: line?.customFields?.stockLevel,
            quantity: newQuantity - line?.quantity,
            offerType: line?.customFields?.type
          })
        } else if (newQuantity < line.quantity) {
          DataLayerUtils.removeFromCart({
            orderCode: currentCart?.code,
            product: line?.productVariant?.product,
            productVariant: line?.productVariant,
            stockLevel: line?.customFields?.stockLevel,
            quantity: line?.quantity - newQuantity,
            offerType: line?.customFields?.type
          })
        }
      }
      setLoading(false)
    }
  }

  useEffect(() => {
    if (qntProduct !== line?.quantity) {
      handleQuantityChange(qntProduct)
    }
  }, [qntProduct])

  useEffect(() => {
    setIsRemoved(false)
    setQntProduct(line?.quantity)
  }, [line])

  if (isRemoved) {
    return null
  }

  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      position="relative"
      w="full"
      justifyContent="space-between"
      alignItems={{ base: 'flex-start', md: 'center' }}
      borderBottom="1px"
      borderColor="#f4f2f3"
      p="15px">
      <Flex flexDirection="row" alignItems={'center'}>
        {!isNil(line?.featuredAsset?.image?.childImageSharp?.gatsbyImageData) && (
          <GatsbyImage
            image={line?.featuredAsset?.image?.childImageSharp?.gatsbyImageData}
            loading="eager"
            alt={line?.productVariant?.name}
            style={{ width: '100px' }}
          />
        )}
        <Flex flexDirection={{ base: 'column', md: 'initial' }} ml="12px">
          <Link to={`/${line?.productVariant?.product?.customFields?.urlKey}`}>
            <Text
              fontSize="13px"
              color="#000"
              fontFamily="PT Sans,Arial,sans-serif"
              w={{ base: 'full', md: '160px' }}
              _hover={{ textDecoration: 'underline' }}>
              {line?.productVariant?.name}
            </Text>
          </Link>
          {websiteContext?.isMobile && (
            <Button
              className="cartButtonRemoveOrderLine"
              onClick={() => handleQuantityChange(0)}
              isDisabled={loading}
              variant="link"
              textDecoration="underline"
              color="#999"
              p="0"
              justifyContent="flex-start"
              fontSize="14px">
              Supprimer cet article
            </Button>
          )}
        </Flex>
      </Flex>
      <Flex flexDirection={{ base: 'row', md: 'initial' }} w={{ base: 'full', md: 'initial' }}>
        {websiteContext?.isMobile && (
          <Select
            mt="15px"
            maxW="65px"
            mr="15px"
            iconSize="12px"
            icon={<ChakraIcons.ArrowUpDownIcon />}
            defaultValue={line?.quantity}
            onChange={(e) => {
              handleQuantityChange(e.target.value)
            }}>
            {[...Array((line?.quantity || 0) + 5)].map((e, i) => {
              return (
                <option value={i} key={i}>
                  {i}
                </option>
              )
            })}
          </Select>
        )}
        <Text
          w={{ base: 'full', md: '70px' }}
          mt={{ base: '15px', md: '0' }}
          fontSize={{ base: '24px', md: '14px' }}
          color="#000"
          fontWeight="700"
          fontFamily={{
            base: 'PT Sans Narrow, Arial,sans-serif',
            md: 'PT Sans, Arial,sans-serif'
          }}>
          {priceFormat(line?.linePriceWithTax, 'EUR')}
        </Text>
      </Flex>

      {!websiteContext?.isMobile && (
        <>
          <QantityInput onChange={setQntProduct} value={qntProduct} isLoading={loading} />

          <Button variant="link" onClick={() => handleQuantityChange(0)} isDisabled={loading}>
            <Flex
              border="1px"
              borderColor="#999"
              borderRadius="50%"
              w="26px"
              h="26px"
              justifyContent="center"
              alignItems="center">
              <ChakraIcons.SmallCloseIcon boxSize="16px" color="#999" />
            </Flex>
          </Button>
        </>
      )}
      {loading && (
        <Flex
          w="100%"
          h="100%"
          position="absolute"
          ml="-20px"
          align="center"
          justify="center"
          background="rgba(0,0,0,0.1)">
          <CircularProgress isIndeterminate={true} color={websiteContext?.mainColor} />
        </Flex>
      )}
      <MaxProductModal
        isOpen={isOpenOrderLimitErrorModal}
        onOpen={onOpenOrderLimitErrorModal}
        onClose={onCloseOrderLimitErrorModal}
      />
      <ErrorModal
        isOpen={isOpenErrorModal}
        onOpen={onOpenErrorModal}
        onClose={onCloseErrorModal}
        icon="informationCercle"
        message="Une erreur est survenue lors de l'ajout au panier!"
        color={websiteContext?.mainColor}
      />
    </Flex>
  )
}

const CartButton = (props) => {
  const {
    link = `/checkout/onepage?previousUrl=` + windowGlobal?.location?.pathname,
    onClickOutside,
    onCloseCart,
    onOpenCart,
    isOpenCart
  } = props
  const websiteContext = useContext(WebsiteContext)
  const currentCart = useContext(CartContext)
  const currentUser = useContext(UserContext)
  const [loading, setLoading] = useState(false)
  const [isLocked, setIsLocked] = useState(false)
  useEffect(() => {
    setLoading(false)
  }, [currentCart])

  const buttonPopoverRef = useRef()
  const popoverRef = useRef()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonPopoverRef.current && !buttonPopoverRef.current.contains(event.target)) {
        if (popoverRef.current && !popoverRef.current.contains(event.target)) {
          onClickOutside && onClickOutside()
          setIsLocked(false)
          onCloseCart()
        }
      }
    }
    document.addEventListener('click', handleClickOutside, true)
    return () => {
      document.removeEventListener('click', handleClickOutside, true)
    }
  }, [onClickOutside])

  const handleClick = () => {
    if (isOpenCart && isLocked) {
      onCloseCart()
      setIsLocked(false)
    } else {
      onOpenCart()
      setIsLocked(true)
    }
  }

  const LoyaltyStatus = (props) => {
    const { message } = props
    const websiteContext = useContext(WebsiteContext)
    return (
      <Flex direction="row" p="20px 0" w="full" backgroundColor="#f7ddbd" mt="10px">
        <Flex w="20%" alignItems="flex-start" justify="center">
          <Flex w="50px">
            <GatsbyImage
              image={websiteContext?.loyaltyCardImage?.image?.childImageSharp?.gatsbyImageData}
              alt="Carte de fidélité"
              loading="eager"
            />
          </Flex>
        </Flex>
        <Flex direction="column" justifyContent="center" w="80%" pr="30px">
          <Box fontSize="14px" fontFamily="PT Sans">
            {message}
          </Box>
        </Flex>
      </Flex>
    )
  }

  return (
    <Popover
      isOpen={isOpenCart}
      trigger={!currentCart || isEmpty(currentCart?.lines) ? 'click' : isLocked ? 'click' : 'hover'}
      onClose={onCloseCart}
      onOpen={onOpenCart}
      strategy="fixed"
      autoFocus
      offset={[-200, 10]}>
      <PopoverTrigger placement="bottom">
        <Button
          id="cartButton"
          ref={isLocked ? buttonPopoverRef : null}
          variant="unstyled"
          style={{
            border: 'none',
            boxShadow: 'none',
            flexDirection: 'column',
            backgroundColor: '#ffffff',
            alignItems: 'center',
            display: 'flex',
            width: '40px',
            height: 'auto'
          }}
          position="relative"
          onClick={handleClick}>
          <Text
            position="absolute"
            top="10px"
            fontWeight="700"
            fontSize="12px"
            color={isOpenCart ? websiteContext?.mainColor : '#333333'}>
            {currentCart?.customFields?.totalProductQuantity || 0}
          </Text>
          <Picto
            icon="bag"
            width="32px"
            height="32px"
            color={isOpenCart ? websiteContext?.mainColor : '#333333'}
          />
          <Text
            display={{ base: 'none', md: 'block' }}
            fontSize="12px"
            fontFamily="PT Sans, Arial, sans-serif"
            fontWeight="normal"
            mt="1px">
            Panier
          </Text>
          {!(currentCart || loading) && (
            <Flex
              w="100%"
              h="100%"
              position="absolute"
              justify="center"
              align="center"
              background="rgba(0,0,0,0)"
              pb="12px">
              <CircularProgress
                isIndeterminate={true}
                size="10px"
                color={websiteContext?.mainColor}
              />
            </Flex>
          )}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContentChevronDown rightGapChevron="12px" p="0" pt="20px" pb="20px">
          {currentUser?.customFields?.loyaltyStatus && (
            <LoyaltyStatus
              message={`Vous bénéficiez de tous les avantages liés à la carte de Fidélité ${websiteContext?.title}.`}
            />
          )}
          {!currentCart || isEmpty(currentCart?.lines) ? (
            <Text
              ref={popoverRef}
              ml="20px"
              my="20px"
              fontSize="14px"
              color="#333"
              fontFamily="PT Sans, Arial,sans-serif">
              Il n&apos;y a aucun objet dans votre panier.
            </Text>
          ) : (
            <Flex
              w="full"
              justifyContent="center"
              direction="column"
              ref={isLocked ? popoverRef : null}>
              <Flex direction="column" marginBottom="50px" overflowY="auto" maxH="240px">
                {currentUser?.customFields?.loyaltyStatus ||
                  (currentCart?.lines?.find(
                    (l) => l.productVariant?.product?.customFields?.productType === 'FID'
                  ) && (
                    <LoyaltyStatus
                      message={
                        currentUser?.customFields?.loyaltyIsRenew
                          ? `Vous bénéficiez de nouveau de tous les avantages liés à la carte de Fidélité ${websiteContext?.title}`
                          : `Vous avez choisi de bénéficier des avantages fidélité. Si vous détenez déjà la carte, vous
                  serez automatiquement reconnu lors de votre identification. Si vous ne la détenez pas,
                  vous aurez la possibilité de la supprimer de votre panier après identification.`
                      }
                    />
                  ))}
                {currentCart?.lines
                  ?.filter((l) => l.productVariant?.product?.customFields?.productType === 'PDT')
                  .map((line) => (
                    <ProductCardInPopoverCart
                      key={`cart_line_${line?.id}`}
                      line={line}
                      setIsLoading={setLoading}
                    />
                  ))}
              </Flex>
              <Flex
                mb="30px"
                p="15px"
                w="full"
                justifyContent="space-between"
                color={websiteContext?.mainColor}
                fontWeight="700"
                fontSize="18px"
                fontFamily="PT Sans, Arial,sans-serif">
                <Text>Total de mes produits</Text>
                <Text>{priceFormat(currentCart?.customFields?.totalProductPrice, 'EUR')}</Text>
              </Flex>
              <Link
                to={link}
                style={{ textAlign: 'center' }}
                onClick={() => {
                  onCloseCart()
                  DataLayerUtils.addEvent({
                    event: 'qwampEvent',
                    eventCat: 'Header',
                    eventAct: 'Départ panier'
                  })
                }}>
                <Button
                  w="initial"
                  borderRadius="0"
                  bg={websiteContext?.mainColor}
                  color="white"
                  fontSize="19px"
                  fontWeight="normal"
                  fontFamily="PT Sans Narrow ,PT Sans, Arial,sans-serif"
                  _hover={{ bg: websiteContext.darkColor }}
                  _focus={{ border: 'none' }}>
                  Valider mon panier
                </Button>
              </Link>
            </Flex>
          )}
        </PopoverContentChevronDown>
      </Portal>
    </Popover>
  )
}

export default CartButton
