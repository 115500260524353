import './loyaltyContent.css'

import { Box, Flex, Link, Svg, Text } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

const BoxPicto = (props) => {
  const { title, subtitle, link, textButton, content, picto, bgColor } = props

  return (
    <Box
      w={{ base: '100%', md: '490px' }}
      bgColor="#fff"
      pl={{ base: '15px', md: '26px' }}
      pr={{ base: '15px', md: '26px' }}
      pt="18px"
      pb="18px"
      mb={{ base: '15px', md: '30px' }}
      className="loyalty-box">
      <Flex align="center">
        <Flex
          align="center"
          justify="space-around"
          w={{ base: '30vw', md: '135px' }}
          h={{ base: '30vw', md: '135px' }}
          bgColor={bgColor}
          color="#fff">
          <Flex width={{ base: '60%', md: '100px' }} height="auto">
            <Svg color="#fff" data={picto} width="100%" height="100%" />
          </Flex>
        </Flex>
        <Flex flexDirection="column" w="65%">
          <Text
            fontSize={{ base: '25px', md: '39px' }}
            fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
            textTransform="uppercase"
            pl="20px"
            fontWeight="bold"
            lineHeight="1">
            {title}
          </Text>
          <Text
            fontSize={{ base: '14px', md: '14px' }}
            fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif"
            pl="22px">
            {subtitle}
          </Text>
        </Flex>
      </Flex>

      <Box borderTop="1px solid #333" mt="20px" pt="15px">
        <Box
          fontFamily="PT Sans, Arial, sans-serif"
          fontSize={{ base: '13px', md: '14px' }}
          mb="7px"
          dangerouslySetInnerHTML={{ __html: content }}></Box>
        <Link href={link}>
          <Box
            display={link ? '' : 'none'}
            w={{ base: '100%', md: '40%' }}
            // bgColor={bgColor}
            bg="pink"
            color="#fff"
            textAlign="center"
            padding="5px 30px"
            mt={{ base: '15px', md: '0px' }}
            aria-label="En savoir plus">
            {textButton}
          </Box>
        </Link>
      </Box>
    </Box>
  )
}

const LoyaltyContent = (props) => {
  const { loyaltyPage } = props

  return (
    <Flex
      w={{ base: '100%', md: '1000px' }}
      m="0 auto"
      flexWrap="wrap"
      justifyContent="space-between">
      {loyaltyPage?.legalMentionTitle && (
        <Text
          as="h2"
          fontWeight="bold"
          fontSize={{ base: '16px', md: '20px' }}
          fontFamily="PT Sans, Arial, sans-serif"
          p={{ base: '8px 10px 0', md: '10px 0 0' }}>
          {loyaltyPage?.legalMentionTitle}
        </Text>
      )}
      {loyaltyPage?.legalMentionText && (
        <Box
          fontSize={{ base: '10px', md: '12px' }}
          fontFamily="PT Sans, Arial, sans-serif"
          p={{ base: '8px 10px 0', md: '10px 0 0' }}
          w={{ base: '100%', md: '1000px' }}
          dangerouslySetInnerHTML={{ __html: loyaltyPage?.legalMentionText }}
        />
      )}
      <Flex mt="20px" direction="column">
        {loyaltyPage?.sectionBoxPicto?.map((section, i) => (
          <Box key={`section_${i}`}>
            <Box
              display={{ base: 'none', md: 'block' }}
              mt="0"
              fontWeight={700}
              fontSize="26px"
              fontFamily="PT Sans Narrow, PT Sans, Arial, sans-serif">
              {section.sectionTitle}
            </Box>
            <Flex
              w={{ base: '100%', md: '1000px' }}
              m="0 auto"
              pt={{ base: 0, md: '20px' }}
              flexWrap="wrap"
              justifyContent="space-between">
              {section?.pictoBox?.map((box, j) => {
                return (
                  <BoxPicto
                    key={`boxPicto_${i}_${j}`}
                    title={box?.title}
                    subtitle={box?.subtitle}
                    content={box?.content}
                    picto={box?.picto}
                    // bgColor={loyaltyPage?.bgColor}
                    bgColor={loyaltyPage?.secondaryColor}
                  />
                )
              })}
            </Flex>
          </Box>
        ))}
      </Flex>
    </Flex>
  )
}

export default LoyaltyContent
