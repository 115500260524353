import { Flex } from '@stars-ecom/shared-atoms-ui'
import React from 'react'

import StoreLocatorContent from './StoreLocatorContent'
import StoreLocatorHeader from './StoreLocatorHeader'

const StoreLocatorLayout = (props) => {
  const { storeLocator, location } = props

  return (
    <Flex direction="column" align="center">
      <StoreLocatorHeader storeLocator={storeLocator} location={location} />
      <StoreLocatorContent storeLocator={storeLocator} />
    </Flex>
  )
}

export default StoreLocatorLayout
